exports.clickKakaoLogin = () => {
  if (window.$cookies.get('platform') === 'android') {
    window.FloobWebViewHandler.clickKakaoLogin();
  } else if (window.$cookies.get('platform') === 'ios') {
    window.webkit.messageHandlers.FloobWeb.postMessage('clickKakaoLogin');
  }
};

exports.clickAppleLogin = () => {
  if (window.$cookies.get('platform') === 'ios') {
    window.webkit.messageHandlers.FloobWeb.postMessage('clickAppleLogin');
  }
};

exports.clickLightMode = () => {
  if (window.$cookies.get('platform') === 'ios') {
    window.webkit.messageHandlers.FloobWeb.postMessage('clickLightMode');
  }
};

exports.clickDarkMode = () => {
  if (window.$cookies.get('platform') === 'ios') {
    window.webkit.messageHandlers.FloobWeb.postMessage('clickDarkMode');
  }
};

exports.onSuccessLogin = () => {
  if (window.$cookies.get('platform') === 'android') {
    window.FloobWebViewHandler.onSuccessLogin();
  } else if (window.$cookies.get('platform') === 'ios') {
    window.webkit.messageHandlers.FloobWeb.postMessage('onSuccessLogin');
  }
};

exports.onLoggedOut = () => {
  if (window.$cookies.get('platform') === 'ios') {
    window.webkit.messageHandlers.FloobWeb.postMessage('onLoggedOut');
  }
};

exports.showToast = message => {
  if (window.$cookies.get('platform') === 'android') {
    window.FloobWebViewHandler.showToast(message);
  }
};

exports.clickCamera = () => {
  if (window.$cookies.get('platform') === 'ios') {
    window.webkit.messageHandlers.FloobWeb.postMessage('clickCamera');
  }
};

exports.openCamera = quickMode => {
  if (window.$cookies.get('platform') === 'ios') {
    if (quickMode) {
      window.webkit.messageHandlers.FloobWeb.postMessage('openCameraQuickly');
    } else {
      window.webkit.messageHandlers.FloobWeb.postMessage('openCamera');
    }
  }
};

exports.openPhotos = quickMode => {
  if (window.$cookies.get('platform') === 'ios') {
    if (quickMode) {
      window.webkit.messageHandlers.FloobWeb.postMessage('openPhotosQuickly');
    } else {
      window.webkit.messageHandlers.FloobWeb.postMessage('openPhotos');
    }
  }
};

exports.completedSendingData = () => {
  if (window.$cookies.get('platform') === 'ios') {
    window.webkit.messageHandlers.FloobWeb.postMessage('completeSendingData');
  }
};

exports.openInstagramLogin = () => {
  if (window.$cookies.get('platform') === 'ios') {
    window.webkit.messageHandlers.FloobWeb.postMessage('openInstagramLogin');
  }
};

exports.onSuccessInstagramLogin = () => {
  if (window.$cookies.get('platform') === 'ios') {
    window.webkit.messageHandlers.FloobWebInstagramLogin.postMessage(
      'onSuccessInstagramLogin'
    );
  }
};

exports.openNotificationRequest = () => {
  if (window.$cookies.get('platform') === 'ios') {
    window.webkit.messageHandlers.FloobWeb.postMessage(
      'openNotificationRequestAuthorization'
    );
  }
};

exports.getCurrentLocation = () => {
  if (window.$cookies.get('platform') === 'ios') {
    window.webkit.messageHandlers.FloobWeb.postMessage('getCurrentLocation');
  }
};

exports.openUrl = url => {
  if (window.$cookies.get('platform') === 'ios') {
    window.webkit.messageHandlers.FloobWeb.postMessage({
      action: 'openUrl',
      url: url
    });
  }
};

exports.openUrlInApp = url => {
  if (window.$cookies.get('platform') === 'ios') {
    window.webkit.messageHandlers.FloobWeb.postMessage({
      action: 'openUrlInApp',
      url: url
    });
  }
};

exports.openSettings = () => {
  if (window.$cookies.get('platform') === 'ios') {
    window.webkit.messageHandlers.FloobWeb.postMessage('openSettings');
  }
};

exports.checkNotificationDetermined = () => {
  if (window.$cookies.get('platform') === 'ios') {
    window.webkit.messageHandlers.FloobWeb.postMessage(
      'checkNotificationDetermined'
    );
  }
};

exports.requestReview = () => {
  if (window.$cookies.get('platform') === 'ios') {
    window.webkit.messageHandlers.FloobWeb.postMessage('requestReview');
  }
};
