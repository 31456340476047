<template>
  <pre style="overflow: auto; white-space: pre-wrap;">

            
1. 개인정보의 처리 목적

(‘훌룹(Floob)’ 이하 ‘Floob’) 은(는) 다음의 목적을 위하여 개인정보를 처리하고 있으며, 다음의 목적 이외의 용도로는 이용하지 않습니다.

사용자 행동 패턴 분석
2. 개인정보의 처리 및 보유 기간

① ‘Floob’ 은(는) 정보주체로부터 개인정보를 수집할 때 동의 받은 개인정보 보유․ 이용기간 또는 법령에 따른 개인정보 보유․ 이용기간 내에서 개인정보를 처리․ 보유합니다.

② 구체적인 개인정보 처리 및 보유 기간은 다음과 같습니다.

서비스 이용 기록: 5년
3. 개인정보의 제3자 제공에 관한 사항

① ‘Floob’ 은(는) 정보주체의 동의, 법률의 특별한 규정 등 개인정보 보호법 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.

② ‘Floob’ 은(는) 개인정보를 제3자에게 제공하고 있지 않습니다.

4. 개인정보처리 위탁

① ‘Floob’ 은(는) 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있지 않습니다.

② ‘Floob’ 은(는) 위탁계약 체결시 개인정보 보호법 제25조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적․관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리․감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.

③ 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.

5. 정보주체와 법정대리인의 권리·의무 및 그 행사방법 이용자는 개인정보주체로써 다음과 같은 권리를 행사할 수 있습니다.

① 정보주체는 ‘Floob’ 에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다.

개인정보 열람요구
오류 등이 있을 경우 정정 요구
삭제요구
처리정지 요구
6. 처리하는 개인정보의 항목 작성

① ‘Floob’ 은(는) 다음의 개인정보 항목을 처리하고 있습니다.

필수항목: 서비스 이용 기록
7. 개인정보의 파기(‘Floob’)은(는) 원칙적으로 개인정보 처리목적이 달성된 경우에는 지체없이 해당 개인정보를 파기합니다. 파기의 절차, 기한 및 방법은 다음과 같습니다.

파기절차 이용자가 입력한 정보는 목적 달성 후 별도의 DB에 옮겨져(종이의 경우 별도의 서류) 내부 방침 및 기타 관련 법령에 따라 일정기간 저장된 후 혹은 즉시 파기됩니다. 이 때, DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다.

파기기한 이용자의 개인정보는 개인정보의 보유기간이 경과된 경우에는 보유기간의 종료일로부터 5일 이내에, 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 5일 이내에 그 개인정보를 파기합니다.

8. 개인정보 자동 수집 장치의 설치•운영 및 거부에 관한 사항

‘Floob’은 정보주체의 이용정보를 저장하고 수시로 불러오는 ‘쿠키’를 사용하지 않습니다.

9. 개인정보 보호책임자 작성

① Bradley Pig(‘Floob’) 은(는) 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.

▶ 개인정보 보호책임자

성명: Bradley Kwon

직책: 개발자

직급: 대표

연락처: brdlykwon@gmail.com

※ 개인정보 보호 담당부서로 연결됩니다.

② 정보주체께서는 ‘Floob’ 의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. ‘Floob’ 은(는) 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.

10. 개인정보 처리방침 변경

①이 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행 7일 전부터 공지사항을 통하여 고지할 것입니다.

11. 개인정보의 안전성 확보 조치 *(‘Floob’)*은(는) 개인정보보호법 제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적/관리적 및 물리적 조치를 하고 있습니다.

내부관리계획의 수립 및 시행

개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.

개인정보에 대한 접근 제한

개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.

12. 개인정보 열람청구

① 정보주체는 개인정보 보호법 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다. ‘Floob’ 은(는) 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.

② 정보주체께서는 제1항의 열람청구 접수․처리부서 이외에, 행정안전부의 ‘개인정보보호 종합지원 포털’ 웹사이트(www.privacy.go.kr)를 통하여서도 개인정보 열람청구를 하실 수 있습니다.

▶ 행정안전부 개인정보보호 종합지원 포털 → 개인정보 민원 → 개인정보 열람등 요구 (본인확인을 위하여 아이핀(I-PIN)이 있어야 함)

13. 정보주체의 권익침해에 대한 구제방법

아래의 기관은 ‘Floob’ 와(과)는 별개의 기관으로서, ‘Floob’ 의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다.

▶ 개인정보 침해신고센터 (한국인터넷진흥원 운영)

소관업무 : 개인정보 침해사실 신고, 상담 신청
홈페이지 : privacy.kisa.or.kr
전화 : (국번없이) 118
주소 : (58324) 전남 나주시 진흥길 9(빛가람동 301-2) 3층 개인정보침해신고센터
▶ 개인정보 분쟁조정위원회

소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)
홈페이지 : www.kopico.go.kr
전화 : (국번없이) 1833-6972
주소 : (03171)서울특별시 종로구 세종대로 209 정부서울청사 4층
▶ 대검찰청 사이버범죄수사단 : 02-3480-3573 (www.spo.go.kr)

▶ 경찰청 사이버안전국 : 182 (http://cyberbureau.police.go.kr)

                </pre
  >
</template>

<script>
export default {};
</script>

<style></style>
