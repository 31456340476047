<template>
  <section>
    <v-list
      v-observe-visibility="{
        callback: onVisibilityChanged
      }"
      class="pt-5"
      three-line
    >
      <v-list-item class="px-6">
        <v-list-item-content>
          <v-list-item-title>
            <h1>로그인</h1>
          </v-list-item-title>
          <v-list-item-subtitle class="mt-2"
            >{{
              `간편 로그인과 함께 Floob 회원가입에 동의합니다. ${randomedEmoji}`
            }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-container class="mb-10 px-0">
          <v-row v-if="isIOS" no-gutters
            ><v-col class="py-1">
              <v-btn
                block
                depressed
                large
                color="black"
                dark
                :ripple="$store.state.isDisplayTouch"
                @click="onClickLogin('apple')"
              >
                <v-avatar size="18" tile class="mr-3">
                  <img
                    src="https://image.floob.co.kr/apple-login-icon.png"
                    alt="Apple로 로그인"
                  />
                </v-avatar>
                <b style="color:white">Apple로 로그인</b>
              </v-btn>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col class="py-1">
              <v-btn
                block
                depressed
                large
                color="#f4eb31"
                :ripple="$store.state.isDisplayTouch"
                @click="onClickLogin('kakao')"
                :loading="isLoading"
                dark
              >
                <v-avatar size="24px" tile class="mr-3">
                  <img
                    src="https://image.floob.co.kr/kakao-login-icon.png"
                    alt="카카오로 로그인"
                  />
                </v-avatar>
                <b style="color:black">카카오로 로그인</b>
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="false && !$cookies.get('webview')" no-gutters>
            <v-col class="py-1">
              <v-btn
                block
                depressed
                large
                color="rgb(27 118 242)"
                :ripple="$store.state.isDisplayTouch"
                dark
                @click="onClickLogin('facebook')"
              >
                <v-avatar size="21px" tile class="mr-3">
                  <img
                    src="https://floob.blob.core.windows.net/image/facebook-login-icon.png"
                    alt="페이스북으로 로그인"
                  />
                </v-avatar>
                <b style="color:white">Facebook으로 로그인</b>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-list-item>
    </v-list>

    <floob-user-nickname-update-dialog
      :dialog.sync="nicknameUpdateDialog"
      v-on:updated="onUpdatedUserNickname"
    ></floob-user-nickname-update-dialog>
  </section>
</template>

<script>
import _ from 'lodash';

import api from '@/api';
import wvc from '@/util/webViewController';

import floobUserNicknameUpdateDialog from '@/components/userNicknameUpdateDialog.vue';

var vm;
let kakaoAuthSettings = {
  success: auth => {
    let kakaoAccessToken = auth.access_token;
    return api.auth
      .verifyKakaoAuth(kakaoAccessToken)
      .then(verified => {
        if (verified.isVerified === true) {
          return api.auth
            .refreshAccessTokenByAtuhTicket(verified.ticket._id)
            .then(accessToken => {
              window.$cookies.set(
                'access_token',
                accessToken,
                Infinity,
                '/',
                location.hostname.replace('www', '')
              );

              return api.user.getUserBySession().then(user => {
                if (user.nickname) {
                  vm.onSuccessLogin(user);
                } else {
                  vm.nicknameUpdateDialog.show(user);

                  window.Floob._analytics.logEvent('signup', {
                    user_id: user._id,
                    user_name: user.nickname
                  });
                }
                return;
              });
            });
        } else {
          throw new Error('Unverified kakao auth.');
        }
      })
      .finally(() => {
        vm.isLoading = false;
      });
  },
  fail: err => {
    vm.isLoading = false;
    console.error('Kakao login error occured.', err);
  }
};

export default {
  components: {
    floobUserNicknameUpdateDialog
  },
  data() {
    return {
      // controlls
      isLoading: false,

      // modules
      nicknameUpdateDialog: null,

      // datas
      randomedEmoji: null,
      emojis: ['😃', '😆', '😍', '😛', '🤗', '😉', '🤩'],
      loginType: null
    };
  },
  methods: {
    onSuccessLogin(user) {
      this.$store.dispatch('set', { key: 'user', value: { ...user } });
      if (this.$cookies.get('webview')) {
        wvc.onSuccessLogin();
      }

      this.$emit('success', user);
    },
    onFailLogin() {
      this.$emit('fail', new Error('Login fail!!'));
    },
    onClickLogin(type) {
      this.loginType = type;
      window.Floob._analytics.logEvent(`login`, {
        methods: type
      });
      if (!this.isLoading) {
        if (type === 'kakao') {
          if (window.$cookies.get('webview')) {
            wvc.clickKakaoLogin();
          } else {
            this.isLoading = true;

            window.Kakao.Auth.login(kakaoAuthSettings);
          }
        } else if (
          type === 'apple' &&
          window.$cookies.get('webview') &&
          window.$cookies.get('platform') === 'ios'
        ) {
          wvc.clickAppleLogin();
        } else if (type === 'facebook') {
          window.FB.login(response => {
            // handle the response
            console.log(
              'facebook accessToken.',
              response,
              response.authResponse.accessToken
            );

            api.auth
              .verifyFacebookAuth(
                response.authResponse.userID,
                response.authResponse.accessToken
              )
              .then(verified => {
                if (verified.isVerified === true) {
                  return api.auth
                    .refreshAccessTokenByAtuhTicket(verified.ticket._id)
                    .then(accessToken => {
                      window.$cookies.set(
                        'access_token',
                        accessToken,
                        Infinity,
                        '/',
                        location.hostname.replace('www', '')
                      );

                      api.user.getUserBySession().then(user => {
                        console.log('getUserBySession', user);
                        if (user.nickname) {
                          this.onSuccessLogin(user);
                        } else {
                          this.nicknameUpdateDialog.show(user);

                          window.Floob._analytics.logEvent('signup', {
                            user_id: user._id,
                            user_name: user.nickname
                          });
                        }
                      });
                    });
                } else {
                  throw new Error('Unverified facebook auth.');
                }
              });
          });
        }
      }
    },
    onVisibilityChanged(isVisible) {
      if (isVisible) {
        this.randomedEmoji = _.shuffle(this.emojis)[0];
      }
    },
    onUpdatedUserNickname(user) {
      this.onSuccessLogin(user);
    }
  },
  computed: {
    isIOS() {
      return (
        this.$cookies.get('webview') && this.$cookies.get('platform') === 'ios'
      );
    }
  },
  created() {
    vm = this;
    if (this.$cookies.get('webview')) {
      const verifyKakaoAuth = kakaoAccessToken => {
        api.auth.verifyKakaoAuth(kakaoAccessToken).then(verified => {
          if (verified.isVerified === true) {
            return api.auth
              .refreshAccessTokenByAtuhTicket(verified.ticket._id)
              .then(accessToken => {
                window.$cookies.set(
                  'access_token',
                  accessToken,
                  Infinity,
                  '/',
                  location.hostname.replace('www', '')
                );

                api.user.getUserBySession().then(user => {
                  if (user.nickname) {
                    this.onSuccessLogin(user);
                  } else {
                    this.nicknameUpdateDialog.show(user);
                  }
                });
              });
          } else {
            throw new Error('Unverified kakao auth.');
          }
        });
      };
      const verifyAppleAuth = (appleUserId, userName, email) => {
        api.auth
          .verifyAppleAuth(appleUserId, userName, email)
          .then(verified => {
            if (verified.isVerified === true) {
              return api.auth
                .refreshAccessTokenByAtuhTicket(verified.ticket._id)
                .then(accessToken => {
                  window.$cookies.set(
                    'access_token',
                    accessToken,
                    Infinity,
                    '/',
                    location.hostname.replace('www', '')
                  );

                  api.user.getUserBySession().then(user => {
                    if (user.nickname) {
                      this.onSuccessLogin(user);
                    } else {
                      this.nicknameUpdateDialog.show(user);
                    }
                  });
                });
            } else {
              throw new Error('Unverified apple auth.');
            }
          });
      };

      if (!window.Floob._webview.verifyKakaoAuth) {
        window.Floob._webview.verifyKakaoAuth = verifyKakaoAuth;
      }
      if (!window.Floob._webview.verifyAppleAuth) {
        window.Floob._webview.verifyAppleAuth = verifyAppleAuth;
      }
    }
  }
};
</script>

<style></style>
