<template>
  <section>
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xs"
      transition="slide-x-reverse-transition"
      max-width="400px"
      persistent
      scrollable
    >
      <v-card
        :tile="$vuetify.breakpoint.xs"
        :class="{ 'floob-dialog-card': !$vuetify.breakpoint.xs }"
        height="800"
      >
        <v-card-title class="floob-toolbar-title">
          <v-btn
            text
            x-large
            @click="onClickClose"
            :ripple="$store.state.isDisplayTouch"
            class="floob-toolbar-button-left"
          >
            <v-icon>mdi-chevron-left</v-icon></v-btn
          >
          <v-toolbar-title><h1>사용자 검색</h1> </v-toolbar-title>
          <v-btn
            text
            x-large
            :ripple="$store.state.isDisplayTouch"
            :loading="isLoading"
            class="pr-0"
            disabled
          >
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-title class="px-0 pb-0">
          <v-container fluid class="py-0">
            <v-row no-gutters>
              <v-col>
                <v-list class="pb-0">
                  <v-list-item class="px-1"
                    ><v-list-item-content class="pt-0">
                      <v-text-field
                        v-model="searchKeyword"
                        solo-inverted
                        flat
                        :autofocus="false"
                        hide-details
                        label="검색 할 아이디 또는 이름을 입력해주십시오"
                        color="black"
                      >
                      </v-text-field>
                    </v-list-item-content>
                  </v-list-item>

                  <v-subheader v-if="searchKeyword" class="pl-2"
                    ><b>{{
                      `검색 결과 (${searchedUsers.length}건)`
                    }}</b></v-subheader
                  >
                </v-list>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-container fluid class="pt-0">
            <v-row no-gutters>
              <v-col>
                <v-list class="pt-0">
                  <v-list-item
                    v-for="user in searchedUserAndFriends"
                    :key="user._id"
                    :ripple="$store.state.isDisplayTouch"
                  >
                    <v-list-item-avatar>
                      <v-img
                        v-if="user.profileImageUrl"
                        :src="user.profileImageUrl"
                      ></v-img>
                      <v-icon v-else color="grey lighten-1" size="46"
                        >mdi-account-circle</v-icon
                      >
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-subtitle>{{
                        user.nickname
                      }}</v-list-item-subtitle>
                      <v-list-item-title>{{ user.name }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <template v-if="user.friendship">
                        <v-btn
                          v-if="user.friendship.status === 'waiting'"
                          text
                          small
                          :ripple="$store.state.isDisplayTouch"
                          disabled
                        >
                          <b>요청중</b>
                        </v-btn>
                        <v-btn
                          v-else-if="user.friendship.status === 'connected'"
                          text
                          small
                          :ripple="$store.state.isDisplayTouch"
                          disabled
                        >
                          <b>친구됨</b>
                        </v-btn>
                      </template>
                      <template v-else>
                        <v-btn
                          depressed
                          small
                          :ripple="$store.state.isDisplayTouch"
                          @click="onClickRequest(user)"
                        >
                          <b>친구 요청</b>
                        </v-btn></template
                      >
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions> </v-card-actions>
      </v-card>
    </v-dialog>
    <floob-message-snackbar :dialog.sync="messageSnackbar">
    </floob-message-snackbar>
    <floob-message-dialog :dialog.sync="messageDialog"> </floob-message-dialog>
  </section>
</template>

<script>
import _ from 'lodash';

import api from '@/api';

import floobMessageSnackbar from '@/components/messageSnackbar.vue';
import floobMessageDialog from '@/components/messageDialog.vue';

export default {
  components: {
    floobMessageSnackbar,
    floobMessageDialog
  },
  props: {
    friendships: Array
  },
  data() {
    return {
      // controlls
      isVisible: false,
      isLoading: false,

      // datas
      searchKeyword: null,
      searchedUsers: [],

      // modules
      messageSnackbar: null,
      messageDialog: null,

      timer: null
    };
  },
  methods: {
    init() {
      this.isLoading = false;
      this.searchKeyword = '';
      this.searchedUsers = [];
    },
    onClickClose() {
      this.init();
      this.isVisible = false;
    },
    updateSelectableFriends() {
      let keyword = this.searchKeyword.replace(/ /gi, '');
      if (keyword && keyword.length >= 1) {
        api.user
          .getUsersByConditions(keyword, keyword, 5)
          .then(users => {
            api.friendship.getFriendships().then(friendships => {
              this.$emit('update:friendships', friendships);
            });

            this.searchedUsers = _.orderBy(
              _.reject(users, u => {
                return u._id === this.$store.state.user._id;
              }),
              ['nickname'],
              ['asc']
            );
          })
          .finally(() => {
            this.timer = null;
            this.isLoading = false;
          });
      } else {
        this.searchedUsers = [];
        this.timer = null;
        this.isLoading = false;
      }
    },
    onClickRequest(user) {
      this.messageDialog.confirm(
        `${user.nickname}님이 요청을 수락하면 친구로 등록 완료 됩니다.`,
        '친구 요청하시겠습니까?',
        () => {
          this.createFriendship(user);
        }
      );
    },
    createFriendship(user) {
      api.friendship.createFriendship(user._id).then(created => {
        this.$emit('created', created);
      });
    }
  },
  watch: {
    searchKeyword() {
      if (!this.isLoading && !this.timer) {
        this.isLoading = true;
        this.timer = setTimeout(() => {
          this.updateSelectableFriends();
        }, 500);
      }
    }
  },
  computed: {
    searchedUserAndFriends() {
      return _.map(this.searchedUsers, u => {
        let friendship = _.find(this.friendships, fs => {
          return (
            (fs.requester._id === this.$store.state.user._id &&
              fs.acceptor._id === u._id) ||
            (fs.acceptor._id === this.$store.state.user._id &&
              fs.requester._id === u._id)
          );
        });

        if (friendship) {
          if (friendship.requester._id === this.$store.state.user._id) {
            friendship.type = 'requested';
          } else {
            friendship.type = 'recieved';
          }
          u.friendship = friendship;
        }

        return u;
      });
    }
  },
  created() {
    this.init();
    this.$emit('update:dialog', {
      show: () => {
        api.friendship.getFriendships().then(friendships => {
          this.$emit('update:friendships', friendships);
        });

        this.isVisible = true;
      },
      hide: () => {
        this.init();
        this.isVisible = false;
      }
    });
  }
};
</script>

<style></style>
