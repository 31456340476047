<template>
  <section>
    <v-app-bar
      v-if="!hideHeader"
      app
      fixed
      flat
      :color="$vuetify.theme.dark ? null : 'white'"
      v-bind:class="{ 'floob-header': isScrolled && !$vuetify.theme.dark }"
    >
      <v-toolbar-title>
        <h1 class="px-2" @click="scrollTop">
          타임라인
        </h1>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        v-if="false"
        text
        small
        class="px-0"
        :ripple="$store.state.isDisplayTouch"
        @click="onClickCreateMeal"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-btn
        v-if="isLoggedIn"
        text
        small
        class="px-0"
        :ripple="$store.state.isDisplayTouch"
        @click="onClickCreateMealQuickly"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-app-bar>

    <v-sheet
      class="floob-tab-item"
      :height="$route.name === 'app' ? frameHeight : null"
      v-scroll.self="onScroll"
      id="floobTimelineFrameSheet"
    >
      <v-container
        fluid
        id="floob-timeline-content"
        class="py-0"
        v-bind:style="`min-height:${frameHeight + 50}px;`"
      >
        <template v-if="isLoggedIn">
          <v-row v-if="isVisibleReload" no-gutters>
            <v-col>
              <v-btn
                block
                text
                color="grey"
                :ripple="$store.state.isDisplayTouch"
                ><v-icon>mdi-refresh</v-icon></v-btn
              >
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-container fluid class="pa-0">
                <template v-if="meals.length > 0">
                  <v-row v-for="date in groupedDates" :key="date">
                    <v-col>
                      <v-subheader class="pl-4" style="height:24px">
                        {{ date }}
                      </v-subheader>
                      <v-divider></v-divider>
                      <v-list>
                        <template v-for="meal in groupedMealsByDate[date]">
                          <floob-timeline-meal-list-item
                            :id="`floob-timeline-meal-${meal._id}`"
                            :meal="meal"
                            :key="meal._id"
                            v-on:duplicated="onDuplicatedMeal"
                          >
                          </floob-timeline-meal-list-item>
                        </template>
                      </v-list>
                    </v-col>
                  </v-row>
                </template>

                <v-row v-if="isReloading" no-gutters>
                  <v-col
                    cols="12"
                    class="py-0"
                    v-for="idx in Array.from(Array(5).keys())"
                    :key="idx"
                    ><v-skeleton-loader type="table-cell"></v-skeleton-loader>
                    <v-skeleton-loader
                      type="list-item-avatar-two-line"
                    ></v-skeleton-loader>
                    <v-skeleton-loader
                      type="list-item-avatar-two-line"
                    ></v-skeleton-loader>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
          <v-row v-if="!isNoMoreMeals" no-gutters>
            <v-col class="py-4">
              <v-btn
                block
                text
                color="grey"
                :loading="isLoading"
                :ripple="$store.state.isDisplayTouch"
                @click="getMorePrevMeals"
                >더보기</v-btn
              >
            </v-col>
          </v-row>
          <v-row v-if="!isLoading" no-gutters>
            <v-col v-if="meals.length === 0">
              <v-btn
                block
                text
                color="grey"
                :ripple="$store.state.isDisplayTouch"
                @click="onClickCreateMealQuickly"
                class="pt-10 mt-10"
                >첫번째 식사 기록 만들어주세요😃</v-btn
              >
            </v-col>
            <v-col v-else-if="isNoMoreMeals">
              <v-btn
                block
                text
                color="grey"
                :ripple="$store.state.isDisplayTouch"
                >더이상 게시물이 없습니다😞</v-btn
              >
            </v-col>
          </v-row>
        </template>
        <v-row v-else no-gutters>
          <v-col>
            <v-btn
              text
              block
              :ripple="$store.state.isDisplayTouch"
              class="pt-10 mt-10"
              color="grey"
              @click="
                $store.dispatch('set', {
                  key: 'isVisibleLoginDialog',
                  value: true
                })
              "
            >
              {{ `로그인이 필요합니다${getRandomEmojiSymbol()}` }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>

    <floob-meal-creation-dialog
      :dialog.sync="mealCreationDialog"
      v-on:created="onCreatedMeal"
      v-on:updated="onUpdatedMeal"
    ></floob-meal-creation-dialog>

    <floob-meal-update-dialog
      :dialog.sync="mealUpdateDialog"
      v-on:updated="onUpdatedMeal"
    >
    </floob-meal-update-dialog>

    <floob-meal-content-selection-dialog
      :dialog.sync="mealContentSelectionDialog"
      v-on:selected="onSelectedMealContent"
    ></floob-meal-content-selection-dialog>

    <floob-message-dialog :dialog.sync="messageDialog"></floob-message-dialog>
    <floob-message-snackbar
      :dialog.sync="messageSnackbar"
    ></floob-message-snackbar>
  </section>
</template>

<script>
import _ from 'lodash';
import $ from 'jquery';
import moment from 'moment';

import api from '@/api';
import wvc from '@/util/webViewController';
import converter from '@/util/converter';
import compressor from '@/util/compressor';
import emoji from '@/util/emoji';

import floobMealCreationDialog from '@/components/mealCreationDialog.vue';
import floobMealUpdateDialog from '@/components/mealUpdateDialog.vue';
import floobTimelineMealListItem from '@/components/mealListItem.vue';
import floobMealContentSelectionDialog from '@/components/mealContentSelectionDialog.vue';

import floobMessageDialog from '@/components/messageDialog.vue';
import floobMessageSnackbar from '@/components/messageSnackbar.vue';

export default {
  props: {
    hideHeader: {
      default: false
    }
  },
  components: {
    floobMealCreationDialog,
    floobMealUpdateDialog,
    floobTimelineMealListItem,
    floobMealContentSelectionDialog,
    floobMessageDialog,
    floobMessageSnackbar
  },
  data() {
    return {
      // modules
      mealCreationDialog: null,
      mealUpdateDialog: null,
      mealContentSelectionDialog: null,
      messageDialog: null,
      messageSnackbar: null,

      // controlls
      searchCount: 10,
      isLoading: false,
      isReloading: false,
      isScrolled: false,
      isNoMoreMeals: false,
      window: {
        width: 0,
        height: 0
      },
      isQuickMode: false,
      isVisibleReload: false,

      // datas
      meals: []
    };
  },
  methods: {
    init() {
      this.meals = [];
      this.isLoading = false;
      this.isReloading = false;
      this.isScrolled = false;
      this.isNoMoreMeals = false;
      this.isVisibleReload = false;
    },
    handleResize() {
      //console.log('handleResize', e);
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    convertDetailedMealedDateString(mealedDate) {
      return moment(mealedDate).format('LLLL');
    },
    convertShortenMealDateString(mealedDate) {
      return converter.convertShortenMealDateString(mealedDate);
    },
    onClickCreateMeal() {
      this.mealCreationDialog.show();
    },
    onClickCreateMealQuickly() {
      this.mealContentSelectionDialog.show({ quickMode: true });
    },
    getRandomEmojiSymbol() {
      return emoji.getRandomEmojiSymbol();
    },
    scrollTop() {
      document
        .getElementById('floobTimelineFrameSheet')
        .scrollTo({ top: 0, behavior: 'smooth' });
    },
    scrollSmoothTo(elementId) {
      var element = document.getElementById(elementId);
      element.scrollIntoView({
        block: 'nearest',
        behavior: 'smooth'
      });
    },
    scroll(y) {
      if (!this.isLoading && y < -1) {
        if (y <= -30) {
          this.isVisibleReload = true;
        }
        if (y <= -80) {
          this.reload();
        }
      } else {
        this.isVisibleReload = false;
      }

      if (y > 0) {
        this.isScrolled = true;
      } else {
        this.isScrolled = false;
      }

      if (
        !this.isLoading &&
        !this.isNoMoreMeals &&
        y + this.frameHeight === $('#floob-timeline-content').height()
      ) {
        this.getMorePrevMeals();
      }
    },
    onScroll(e) {
      this.scroll(e.target.scrollTop);
    },
    onSelectedMealContent(meal) {
      let fileData =
        meal.files &&
        meal.files.length > 0 &&
        meal.files[0].type === 'image' &&
        meal.files[0].data
          ? meal.files[0].data
          : null;

      this.createMeal(meal, fileData);
    },
    isViewable(meal) {
      let elementId = `floob-timeline-meal-${meal._id}`;
      const listItemHighet = 56;
      if (
        document.getElementById('floobTimelineFrameSheet').scrollTop >
          document.getElementById(elementId).offsetTop + listItemHighet ||
        document.getElementById(elementId).offsetTop -
          document.getElementById('floobTimelineFrameSheet').scrollTop >
          document.getElementById('floobTimelineFrameSheet').offsetTop
      ) {
        return false;
      } else {
        return true;
      }
    },
    onDuplicatedMeal(duplicatedMeal) {
      this.onCreatedMeal(duplicatedMeal);
    },
    onCreatedMeal(createdMeal) {
      createdMeal.isRequiredFocus = true;
      this.meals.push(createdMeal);

      setTimeout(() => {
        if (!this.isViewable(createdMeal)) {
          this.scrollSmoothTo(`floob-timeline-meal-${createdMeal._id}`);
        }
        // this.messageDialog.show({
        //   title: `${this.convertShortenMealDateString(
        //     createdMeal.mealedDate
        //   )} 등록됨`,
        //   content: `식사 기록이 추가 되었습니다 😋\n자세한 정보를 등록 하시겠습니까?`,
        //   isVisibleCancelButton: false,
        //   isVisibleOkButton: false,
        //   additionalButtons: [
        //     {
        //       text: '다음에 하기',
        //       action: () => {
        //         this.messageDialog.hide();
        //       }
        //     },
        //     {
        //       text: '네',
        //       action: () => {
        //         this.mealUpdateDialog.show(createdMeal);
        //       }
        //     }
        //   ]
        // });
      }, 500);
    },
    onUpdatedMeal(updatedMeal) {
      let idx = _.findIndex(this.meals, meal => {
        return meal._id === updatedMeal._id;
      });
      this.meals.splice(idx, 1, updatedMeal);
    },
    onDeletedMeal(deletedMeal) {
      let idx = _.findIndex(this.meals, meal => {
        return meal._id === deletedMeal._id;
      });
      this.meals.splice(idx, 1);
    },
    reload() {
      this.init();
      this.isReloading = true;
      this.getMorePrevMeals();
    },
    getMorePrevMeals() {
      if (!this.isLoading && !this.isNoMoreMeals) {
        this.isLoading = true;

        setTimeout(() => {
          api.meal
            .getMealsByTimeline(
              this.searchCount,
              this.lastMealDate,
              this.lastMealId
            )
            .then(meals => {
              // console.log('getMealsByTimeline', meals);
              if (meals && meals.length > 0) {
                meals.forEach(meal => {
                  if (_.find(this.meals, { _id: meal._id })) {
                    return;
                  }
                  this.meals.push({
                    ...meal
                  });
                });
              } else {
                this.isNoMoreMeals = true;
              }
            })
            .finally(() => {
              this.isLoading = false;
              this.isReloading = false;
              this.isVisibleReload = false;
            });
        }, 1000);
      }
    },
    createMeal(meal, fileData) {
      this.mealContentSelectionDialog.set({ key: 'isLoading', value: true });
      setTimeout(() => {
        api.meal
          .createMeal(meal, converter.convertDataURItoBlob(fileData))
          .then(createdMeal => {
            if (createdMeal) {
              window.Floob._analytics.logEvent('create_meal_success', {
                id: createdMeal._id,
                quick_mode: true
              });
              this.mealContentSelectionDialog.set({
                key: 'isLoading',
                value: false
              });
              this.mealContentSelectionDialog.hide();
              this.onCreatedMeal(createdMeal);
            } else {
              this.mealContentSelectionDialog.set({
                key: 'isLoading',
                value: false
              });
              this.messageDialog.error(
                '식사기록 생성이 실패하였습니다.\n다시 시도하여 주시기 바랍니다.'
              );
            }
          });
      }, 1000);
    }
  },
  watch: {
    isLoggedIn(value) {
      if (value) {
        this.init();
        this.getMorePrevMeals();
      }
    },
    updatedMeal(value) {
      if (this.meals && this.meals.length > 0 && value) {
        let idx = _.findIndex(this.meals, meal => {
          return meal._id === value._id;
        });
        this.meals.splice(idx, 1, value);
      }
    },
    deletedMeal(value) {
      if (this.meals && this.meals.length > 0 && value) {
        let idx = _.findIndex(this.meals, meal => {
          return meal._id === value._id;
        });
        this.meals.splice(idx, 1);
      }
    }
  },
  computed: {
    updatedMeal() {
      return this.$store.state.syncUpdatedMeal;
    },
    deletedMeal() {
      return this.$store.state.syncDeletedMeal;
    },
    isIOS() {
      return (
        this.$cookies.get('webview') && this.$cookies.get('platform') === 'ios'
      );
    },
    frameHeight() {
      let headerHeight = 56;
      let bottomNavHeight = 80;
      return this.window.height - headerHeight - bottomNavHeight;
    },
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    },
    firstMealId() {
      if (this.meals && this.meals.length > 0) {
        return this.meals[0]._id;
      } else {
        return null;
      }
    },
    lastMealId() {
      if (this.meals && this.meals.length > 0) {
        return this.meals[this.meals.length - 1]._id;
      } else {
        return null;
      }
    },
    lastMealDate() {
      if (this.meals && this.meals.length > 0) {
        return this.meals[this.meals.length - 1].mealedDate;
      } else {
        return null;
      }
    },
    groupedDates() {
      return _.keys(this.groupedMealsByDate);
    },
    groupedMealsByDate() {
      if (this.meals && this.meals.length > 0) {
        return _.groupBy(_.orderBy(this.meals, ['mealedDate'], ['desc']), _ => {
          return moment(_.mealedDate).format('YYYY-MM-DD');
        });
      } else {
        return [];
      }
    }
  },
  created() {
    this.$emit('update:module', {
      showMealCreation: () => {
        this.onClickCreateMealQuickly();
      },
      scrollTop: () => {
        this.scrollTop();
      }
    });

    window.addEventListener('resize', this.handleResize);
    this.handleResize();

    if (
      this.$cookies.get('webview') &&
      this.$cookies.get('platform') === 'ios'
    ) {
      const createMealQuickly = meal => {
        wvc.completedSendingData();

        if (meal && moment(meal.mealedDate).isValid()) {
          meal.mealedDate = moment(meal.mealedDate);
        } else {
          meal.mealedDate = null;
        }

        if (meal && Array.isArray(meal.files) && meal.files.length > 0) {
          if (meal.files[0].type === 'image') {
            let dataUrl = meal.files[0].data;

            compressor
              .compressImageFromDataUrl(dataUrl)
              .then(compressedFile => {
                const fr = new FileReader();

                fr.readAsDataURL(compressedFile);

                fr.addEventListener('load', () => {
                  meal.files[0] = {
                    type: 'image',
                    url: null,
                    data: fr.result
                  };

                  let fileData =
                    meal.files &&
                    meal.files.length > 0 &&
                    meal.files[0].type === 'image' &&
                    meal.files[0].data
                      ? meal.files[0].data
                      : null;

                  this.createMeal(meal, fileData);
                });
              });
          }
        }
      };
      window.FloobWebView.createMealQuickly = createMealQuickly;
      window.Floob._webview.createMealQuickly = createMealQuickly;
    }

    this.getMorePrevMeals();
  },
  mounted() {
    // require('body-scroll-lock').disableBodyScroll(
    //   document.querySelector('#floobTimelineFrameSheet')
    // );
  }
};
</script>

<style></style>
