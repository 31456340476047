<template>
  <section>
    <v-dialog
      v-model="isVisible"
      fullscreen
      transition="slide-x-reverse-transition"
      persistent
      scrollable
    >
      <v-card tile class="floob-meal">
        <v-card-title v-if="!closable">
          <floob-header></floob-header>
        </v-card-title>

        <v-card-text class="pa-0">
          <v-container :fluid="$vuetify.breakpoint.mdAndDown" class="py-0">
            <v-row>
              <v-col
                sm="6"
                offset-sm="3"
                md="4"
                offset-md="4"
                xl="2"
                offset-xl="5"
                class="pa-0"
              >
                <template v-if="isLoading">
                  <v-skeleton-loader type="card,list-item"> </v-skeleton-loader>

                  <v-overlay :absolute="true" opacity="0">
                    <v-btn
                      v-if="closable"
                      v-bind:style="{
                        color: 'white'
                      }"
                      depressed
                      icon
                      color="rgb(39 39 39 / 90%)"
                      large
                      class="mx-2 mt-2"
                      :ripple="$store.state.isDisplayTouch"
                      @click.stop.prevent="onClickClose"
                    >
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                  </v-overlay>
                </template>
                <template v-if="!isLoading && meal">
                  <v-card v-if="meal.files && meal.files.length > 0" flat tile>
                    <!-- image -->
                    <v-row
                      v-if="meal.files[0].type === 'image'"
                      no-gutters
                      class="mb-0"
                    >
                      <v-col>
                        <v-img
                          :src="meal.files[0].data || meal.files[0].url"
                          aspect-ratio="1"
                          ref="mealContentImg"
                          class="floob-meal-food-tag-img"
                          @click="isVisibleFoodTags = !isVisibleFoodTags"
                          @load="onLoadImage"
                        >
                          <v-btn
                            v-if="isCaptureMode"
                            v-bind:style="{
                              color: 'white'
                            }"
                            depressed
                            tile
                            small
                            color="#FAC22D"
                            class="px-2"
                            :ripple="$store.state.isDisplayTouch"
                          >
                            <v-img :width="16" src="/favicon.ico"></v-img>
                            Floob
                          </v-btn>
                          <v-btn
                            v-else-if="closable"
                            v-bind:style="{
                              color: 'white'
                            }"
                            depressed
                            icon
                            color="rgb(39 39 39 / 90%)"
                            large
                            class="mx-2 mt-2"
                            :ripple="$store.state.isDisplayTouch"
                            @click.stop.prevent="onClickClose"
                          >
                            <v-icon>mdi-chevron-left</v-icon>
                          </v-btn>
                          <template v-if="isLoadedContent && isVisibleFoodTags">
                            <v-btn
                              v-for="(tag, idx) in meal.foodTags"
                              :key="idx"
                              v-bind:style="{
                                color: 'white',
                                left: `${tag.position.x *
                                  $refs.mealContentImg.$el.clientWidth}px`,
                                top: `${tag.position.y *
                                  $refs.mealContentImg.$el.clientHeight}px`
                              }"
                              depressed
                              small
                              color="rgb(39 39 39 / 90%)"
                              class="px-2"
                              :ripple="$store.state.isDisplayTouch"
                              >{{ tag.food.name }}
                            </v-btn>
                          </template>

                          <v-img
                            v-if="false"
                            v-bind:style="{
                              color: 'white',
                              left: `282px`,
                              top: `336px`
                            }"
                            :width="100"
                            contain
                            src="/img/icons/appstore_badge.png"
                          ></v-img>
                        </v-img>
                      </v-col>
                    </v-row>
                    <!-- emoji -->
                    <v-row
                      v-else-if="meal.files[0].type === 'emoji'"
                      no-gutters
                      justify="center"
                    >
                      <v-col sm="4">
                        <v-btn
                          v-if="closable"
                          icon
                          large
                          class="mx-2 mt-2"
                          :ripple="$store.state.isDisplayTouch"
                          @click.stop.prevent="onClickClose"
                        >
                          <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        <v-card flat>
                          <v-row
                            no-gutters
                            justify="center"
                            align="center"
                            style="height: 240px;"
                          >
                            <v-col class="text-center">
                              <v-img
                                :src="meal.files[0].data || meal.files[0].url"
                                contain
                                class="floob-meal-food-tag-img"
                                :max-height="120"
                              >
                              </v-img>
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-col>
                    </v-row>
                    <!-- empty -->
                    <v-row v-else no-gutters justify="center">
                      <v-col>
                        <v-btn
                          v-if="closable"
                          icon
                          large
                          class="mx-2 mt-2"
                          :ripple="$store.state.isDisplayTouch"
                          @click.stop.prevent="onClickClose"
                        >
                          <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card>

                  <v-btn
                    v-else-if="closable"
                    icon
                    large
                    class="mx-2 mt-2"
                    :ripple="$store.state.isDisplayTouch"
                    @click.stop.prevent="onClickClose"
                  >
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>

                  <v-list>
                    <v-list-item class="ml-1">
                      <v-list-item-avatar
                        v-if="meal.owner.profileImageUrl"
                        size="32"
                        class="mr-3"
                      >
                        <v-img :src="meal.owner.profileImageUrl"></v-img>
                      </v-list-item-avatar>
                      <v-list-item-content class="pa-0">
                        <v-list-item-subtitle>
                          {{ meal.owner.nickname }}
                        </v-list-item-subtitle>
                        <v-list-item-title
                          @click="
                            isVisibleDetailMealedDate = !isVisibleDetailMealedDate
                          "
                        >
                          {{
                            isVisibleDetailMealedDate
                              ? convertLongMealDateString(meal.mealedDate)
                              : convertShortenMealDateString(meal.mealedDate)
                          }}
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn
                          small
                          icon
                          color="grey"
                          :ripple="$store.state.isDisplayTouch"
                          @click.stop.prevent="onClickMoreAction"
                        >
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                  <v-divider></v-divider>
                  <v-card-text v-scroll.self="onScroll">
                    <v-list class="pt-0">
                      <v-list-item v-if="meal.title" class="pa-0">
                        <v-list-item-content class="px-0 pb-0">
                          <v-list-item-title>
                            <v-card-title class="pa-0">
                              {{ meal.title }}</v-card-title
                            >
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>

                    <v-container fluid class="pa-0">
                      <v-row
                        v-if="meal.review && meal.review.score > 0"
                        align="center"
                        class="mx-0 py-2"
                      >
                        <v-rating
                          :value="meal.review.score"
                          color="amber"
                          background-color="amber lighten-1"
                          dense
                          readonly
                          half-increments
                        ></v-rating>

                        <div
                          v-show="false"
                          class="grey--text ml-4"
                          style="font-size:1.2em;"
                        >
                          {{ meal.review.score.toFixed(1) }}
                        </div>
                      </v-row>
                    </v-container>

                    <v-container v-if="meal.desc" fluid class="px-0">
                      <v-row>
                        <v-col>
                          <div style="margin-left:-4px;">
                            <v-icon color="grey lighten-2"
                              >mdi-format-quote-open</v-icon
                            >
                          </div>
                          <div
                            class="pl-2"
                            v-html="convertMealDescHtml(meal.desc)"
                          ></div>
                        </v-col>
                      </v-row>
                    </v-container>

                    <v-container
                      fluid
                      class="px-0"
                      v-if="meal.foodTags && meal.foodTags.length > 0"
                    >
                      <v-subheader class="pl-0">
                        <b>음식 정보</b>
                      </v-subheader>
                      <v-row @click="isVisibleFoodTags = !isVisibleFoodTags">
                        <v-col class="py-0 pl-4">
                          <v-chip-group
                            v-model="foods"
                            multiple
                            active-class="primary--text"
                            column
                            class="floob-meal-food-tags"
                          >
                            <v-chip
                              small
                              v-for="(foodTag, idx) in meal.foodTags"
                              :key="idx"
                              disabled
                            >
                              {{ foodTag.food.name }}
                            </v-chip>
                          </v-chip-group>
                        </v-col>
                      </v-row>
                    </v-container>

                    <v-container
                      fluid
                      class="px-0"
                      v-if="meal.store && meal.store._id"
                    >
                      <v-subheader class="pl-0">
                        <b>식당 정보</b>
                      </v-subheader>
                      <v-row>
                        <v-col class="py-0 pl-4 pr-0">
                          <v-list dense class="pa-0">
                            <v-list-item
                              :ripple="$store.state.isDisplayTouch"
                              @click="showStorePopup(meal.store)"
                              class="px-0"
                            >
                              <v-list-item-avatar
                                size="24"
                                v-if="storeSiteContent"
                              >
                                <v-img :src="storeSiteContent.iconUrl"> </v-img>
                              </v-list-item-avatar>

                              <v-list-item-content>
                                <v-list-item-title
                                  ><b>{{
                                    meal.store.name
                                  }}</b></v-list-item-title
                                >
                                <v-list-item-subtitle>
                                  {{ meal.store.address }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                              <v-list-item-action
                                ><v-btn
                                  text
                                  :ripple="$store.state.isDisplayTouch"
                                  disabled
                                  ><v-icon>mdi-chevron-right</v-icon></v-btn
                                >
                              </v-list-item-action>
                            </v-list-item>
                          </v-list>
                        </v-col>
                      </v-row>
                    </v-container>

                    <v-container v-if="isEditable" fluid class="px-0">
                      <v-btn
                        v-if="
                          !meal.title ||
                            !meal.desc ||
                            !meal.review ||
                            meal.foodTags <= 0
                        "
                        text
                        color="grey"
                        class="pl-0"
                        disabled
                        >더 많은 정보를 추가해보세요...</v-btn
                      >
                      <v-row v-if="!meal.title || !meal.desc">
                        <v-col>
                          <v-alert text color="info" class="mb-0">
                            <b>
                              제목 및 설명 추가
                            </b>
                            <div>
                              식사의 제목을 짖고 설명을 입력하여 보다 의미있는
                              식사로 만들어보세요.
                            </div>

                            <v-divider
                              class="my-4 info"
                              style="opacity: 0.22"
                            ></v-divider>

                            <v-row align="center" no-gutters>
                              <v-spacer></v-spacer>
                              <v-col class="shrink">
                                <v-btn
                                  color="info"
                                  outlined
                                  :ripple="$store.state.isDisplayTouch"
                                  @click="mealUpdateDialog.show(meal)"
                                >
                                  수정하기
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-alert>
                        </v-col>
                      </v-row>

                      <v-row v-if="!meal.review">
                        <v-col>
                          <v-alert text color="info" class="mb-0">
                            <b>
                              식사 평가 추가
                            </b>
                            <div>
                              식사의 만족도는 어땠나요? 식사 평가를 하고
                              보고서에서 이번달 나의 식사는 어땠는지
                              확인해보세요.
                            </div>

                            <v-divider
                              class="my-4 info"
                              style="opacity: 0.22"
                            ></v-divider>

                            <v-row align="center" no-gutters>
                              <v-spacer></v-spacer>
                              <v-col class="shrink">
                                <v-btn
                                  color="info"
                                  outlined
                                  :ripple="$store.state.isDisplayTouch"
                                  @click="
                                    mealUpdateDialog.show(meal, {
                                      focus: 'review'
                                    })
                                  "
                                >
                                  평가하기
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-alert>
                        </v-col>
                      </v-row>

                      <v-row v-if="meal.foodTags <= 0">
                        <v-col>
                          <v-alert text color="info" class="mb-0">
                            <b>
                              음식 정보 추가
                            </b>
                            <div>
                              식사에 포함 된 음식들을 등록하고 보고서에서 이번달
                              내가 섭취한 음식들은 무엇인지 확인해보세요.
                            </div>

                            <v-divider
                              class="my-4 info"
                              style="opacity: 0.22"
                            ></v-divider>

                            <v-row align="center" no-gutters>
                              <v-spacer></v-spacer>
                              <v-col class="shrink">
                                <v-btn
                                  color="info"
                                  outlined
                                  :ripple="$store.state.isDisplayTouch"
                                  @click="
                                    mealUpdateDialog.show(meal, {
                                      focus: 'food'
                                    })
                                  "
                                >
                                  추가하기
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-alert>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </template>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>

      <floob-message-dialog :dialog.sync="messageDialog"></floob-message-dialog>
      <floob-message-snackbar
        :dialog.sync="messageSnackbar"
      ></floob-message-snackbar>
    </v-dialog>

    <floob-meal-update-dialog
      :dialog.sync="mealUpdateDialog"
      v-on:updated="onUpdatedMeal"
    >
    </floob-meal-update-dialog>

    <v-bottom-sheet v-model="isVisibleMoreAction">
      <v-list class="pa-2 floob-action-sheet">
        <v-list-item
          :ripple="$store.state.isDisplayTouch"
          @click="onClickShare"
        >
          <v-list-item-content>
            <v-list-item-title>공유하기 </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="isEditable"
          :ripple="$store.state.isDisplayTouch"
          @click="onClickUpdate"
        >
          <v-list-item-content>
            <v-list-item-title>수정하기 </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="isEditable"
          :ripple="$store.state.isDisplayTouch"
          @click="onClickDelete"
        >
          <v-list-item-content>
            <v-list-item-title class="red--text text--darken-2"
              >삭제
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="false"
          :ripple="$store.state.isDisplayTouch"
          @click="onClickCaptureMode"
        >
          <v-list-item-content>
            <v-list-item-title>캡쳐모드 </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-bottom-sheet>

    <floob-browser-dialog :dialog.sync="browserDialog"> </floob-browser-dialog>
  </section>
</template>

<script>
import api from '@/api';
import linkApi from '@/api/link';

import semver from 'semver';
import converter from '@/util/converter';

import wvc from '@/util/webViewController';

import copy from 'clipboard-copy';

import floobHeader from '@/components/header_v2.vue';
import floobMealUpdateDialog from '@/components/mealUpdateDialog.vue';
import floobMessageDialog from '@/components/messageDialog.vue';
import floobMessageSnackbar from '@/components/messageSnackbar.vue';
import floobBrowserDialog from '@/components/browserDialog.vue';

export default {
  props: {
    closable: {
      type: Boolean,
      default: true
    }
  },
  components: {
    floobHeader,
    floobMealUpdateDialog,
    floobMessageDialog,
    floobMessageSnackbar,
    floobBrowserDialog
  },
  data() {
    return {
      // modules
      mealUpdateDialog: null,
      messageDialog: null,
      messageSnackbar: null,
      browserDialog: null,

      // controlls
      isVisible: false,
      isLoading: false,
      isLoadedContent: false,
      isVisibleMoreAction: false,
      isCaptureMode: false,
      isScrolled: false,
      isVisibleFoodTags: false,
      isEditable: false,
      isVisibleDetailMealedDate: false,

      // datas
      meal: null,
      foods: [],
      storeSiteContent: null
    };
  },
  methods: {
    init() {
      this.meal = null;
      this.isLoading = false;
      this.isLoadedContent = false;
      this.isVisible = false;
      this.isCaptureMode = false;
      this.isScrolled = false;
      this.isVisibleFoodTags = false;
      this.isEditable = false;
      this.isVisibleDetailMealedDate = false;
      this.storeSiteContent = null;
    },
    onLoadImage() {
      this.isLoadedContent = true;
    },
    onScroll(e) {
      if (e.target.scrollTop > 0) {
        this.isScrolled = true;
      } else {
        this.isScrolled = false;
      }
    },
    onClickMoreAction() {
      this.isVisibleMoreAction = true;
    },
    onClickClose() {
      this.isVisible = false;
      if (this.$route.name === 'meal') {
        this.$router.push({ name: 'app' });
      }
    },
    convertShortenMealDateString(mealedDate) {
      return converter.convertShortenMealDateString(mealedDate);
    },
    convertLongMealDateString(mealedDate) {
      return converter.convertLongMealDateString(mealedDate);
    },
    convertMealDescHtml(desc) {
      return converter.convertTextToHtml(desc);
    },
    onClickUpdate() {
      this.mealUpdateDialog.show(this.meal);
      setTimeout(() => {
        this.isVisibleMoreAction = false;
      }, 500);
    },
    onUpdatedMeal(updatedMeal) {
      this.meal = updatedMeal;

      this.$emit('updated', updatedMeal);
    },
    onClickDelete() {
      this.isVisibleMoreAction = false;
      setTimeout(() => {
        this.messageDialog.confirm(
          '선택하신 게시물을 삭제하시겠습니까?',
          null,
          () => {
            this.deleteMeal();
          }
        );
      }, 500);
    },
    deleteMeal() {
      api.meal.deleteMeal(this.meal).then(deletedMeal => {
        this.$emit('deleted', deletedMeal);
      });
    },
    onClickCaptureMode() {
      this.isCaptureMode = !this.isCaptureMode;
    },
    onClickShare() {
      let shareLink = `https://link.floob.co.kr/meal/${this.meal._id}`;

      if (
        document.location.protocol === 'https:' &&
        navigator &&
        typeof navigator.share === 'function'
      ) {
        navigator.share({
          text: `Floob: 식사기록 인 라이프\n@${
            this.meal.owner.nickname
          }\n${this.convertShortenMealDateString(this.meal.mealedDate)}\n${this
            .meal.title || ''}`,
          url: shareLink
        });
      } else {
        copy(shareLink).then(() => {
          this.messageDialog.alert('클립보드에 링크가 복사 되었습니다.');
        });
      }
    },
    showStorePopup(store) {
      if (store) {
        if (store.serviceProvider === 'kakao-map') {
          if (this.$cookies.get('webview')) {
            if (
              semver.valid(this.$cookies.get('version')) &&
              semver.gte(this.$cookies.get('version'), '1.4.0')
            ) {
              wvc.openUrl(
                `https://place.map.kakao.com/m/${store.serviceProviderPlaceId}`
              );
            } else {
              this.browserDialog.show(
                '식당',
                'Powered by Kakao Map',
                `https://place.map.kakao.com/m/${store.serviceProviderPlaceId}`
              );
            }
          } else {
            if (this.$store.state.isMobileDevice) {
              window.open(
                `https://place.map.kakao.com/m/${store.serviceProviderPlaceId}`
              );
            } else {
              window.open(
                `https://place.map.kakao.com/${store.serviceProviderPlaceId}`
              );
            }
          }
        }
      }
    }
  },
  computed: {
    isAdmin() {
      return (
        this.$store.state.isLoggedIn &&
        this.$store.state.user.introduction &&
        this.$store.state.user.introduction.includes(`I am a floob`)
      );
    }
  },
  watch: {
    meal(value) {
      if (value) {
        if (value.foodTags && value.foodTags.length > 0) {
          this.foods = Array.from(
            {
              length: value.foodTags.length
            },
            (_, i) => i
          );
        }
      }
    }
  },
  created() {
    this.$emit('update:dialog', {
      show: meal => {
        this.init();

        this.isLoading = true;

        setTimeout(() => {
          api.meal
            .getMeal(meal._id)
            .then(meal => {
              if (meal) {
                this.meal = meal;

                if (this.meal && this.meal.store) {
                  if (
                    this.$store.state.sourceLinkCache.has(
                      this.meal.store.serviceProviderPlaceUrl
                    )
                  ) {
                    let source = this.$store.state.sourceLinkCache.get(
                      this.meal.store.serviceProviderPlaceUrl
                    );
                    this.storeSiteContent = source;
                  } else {
                    linkApi
                      .getSiteContents(this.meal.store.serviceProviderPlaceUrl)
                      .then(siteContents => {
                        this.storeSiteContent = siteContents;
                        this.$store.state.sourceLinkCache.set(
                          this.meal.store.serviceProviderPlaceUrl,
                          siteContents
                        );
                      });
                  }
                }

                if (this.meal.owner._id === this.$store.state.user._id) {
                  this.isEditable = true;
                  // this.messageSnackbar.show(
                  //   `식사의 자세한 정보를 등록해보세요 🤗\n
                  //   식사기록 보기에서 더 많은 정보가 노출 되고
                  //   보고서에서 통계 정보를 제공 받을 수 있습니다.`,
                  //   {
                  //     position: 'bottom',
                  //     timeout: -1,
                  //     delay: 300,
                  //     buttons: [
                  //       // {
                  //       //   text: '닫기',
                  //       //   action: () => {
                  //       //     this.messageSnackbar.hide();
                  //       //   }
                  //       // },
                  //       {
                  //         text: '더 많은 정보 등록하기',
                  //         action: () => {
                  //           this.mealUpdateDialog.show(this.meal);
                  //         }
                  //       }
                  //     ]
                  //   }
                  // );
                }
              } else {
                alert('존재하지 않는 게시물입니다.');

                this.init();
                this.isVisible = false;
              }
            })
            .finally(() => {
              this.isLoading = false;
            });
        }, 200);

        this.isVisible = true;
      },
      hide: () => {
        this.init();
        this.isVisible = false;
      }
    });
  }
};
</script>

<style>
.floob-meal .v-overlay__content {
  width: 100%;
  height: 100%;
}

.floob-meal-food-tags .v-chip--disabled {
  opacity: unset !important;
}
</style>
