<template>
  <section>
    <v-dialog
      v-model="isVisible"
      :persistent="isPersistent"
      max-width="300px"
      content-class="floob-dialog"
    >
      <v-card
        class="floob-dialog-card"
        flat
        v-bind:style="{
          backgroundColor: $vuetify.theme.dark ? '#2d2d2d' : null
        }"
      >
        <v-card-title class="pt-4" style="font-size:1rem; font-weight:bold;">{{
          title
        }}</v-card-title>
        <v-card-text v-html="contentHtml"> </v-card-text>
        <v-card-actions class="pb-3">
          <v-btn
            v-if="type !== 'alert' && isVisibleCancelButton"
            text
            :ripple="$store.state.isDisplayTouch"
            @click="onClickCancel"
            style="font-size: 1rem !important;"
            >취소</v-btn
          >
          <v-btn
            v-if="isVisibleCustomerServiceButton"
            text
            :ripple="$store.state.isDisplayTouch"
            color="red"
            @click="onClickCustomerServiceOpen"
            style="font-size: 1rem !important;"
          >
            오류 신고
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-for="(additionalButton, idx) in additionalButtons"
            :key="idx"
            text
            :ripple="$store.state.isDisplayTouch"
            :color="additionalButton.color || null"
            @click="
              () => {
                additionalButton.action();
                if (!additionalButton.isPersistent) {
                  isVisible = false;
                }
              }
            "
            style="font-size: 1rem !important;"
          >
            {{ additionalButton.text }}
          </v-btn>
          <v-btn
            v-if="isVisibleOkButton"
            text
            :ripple="$store.state.isDisplayTouch"
            @click="onClickOk"
            style="font-size: 1rem !important;"
            >확인</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import wvc from '@/util/webViewController';

import converter from '@/util/converter';

export default {
  data() {
    return {
      type: 'alert',
      isVisible: false,
      title: null,
      content: null,
      callback: null,
      isVisibleCancelButton: true,
      isVisibleCustomerServiceButton: false,
      isVisibleOkButton: true,
      additionalButtons: [],
      isPersistent: true
    };
  },
  methods: {
    init() {
      this.type = 'alert';
      this.title = null;
      this.content = null;
      this.callback = null;
      this.isVisibleCancelButton = true;
      this.isVisibleCustomerServiceButton = false;
      this.isVisibleOkButton = true;
      this.additionalButtons = [];
      this.isPersistent = true;
    },
    onClickOk() {
      if (this.type === 'alert') {
        this.isVisible = false;
      } else if (this.type === 'confirm') {
        if (this.callback) {
          if (typeof this.callback === 'function') {
            this.callback();
            this.isVisible = false;
          } else if (typeof this.callback.then === 'function') {
            this.callback().then(() => {
              this.isVisible = false;
            });
          }
        }
      }
    },
    onClickCancel() {
      this.isVisible = false;
    },
    onClickCustomerServiceOpen() {
      let url = `https://docs.google.com/forms/d/e/1FAIpQLSc7e2prRQI9sF2dKdAKZg9JaZiyVkErbYdqYDKVwfMuQ_yxaw/viewform?usp=pp_url&entry.485428648=${
        this.$store.state.user && this.$store.state.user.nickname
          ? this.$store.state.user.nickname
          : ''
      }&entry.1591633300=버그 신고`;
      if (this.$cookies.get('webview')) {
        wvc.openUrl(url);
      } else {
        window.open(url);
      }
    }
  },
  computed: {
    contentHtml() {
      return converter.convertTextToHtml(this.content);
    }
  },
  created() {
    this.$emit('update:dialog', {
      alert: (message, title) => {
        this.init();
        this.type = 'alert';
        this.title = title || null;
        this.content = message;
        this.isVisible = true;
      },
      confirm: (message, title, callback) => {
        this.init();
        this.type = 'confirm';
        this.title = title || null;
        this.content = message;
        this.callback = callback;
        this.isVisible = true;
      },
      error: message => {
        this.init();
        this.type = 'alert';
        this.isVisibleCustomerServiceButton = true;
        this.title = 'Floob 오류 발생 😥';
        this.content = `${message}\n\n오류가 지속 될 경우 고객센터로 연락바랍니다.`;
        this.isVisible = true;
      },
      show: dialog => {
        this.init();
        this.type = 'custom';
        this.title = dialog.title || null;
        this.content = dialog.content;
        if (typeof dialog.isPersistent === 'boolean') {
          this.isPersistent = dialog.isPersistent;
        }

        if (
          dialog.isVisibleCancelButton !== undefined ||
          dialog.isVisibleCancelButton !== null
        ) {
          this.isVisibleCancelButton = dialog.isVisibleCancelButton;
        }

        if (
          dialog.isVisibleOkButton !== undefined ||
          dialog.isVisibleOkButton !== null
        ) {
          this.isVisibleOkButton = dialog.isVisibleOkButton;
        }

        if (
          Array.isArray(dialog.additionalButtons) &&
          dialog.additionalButtons.length > 0
        ) {
          this.additionalButtons = dialog.additionalButtons;
        }

        this.isVisible = true;
      },
      hide: () => {
        this.isVisible = false;
      }
    });
  }
};
</script>
