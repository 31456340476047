<template>
  <section>
    <v-container v-if="meal" fluid>
      <v-row v-if="!$vuetify.breakpoint.xs" no-gutters justify="center">
        <v-col v-if="meal.files && meal.files.length > 0" sm="4">
          <v-card v-if="meal.files[0].type === 'image'" flat>
            <v-img
              @click="onClickUpdateMealFile"
              :src="meal.files[0].data || meal.files[0].url"
              aspect-ratio="1"
            >
            </v-img>
          </v-card>

          <v-card flat v-else-if="meal.files[0].type === 'emoji'">
            <v-row
              no-gutters
              justify="center"
              align="center"
              style="height: 240px;"
            >
              <v-col class="text-center">
                <v-img
                  :src="meal.files[0].data || meal.files[0].url"
                  contain
                  class="floob-meal-food-tag-img"
                  :max-height="120"
                  @click="onClickUpdateMealFile"
                >
                </v-img>
              </v-col>
            </v-row>
          </v-card>
        </v-col>

        <v-col v-else sm="4">
          <v-card flat>
            <v-row no-gutters justify="center">
              <v-col class="text-center">
                <v-img @click="onClickUpdateMealFile">
                  <v-icon size="180">mdi-image</v-icon>
                </v-img>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <v-list two-line>
            <v-list-item>
              <v-list-item-avatar
                v-if="$vuetify.breakpoint.xs"
                rounded
                @click="onClickUpdateMealFile"
              >
                <template v-if="meal.files && meal.files.length > 0">
                  <v-img
                    v-if="meal.files[0].type === 'image'"
                    :src="meal.files[0].data || meal.files[0].url"
                  >
                  </v-img>

                  <v-img
                    v-else-if="meal.files[0].type === 'emoji'"
                    :src="meal.files[0].url"
                    contain
                    :height="32"
                  ></v-img>
                </template>
                <v-icon v-else size="58">mdi-image</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <template v-if="isVisibleMealTitleInput">
                  <v-text-field
                    v-model="meal.title"
                    solo-inverted
                    flat
                    :autofocus="false"
                    hide-details
                    ref="mealTitleInput"
                    @blur="updateMealTitle"
                  >
                  </v-text-field>
                </template>
                <template v-else-if="isVisibleMealedDateInput">
                  <v-text-field
                    v-model="mealedDateString"
                    type="datetime-local"
                    solo-inverted
                    flat
                    :autofocus="false"
                    hide-details
                    ref="mealedDateInput"
                    @blur="updateMealMelaedDate"
                  >
                  </v-text-field>
                </template>
                <template v-else>
                  <v-list-item-title
                    v-if="meal.title"
                    @click="onClickUpdateMealTitle"
                  >
                    {{ meal.title }}
                  </v-list-item-title>
                  <v-list-item-title
                    v-else
                    @click="onClickUpdateMealTitle"
                    style="color:grey"
                  >
                    식사 제목을 입력해주세요
                  </v-list-item-title>
                  <v-list-item-subtitle @click="onClickUpdateMealedDate">
                    {{ shortenMealDateString }}
                  </v-list-item-subtitle>
                </template>
              </v-list-item-content>
            </v-list-item>

            <v-subheader>
              설명
            </v-subheader>

            <v-list-item style="margin-top: -14px;">
              <v-textarea
                v-model="meal.desc"
                solo-inverted
                flat
                :autofocus="false"
                no-resize
                hide-details
                :rows="descRows"
                :readonly="!isEditable"
                @blur="updateMealDesc"
                label="이 식사에 대한 설명을 입력해주세요"
              ></v-textarea>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>

      <v-row no-gutters class="pt-4">
        <v-col>
          <v-btn
            v-if="meal._id && !isVisibleMoreMealDetail"
            text
            :ripple="$store.state.isDisplayTouch"
            color="grey"
            @click="isVisibleMoreMealDetail = true"
            >더 많은 정보 등록하기</v-btn
          >
        </v-col>
      </v-row>

      <!-- 식사 평가 -->
      <v-row
        v-if="meal.review || isVisibleMoreMealDetail || isFocusReview"
        no-gutters
      >
        <v-col>
          <v-list>
            <v-list-item>
              <v-list-item-content class="pb-0">
                <v-list-item-title>
                  <h1>식사 평가</h1>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-rating
              v-model="reviewScore"
              half-increments
              color="amber"
              background-color="amber lighten-1"
              large
              :ripple="$store.state.isDisplayTouch"
            ></v-rating>
          </v-list>
        </v-col>
      </v-row>

      <!-- 음식 정보 -->
      <v-row
        v-if="
          (meal.foodTags && meal.foodTags.length > 0) ||
            isVisibleMoreMealDetail ||
            isFocusFood
        "
        no-gutters
      >
        <v-col>
          <v-list>
            <v-list-item>
              <v-list-item-content class="pb-0">
                <v-list-item-title>
                  <h1>음식 정보</h1>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="meal.foodTags && meal.foodTags.length > 0"
              class="pl-2 pr-0"
            >
              <v-list-item-avatar class="mr-0">
                <v-btn icon :loading="isLoadingUpdateFood"
                  ><v-icon color="primary">mdi-check</v-icon>
                </v-btn>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <b v-if="meal.foodTags.length === 1" class="pl-1">{{
                    `${meal.foodTags[0].food.name}`
                  }}</b>
                  <b v-else-if="meal.foodTags.length > 1" class="pl-1">{{
                    `${meal.foodTags[0].food.name} 외 ${meal.foodTags.length -
                      1}개`
                  }}</b
                  ><v-btn
                    :ripple="$store.state.isDisplayTouch"
                    icon
                    x-small
                    @click="onClickRemoveFood()"
                    ><v-icon x-small>mdi-close</v-icon></v-btn
                  ></v-list-item-title
                >
                <v-list-item-subtitle
                  v-if="getCalorieSum(meal.foodTags)"
                  class="pl-1"
                  >{{
                    `섭취량 ${getCalorieSum(meal.foodTags)
                      .toFixed(2)
                      .toLocaleString()}kcal`
                  }}</v-list-item-subtitle
                >
              </v-list-item-content>
              <v-list-item-action
                v-if="isMealContentType === 'image'"
                :ripple="$store.state.isDisplayTouch"
                @click="mealFoodTagDialog.show(meal)"
              >
                <v-btn text :ripple="$store.state.isDisplayTouch" disabled
                  ><v-icon>mdi-chevron-right</v-icon></v-btn
                >
              </v-list-item-action>
              <v-list-item-action
                v-else
                :ripple="$store.state.isDisplayTouch"
                @click="foodSelectionDialog.show()"
              >
                <v-btn text :ripple="$store.state.isDisplayTouch" disabled
                  ><v-icon>mdi-chevron-right</v-icon></v-btn
                >
              </v-list-item-action>
            </v-list-item>
            <template v-else>
              <v-list-item
                v-if="isMealContentType === 'image'"
                :ripple="$store.state.isDisplayTouch"
                class="pr-1"
                @click="mealFoodTagDialog.show(meal)"
              >
                <v-list-item-content>
                  <v-list-item-subtitle>
                    음식 태그하기
                  </v-list-item-subtitle></v-list-item-content
                ><v-list-item-action>
                  <v-btn text disabled
                    ><v-icon>mdi-chevron-right</v-icon></v-btn
                  >
                </v-list-item-action>
              </v-list-item>
              <v-list-item
                v-else
                :ripple="$store.state.isDisplayTouch"
                class="pr-1"
                @click="foodSelectionDialog.show()"
              >
                <v-list-item-content>
                  <v-list-item-subtitle>
                    음식 선택하기
                  </v-list-item-subtitle></v-list-item-content
                ><v-list-item-action>
                  <v-btn text disabled
                    ><v-icon>mdi-chevron-right</v-icon></v-btn
                  >
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col>
          <v-list>
            <v-list-item>
              <v-list-item-content class="pb-0">
                <v-list-item-title>
                  <h1>식당 정보</h1>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="meal.store" class="pl-2 pr-0">
              <v-list-item-avatar class="mr-0">
                <v-btn icon :loading="isLoadingUpdateStore"
                  ><v-icon color="primary">mdi-check</v-icon></v-btn
                >
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <b class="pl-1" @click="onClickShowStore(meal.store)">{{
                    `${meal.store.name}`
                  }}</b
                  ><v-btn
                    :ripple="$store.state.isDisplayTouch"
                    icon
                    x-small
                    @click="onClickRemoveStore()"
                    ><v-icon x-small>mdi-close</v-icon></v-btn
                  ></v-list-item-title
                >
              </v-list-item-content>
              <v-list-item-action
                :ripple="$store.state.isDisplayTouch"
                @click="storeSelectionDialog.show()"
              >
                <v-btn text :ripple="$store.state.isDisplayTouch" disabled
                  ><v-icon>mdi-chevron-right</v-icon></v-btn
                >
              </v-list-item-action>
            </v-list-item>
            <v-list-item
              v-else
              :ripple="$store.state.isDisplayTouch"
              class="pr-1"
              @click="storeSelectionDialog.show()"
            >
              <v-list-item-content>
                <v-list-item-subtitle>
                  식당 선택하기
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn text disabled><v-icon>mdi-chevron-right</v-icon></v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>

      <v-divider class="mt-4"> </v-divider>
      <v-row no-gutters>
        <v-col>
          <v-list>
            <v-list-item
              class="pt-2 pr-2"
              :ripple="$store.state.isDisplayTouch"
            >
              <v-list-item-content>
                <v-list-item-title> <h1>스토리 포함</h1></v-list-item-title>
                <v-list-item-subtitle
                  >이 식사를 내 스토리 게시물에 노출합니다</v-list-item-subtitle
                >
              </v-list-item-content>
              <v-list-item-action>
                <v-switch
                  v-model="meal.isStory"
                  color="success"
                  inset
                  :ripple="$store.state.isDisplayTouch"
                  :disabled="isLoadingUpdateStory"
                >
                </v-switch>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-container>

    <floob-meal-content-selection-dialog
      :dialog.sync="mealContentSelectionDialog"
      :meal="meal"
      v-on:selected="onSelectedMealContnet"
    ></floob-meal-content-selection-dialog>

    <floob-food-selection-dialog
      :dialog.sync="foodSelectionDialog"
      v-on:selected="onSelectedFood"
    >
    </floob-food-selection-dialog>

    <floob-meal-food-tag-dialog
      :dialog.sync="mealFoodTagDialog"
      v-on:selected="onSelectedFoodTags"
    >
    </floob-meal-food-tag-dialog>

    <floob-store-selection-dialog
      :module.sync="storeSelectionDialog"
      v-on:selected="onSelectedStore"
    >
    </floob-store-selection-dialog>

    <floob-message-dialog :dialog.sync="messageDialog"></floob-message-dialog>
    <floob-browser-dialog :dialog.sync="browserDialog"> </floob-browser-dialog>
  </section>
</template>

<script>
import _ from 'lodash';
import semver from 'semver';
import moment from 'moment';

import wvc from '@/util/webViewController';

import api from '@/api';
import converter from '@/util/converter';

import floobMealContentSelectionDialog from '@/components/mealContentSelectionDialog.vue';
import floobFoodSelectionDialog from '@/components/foodSelectionDialog.vue';
import floobMealFoodTagDialog from '@/components/mealFoodTagDialog.vue';
import floobStoreSelectionDialog from '@/components/storeSelectionDialog.vue';
import floobMessageDialog from '@/components/messageDialog.vue';

import floobBrowserDialog from '@/components/browserDialog.vue';

export default {
  props: {
    meal: Object,
    isEditable: {
      type: Boolean,
      default: false
    },
    isFocusReview: {
      type: Boolean,
      default: false
    },
    isFocusFood: {
      type: Boolean,
      default: false
    },
    isDetailed: {
      type: Boolean,
      default: false
    }
  },
  components: {
    floobMealContentSelectionDialog,
    floobFoodSelectionDialog,
    floobMealFoodTagDialog,
    floobStoreSelectionDialog,
    floobMessageDialog,
    floobBrowserDialog
  },
  data() {
    return {
      // controlls
      isLoading: false,
      isLoadingUpdateFood: false,
      isLoadingUpdateStore: false,
      isLoadingUpdateStory: false,
      isVisibleMealTitleInput: false,
      isVisibleMealedDateInput: false,
      isVisibleDetailMealedDate: false,
      isVisibleMoreMealDetail: true,

      // modules
      mealContentSelectionDialog: null,
      foodSelectionDialog: null,
      mealFoodTagDialog: null,
      storeSelectionDialog: null,
      messageDialog: null,
      browserDialog: null
    };
  },
  methods: {
    init() {
      this.isLoading = false;
      this.isLoadingUpdateFood = false;
      this.isLoadingUpdateStore = false;
      this.isLoadingUpdateStory = false;
      this.isVisibleMealTitleInput = false;
      this.isVisibleMealedDateInput = false;
      this.isVisibleDetailMealedDate = false;
      this.isVisibleMoreMealDetail = true;

      if (!moment(this.meal.mealedDate).isValid()) {
        this.meal.mealedDate = moment();
      }
    },
    onClickUpdateMealTitle() {
      if (this.isEditable) {
        this.isVisibleMealTitleInput = true;
        setTimeout(() => {
          this.$refs.mealTitleInput.focus();
        }, 200);
      }
    },
    onClickUpdateMealedDate() {
      if (this.isEditable) {
        this.isVisibleMealedDateInput = true;
        setTimeout(() => {
          this.$refs.mealedDateInput.focus();
        }, 200);
      }
    },
    onClickUpdateMealFile() {
      if (this.isEditable) this.mealContentSelectionDialog.show();
    },
    onSelectedMealContnet(meal) {
      if (meal && meal.title) {
        this.meal.title = meal.title;
      }

      if (meal && meal.desc) {
        this.meal.desc = meal.desc.trim();
      } else {
        this.meal.desc = null;
      }

      if (meal && moment(meal.mealedDate).isValid()) {
        this.meal.mealedDate = moment(meal.mealedDate);
      }

      if (
        meal &&
        meal.location &&
        meal.location.latitude &&
        meal.location.longitude
      ) {
        this.meal.location = meal.location;
      }

      this.meal.files = meal.files;
      this.$emit('update:meal', this.meal);

      this.mealContentSelectionDialog.hide();

      this.updateMealFiles();
    },
    onClickDeleteMeal() {
      this.messageDialog.confirm(
        '선택하신 게시물을 삭제하시겠습니까?',
        null,
        () => {
          this.deleteMeal();
        }
      );
    },
    deleteMeal() {
      api.meal.deleteMeal(this.meal).then(deletedMeal => {
        this.$emit('deleted', deletedMeal);
      });
    },
    onSelectedFood(food) {
      this.foodSelectionDialog.hide();

      this.isLoadingUpdateFood = true;

      setTimeout(() => {
        this.meal.foodTags = [
          {
            food: food,
            position: {
              x: 0,
              y: 0
            },
            calorie: food.calorie
          }
        ];
        this.updateMealFoodTags().finally(() => {
          this.isLoadingUpdateFood = false;
        });
      }, 500);
    },
    onSelectedFoodTags(foodTags) {
      this.mealFoodTagDialog.hide();

      this.isLoadingUpdateFood = true;

      setTimeout(() => {
        this.meal.foodTags = foodTags;
        this.updateMealFoodTags().finally(() => {
          this.isLoadingUpdateFood = false;
        });
      }, 500);
    },
    onClickRemoveFood() {
      this.isLoadingUpdateFood = true;
      setTimeout(() => {
        return api.meal
          .updateMeal({
            _id: this.meal._id,
            foodTags: null
          })
          .then(updatedMeal => {
            this.$emit('updated', updatedMeal);
          })
          .finally(() => {
            this.isLoadingUpdateFood = false;
          });
      }, 500);
    },
    onSelectedStore(store) {
      this.storeSelectionDialog.hide();

      this.isLoadingUpdateStore = true;

      api.store.upsertStore(store).then(updatedStore => {
        setTimeout(() => {
          this.meal.store = {
            ...store,
            _id: updatedStore._id
          };
          this.updateMealStore().then(() => {
            this.isLoadingUpdateStore = false;
          });
        }, 500);
      });
    },
    onClickRemoveStore() {
      this.isLoadingUpdateStore = true;
      setTimeout(() => {
        return api.meal
          .updateMeal({
            _id: this.meal._id,
            store: null
          })
          .then(updatedMeal => {
            this.$emit('updated', updatedMeal);

            this.isLoadingUpdateStore = false;
          });
      }, 500);
    },
    // meal mutations origin
    updateMealFiles() {
      if (this.meal && this.meal._id) {
        let fileData =
          this.meal.files &&
          this.meal.files.length > 0 &&
          this.meal.files[0].type === 'image' &&
          this.meal.files[0].data
            ? this.meal.files[0].data
            : null;

        api.meal
          .updateMealFile(this.meal, converter.convertDataURItoBlob(fileData))
          .then(updatedMeal => {
            this.$emit('updated', updatedMeal);
          });
      }
    },
    updateMealTitle() {
      if (this.meal && this.meal._id) {
        api.meal
          .updateMeal({
            _id: this.meal._id,
            title: this.meal.title
          })
          .then(updatedMeal => {
            this.$emit('updated', updatedMeal);
            this.isVisibleMealTitleInput = false;
          });
      } else {
        this.isVisibleMealTitleInput = false;
      }
    },
    updateMealMelaedDate() {
      if (this.meal && this.meal._id) {
        api.meal
          .updateMeal({
            _id: this.meal._id,
            mealedDate: this.meal.mealedDate
          })
          .then(updatedMeal => {
            this.$emit('updated', updatedMeal);
            this.isVisibleMealedDateInput = false;
          });
      } else {
        this.isVisibleMealedDateInput = false;
      }
    },
    updateMealDesc() {
      if (this.meal && this.meal._id) {
        api.meal
          .updateMeal({
            _id: this.meal._id,
            desc: this.meal.desc
          })
          .then(updatedMeal => {
            this.$emit('updated', updatedMeal);
          });
      }
    },
    updateMealStore() {
      return api.meal
        .updateMeal({
          _id: this.meal._id,
          store: {
            _id: this.meal.store._id
          }
        })
        .then(updatedMeal => {
          this.$emit('updated', updatedMeal);
        });
    },
    updateMealFoodTags() {
      return api.meal
        .updateMeal({
          _id: this.meal._id,
          foodTags: _.map(this.meal.foodTags, tag => {
            return {
              food: {
                _id: tag.food._id
              },
              position: tag.position,
              calorie: tag.calorie
            };
          })
        })
        .then(updatedMeal => {
          this.$emit('updated', updatedMeal);
          return updatedMeal;
        });
    },
    onClickShowStore(store) {
      if (store) {
        if (store.serviceProvider === 'kakao-map') {
          if (this.$cookies.get('webview')) {
            if (
              semver.valid(this.$cookies.get('version')) &&
              semver.gte(this.$cookies.get('version'), '1.4.0')
            ) {
              wvc.openUrl(
                `https://place.map.kakao.com/m/${store.serviceProviderPlaceId}`
              );
            } else {
              this.browserDialog.show(
                '식당',
                'Powered by Kakao Map',
                `https://place.map.kakao.com/m/${store.serviceProviderPlaceId}`
              );
            }
          } else {
            if (this.$store.state.isMobileDevice) {
              window.open(
                `https://place.map.kakao.com/m/${store.serviceProviderPlaceId}`
              );
            } else {
              window.open(
                `https://place.map.kakao.com/${store.serviceProviderPlaceId}`
              );
            }
          }
        } else if (store.serviceProvider === 'naver-map') {
          if (this.$cookies.get('webview')) {
            wvc.openUrl(
              `https://map.naver.com/entry/place/${store.serviceProviderPlaceId}`
            );
          } else {
            if (this.$store.state.isMobileDevice) {
              window.open(
                `https://m.place.naver.com/restaurant/${store.serviceProviderPlaceId}/home`
              );
            } else {
              window.open(
                `https://map.naver.com/entry/place/${store.serviceProviderPlaceId}`
              );
            }
          }
        } else {
          if (this.$cookies.get('webview')) {
            wvc.openUrl(store.serviceProviderPlaceUrl);
          } else {
            window.open(store.serviceProviderPlaceUrl);
          }
        }
      }
    },
    onClickStory() {
      if (!this.isStoryAvailable) {
        this.messageDialog.alert(
          `이 식사를 내 스토리에 노출하려면 다음 조건을 만족해야합니다.

          • 식사 사진 필요(이모지 허용 불가)
          • 식사 제목 필요
          • 식사 설명 필요
          `,
          '스토리 노출 방법'
        );
      }
    },
    getCalorieSum(foodTags) {
      if (foodTags && Array.isArray(foodTags)) {
        return _.reduce(
          foodTags,
          (sum, foodTag) => {
            if (foodTag && foodTag.calorie && !isNaN(foodTag.calorie)) {
              return sum + foodTag.calorie;
            } else {
              return sum;
            }
          },
          0
        );
      } else {
        return null;
      }
    }
  },
  computed: {
    isMealContentType() {
      if (this.meal) {
        if (this.meal.files && this.meal.files.length > 0) {
          return this.meal.files[0].type;
        } else {
          if (this.meal.title) {
            return 'text';
          } else {
            return 'none';
          }
        }
      } else {
        return 'none';
      }
    },
    shortenMealDateString() {
      return converter.convertShortenMealDateString(this.meal.mealedDate);
    },
    detailedMealedDateString() {
      return moment(this.meal.mealedDate).format('LLLL');
    },
    mealedDateString: {
      get() {
        return moment(this.meal.mealedDate).isValid()
          ? moment(this.meal.mealedDate).format('YYYY-MM-DDTHH:mm')
          : null;
      },
      set(value) {
        this.meal.mealedDate = moment(value);
      }
    },
    descRows() {
      if (this.meal && this.meal.desc) {
        if (this.meal.desc.split('\n').length > 5) {
          return 5;
        } else {
          return this.meal.desc.split('\n').length;
        }
      } else {
        return 1;
      }
    },
    reviewScore: {
      get() {
        if (this.meal && this.meal.review && this.meal.review.score > 0) {
          return this.meal.review.score;
        } else {
          return null;
        }
      },
      set(value) {
        if (this.meal) {
          let review = {
            target: {
              type: 'meal',
              _id: this.meal._id
            },
            score: value,
            comment: null
          };

          api.review.upsertReview(review).then(updatedReview => {
            this.meal.review = updatedReview;

            this.$emit('updated', this.meal);
          });
        }
      }
    },
    isStory() {
      return this.meal ? this.meal.isStory : false;
    },
    isStoryAvailable() {
      return (
        this.meal &&
        this.meal.title &&
        this.meal.desc &&
        this.meal.files &&
        this.meal.files[0].type === 'image'
      );
    }
  },
  watch: {
    isDetailed(value) {
      if (value) {
        this.isVisibleMoreMealDetail = true;
      }
    },
    isFocusFood: {
      handler(value) {
        if (value) {
          setTimeout(() => {
            if (this.isMealContentType === 'image') {
              this.mealFoodTagDialog.show(this.meal);
            } else {
              this.foodSelectionDialog.show();
            }
          }, 500);
        }
      },
      immediate: true,
      deep: true
    },
    isStory(value) {
      if (this.meal && this.meal._id) {
        this.isLoadingUpdateStory = true;
        setTimeout(() => {
          api.meal
            .updateMeal({
              _id: this.meal._id,
              isStory: value
            })
            .then(updatedMeal => {
              this.$emit('updated', updatedMeal);
            })
            .finally(() => {
              this.isLoadingUpdateStory = false;
            });
        }, 500);
      }
    }
  },
  created() {
    this.init();
    if (
      this.meal.review &&
      this.meal.foodTags &&
      this.meal.foodTags.length > 0
    ) {
      this.isVisibleMoreMealDetail = true;
    }
  }
};
</script>

<style></style>
