<template>
  <section>
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xs"
      transition="slide-x-reverse-transition"
      max-width="600px"
      persistent
      scrollable
    >
      <v-card
        :tile="$vuetify.breakpoint.xs"
        :class="{ 'floob-dialog-card': !$vuetify.breakpoint.xs }"
      >
        <v-card-title class="floob-toolbar-title">
          <v-btn
            text
            x-large
            @click="isVisible = false"
            :ripple="$store.state.isDisplayTouch"
            class="floob-toolbar-button-left"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-toolbar-title><h1>사용자 관리</h1></v-toolbar-title>
          <v-btn text x-large class="pr-0"> </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-title class="px-0 pb-0">
          <v-container fluid class="py-0">
            <v-row no-gutters>
              <v-col>
                <v-list class="pb-0">
                  <v-list-item class="px-1"
                    ><v-list-item-content class="pt-0">
                      <v-text-field
                        v-model="searchKeyword"
                        solo-inverted
                        flat
                        :autofocus="false"
                        hide-details
                        label="검색 할 아이디 또는 이름을 입력해주십시오"
                        color="black"
                      >
                      </v-text-field>
                    </v-list-item-content>
                  </v-list-item>

                  <v-subheader v-if="searchKeyword" class="pl-2"
                    ><b>{{
                      `검색 결과 (${searchedUsers.length}건)`
                    }}</b></v-subheader
                  >
                </v-list>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-container v-if="isLoading" fluid class="py-0">
            <v-row>
              <v-col>
                <v-btn
                  block
                  text
                  color="grey"
                  :loading="true"
                  :ripple="$store.state.isDisplayTouch"
                ></v-btn>
              </v-col>
            </v-row>
          </v-container>
          <v-list v-else nav>
            <v-list-item
              v-for="user in searchedUsers"
              :key="user._id"
              class="pr-0"
            >
              <v-list-item-avatar>
                <v-img
                  v-if="user.profileImageUrl"
                  :src="user.profileImageUrl"
                ></v-img>
                <v-icon color="grey lighten-1" v-else size="46"
                  >mdi-account-circle</v-icon
                >
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-subtitle>
                  <v-icon
                    x-small
                    :color="
                      user.sessions && user.sessions.length > 0
                        ? 'green lighten-2'
                        : 'grey'
                    "
                    >mdi-circle
                  </v-icon>
                  {{ user.nickname }}
                  {{ user.mealCount > 0 ? ` • ${user.mealCount}` : '' }}
                </v-list-item-subtitle>
                <v-list-item-title>
                  {{ user.name }}
                </v-list-item-title>
              </v-list-item-content>
              <v-card-actions>
                <v-btn
                  icon
                  @click="onClickActionSheet(user)"
                  :ripple="$store.state.isDisplayTouch"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </v-card-actions>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>

      <v-bottom-sheet v-model="isVisibleAction" scrollable>
        <v-card v-if="selectedUser" flat tile>
          <v-card-title class="pa-0">
            <v-list>
              <v-list-item>
                <v-list-item-avatar>
                  <v-img
                    v-if="selectedUser.profileImageUrl"
                    :src="selectedUser.profileImageUrl"
                  ></v-img>
                  <v-icon color="grey lighten-1" v-else x-large
                    >mdi-account-circle</v-icon
                  >
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    {{ selectedUser.nickname }}
                  </v-list-item-subtitle>
                  <v-list-item-title>
                    {{ selectedUser.name }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pa-0">
            <v-list dense class="px-2 py-0 pb-6">
              <v-subheader><b>ID</b></v-subheader>
              <v-list-item>
                <v-list-item-content class="pt-0">
                  <v-list-item-subtitle>
                    {{ `${selectedUser._id}` }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-subheader><b>Status</b></v-subheader>
              <v-list-item>
                <v-list-item-content class="pt-0">
                  <v-list-item-subtitle>
                    <div>
                      <v-icon
                        v-if="selectedUser.status === 'normal'"
                        color="green lighten-2"
                        x-small
                        >mdi-circle</v-icon
                      >
                      <v-icon v-else color="grey" x-small>mdi-circle</v-icon>
                      {{ `${selectedUser.status}` }}
                    </div>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-subheader><b>Authentications</b></v-subheader>
              <v-list-item>
                <v-list-item-content class="pt-0">
                  <v-list-item-subtitle>
                    <div v-for="auth in selectedUser.auths" :key="auth.type">
                      <v-icon
                        v-if="auth.isAuthenticated"
                        color="green lighten-2"
                        x-small
                        >mdi-check-circle</v-icon
                      >
                      <v-icon v-else color="grey" x-small>mdi-circle</v-icon>
                      {{ `${auth.type}` }}
                    </div>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <template
                v-if="selectedUser.sessions && selectedUser.sessions.length > 0"
              >
                <v-subheader><b>Current Sessions</b></v-subheader>
                <v-list-item
                  v-for="session in selectedUser.sessions"
                  :key="session._id"
                >
                  <v-list-item-content class="pt-0">
                    <v-list-item-subtitle>
                      <div>
                        <v-icon color="green lighten-2" x-small
                          >mdi-circle</v-icon
                        >
                        {{ `${session._id}` }}
                      </div>
                      <div>
                        {{
                          `${convertShortDateString(
                            session.expireDate
                          )} expired`
                        }}
                      </div>
                      <div>
                        {{ `${session.application}` }}
                      </div>
                      <div>
                        {{ `${session.userAgent}` }}
                      </div>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-else-if="selectedUser.lastSession">
                <v-subheader><b>Last Session</b></v-subheader>
                <v-list-item>
                  <v-list-item-content class="py-0">
                    <v-list-item-subtitle>
                      <div>
                        <v-icon color="grey" x-small>mdi-circle</v-icon>
                        {{ `${selectedUser.lastSession._id}` }}
                      </div>
                      <div>
                        {{
                          `${convertShortDateString(
                            selectedUser.lastSession.expireDate
                          )} expired`
                        }}
                      </div>
                      <div>
                        {{ `${selectedUser.lastSession.application}` }}
                      </div>
                      <div>
                        {{ `${selectedUser.lastSession.userAgent}` }}
                      </div>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
            <v-list
              v-if="
                selectedUser.deviceTokens &&
                  selectedUser.deviceTokens.length > 0
              "
              dense
              class="px-2 py-0"
            >
              <v-subheader><b>Device Tokens</b></v-subheader>
              <v-list-item>
                <v-list-item-content class="pt-0">
                  <v-list-item-subtitle>
                    <div
                      v-for="deviceToken in selectedUser.deviceTokens"
                      :key="deviceToken._id"
                    >
                      <v-icon
                        v-if="deviceToken.isSent"
                        color="green lighten-2"
                        x-small
                        >mdi-check-circle</v-icon
                      >
                      <v-icon
                        v-else-if="deviceToken.isFail"
                        color="red lighten-2"
                        x-small
                        >mdi-close-circle</v-icon
                      >
                      {{
                        `${
                          deviceToken.isAllowed === true
                            ? 'Allowed'
                            : `${
                                deviceToken.isAllowed === false
                                  ? 'Denied'
                                  : 'Not determined'
                              }`
                        }:${deviceToken.token}`
                      }}
                    </div>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-list dense>
              <v-list-item
                :ripple="false"
                @click="onClickSendBeforeMealMessages(selectedUser._id)"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    식사 전 알림 보내기
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                :ripple="false"
                @click="onClickSendAfterMealMessages(selectedUser._id)"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    식사 후 알림 보내기
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="onClickImpersonatedLogin(selectedUser._id)">
                <v-list-item-content>
                  <v-list-item-title>
                    디버그 로그인
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-actions>
        </v-card>
      </v-bottom-sheet>
      <floob-message-snackbar :dialog.sync="messageSnackbar">
      </floob-message-snackbar>
    </v-dialog>
  </section>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import api from '@/api';

import floobMessageSnackbar from '@/components/messageSnackbar.vue';
export default {
  components: {
    floobMessageSnackbar
  },
  data() {
    return {
      // controlls
      isVisible: false,
      isVisibleAction: false,
      isLoading: false,

      // datas
      selectedUser: null,
      searchKeyword: null,
      searchedUsers: [],

      // modules
      messageSnackbar: null
    };
  },
  methods: {
    init() {
      this.searchKeyword = '';
      this.searchedUsers = [];
    },
    onClickActionSheet(user) {
      this.selectedUser = {
        ...user,
        auths: Object.keys(user.auth).map(key => ({
          ...user.auth[key],
          type: key
        }))
      };
      this.isVisibleAction = true;
    },
    setMessageSent(results) {
      results.forEach((result, i) => {
        if (
          result &&
          result.deviceToken &&
          result.deviceToken.token &&
          result.response &&
          result.response.success
        ) {
          let idx = _.findIndex(this.selectedUser.deviceTokens, d => {
            return d.token === result.deviceToken.token;
          });
          setTimeout(() => {
            this.selectedUser.deviceTokens[idx].isSent = true;
            this.selectedUser.deviceTokens.splice(
              idx,
              1,
              this.selectedUser.deviceTokens[idx]
            );
          }, (i + 1) * 1000);
        } else {
          let idx = _.findIndex(this.selectedUser.deviceTokens, d => {
            return d.token === result.deviceToken.token;
          });
          setTimeout(() => {
            this.selectedUser.deviceTokens[idx].isFail = true;
            this.selectedUser.deviceTokens.splice(
              idx,
              1,
              this.selectedUser.deviceTokens[idx]
            );
          }, (i + 1) * 1000);
        }
      });
    },
    onClickSendBeforeMealMessages(userId) {
      return api.message.sendBeforeMealMessages(userId).then(results => {
        this.messageSnackbar.show('메시지가 발송 되었습니다.');
        this.setMessageSent(results);
      });
    },
    onClickSendAfterMealMessages(userId) {
      this.messageSnackbar.show('메시지가 발송 되었습니다.');
      return api.message.sendAfterMealMessages(userId).then(results => {
        this.setMessageSent(results);
      });
    },
    onClickImpersonatedLogin(userId) {
      api.auth.verifyAdmin(userId).then(verified => {
        if (verified.isVerified === true) {
          return api.auth
            .refreshAccessTokenByAtuhTicket(verified.ticket._id)
            .then(accessToken => {
              window.$cookies.set(
                'access_token',
                accessToken,
                Infinity,
                '/',
                location.hostname.replace('www', '')
              );

              api.user.getUserBySession().then(user => {
                this.$store.dispatch('set', {
                  key: 'user',
                  value: { ...user }
                });
                this.isVisible = false;
                window.location.reload();
              });
            });
        } else {
          throw new Error('Unverified admin auth.');
        }
      });
    },
    convertShortDateString(date) {
      return moment(date).format('YYYY-MM-DD HH:mm');
    },
    getSearchedUsers() {
      let keyword = this.searchKeyword.replace(/ /gi, '');
      if (keyword && keyword.length >= 1) {
        api.user
          .getUsersByConditions(keyword, keyword, 10)
          .then(users => {
            api.friendship.getFriendships().then(friendships => {
              this.$emit('update:friendships', friendships);
            });

            this.searchedUsers = users;
          })
          .finally(() => {
            this.timer = null;
            this.isLoading = false;
          });
      } else {
        api.user
          .getUsersByAll()
          .then(users => {
            //console.log('getUsersByAll', users);

            this.searchedUsers = _.orderBy(users, ['mealCount'], ['desc']);
          })
          .finally(() => {
            this.timer = null;
            this.isLoading = false;
          });
      }
    }
  },
  watch: {
    isVisible(value) {
      if (!value) {
        this.init();
      } else {
        if (!this.isLoading && !this.timer) {
          this.isLoading = true;

          api.user
            .getUsersByAll()
            .then(users => {
              //console.log('getUsersByAll', users);

              this.searchedUsers = _.orderBy(users, ['mealCount'], ['desc']);
            })
            .finally(() => {
              this.timer = null;
              this.isLoading = false;
            });
        }
      }
    },
    isVisibleAction(value) {
      if (!value) {
        this.selectedUser = null;
      }
    },
    searchKeyword() {
      if (!this.isLoading && !this.timer) {
        this.isLoading = true;
        this.timer = setTimeout(() => {
          this.getSearchedUsers();
        }, 500);
      }
    }
  },
  created() {
    this.$emit('update:dialog', {
      show: () => {
        this.isVisible = true;
      }
    });
  }
};
</script>

<style scoped>
.floob-profile-avatar {
  border: solid 1px rgb(209, 209, 209) !important;
}
</style>
