<template>
  <section>
    <v-snackbar
      class="floob-snackbar"
      :color="color"
      v-model="isVisible"
      :timeout="timeout"
      elevation="24"
      :top="position === 'bottom' ? false : true"
      vertical
    >
      <div :style="`color:${textColor}`" v-html="textHtml"></div>
      <template v-if="buttons.length > 0" v-slot:action="{}">
        <v-btn
          v-for="(button, idx) in buttons"
          :key="idx"
          color="grey"
          text
          @click="button.action"
        >
          {{ button.text }}
        </v-btn>
      </template>
    </v-snackbar>
  </section>
</template>

<script>
import converter from '@/util/converter';

export default {
  data() {
    return {
      isVisible: false,
      text: null,
      color: 'white',
      textColor: 'grey',
      delay: 1000,
      timeout: 3000,
      position: 'top',
      buttons: []
    };
  },
  methods: {
    init() {
      this.text = null;
      this.color = 'white';
      this.textColor = 'grey';
      this.delay = 1000;
      this.timeout = 3000;
      this.position = 'top';
      this.buttons = [];
    }
  },
  computed: {
    defaultColor() {
      return this.$vuetify.theme.dark ? '#1E1E1E' : 'white';
    },
    defaultTextColor() {
      return this.$vuetify.theme.dark ? 'white' : 'grey';
    },
    textHtml() {
      if (this.text) {
        return converter.convertTextToHtml(this.text);
      } else {
        return '';
      }
    }
  },
  created() {
    this.$emit('update:dialog', {
      show: (text, options) => {
        this.init();

        if (options && !isNaN(options.delay)) {
          this.delay = options.delay;
        }

        setTimeout(() => {
          this.text = text;

          if (options && !isNaN(options.timeout)) {
            this.timeout = options.timeout;
          }

          if (options && options.color) {
            this.color = options.color;
          } else {
            this.color = this.defaultColor;
          }

          if (options && options.textColor) {
            this.textColor = options.textColor;
          } else {
            this.textColor = this.defaultTextColor;
          }

          if (
            options &&
            options.position &&
            ['top', 'bottom'].includes(options.position)
          ) {
            this.position = options.position;
          } else {
            this.textColor = 'top';
          }

          if (options && options.buttons && options.buttons.length > 0) {
            this.buttons = options.buttons;
          } else {
            this.buttons = [];
          }
          this.isVisible = true;
        }, this.delay);
      },
      hide: () => {
        this.isVisible = false;
      }
    });
  }
};
</script>

<style></style>
