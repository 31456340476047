import qs from 'qs';
import axios from 'axios';

const API_BASE_URL = 'https://graph.instagram.com';

export default {
  getMedias(params) {
    let querystring = qs.stringify(params);

    return axios({
      url: `${API_BASE_URL}/me/media/?${querystring}`,
      method: 'GET'
    }).then(res => {
      // console.log('instagram getMedias', res);
      return res.data;
    });
  },
  getUser(params) {
    let querystring = qs.stringify(params);

    return axios({
      url: `${API_BASE_URL}/me/?${querystring}`,
      method: 'GET'
    }).then(res => {
      // console.log('instagram getUser', res);
      return res.data;
    });
  }
};
