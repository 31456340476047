<template>
  <section>
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xs"
      transition="slide-x-reverse-transition"
      persistent
      scrollable
    >
      <v-card
        :tile="$vuetify.breakpoint.xs"
        :class="{ 'floob-dialog-card': !$vuetify.breakpoint.xs }"
      >
        <v-card-title class="floob-toolbar-title">
          <v-btn
            text
            x-large
            @click="isVisible = false"
            :ripple="$store.state.isDisplayTouch"
            class="floob-toolbar-button-left"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-toolbar-title><h1>Cookies</h1></v-toolbar-title>
          <v-btn text x-large class="pr-0"> </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="px-4">
          <v-list>
            <v-list-item v-for="(cookie, idx) in cookies" :key="idx">
              <v-list-item-content>
                <v-list-item-subtitle>
                  {{ cookie.key }}
                </v-list-item-subtitle>
                <v-list-item-title>
                  {{ cookie.value }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import _ from 'lodash';

export default {
  data() {
    return {
      // controlls
      isVisible: false,

      // datas
      cookies: null
    };
  },
  created() {
    this.$emit('update:dialog', {
      show: () => {
        this.isVisible = true;

        this.cookies = _.map(this.$cookies.keys(), cookieKey => {
          return {
            key: cookieKey,
            value: this.$cookies.get(cookieKey)
          };
        });
      }
    });
  }
};
</script>

<style></style>
