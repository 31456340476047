import axios from 'axios';
import moment from 'moment';

const WAIT_TIME_MS = 1000;

const WEB_VERSION = require('../../package.json').version;

let API_BASE_URL = 'https://api.floob.co.kr';
//API_BASE_URL = '//localhost:4000';
//API_BASE_URL = '//192.168.0.2:4000';
//API_BASE_URL = '//192.168.0.21:4000';
//API_BASE_URL = '//192.168.0.20:4000';

let _lock = false;

const waitAndGetAccessToken = () => {
  return new Promise(resolve => {
    setTimeout(() => {
      let accessToken = window.$cookies.get('access_token');

      if (!_lock && accessToken) {
        return resolve(accessToken);
      } else {
        return waitAndGetAccessToken();
      }
    }, WAIT_TIME_MS);
  });
};

const createAccessToken = () => {
  return axios({
    url: `${API_BASE_URL}/api/auth/createAccessToken`,
    method: 'POST'
  }).then(res => {
    if (res && res.data && res.data.accessToken) {
      return res.data.accessToken;
    } else {
      throw new Error('Fail to authentication.');
    }
  });
};

const getAccessToken = () => {
  if (_lock) {
    return waitAndGetAccessToken();
  } else {
    let accessToken = window.$cookies.get('access_token');

    if (!accessToken) {
      _lock = true;

      return createAccessToken().then(accessToken => {
        window.$cookies.set(
          'access_token',
          accessToken,
          Infinity,
          '/',
          location.hostname.replace('www', '')
        );

        _lock = false;

        return accessToken;
      });
    } else {
      return new Promise(resolve => {
        return resolve(accessToken);
      });
    }
  }
};

const request = {
  post(path, params, options) {
    let body = params;

    return getAccessToken()
      .then(accessToken => {
        let requestConfig = {
          url: `${API_BASE_URL}${path}`,
          method: 'POST',
          data: body,
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'floob-app-version': window.$cookies.get('version') || '',
            'floob-web-version': WEB_VERSION
          }
        };
        if (options) {
          if (
            options.timeout &&
            !isNaN(options.timeout) &&
            parseInt(options.timeout) > 0
          ) {
            requestConfig.timeout = parseInt(options.timeout);
          }
        }
        return axios(requestConfig).catch(err => {
          if (err && err.isAxiosError && err.response && err.response.data) {
            console.error(
              `Call api error occured. - ${err.response.data.message}`,
              err
            );

            if (err.response.status === 401) {
              if (err.response.data.message === 'jwt expired') {
                alert(
                  '세션이 만료 되었습니다.\n다시 로그인하여 주시기 바랍니다.'
                );
              } else {
                alert('세션 인증이 실패하였습니다.');
              }
              _lock = true;
              window.$cookies.remove(
                'access_token',
                '/',
                location.hostname.replace('www', '')
              );
              location.reload();
            }
          }

          return {
            error: err
          };
        });
      })
      .then(res => {
        if (res && res.data) {
          // GraphQL error occured.
          if (res.data.errors) {
            let error = res.data.errors[0];

            if (error.statusCode === 400) {
              alert('요청 정보가 잘못 되었습니다.');
            } else if (error.statusCode === 401) {
              alert('인증이 실패 되었습니다.');
            } else if (error.statusCode === 403) {
              alert('접근 권한이 없습니다.');
            } else if (error.statusCode === 404) {
              alert('데이터가 존재하지 않습니다.');
            } else if (error.statusCode === 422) {
              alert('처리할 수 없는 요청입니다.');
            } else {
              alert(
                `예기치 않은 오류가 발생하였습니다.\n\n오류메시지\n${error.message}`
              );
            }

            // alert('Unexpected error occured.');
            console.error('Floob api data error occured.', res.data);
            return;
          }
          return res.data;
        } else {
          return null;
        }
      });
  }
};

export default {
  app: {
    version(type) {
      return axios.get(`${API_BASE_URL}/${type}/version`).then(res => {
        return res.data;
      });
    },
    getAppInfo() {
      return axios.get(`${API_BASE_URL}/app-info`).then(res => {
        return res.data;
      });
    }
  },
  auth: {
    refreshAccessTokenBySession() {
      return request
        .post('/api/auth/refreshAccessTokenBySession')
        .then(data => {
          window.$cookies.set(
            'access_token',
            data.accessToken,
            Infinity,
            '/',
            location.hostname.replace('www', '')
          );
          return data.accessToken;
        });
    },
    refreshAccessTokenByAtuhTicket(ticketId) {
      return request
        .post('/api/auth/refreshAccessTokenByAtuhTicket', { ticketId })
        .then(data => {
          return data.accessToken;
        });
    },
    verifyKakaoAuth(kakaoAccessToken) {
      return request
        .post('/api/auth/verifyKakaoAuth', {
          kakaoAccessToken
        })
        .then(verified => {
          if (verified) {
            return verified;
          } else {
            throw new Error('Fail to kakao autenticate.');
          }
        });
    },
    verifyAppleAuth(appleUserId, userName, email) {
      return request
        .post('/api/auth/verifyAppleAuth', {
          appleUserId,
          userName,
          email
        })
        .then(verified => {
          if (verified) {
            return verified;
          } else {
            throw new Error('Fail to apple autenticate.');
          }
        });
    },
    verifyFacebookAuth(userId, accessToken) {
      return request
        .post('/api/auth/verifyFacebookAuth', {
          userId,
          accessToken
        })
        .then(verified => {
          if (verified) {
            return verified;
          } else {
            throw new Error('Fail to apple autenticate.');
          }
        });
    },
    verifyAdmin(impersonatedUserId) {
      return request
        .post('/api/auth/verifyAdmin', {
          impersonatedUserId
        })
        .then(verified => {
          if (verified) {
            return verified;
          } else {
            throw new Error('Fail to admin autenticate.');
          }
        });
    },
    getAccessTokenByInstagram(code) {
      return request
        .post('/api/auth/getAccessTokenByInstagram', { code: code })
        .then(data => {
          if (data) {
            return data.accessToken;
          } else {
            throw new Error('Fail to getAccessToken from instagram.');
          }
        });
    },
    unverifyInstagramAuth() {
      return request.post('/api/auth/unverifyInstagramAuth').then(() => {
        return true;
      });
    },
    expireInstagramAuth() {
      return request.post('/api/auth/expireInstagramAuth').then(() => {
        return true;
      });
    }
  },
  user: {
    model: {
      all: `{
        _id,
        nickname,
        name,
        status,
        introduction,
        profileImageUrl,
        gender,
        followUserIds,
        auth {
          apple {
            isAuthenticated,
            authenticatedDate
          }
          kakao {
            isAuthenticated,
            authenticatedDate
          },
          facebook {
            isAuthenticated,
            authenticatedDate
          },
          instagram {
            isAuthenticated,
            authenticatedDate,
            accessToken
          },
          floober {
            isAuthenticated
          }
        },
        lastSession {
          _id,
          device,
          application,
          userAgent,
          expireDate
        },
        sessions {
          _id,
          device,
          application,
          userAgent,
          expireDate
        },
        deviceTokens {
          _id,
          type,
          token,
          isAllowed,
          updatedDate
        },
        followCount,
        autoFoodTag,
        mealCount,
        createdDate,
      }`,
      publicOnly: `{
        _id,
        nickname,
        name,
        gender,
        introduction,
        profileImageUrl,
        randomFollowCount,
        followCount,
        auth {
          floober {
            isAuthenticated
          }
        }
        autoFoodTag,
        createdDate,
      }`
    },
    getUserBySession() {
      return request
        .post('/api/user', {
          query: `
          {
            getUserBySession ${this.model.all}
          }`
        })
        .then(data => {
          return data.data.getUserBySession;
        });
    },
    getUser(id) {
      return request
        .post('/api/user', {
          query: `
          {
            getUser (_id: "${id}") ${this.model.publicOnly}
          }`
        })
        .then(data => {
          return data.data.getUser;
        });
    },
    getUsersByAuthenticatedAuth(auth) {
      return request
        .post('/api/user', {
          query: `
          {
            getUsersByAuthenticatedAuth (auth: "${auth}") ${this.model.publicOnly}
          }`
        })
        .then(data => {
          return data.data.getUsersByAuthenticatedAuth;
        });
    },
    getUserExsitsByNickname(nickname) {
      return request
        .post('/api/user', {
          query: `
          {
            getUserExsitsByNickname (nickname: "${nickname}")
          }`
        })
        .then(data => {
          return data.data.getUserExsitsByNickname;
        });
    },
    getUsersByConditions(nickname, name, limit) {
      return request
        .post('/api/user', {
          query: `
            query ($nickname: String, $name: String, $limit: Int) {
              getUsersByConditions (nickname: $nickname, name: $name, limit: $limit) ${this.model.all}
            }`,
          variables: {
            nickname: nickname,
            name: name,
            limit: limit
          }
        })
        .then(data => {
          return data.data.getUsersByConditions;
        });
    },
    getUsersByAll() {
      return request
        .post('/api/user', {
          query: `{
            getUsersByAll ${this.model.all}
          }`
        })
        .then(data => {
          return data.data.getUsersByAll;
        });
    },
    updateUser(user) {
      return request
        .post('/api/user', {
          query: `
                    mutation ($user: UserInput!) {
                        updateUser (user: $user) ${this.model.all}
                    }
                `,
          variables: { user }
        })
        .then(data => {
          return data.data.updateUser;
        });
    },
    updateUserProfileImage(image) {
      const formData = new FormData();
      formData.append('userProfileImageFile', image);

      return request
        .post('/api/user/updateUserProfileImage', formData)
        .then(data => {
          return data;
        });
    },
    upsertDeviceToken(deviceToken) {
      return request
        .post('/api/user/upsertDeviceToken', deviceToken)
        .then(data => {
          console.log('Updated deviceToken', data);
          return;
        });
    },
    withdrawUser(user) {
      return request.post('/api/user/withdrawUser', user);
    },
    deleteUser(user) {
      return request.post('/api/user/deleteUser', user);
    }
  },
  meal: {
    model: `{
      _id,
      title,
      desc,
      files {
        type,
        url
      },
      mealedDate,
      location {
        latitude,
        longitude
      },
      owner {
        _id,
        name,
        nickname,
        gender,
        autoFoodTag,
        profileImageUrl
      },
      liked {
        _id,
        createdDate
      },
      likeCount,
      randomLikeCount,
      review {
        _id,
        score,
        comment,
      },
      food {
        _id,
        name,
        type,
        imageUrl,
        country,
        calorie,
      },
      foods {
        _id,
        name,
        type,
        imageUrl,
        country,
        calorie,
      },
      foodTags {
        food {
          _id,
          name,
          type,
          imageUrl,
          country,
          calorie,
        },
        position {
          x,
          y
        },
        calorie
      },
      store {
        _id,
        name,
        profileImageUrl,
        serviceProvider,
        serviceProviderPlaceId,
        serviceProviderPlaceUrl,
        location {
          latitude,
          longitude
        },
        address
      },
      sourceUrl,
      isStory,
      createdDate,
    }`,
    createMeal(meal, file) {
      const formData = new FormData();
      if (meal.title) formData.append('title', meal.title);
      if (meal.desc) formData.append('desc', meal.desc);
      if (meal.mealedDate) {
        formData.append(
          'mealedDate',
          moment(meal.mealedDate).format('YYYY-MM-DDTHH:mm:ssZ')
        );
      }
      if (meal.sourceUrl) formData.append('sourceUrl', meal.sourceUrl);

      if (meal.location) {
        formData.append('latitude', meal.location.latitude);
        formData.append('longitude', meal.location.longitude);
      }

      meal.files.forEach(f => {
        if (f.type === 'emoji' && f.url) {
          formData.append('emojis', f.url);
        } else if (f.type === 'image' && f.url) {
          formData.append('images', f.url);
        }
      });
      if (file) {
        formData.append('file', file);
      }

      return request.post('/api/meal/createMeal', formData).then(data => {
        return data;
      });
    },
    duplicateMeal(mealId) {
      return request
        .post('/api/meal', {
          query: `
                  mutation (
                    $_id:ID!
                  ) {
                    duplicateMeal (meal: {
                        _id: $_id
                    }) ${this.model}
                  }
              `,
          variables: { _id: mealId }
        })
        .then(data => {
          return data.data.duplicateMeal;
        });
    },
    updateMealPartial(meal, props) {
      let _meal = {
        ...meal
      };
      let mutationQuery = '';
      let paramQuery = '';

      props.forEach(prop => {
        let propType = null;
        let param = null;

        if (prop === 'title' || prop === 'desc') {
          propType = 'String';
          param = `$${prop}`;
        } else if (prop === 'mealedDate') {
          propType = 'Date';
          param = `$${prop}`;
        } else if (prop === 'food') {
          propType = 'ID';
          param = `{ _id: $${prop} }`;
          _meal.food = _meal.food && _meal.food._id ? _meal.food._id : null;
        } else if (prop === 'review') {
          propType = 'ID';
          param = `{ _id: $${prop} }`;
          _meal.review =
            _meal.review && _meal.review._id ? _meal.review._id : null;
        } else {
          throw new Error('Invalid property of meal.');
        }

        mutationQuery = `${mutationQuery}
        $${prop}: ${propType},
        `;

        paramQuery = `${paramQuery}
        ${prop}: ${param},`;
      });
      return request
        .post('/api/meal', {
          query: `
                  mutation (
                    $_id:ID!,
                    ${mutationQuery}
                  ) {
                      updateMeal (meal: {
                        _id: $_id,
                        ${paramQuery}
                    }) ${this.model}
                  }
              `,
          variables: _meal
        })
        .then(data => {
          return data.data.updateMeal;
        });
    },
    updateMealFile(meal, file) {
      const formData = new FormData();
      formData.append('_id', meal._id);
      meal.files.forEach(f => {
        if (f.type === 'image' && f.url) {
          formData.append('images', f.url);
        } else if (f.type === 'emoji' && f.url) {
          formData.append('emojis', f.url);
        }
      });

      if (file) {
        formData.append('file', file);
      }

      return request.post('/api/meal/updateMealFile', formData).then(data => {
        return data;
      });
    },
    updateMeal(meal) {
      return request
        .post('/api/meal', {
          query: `
              mutation updateMeal($meal: MealInput) {
                updateMeal (meal: $meal) ${this.model}
              }
          `,
          variables: {
            meal: meal
          }
        })
        .then(data => {
          return data.data.updateMeal;
        });
    },
    getMeal(id) {
      return request
        .post('/api/meal', {
          query: `
          {
            getMeal (_id: "${id}") ${this.model}
          }
          `
        })
        .then(data => {
          return data.data.getMeal;
        });
    },
    getMealsByFeed(limit, offsetMealedDate, offsetId) {
      return request
        .post('/api/meal', {
          query: `
            query getMealsByFeed($limit: Int, $offsetMealedDate: Date, $offsetId: ID) {
              getMealsByFeed (limit: $limit, offsetMealedDate: $offsetMealedDate, offsetId: $offsetId) ${this.model}
            }
          `,
          variables: {
            limit,
            offsetMealedDate,
            offsetId
          }
        })
        .then(data => {
          return data.data.getMealsByFeed;
        });
    },
    getMealsByTimeline(limit, offsetMealedDate, offsetId) {
      return request
        .post('/api/meal', {
          query: `
          {
            getMealsByTimeline (
              limit: ${limit},
              ${
                offsetMealedDate
                  ? `offsetMealedDate: "${offsetMealedDate}",`
                  : ''
              },
              ${offsetId ? `offsetId: "${offsetId}",` : ''}
            ) ${this.model}
          }
          `
        })
        .then(data => {
          return data.data.getMealsByTimeline;
        });
    },
    getMealsByReport(startMealedDate, endMealedDate) {
      return request
        .post('/api/meal', {
          query: `
              {
                getMealsByReport (
                    startMealedDate: "${moment(startMealedDate).format(
                      'YYYY-MM-DDTmm:HH:ssZ'
                    )}",
                    endMealedDate: "${moment(endMealedDate).format(
                      'YYYY-MM-DDTmm:HH:ssZ'
                    )}"
                    ) ${this.model}
              }
          `
        })
        .then(data => {
          return data.data.getMealsByReport;
        });
    },
    getMealsByStory(ownerId, limit, offsetMealedDate, offsetId) {
      return request
        .post('/api/meal', {
          query: `
            query getMealsByStory($ownerId: ID!, $limit: Int, $offsetMealedDate: Date, $offsetId: ID) {
              getMealsByStory (ownerId: $ownerId, limit: $limit, offsetMealedDate: $offsetMealedDate, offsetId: $offsetId) ${this.model}
            }
          `,
          variables: {
            ownerId,
            limit,
            offsetMealedDate,
            offsetId
          }
        })
        .then(data => {
          return data.data.getMealsByStory;
        });
    },
    getMealStatByStory(ownerId) {
      return request
        .post('/api/meal', {
          query: `
            query getMealStatByStory($ownerId: ID!) {
              getMealStatByStory (ownerId: $ownerId) {
                mealCount,
                followCount,
                storyCount,
                storeCount,
              }
            }
          `,
          variables: {
            ownerId
          }
        })
        .then(data => {
          return data.data.getMealStatByStory;
        });
    },
    getMealsByAdmin(limit, offsetId) {
      return request
        .post('/api/meal', {
          query: `
            query getMealsByAdmin($limit: Int, $offsetId: ID) {
              getMealsByAdmin (limit: $limit, offsetId: $offsetId) ${this.model}
            }
          `,
          variables: {
            limit,
            offsetId
          }
        })
        .then(data => {
          return data.data.getMealsByAdmin;
        });
    },
    deleteMeal(meal) {
      return request
        .post('/api/meal', {
          query: `
                    mutation {
                      deleteMeal (_id: "${meal._id}") {
                          _id,
                        }
                    }
                `
        })
        .then(data => {
          return data.data.deleteMeal;
        });
    }
  },
  store: {
    model: `
    {
      _id,
      name,
      profileImageUrl,
      serviceProvider,
      serviceProviderPlaceId,
      serviceProviderPlaceUrl,
      address,
      location {
        latitude,
        longitude
      },
      createdDate,
      owner {
          _id
      }
    }`,
    createStore(store) {
      return request
        .post('/api/store', {
          query: `
                    mutation (
                      $name:String!, 
                      $serviceProvider:String, 
                      $serviceProviderPlaceId:String, 
                      $serviceProviderPlaceUrl:String, 
                      $address:String, 
                      $location:LocationInput
                    ) {
                        createStore (store: {
                            name: $name,
                            serviceProvider: $serviceProvider
                            serviceProviderPlaceId: $serviceProviderPlaceId
                            serviceProviderPlaceUrl: $serviceProviderPlaceUrl
                            address: $address
                            location: $location
                        }) ${this.model}
                    }
                `,
          variables: store
        })
        .then(data => {
          return data.data.createStore;
        });
    },
    upsertStore(store) {
      return request
        .post('/api/store', {
          query: `
                    mutation (
                      $name:String!, 
                      $serviceProvider:String, 
                      $serviceProviderPlaceId:String, 
                      $serviceProviderPlaceUrl:String, 
                      $address:String, 
                      $location:LocationInput
                    ) {
                        upsertStore (store: {
                            name: $name,
                            serviceProvider: $serviceProvider
                            serviceProviderPlaceId: $serviceProviderPlaceId
                            serviceProviderPlaceUrl: $serviceProviderPlaceUrl
                            address: $address
                            location: $location
                        }) ${this.model}
                    }
                `,
          variables: store
        })
        .then(data => {
          return data.data.upsertStore;
        });
    }
  },
  post: {
    model: `{
      _id,
      title,
      content,
      accessType,
      imageUrls,
      fileType,
      fileUrl,
      mealedDate,
      location {
        latitude,
        longitude
      },
      tags,
      createdDate,
      owner {
        _id,
        name,
        nickname,
        profileImageUrl
      },
      food {
        _id,
        name,
        imageUrl
      },
      store {
        _id,
        name,
        profileImageUrl,
        serviceProvider,
        serviceProviderPlaceId,
        location {
          latitude,
          longitude
        },
        address
      },
      recipe {
        _id,
        url,
        name,
        ingredients,
        steps,
      },
      delivery {
        _id,
        url,
        name,
        serviceProvider,
        serviceProviderStoreId,
      },
      score,
      review,
      like {
        _id
      },
      likeCount,
      replyCount
  }`,
    getPostsByFeed(limit, offsetId, order) {
      return request
        .post('/api/post', {
          query: `
                  {
                    getPostsByFeed (
                        limit: ${limit}, 
                        ${offsetId ? `offsetId: "${offsetId}",` : ''}
                        ${order ? `order: "${order}",` : ''}
                      ) ${this.model}
                  }
              `
        })
        .then(data => {
          return data.data.getPostsByFeed;
        });
    },
    getPostsByMealReport(startMealedDate, endMealedDate) {
      return request
        .post('/api/post', {
          query: `
                  {
                    getPostsByMealReport (
                        startMealedDate: "${moment(startMealedDate).format(
                          'YYYY-MM-DDTmm:HH:ssZ'
                        )}",
                        endMealedDate: "${moment(endMealedDate).format(
                          'YYYY-MM-DDTmm:HH:ssZ'
                        )}"
                        ) ${this.model}
                  }
              `
        })
        .then(data => {
          return data.data.getPostsByMealReport;
        });
    },
    getPostsByTimeline(limit, offsetId) {
      return request
        .post('/api/post', {
          query: `
                  {
                    getPostsByTimeline (
                        limit: ${limit}, 
                        ${offsetId ? `offsetId: "${offsetId}",` : ''}
                      ) ${this.model}
                  }
              `
        })
        .then(data => {
          return data.data.getPostsByTimeline;
        });
    },
    getPostsByProfile(ownerId, limit, offsetId) {
      return request
        .post('/api/post', {
          query: `
                  {
                    getPostsByProfile (
                        ownerId: "${ownerId}",
                        limit: ${limit}, 
                        ${offsetId ? `offsetId: "${offsetId}",` : ''}
                      ) ${this.model}
                  }
              `
        })
        .then(data => {
          return data.data.getPostsByProfile;
        });
    },
    getPostStatByProfile(ownerId) {
      return request
        .post('/api/post', {
          query: `
                {
                  getPostStatByProfile (
                      ownerId: "${ownerId}"
                    ) {
                      posts {
                        success
                        count
                      },
                      storePosts {
                          success
                          count
                      },
                      recipePosts {
                          success
                          count
                      },
                    }
                }
            `
        })
        .then(data => {
          return data.data.getPostStatByProfile;
        });
    },
    getPost(id) {
      return request
        .post('/api/post', {
          query: `
                    {
                        getPost (_id: "${id}") ${this.model}
                    }
                `
        })
        .then(data => {
          return data.data.getPost;
        });
    },
    createPost(post, file) {
      const formData = new FormData();
      if (post.title) formData.append('title', post.title);
      if (post.content) formData.append('content', post.content);
      if (post.accessType) formData.append('accessType', post.accessType);
      if (post.mealedDate) formData.append('mealedDate', post.mealedDate);
      if (post.location) {
        formData.append('latitude', post.location.latitude);
        formData.append('longitude', post.location.longitude);
      }

      formData.append('file', file);

      return request.post('/api/post/createPost', formData).then(data => {
        return data;
      });
    },
    updatePost(post) {
      if (post.store) {
        post.store = post.store._id;
      }
      if (post.food) {
        post.food = post.food._id;
      }
      return request
        .post('/api/post', {
          query: `
                    mutation (
                      $_id:ID!, 
                      $title:String, 
                      $content:String, 
                      $accessType:String, 
                      $tags:[String], 
                      $mealedDate:Date
                      $location:Location
                      $isSelfCooking: Boolean
                      $recipeUrl: String,
                      ${post.store ? '$store: ID,' : ''}
                      ${post.food ? '$food: ID,' : ''}
                      ${post.recipe ? '$recipe: ID,' : ''}
                    ) {
                        updatePost (post: {
                          _id: $_id,
                          title: $title,
                          content: $content,
                          accessType: $accessType,
                          tags: $tags,
                          mealedDate: $mealedDate,
                          location: $location,
                          isSelfCooking: $isSelfCooking,
                          recipeUrl: $recipeUrl,
                          ${post.store ? 'store: { _id: $store },' : ''}
                          ${post.food ? 'food: { _id: $food },' : ''}
                          ${post.recipe ? 'recipe: { _id: $recipe },' : ''}
                      }) ${this.model}
                    }
                `,
          variables: {
            _id: post._id,
            title: post.title,
            content: post.content,
            accessType: post.accessType,
            tags: post.tags,
            mealedDate: post.mealedDate,
            location: post.location,
            isSelfCooking: post.isSelfCooking,
            recipeUrl: post.recipeUrl,
            store: post.store,
            food: post.food,
            recipe: post.recipe
          }
        })
        .then(data => {
          return data.data.updatePost;
        });
    },
    updatePostAccessType(post) {
      return request
        .post('/api/post', {
          query: `
                    mutation (
                      $_id:ID!,
                      $accessType:String, 
                    ) {
                        updatePost (post: {
                          _id: $_id,
                          accessType: $accessType,
                      }) ${this.model}
                    }
                `,
          variables: {
            _id: post._id,
            accessType: post.accessType
          }
        })
        .then(data => {
          return data.data.updatePost;
        });
    },
    updatePostFood(post) {
      return request
        .post('/api/post', {
          query: `
                    mutation (
                      $_id:ID!,                      
                      ${post.food ? `$food: ID,` : ''}
                    ) {
                        updatePost (post: {
                          _id: $_id,
                          food: ${post.food ? `{ _id: $food }` : `null`},
                      }) ${this.model}
                    }
                `,
          variables: {
            _id: post._id,
            food: post.food
          }
        })
        .then(data => {
          return data.data.updatePost;
        });
    },
    updatePostStore(post) {
      return request
        .post('/api/post', {
          query: `
                    mutation (
                      $_id:ID!,
                      ${post.store ? `$store: ID,` : ''}            
                    ) {
                        updatePost (post: {
                          _id: $_id,
                          store: ${post.store ? `{ _id: $store }` : `null`},  
                          recipe: null,
                          delivery: null, 
                      }) ${this.model}
                    }
                `,
          variables: {
            _id: post._id,
            store: post.store
          }
        })
        .then(data => {
          return data.data.updatePost;
        });
    },
    updatePostRecipe(post) {
      return request
        .post('/api/post', {
          query: `
          mutation (
            $_id:ID!,
            ${post.recipe ? `$recipe: ID,` : ''},                      
          ) {
              updatePost (post: {
                _id: $_id,
                recipe: ${post.recipe ? `{ _id: $recipe }` : `null`},
                store: null,
                delivery: null,
            }) ${this.model}
          }`,
          variables: {
            _id: post._id,
            recipe: post.recipe
          }
        })
        .then(data => {
          return data.data.updatePost;
        });
    },
    updatePostDelivery(post) {
      return request
        .post('/api/post', {
          query: `
          mutation (
            $_id:ID!,
            ${post.delivery ? `$delivery: ID,` : ''},                      
          ) {
              updatePost (post: {
                _id: $_id,
                delivery: ${post.delivery ? `{ _id: $delivery }` : `null`},
                store: null,
                recipe: null,
            }) ${this.model}
          }`,
          variables: {
            _id: post._id,
            delivery: post.delivery
          }
        })
        .then(data => {
          return data.data.updatePost;
        });
    },
    updatePostReview(post) {
      return request
        .post('/api/post', {
          query: `
                    mutation (
                      $_id:ID!,
                      $score: Float,
                      $review: String,
                    ) {
                        updatePost (post: {
                          _id: $_id,
                          score: $score,
                          review: $review,
                      }) ${this.model}
                    }
                `,
          variables: {
            _id: post._id,
            score: post.score,
            review: post.review
          }
        })
        .then(data => {
          return data.data.updatePost;
        });
    },
    deletePost(post) {
      return request
        .post('/api/post', {
          query: `
                    mutation {
                        deletePost (_id: "${post._id}") {
                          _id,
                        }
                    }
                `
        })
        .then(data => {
          return data.data.deletePost;
        });
    },
    updatePostImages(postId, images) {
      const formData = new FormData();

      formData.append('_id', postId);

      images.forEach(imageFile => {
        formData.append('postImageFiles', imageFile);
      });

      return request.post('/api/post/updatePostImages', formData).then(data => {
        return data;
      });
    },
    createReply(reply) {
      return request
        .post('/api/reply', {
          query: `
                    mutation {
                        createReply (reply: {
                            postId: "${reply.postId}",
                            content: """${reply.content}""",
                            owner: {
                                _id: "${reply.owner._id}"
                            }
                        }) {
                            _id,
                            postId,
                            content,
                            createdDate,
                            owner {
                                _id
                            }
                        }
                    }
                `
        })
        .then(data => {
          console.log('created reply', data);
          return data.data.createReply;
        });
    },
    getAllRepliesByPostId(postId) {
      return request
        .post('/api/reply', {
          query: `
                    {
                      getAllRepliesByPostId (postId: "${postId}") {
                          _id,
                          postId,
                          content,
                          createdDate,
                          updatedDate,
                          owner {
                            _id,
                            name,
                            profileImageUrl
                          }
                        }
                    }
                `
        })
        .then(data => {
          return data.data.getAllRepliesByPostId;
        });
    },
    deleteReply(reply) {
      return request
        .post('/api/reply', {
          query: `
                    mutation {
                        deleteReply (_id: "${reply._id}") {
                          _id,
                        }
                    }
                `
        })
        .then(data => {
          return data.data.deleteReply;
        });
    },
    updateReply(reply) {
      return request
        .post('/api/reply', {
          query: `
                    mutation {
                      updateReply (reply: {
                          _id: "${reply._id}",
                          content: """${reply.content}""",
                          owner: {
                              _id: "${reply.owner._id}"
                          }
                      }) {
                        _id,
                        postId,
                        content,
                        createdDate,
                        updatedDate,
                        owner {
                            _id,
                            name,
                            profileImageUrl
                        }
                      }
                    }
                `
        })
        .then(data => {
          return data.data.updateReply;
        });
    },
    getLikeByPostIdAndOwnerId(postId, ownerId) {
      return request
        .post('/api/like', {
          query: `
                  {
                      getLikeByPostIdAndOwnerId (postId: "${postId}", ownerId: "${ownerId}") {
                          _id,
                          postId,
                          owner {
                            _id
                          }
                      }
                  }
              `
        })
        .then(data => {
          return data.data.getLikeByPostIdAndOwnerId;
        });
    },
    createLike(like) {
      return request
        .post('/api/like', {
          query: `
                    mutation {
                        createLike (like: {
                            postId: "${like.postId}",
                            owner: {
                                _id: "${like.owner._id}"
                            }
                        }) {
                            _id,
                            postId,
                            owner {
                                _id
                            }
                        }
                    }
                `
        })
        .then(data => {
          return data.data.createLike;
        });
    },
    deleteLike(like) {
      return request
        .post('/api/like', {
          query: `
                    mutation {
                        deleteLike (_id: "${like._id}") {
                          _id,
                        }
                    }
                `
        })
        .then(data => {
          return data.data.deleteLike;
        });
    }
  },
  review: {
    model: `{
      _id,
      score,
      comment,
      owner {
        _id
      },
      createdDate
    }`,
    upsertReview(review) {
      return request
        .post('/api/review', {
          query: `
            mutation (
              $target: TargetInput,
              $score: Float,
              $comment: String
            ) {
              upsertReview (review: {
                target: $target,
                score: $score,
                comment: $comment
              }) ${this.model}
            }
            `,
          variables: review
        })
        .then(data => {
          return data.data.upsertReview;
        });
    }
  },
  food: {
    model: `{
      _id,
      name,
      imageUrl,
      createdDate,
    }`,
    createFood(food) {
      return request
        .post('/api/food', {
          query: `
            mutation createFood($food: FoodInput) {
              createFood (food: $food) ${this.model}
            }
        `,
          variables: {
            food: food
          }
        })
        .then(data => {
          return data.data.createFood;
        });
    },
    getFood(id) {
      return request
        .post('/api/food', {
          query: `
          {
            getFood (_id: "${id}") {
              _id,
              name,
              imageUrl,
              createdDate,
            }
          }
          `
        })
        .then(data => {
          return data.data.getFood;
        });
    },
    getFoodsByKeyword(keyword) {
      return request
        .post('/api/food', {
          query: `
          {
            getFoodsByKeyword(keyword: "${keyword}") {
              _id,
              name,
              imageUrl,
              createdDate,
            }
          }`
        })
        .then(data => {
          return data.data.getFoodsByKeyword;
        });
    },
    getFoodsByRank(limit) {
      return request
        .post('/api/food', {
          query: `
          {
            getFoodsByRank(limit: ${limit}) {
              _id,
              name,
              imageUrl,
              calorie,
              protein,
              carbohydrate,
              fat,
              calcium,
              sodium,
              cholesterol,
              createdDate,
            }
          }`
        })
        .then(data => {
          return data.data.getFoodsByRank;
        });
    },
    getFoodByName(name) {
      return request
        .post('/api/food', {
          query: `
          {
            getFoodByName (name: "${name}") {
              _id,
              name,
              imageUrl,
              createdDate,
            }
          }
          `
        })
        .then(data => {
          return data.data.getFoodByName;
        });
    }
  },
  recipe: {
    model: `{
      _id,
      owner {
        _id
      },
      url,
      name,
      ingredients,
      steps,
      createdDate,
    }`,
    checkRecipeUrl(url) {
      return request
        .post('/api/recipe/checkRecipeUrl', {
          url: url
        })
        .then(data => {
          return data;
        });
    },
    createRecipe(recipe) {
      return request
        .post('/api/recipe', {
          query: `
              mutation {
                createRecipe (recipe: {
                      url: """${recipe.url}"""
                  }) ${this.model}
              }
          `
        })
        .then(data => {
          return data.data.createRecipe;
        });
    },
    getRecipeByUrl(url) {
      return request
        .post('/api/recipe', {
          query: `
            {
              getRecipeByUrl (url: "${url}") ${this.model}
            }
          `
        })
        .then(data => {
          return data.data.getRecipeByUrl;
        });
    }
  },
  delivery: {
    model: `{
      _id,
      owner {
        _id
      },
      url,
      name,
      serviceProvider,
      serviceProviderStoreId,
      createdDate,
    }`,
    createDelivery(delivery) {
      return request
        .post('/api/delivery', {
          query: `
              mutation (
                $url:String,
                $name:String,
                $serviceProvider:String, 
                $serviceProviderStoreId:String, 
              ) {
                createDelivery (delivery: {
                      url: $url,
                      name: $name,
                      serviceProvider: $serviceProvider,
                      serviceProviderStoreId: $serviceProviderStoreId
                  }) ${this.model}
              }
          `,
          variables: delivery
        })
        .then(data => {
          return data.data.createDelivery;
        });
    },
    getDeliveryByUrl(url) {
      return request
        .post('/api/delivery', {
          query: `
            {
              getDeliveryByUrl (url: "${url}") ${this.model}
            }
          `
        })
        .then(data => {
          return data.data.getDeliveryByUrl;
        });
    }
  },
  message: {
    sendMessage(userId, title, body, link) {
      return request.post('/api/message/sendMessage', {
        userId,
        title,
        body,
        link
      });
    },
    sendBeforeMealMessages(userId) {
      return request.post('/api/message/sendBeforeMealMessages', {
        userId: userId
      });
    },
    sendAfterMealMessages(userId) {
      return request.post('/api/message/sendAfterMealMessages', {
        userId: userId
      });
    }
  },
  like: {
    model: `{
      _id,
      mealId,
      owner,
      createdDate
    }`,
    getLikeByMealIdAndOwnerId(mealId, ownerId) {
      return request
        .post('/api/like', {
          query: `
            query getLikeByMealIdAndOwnerId($mealId: ID!, $ownerId: ID!) {
              getLikeByMealIdAndOwnerId (mealId: $mealId, ownerId: $ownerId) ${this.model}
            }
          `,
          variables: {
            mealId,
            ownerId
          }
        })
        .then(data => {
          return data.data.getLikeByMealIdAndOwnerId;
        });
    },
    createLike(like) {
      return request
        .post('/api/like', {
          query: `
                    mutation {
                        createLike (like: {
                            mealId: "${like.mealId}",
                            owner: {
                                _id: "${like.owner._id}"
                            }
                        }) {
                            _id,
                            mealId,
                            owner {
                                _id
                            }
                        }
                    }
                `
        })
        .then(data => {
          return data.data.createLike;
        });
    },
    deleteLike(like) {
      return request
        .post('/api/like', {
          query: `
                    mutation {
                        deleteLike (_id: "${like._id}")
                    }
                `
        })
        .then(data => {
          return data.data.deleteLike;
        });
    }
  },
  friendship: {
    model: `{
      _id,
      requester {
        _id,
        nickname,
        name,
        profileImageUrl
      },
      acceptor {
        _id,
        nickname,
        name,
        profileImageUrl
      },
      status,
      updatedDate,
      createdDate
    }`,
    createFriendship(acceptorId) {
      return request
        .post('/api/friendship', {
          query: `
          mutation ($friendship: FriendshipInput) {
            createFriendship (friendship: $friendship) ${this.model}
          }
        `,
          variables: {
            friendship: {
              acceptor: acceptorId
            }
          }
        })
        .then(data => {
          return data.data.createFriendship;
        });
    },
    getFriendships() {
      return request
        .post('/api/friendship', {
          query: `
          query {
            getFriendships ${this.model}
          }
        `
        })
        .then(data => {
          return data.data.getFriendships;
        });
    },
    updateFriendship(friendship) {
      return request
        .post('/api/friendship', {
          query: `
        mutation ($friendship: FriendshipInput) {
          updateFriendship (friendship: $friendship) {
            _id,
            status
          }
        }
      `,
          variables: { friendship }
        })
        .then(data => {
          return data.data.updateFriendship;
        });
    },
    deleteFriendship(friendship) {
      return request
        .post('/api/friendship', {
          query: `
        mutation ($_id: ID!) {
          deleteFriendship (_id: $_id) {
            _id,
            status
          }
        }
      `,
          variables: { _id: friendship._id }
        })
        .then(data => {
          return data.data.deleteFriendship;
        });
    }
  }
};
