<template>
  <section v-if="meal">
    <v-card flat tile>
      <v-list-item>
        <v-list-item-avatar
          v-if="meal.owner.profileImageUrl"
          size="42"
          @click="onClickStory"
          class="mr-3"
        >
          <v-img :src="meal.owner.profileImageUrl"> </v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-subtitle v-if="meal.owner.name" @click="onClickStory"
            ><b v-if="meal.owner.gender === 'bot'"
              >{{ meal.owner.name }}<span class="pl-1">Bot</span></b
            ><b v-else>{{ meal.owner.name }}</b
            >{{ ` • @${meal.owner.nickname}` }}
          </v-list-item-subtitle>
          <v-list-item-subtitle v-else @click="onClickStory"
            >{{ `@${meal.owner.nickname}` }}
          </v-list-item-subtitle>
          <v-list-item-title
            @click="onClickMeal"
            :ripple="$store.state.isDisplayTouch"
          >
            {{ meal.title }}
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn
            small
            icon
            color="grey"
            :ripple="$store.state.isDisplayTouch"
            @click.stop.prevent="isVisibleMore = true"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>

      <v-card-text class="pl-0 pr-0 py-0">
        <v-card flat tile
          ><v-img
            v-if="meal.files[0].type === 'image'"
            :aspect-ratio="1"
            :src="meal.files[0].url"
            :ripple="$store.state.isDisplayTouch"
            @load="onLoadImage"
            @click="onClickMeal"
            :max-width="meal.files[0].type === 'emoji' ? '120px' : ''"
          ></v-img>
          <v-row
            v-else-if="meal.files[0].type === 'emoji'"
            no-gutters
            justify="center"
            align="center"
            style="height: 150px;"
          >
            <v-col class="text-center">
              <v-img
                :src="meal.files[0].url"
                contain
                class="floob-meal-food-tag-img"
                :max-height="120"
                :ripple="$store.state.isDisplayTouch"
                @load="onLoadImage"
                @click="onClickMeal"
              >
              </v-img>
            </v-col>
          </v-row>
        </v-card>

        <div
          v-if="
            isLoadedContent &&
              (meal.store ||
                (meal.foods && meal.foods.length > 0) ||
                (meal.sourceUrl &&
                  convertSiteByUrl(meal.sourceUrl).code === 'instagram'))
          "
          class="pl-2 pb-2"
          style="margin-top: -30px;"
        >
          <div class="px-2">
            <template v-if="meal.store">
              <v-avatar size="24" class="mr-2">
                <v-btn
                  fab
                  dark
                  color="grey darken-3"
                  @click.stop.prevent="onClickStore"
                  :ripple="$store.state.isDisplayTouch"
                >
                  <v-icon small>mdi-storefront-outline</v-icon>
                </v-btn>
              </v-avatar>
            </template>
            <template v-if="meal.foods && meal.foods.length > 0">
              <v-avatar size="24" class="mr-2">
                <v-btn
                  fab
                  dark
                  color="grey darken-3"
                  @click.stop.prevent="isVisibleFood = true"
                  :ripple="$store.state.isDisplayTouch"
                >
                  <v-icon small>mdi-baguette</v-icon>
                </v-btn>
              </v-avatar>
            </template>
            <template
              v-if="convertSiteByUrl(meal.sourceUrl).code === 'instagram'"
            >
              <v-avatar size="24" class="mr-2">
                <v-btn
                  fab
                  dark
                  color="grey darken-3"
                  @click="openUrl(meal.sourceUrl)"
                  :ripple="$store.state.isDisplayTouch"
                >
                  <v-icon small>mdi-instagram</v-icon>
                </v-btn>
              </v-avatar>
            </template>
          </div>
        </div>
        <div v-if="meal.owner.gender != 'bot'" class="px-4 pt-4">
          <div class="px-2">
            {{ convertShortenMealDateString(meal.mealedDate) }}
            <template v-if="meal.review && meal.review.score > 0">
              •
              <v-icon small color="amber" style="margin-top: -2px;"
                >mdi-star</v-icon
              >
              {{ meal.review.score.toFixed(1) }}
            </template>
          </div>
        </div>
        <div class="px-4 py-2" v-if="meal.desc">
          <v-icon color="grey lighten-2">mdi-format-quote-open</v-icon>
          <div class="px-2" v-html="descHtml"></div>

          <template v-if="descLines.length > 5"
            ><v-btn
              v-if="isShowDescMore"
              text
              small
              :ripple="$store.state.isDisplayTouch"
              @click="onClickDescShort"
              color="grey"
              class="px-1"
              >간단히 보기</v-btn
            >
            <v-btn
              v-else
              text
              small
              :ripple="$store.state.isDisplayTouch"
              @click="isShowDescMore = true"
              color="grey"
              class="px-1"
              >... 더보기</v-btn
            >
          </template>

          <v-card
            v-if="descLinkSiteContent"
            rounded
            outlined
            flat
            class="px-2 py-0 mx-1 my-2"
            :color="$vuetify.theme.dark ? '#272727' : 'grey lighten-4'"
          >
            <v-list
              :color="$vuetify.theme.dark ? '#272727' : 'grey lighten-4'"
              dense
              class="pa-0"
            >
              <v-list-item
                class="pa-0"
                @click="openUrl(descLinkSiteContent.url)"
                :ripple="$store.state.isDisplayTouch"
              >
                <v-list-item-avatar v-if="descLinkSiteContent.ogImage" rounded>
                  <v-img :src="descLinkSiteContent.ogImage"></v-img>
                </v-list-item-avatar>
                <v-list-item-content class="pa-0">
                  <v-list-item-title>
                    <v-avatar
                      v-if="descLinkSiteContent.iconUrl"
                      size="12"
                      tile
                      class="mr-1"
                    >
                      <v-img
                        :src="
                          `${
                            !descLinkSiteContent.iconUrl.includes('http')
                              ? `http:${descLinkSiteContent.iconUrl}`
                              : descLinkSiteContent.iconUrl
                          }`
                        "
                      ></v-img> </v-avatar
                    ><b class="pr-1">
                      {{
                        `${descLinkSiteContent.ogTitle ||
                          descLinkSiteContent.title}`
                      }}</b
                    >
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{
                      `${descLinkSiteContent.ogDescription ||
                        descLinkSiteContent.description}`
                    }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </div>
        <v-timeline v-if="false" align-center dense class="pt-4">
          <v-timeline-item
            v-if="false"
            small
            fill-dot
            color="grey"
            :icon="'mdi-format-quote-open'"
          >
            <div class="px-2" v-html="descHtml"></div>
          </v-timeline-item>
          <v-timeline-item v-if="meal.store" small fill-dot class="pb-0">
            <template v-slot:icon>
              <v-avatar size="26">
                <img
                  v-if="storeSiteContent && storeSiteContent.iconUrl"
                  :src="storeSiteContent.iconUrl"
                />
                <img
                  v-else
                  :src="
                    '//t1.daumcdn.net/localimg/localimages/07/common/kakaomap_favicon.ico'
                  "
                />
              </v-avatar>
            </template>
            <v-list dense style="margin-left:-32px;">
              <v-list-item
                :ripple="$store.state.isDisplayTouch"
                @click="showStorePopup(meal.store)"
              >
                <v-list-item-avatar tile size="24" class="mr-2">
                  <b style="font-size:0.8em; color:grey;">식당</b>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    <b>{{ meal.store.name }}</b>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ meal.store.address }}
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-avatar
                  rounded
                  v-if="storeSiteContent && storeSiteContent.ogImage"
                >
                  <v-img :src="storeSiteContent.ogImage"> </v-img>
                </v-list-item-avatar>
              </v-list-item>
            </v-list>
          </v-timeline-item>
          <v-timeline-item
            v-if="meal.sourceUrl"
            small
            fill-dot
            class="pt-0 floob-meal-card"
            color="transparent"
          >
            <template v-slot:icon>
              <v-avatar size="26">
                <v-btn
                  v-if="isSourceSiteContentLoading"
                  x-small
                  icon
                  :loading="true"
                ></v-btn>
                <img v-else :src="sourceSiteContent.iconUrl" />
              </v-avatar>
            </template>
            <v-list dense style="margin-left:-32px;">
              <v-list-item
                :ripple="$store.state.isDisplayTouch"
                @click="openUrl(meal.sourceUrl)"
              >
                <v-list-item-avatar tile size="24" class="mr-2">
                  <b style="font-size:0.8em; color:grey;">출처</b>
                </v-list-item-avatar>
                <v-list-item-content v-if="isSourceSiteContentLoading">
                  <v-list-item-subtitle>
                    {{ meal.sourceUrl }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content v-else
                  ><v-list-item-title
                    ><b>{{
                      convertSiteNameBySiteContent(sourceSiteContent)
                    }}</b>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ `${sourceSiteContent.title}` }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-timeline-item>
        </v-timeline>
      </v-card-text>

      <v-card-actions>
        <v-btn v-show="false" text disabled>조회 32</v-btn>
        <v-btn text disabled class="pl-1 ml-1"
          ><b
            >좋아요
            {{
              meal.likeCount + meal.randomLikeCount > 0
                ? meal.likeCount + meal.randomLikeCount
                : ''
            }}</b
          ></v-btn
        >
        <v-btn v-show="false" text small disabled
          >댓글 {{ meal.replyCount }}</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn
          icon
          :ripple="$store.state.isDisplayTouch"
          v-if="meal.liked && this.$store.state.isLoggedIn"
          @click="onClickUnlike"
        >
          <v-icon color="red lighten-1">mdi-heart</v-icon>
        </v-btn>
        <v-btn
          icon
          :ripple="$store.state.isDisplayTouch"
          v-else
          @click="onClickLike"
        >
          <v-icon color="grey lighten-1">mdi-heart-outline</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>

    <floob-meal-dialog
      :dialog.sync="mealDialog"
      v-on:updated="onUpdatedMeal"
      v-on:deleted="onDeletedMeal"
    >
    </floob-meal-dialog>

    <floob-story-dialog :dialog.sync="storyDialog"></floob-story-dialog>

    <floob-login-dialog
      :dialog.sync="loginDialog"
      :comment="'로그인하면 좋아요를 할 수 있습니다 ❤️'"
    ></floob-login-dialog>

    <v-bottom-sheet v-model="isVisibleMore">
      <v-list class="pa-2 floob-action-sheet">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <h1>식사</h1>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-avatar rounded size="48">
            <template v-if="meal.files.length > 0">
              <v-img
                v-if="meal.files[0].type === 'image'"
                :src="meal.files[0].url"
              ></v-img>
              <v-img
                v-if="meal.files[0].type === 'emoji'"
                :width="24"
                :height="24"
                contain
                :src="meal.files[0].url"
              ></v-img>
            </template>
            <v-icon v-else size="36">mdi-image</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-subtitle v-if="meal.owner.name">
              <v-avatar size="18" class="mr-1">
                <v-img :src="meal.owner.profileImageUrl"> </v-img>
              </v-avatar>
              <b v-if="meal.owner.gender === 'bot'"
                >{{ meal.owner.name }}<span class="pl-1">Bot</span></b
              ><b v-else>{{ meal.owner.name }}</b
              >{{ ` • @${meal.owner.nickname}` }}
            </v-list-item-subtitle>
            <v-list-item-subtitle v-else>
              <v-avatar size="18" class="pr-1">
                <v-img :src="meal.owner.profileImageUrl"> </v-img> </v-avatar
              >{{ `@${meal.owner.nickname}` }}
            </v-list-item-subtitle>
            <v-list-item-title v-if="meal.title">
              {{ meal.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :ripple="$store.state.isDisplayTouch" @click="onClickMeal">
          <v-list-item-content>
            <v-list-item-title>상세보기</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          :ripple="$store.state.isDisplayTouch"
          @click="onClickShare"
        >
          <v-list-item-content>
            <v-list-item-title>공유하기</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <floob-message-dialog :dialog.sync="messageDialog"></floob-message-dialog>
    </v-bottom-sheet>

    <v-bottom-sheet v-if="meal && meal.store" v-model="isVisibleStore">
      <v-list class="pa-2 floob-action-sheet">
        <v-subheader>
          <b>식당 정보</b>
        </v-subheader>
        <v-list-item
          :ripple="$store.state.isDisplayTouch"
          @click="showStorePopup(meal.store)"
        >
          <v-list-item-avatar>
            <v-img
              v-if="meal.store.serviceProvider === 'kakao-map'"
              :src="'https://image.floob.co.kr/kakaomap_icon.ico'"
            >
            </v-img>
            <v-img
              v-else-if="meal.store.serviceProvider === 'naver-map'"
              :src="'https://image.floob.co.kr/navermap_icon.ico'"
            >
            </v-img>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>
              <b>{{ meal.store.name }}</b>
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ meal.store.address }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <div v-if="meal.store.serviceProvider === 'kakao-map'" class="px-4">
          <v-img
            v-if="storeSiteContent"
            :src="storeSiteContent.ogImage"
            :ripple="$store.state.isDisplayTouch"
            @click="showStorePopup(meal.store)"
            aspect-ratio="1.6"
          >
          </v-img>
          <v-btn color="grey" block text :loading="!storeSiteContent"></v-btn>
        </div>
      </v-list>
    </v-bottom-sheet>

    <v-bottom-sheet
      v-if="meal && meal.foods"
      v-model="isVisibleFood"
      scrollable
    >
      <v-card tile class="floob-action-sheet">
        <v-list
          class="pa-2 floob-action-sheet"
          style="padding-bottom: 0px !important;"
        >
          <v-subheader>
            <b>음식 정보</b>
          </v-subheader>
        </v-list>
        <v-card-text class="pa-0">
          <v-list
            class="pa-2 floob-action-sheet"
            style="padding-bottom: 0px !important;"
          >
            <v-list-item v-for="(food, idx) in meal.foods" :key="idx">
              <v-list-item-avatar size="24" rounded class="mr-2">
                <v-icon x-small>mdi-circle-outline</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ food.name }} </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action v-if="food.calorie && !isNaN(food.calorie)">
                {{ `${Math.round(food.calorie)}kcal` }}
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-list class="floob-action-sheet">
            <v-list-item>
              <v-list-item-content class="pt-0">
                <div style="color:grey">
                  <div style="color:rgb(171 171 171); font-size:0.8em;">
                    음식별로 칼로리 정보가 표기 될 수 있습니다.<br />
                    이 정보는 식품의약품안전처의 식품영양성분 데이터베이스를
                    참고하여 제공되며 이는 대략적인 수치이며 실제 섭취량과
                    차이가 있을 수 있습니다.
                  </div>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-actions>
      </v-card>
    </v-bottom-sheet>
  </section>
</template>

<script>
import moment from 'moment';

import api from '@/api';
import linkApi from '@/api/link';

import wvc from '@/util/webViewController';
import converter from '@/util/converter';

import copy from 'clipboard-copy';

import floobMealDialog from '@/components/mealDialog.vue';
import floobStoryDialog from '@/components/storyDialog.vue';
import floobLoginDialog from '@/components/loginDialog.vue';
import floobMessageDialog from '@/components/messageDialog.vue';

export default {
  components: {
    floobMealDialog,
    floobStoryDialog,
    floobLoginDialog,
    floobMessageDialog
  },
  props: {
    meal: Object
  },
  data() {
    return {
      // modules
      mealDialog: null,
      storyDialog: null,
      loginDialog: null,
      messageDialog: null,

      // controlls
      isVisibleMore: false,
      isShowDescMore: false,
      isSourceSiteContentLoading: false,
      isLoadedContent: false,
      isVisibleStore: false,
      isVisibleFood: false,

      // datas
      storeSiteContent: null,
      descLinkSiteContent: null,
      sourceSiteContent: null
    };
  },
  methods: {
    onLoadImage() {
      this.isLoadedContent = true;
    },
    onClickStore() {
      this.isVisibleStore = true;
      if (this.meal.store) {
        if (
          this.$store.state.sourceLinkCache.has(
            this.meal.store.serviceProviderPlaceUrl
          )
        ) {
          let source = this.$store.state.sourceLinkCache.get(
            this.meal.store.serviceProviderPlaceUrl
          );
          this.storeSiteContent = source;
        } else {
          linkApi
            .getSiteContents(this.meal.store.serviceProviderPlaceUrl)
            .then(siteContents => {
              this.storeSiteContent = siteContents;
              this.$store.state.sourceLinkCache.set(
                this.meal.store.serviceProviderPlaceUrl,
                siteContents
              );
            });
        }
      }
    },
    onClickStory() {
      this.storyDialog.show(this.meal.owner._id);
    },
    onClickMeal() {
      this.isVisibleMore = false;
      if (this.$cookies.get('webview')) {
        this.mealDialog.show(this.meal);
      } else {
        this.$router.push({ name: 'meal', params: { mealId: this.meal._id } });
      }
    },
    onUpdatedMeal(updatedMeal) {
      //this.meal = updatedMeal;
      this.$emit('updated', updatedMeal);

      this.$store.dispatch('set', {
        key: 'syncUpdatedMeal',
        value: updatedMeal
      });
    },
    onDeletedMeal(deletedMeal) {
      this.mealDialog.hide();
      this.$emit('deleted', deletedMeal);
    },
    openUrl(url) {
      if (this.$cookies.get('webview')) {
        wvc.openUrl(url);
      } else {
        window.open(url);
      }
    },
    showStorePopup(store) {
      if (store) {
        if (store.serviceProvider === 'kakao-map') {
          if (this.$cookies.get('webview')) {
            wvc.openUrl(
              `https://place.map.kakao.com/m/${store.serviceProviderPlaceId}`
            );
          } else {
            if (this.$store.state.isMobileDevice) {
              window.open(
                `https://place.map.kakao.com/m/${store.serviceProviderPlaceId}`
              );
            } else {
              window.open(
                `https://place.map.kakao.com/${store.serviceProviderPlaceId}`
              );
            }
          }
        }
      }
    },
    convertDetailedMealedDateString(mealedDate) {
      return moment(mealedDate).format('LLLL');
    },
    convertShortenMealDateString(mealedDate) {
      return converter.convertShortenMealDateString(mealedDate);
    },
    onClickShare() {
      this.isVisibleMore = false;
      let shareLink = `https://link.floob.co.kr/meal/${this.meal._id}`;
      if (
        document.location.protocol === 'https:' &&
        navigator &&
        typeof navigator.share === 'function'
      ) {
        navigator.share({
          text: `Floob: 식사기록 인 라이프\n@${
            this.meal.owner.nickname
          }\n${this.convertShortenMealDateString(this.meal.mealedDate)}\n${this
            .meal.title || ''}`,
          url: shareLink
        });
      } else {
        copy(shareLink).then(() => {
          this.messageDialog.alert('클립보드에 링크가 복사 되었습니다.');
        });
      }
    },
    convertSiteByUrl(url) {
      return converter.convertSite(url);
    },
    convertSiteNameBySiteContent(siteContent) {
      if (siteContent) {
        return converter.convertSiteNameBySiteContent(siteContent);
      } else {
        return null;
      }
    },
    onClickDescShort() {
      this.$emit('focus', this.meal);
      this.isShowDescMore = false;
    },
    onClickLike() {
      if (this.$store.state.isLoggedIn && this.$store.state.user) {
        const like = {
          mealId: this.meal._id,
          owner: {
            _id: this.$store.state.user._id
          }
        };
        api.like
          .createLike(like)
          .then(createdLike => {
            this.meal.liked = createdLike;
            if (this.meal.likeCount > 0) {
              this.meal.likeCount++;
            } else {
              this.meal.likeCount = 1;
            }
            this.$store.dispatch('set', {
              key: 'syncUpdatedMeal',
              value: this.meal
            });
          })
          .catch(err => {
            console.error('Fail to create like', err);
          });
      } else {
        //alert('로그인이 필요합니다.');
        this.loginDialog.show();
      }
    },
    onClickUnlike() {
      if (
        this.$store.state.isLoggedIn &&
        this.$store.state.user &&
        this.meal.liked
      ) {
        if (this.meal.liked._id) {
          api.like.deleteLike(this.meal.liked).then(() => {
            if (this.meal.likeCount > 0) {
              this.meal.likeCount--;
            } else {
              this.meal.likeCount = 0;
            }
          });
        }
        this.meal.liked = null;
      }
    }
  },
  computed: {
    isThread() {
      return this.meal && (this.meal.store || this.meal.sourceUrl);
    },
    descLines() {
      if (this.meal.desc) {
        return this.meal.desc.split('\n');
      } else {
        return [];
      }
    },
    descHtml() {
      if (this.meal.desc) {
        if (this.isShowDescMore) {
          return converter.convertTextToHtml(this.meal.desc);
        } else {
          return converter.convertTextToHtml(
            this.descLines.slice(0, 5).join('\n')
          );
        }
      } else {
        return '';
      }
    },
    descUrls() {
      if (this.meal && this.meal.desc) {
        var urlRegex = /(https?:\/\/[^\s]+)/g;
        return this.meal.desc.match(urlRegex);
      } else {
        return [];
      }
    }
  },
  created() {
    if (this.meal) {
      if (this.meal.sourceUrl) {
        this.isSourceSiteContentLoading = true;

        if (this.$store.state.sourceLinkCache.has(this.meal.sourceUrl)) {
          let source = this.$store.state.sourceLinkCache.get(
            this.meal.sourceUrl
          );
          this.sourceSiteContent = source;

          this.isSourceSiteContentLoading = false;
        } else {
          linkApi
            .getSiteContents(this.meal.sourceUrl)
            .then(siteContents => {
              this.sourceSiteContent = siteContents;
              this.$store.state.sourceLinkCache.set(
                this.meal.sourceUrl,
                siteContents
              );
            })
            .finally(() => {
              this.isSourceSiteContentLoading = false;
            });
        }
      }

      if (this.meal.desc && this.descUrls && this.descUrls.length > 0) {
        if (this.$store.state.sourceLinkCache.has(this.descUrls[0])) {
          let source = this.$store.state.sourceLinkCache.get(this.descUrls[0]);
          this.descLinkSiteContent = source;
        } else {
          linkApi.getSiteContents(this.descUrls[0]).then(siteContents => {
            // console.log('descLinkSiteContent', siteContents);
            this.descLinkSiteContent = siteContents;
            this.$store.state.sourceLinkCache.set(
              this.descUrls[0],
              siteContents
            );
          });
        }
      }
    }
  }
};
</script>

<style>
.floob-meal-card .v-timeline-item__dot {
  box-shadow: unset !important;
}
</style>
