<template>
  <section>
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xs"
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card tile>
        <v-card-title class="floob-toolbar-title">
          <v-btn
            text
            x-large
            @click="isVisible = false"
            :ripple="$store.state.isDisplayTouch"
            class="floob-toolbar-button-left"
          >
            <v-icon>mdi-chevron-down</v-icon></v-btn
          >
          <v-toolbar-title style="width:33% !important;">
            <h1 v-if="isScrolled && instagramUserName">
              {{ isScrolled && instagramUserName ? instagramUserName : '' }}
            </h1>
            <img
              v-else
              style="width:19px; margin-bottom: -3px; margin-right:3px;"
              src="https://floob.blob.core.windows.net/image/instagram-icon.ico"
            />
          </v-toolbar-title>
          <v-btn
            text
            x-large
            :ripple="$store.state.isDisplayTouch"
            class="pr-0"
            disabled
          >
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-0" v-scroll.self="onScroll">
          <v-container class="py-0" id="floob-instagram-content">
            <v-row no-gutters v-if="instagramUserName">
              <v-col>
                <v-list class="pa-0">
                  <v-list-item class="px-1">
                    <v-list-item-content>
                      <v-list-item-subtitle>연결된 계정</v-list-item-subtitle>
                      <v-list-item-title
                        ><h1>{{ `${instagramUserName}` }}</h1>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                class="pa-0"
                cols="4"
                v-for="content in contents"
                :key="content.id"
              >
                <v-card tile flat @click="onClickContent(content)">
                  <v-img
                    :src="content.media_url"
                    aspect-ratio="1"
                    class="white--text align-end"
                  >
                    <v-card-title
                      v-if="false"
                      style="font-size:0.7em"
                      v-text="convertContentDate(content.timestamp)"
                    ></v-card-title>
                  </v-img>
                </v-card>
              </v-col>
            </v-row>
            <v-row v-if="isNoMoreContents">
              <v-col
                ><v-btn
                  block
                  text
                  color="grey"
                  :ripple="$store.state.isDisplayTouch"
                  disabled
                  >더이상 게시물이 없습니다</v-btn
                >
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col
                ><v-btn
                  block
                  text
                  color="grey"
                  :loading="isLoading"
                  :ripple="$store.state.isDisplayTouch"
                  @click="getInstagramMedias"
                  >더보기</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
          <!-- 
          <v-list v-else>
            <v-list-item
              v-for="content in contents"
              :key="content.id"
              @click="onClickContent(content)"
            >
              <v-list-item-avatar rounded>
                <v-img :src="content.media_url"> </v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ convertCaptionTitle(content) }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ `${convertContentDate(content.timestamp)} 게시됨` }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          -->
        </v-card-text>
      </v-card>
      <floob-message-dialog :dialog.sync="messageDialog"></floob-message-dialog>
      <floob-message-snackbar
        :dialog.sync="messageSnackbar"
      ></floob-message-snackbar>
    </v-dialog>
  </section>
</template>

<script>
import _ from 'lodash';
import $ from 'jquery';
import moment from 'moment';

import wvc from '@/util/webViewController';
import api from '@/api';
import instagramApi from '@/api/instagram';

import floobMessageDialog from '@/components/messageDialog.vue';
import floobMessageSnackbar from '@/components/messageSnackbar.vue';

export default {
  components: {
    floobMessageDialog,
    floobMessageSnackbar
  },
  data() {
    return {
      // modules
      messageDialog: null,
      messageSnackbar: null,

      // controlls
      isVisible: false,
      isLoading: false,
      afterCursor: null,
      isNoMoreContents: false,
      isScrolled: false,

      // datas
      instagramUserName: null,
      contents: []
    };
  },
  methods: {
    init() {
      this.isLoading = false;
      this.afterCursor = null;
      this.isNoMoreContents = null;
      this.isScrolled = false;
      this.instagramUserName = null;
      this.contents = [];
    },
    onScroll(e) {
      if (e.target.scrollTop > 0) {
        this.isScrolled = true;
      } else {
        this.isScrolled = false;
      }

      const loadingBufferSize = 100;
      if (
        !this.isLoading &&
        !this.isNoMoreContents &&
        e.target.scrollTop + this.frameHeight >=
          $('#floob-instagram-content').height() - loadingBufferSize
      ) {
        this.getInstagramMedias();
      }
    },
    onClickContent(content) {
      console.log('onClickContent', content);
      this.$emit('selected', content);
      this.isVisible = false;
    },
    convertCaptionTitle(media) {
      if (media.caption) {
        let captionLines = media.caption.split('\n');
        return captionLines[0];
      } else {
        return null;
      }
    },
    convertContentDate(timestamp) {
      return moment(timestamp).format('LLLL');
    },
    checkAuthorizedInstagram() {
      api.user
        .getUserBySession()
        .then(user => {
          if (user && user.nickname) {
            if (
              user &&
              user.auth &&
              user.auth.instagram &&
              user.auth.instagram.isAuthenticated
            ) {
              this.messageSnackbar.show('인스타그램 인증이 완료 되었습니다.');
              this.$store.dispatch('set', { key: 'user', value: { ...user } });
              setTimeout(() => {
                this.getInstagramMedias();
              }, 1000);
            } else {
              this.messageDialog.show({
                content: '인스타그램 인증이 실패하였습니다.',
                isVisibleCancelButton: false,
                isVisibleOkButton: false,
                additionalButtons: [
                  {
                    text: '확인',
                    action: () => {
                      this.isVisible = false;
                    }
                  }
                ]
              });
            }
          }
        })
        .catch(err => {
          if (err) {
            console.error(err);
          }
        });
    },
    authorizeInstagram() {
      const FLOOB_INSTAGRAM_APP_ID = '157797632830975';
      const REDIRECTION_URL = 'https://floob.co.kr/auth/instagram.html';
      let instagramAuthPopup = window.open(
        `https://api.instagram.com/oauth/authorize?client_id=${FLOOB_INSTAGRAM_APP_ID}&scope=user_profile,user_media&response_type=code&redirect_uri=${REDIRECTION_URL}`
      );
      var instagramAuthPopupTick = setInterval(() => {
        if (instagramAuthPopup.closed) {
          clearInterval(instagramAuthPopupTick);
          console.log('instagramAuthPopup closed.');

          this.checkAuthorizedInstagram();
        }
      }, 500);
    },
    getInstagramMedias() {
      this.isLoading = true;

      let params = {
        fields: 'id,media_type,media_url,permalink,timestamp,username,caption',
        access_token: this.$store.state.user.auth.instagram.accessToken
      };
      if (this.afterCursor) {
        params.after = this.afterCursor;
      }
      return instagramApi
        .getMedias(params)
        .then(data => {
          if (
            data &&
            data.paging &&
            data.paging.cursors &&
            data.paging.cursors.after
          ) {
            this.afterCursor = data.paging.cursors.after;
          } else {
            this.afterCursor = null;
            this.isNoMoreContents = true;
          }
          this.contents = this.contents.concat(
            _.filter(data.data, c => {
              return (
                c.media_type === 'IMAGE' || c.media_type === 'CAROUSEL_ALBUM'
              );
            })
          );
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  },
  computed: {
    frameHeight() {
      let headerHeight = 56;
      return window.innerHeight - headerHeight;
    },
    isAuthorizedInstagramUser() {
      return (
        this.$store.state.user &&
        this.$store.state.user.auth &&
        this.$store.state.user.auth.instagram &&
        this.$store.state.user.auth.instagram.isAuthenticated &&
        this.$store.state.user.auth.instagram.accessToken
      );
    }
  },
  created() {
    if (
      this.$cookies.get('webview') &&
      this.$cookies.get('platform') === 'ios'
    ) {
      const checkAuthorizedInstagram = () => {
        this.checkAuthorizedInstagram();
      };
      window.FloobWebView.checkAuthorizedInstagram = checkAuthorizedInstagram;
      window.Floob._webview.checkAuthorizedInstagram = checkAuthorizedInstagram;
    }

    this.$emit('update:dialog', {
      show: () => {
        this.init();
        this.isVisible = true;
        this.isLoading = true;

        setTimeout(() => {
          if (!this.isAuthorizedInstagramUser) {
            if (this.$cookies.get('webview')) {
              wvc.openInstagramLogin();
            } else {
              this.authorizeInstagram();
            }
          } else {
            instagramApi
              .getUser({
                fields: 'account_type,id,media_count,username',
                access_token: this.$store.state.user.auth.instagram.accessToken
              })
              .then(data => {
                console.log('instagramApi.getUser', data);
                this.instagramUserName = data.username;
              });

            this.getInstagramMedias().catch(err => {
              console.log('instagram getMedias error occured', err);

              return api.auth.expireInstagramAuth().then(() => {
                this.checkAuthorizedInstagram();
                if (this.$cookies.get('webview')) {
                  wvc.openInstagramLogin();
                } else {
                  this.authorizeInstagram();
                }
              });
            });
          }
        }, 1000);
      },
      hide: () => {
        this.init();
        this.isVisible = false;
      }
    });
  }
};
</script>

<style></style>
