<template>
  <section>
    <v-dialog
      v-model="isVisible"
      fullscreen
      transition="slide-x-reverse-transition"
      persistent
      scrollable
    >
      <v-card tile>
        <v-card-title class="floob-toolbar-title">
          <v-btn
            text
            x-large
            @click="onClickClose"
            :ripple="$store.state.isDisplayTouch"
            class="pl-0"
          >
            취소
          </v-btn>

          <v-toolbar-title><h1>음식 태그</h1></v-toolbar-title>
          <v-btn
            text
            x-large
            :ripple="$store.state.isDisplayTouch"
            :loading="isLoading"
            class="pr-0"
            @click="onClickSave"
            >저장
          </v-btn>
        </v-card-title>

        <v-container fluid class="pa-0">
          <v-row no-gutters>
            <v-col
              sm="6"
              offset-sm="3"
              md="4"
              offset-md="4"
              xl="2"
              offset-xl="5"
              class="pa-0"
            >
              <v-card
                v-if="meal && meal.files && meal.files[0].type === 'image'"
                flat
                :rounded="!$vuetify.breakpoint.xs ? 'xl' : false"
                :tile="$vuetify.breakpoint.xs ? true : false"
              >
                <v-img
                  :src="meal.files[0].data || meal.files[0].url"
                  aspect-ratio="1"
                  @click="onClickMealContent"
                  ref="mealFoodTagContentImg"
                  class="floob-meal-food-tag-img"
                  @load="onLoadImage"
                >
                  <template v-if="isLoadedContent">
                    <v-btn
                      v-for="(tag, idx) in tags"
                      :key="idx"
                      v-bind:style="{
                        color: 'white',
                        left: `${tag.position.x *
                          $refs.mealFoodTagContentImg.$el.clientWidth}px`,
                        top: `${tag.position.y *
                          $refs.mealFoodTagContentImg.$el.clientHeight}px`
                      }"
                      depressed
                      small
                      color="rgb(39 39 39 / 90%)"
                      class="px-2"
                      :ripple="$store.state.isDisplayTouch"
                      @click.stop.prevent="onClickDeleteTag(idx)"
                      >{{ tag.food.name }}
                    </v-btn>
                  </template>
                </v-img>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        <v-list class="pb-0">
          <v-list-item class="pb-2">
            <v-list-item-content>
              <div style="color:grey">
                <div style="color:rgb(171 171 171);">
                  식사 사진을 눌러서 음식을 태그하세요.
                  <br />태그를 다시 눌러서 삭제 할 수 있습니다.
                </div>
              </div></v-list-item-content
            >
          </v-list-item>
          <v-divider></v-divider>
          <template v-if="tags.length > 0"
            ><v-subheader class="mt-2">
              <h1>태그한 음식 정보</h1>
              <v-tooltip
                v-model="isVisibleHelp"
                bottom
                nudge-bottom
                transition="slide-y-reverse-transition"
                class="mr-4"
                max-width="94%"
                color="black"
                :open-on-click="true"
                :open-on-hover="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    small
                    color="grey"
                    @click="isVisibleHelp = !isVisibleHelp"
                  >
                    <v-icon small>mdi-help-circle-outline</v-icon>
                  </v-btn>
                </template>
                <span v-if="false"
                  >음식별로 칼로리 정보가 표기 될 수 있습니다.<br />
                  이 정보는 식품의약품안전처의 식품영양성분 데이터베이스를
                  참고하여 제공되며 이는 대략적인 수치이며 실제 섭취량과 차이가
                  있을 수 있습니다.</span
                >
                <span
                  >음식별로 섭취한 열량을 입력하면 리포트에서 일별 합산 열량을
                  확인 할 수 있습니다.</span
                >
              </v-tooltip>
            </v-subheader>
          </template>
        </v-list>
        <v-card-text class="pa-0">
          <v-list class="py-0">
            <v-list-item v-for="(tag, idx) in tags" :key="idx">
              <v-list-item-content>
                <v-list-item-title>{{ tag.food.name }} </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-text-field
                  v-model="tag.calorie"
                  solo-inverted
                  flat
                  :autofocus="false"
                  hide-details
                  dense
                  style="max-width: 120px;"
                  suffix="kcal"
                  type="number"
                  class="floob-input-text-align-right"
                >
                </v-text-field>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions v-if="isAdmin">
          <v-btn color="grey" text @click="onClickSendAutoFoodTagMessage">
            자동 음식 태그 완료
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <floob-food-selection-dialog
      :dialog.sync="foodSelectionDialog"
      v-on:selected="onSelectedFood"
    >
    </floob-food-selection-dialog>
  </section>
</template>

<script>
import floobFoodSelectionDialog from '@/components/foodSelectionDialog.vue';

import _ from 'lodash';
import api from '@/api';

export default {
  components: {
    floobFoodSelectionDialog
  },
  data() {
    return {
      // modules
      foodSelectionDialog: null,

      // controlls
      isVisible: false,
      isLoading: false,
      isLoadedContent: false,
      selectedPosition: null,
      isVisibleHelp: false,

      // datas
      meal: null,
      tags: []
    };
  },
  methods: {
    init() {
      this.isLoading = false;
      this.isLoadedContent = false;
      this.meal = null;
      this.tags = [];
      this.isVisibleHelp = false;
    },
    onLoadImage() {
      this.isLoadedContent = true;
    },
    onClickClose() {
      this.init();
      this.isVisible = false;
    },
    onClickSave() {
      this.meal = null;
      _.forEach(this.tags, tag => {
        tag.calorie = parseFloat(tag.calorie);
      });
      this.$emit('selected', this.tags);
    },
    onClickMealContent(e) {
      console.log(
        'onClickMealContent',
        `imageSize={width=${
          this.$refs.mealFoodTagContentImg.$el.clientWidth
        },height=${
          this.$refs.mealFoodTagContentImg.$el.clientHeight
        }}, position={x=${e.offsetX /
          this.$refs.mealFoodTagContentImg.$el.clientWidth},y=${e.offsetY /
          this.$refs.mealFoodTagContentImg.$el.clientHeight}}`
      );

      if (
        e.offsetX / this.$refs.mealFoodTagContentImg.$el.clientWidth < 0.95 &&
        e.offsetY / this.$refs.mealFoodTagContentImg.$el.clientHeight < 0.95
      ) {
        this.selectedPosition = {
          x: e.offsetX / this.$refs.mealFoodTagContentImg.$el.clientWidth,
          // * this.$refs.mealFoodTagContentImg.$el.clientWidth,
          y: e.offsetY / this.$refs.mealFoodTagContentImg.$el.clientHeight
          // * this.$refs.mealFoodTagContentImg.$el.clientHeight
        };

        console.log(
          `clickedPosition={x: ${this.selectedPosition.x *
            this.$refs.mealFoodTagContentImg.$el.clientWidth}, y: ${this
            .selectedPosition.y *
            this.$refs.mealFoodTagContentImg.$el.clientHeight}}`
        );

        this.foodSelectionDialog.show();
      }
    },
    onSelectedFood(food) {
      this.tags.push({
        food: food,
        position: this.selectedPosition,
        calorie: food.calorie
      });

      this.foodSelectionDialog.hide();
    },
    onClickDeleteTag(idx) {
      this.tags.splice(idx, 1);
    },
    onClickSendSuggestFoodMessage() {
      return api.message.sendMessage(
        this.meal.owner._id,
        `혹시 ${this.tags[0].food.name} 드셨나요?`,
        `어떤 음식을 드셨는지 식사 기록에 태그해보세요. 보다 체계적으로 식사 기록을 관리 하실 수 있습니다. 👀`,
        `https://floob.co.kr/tag-food/${this.meal._id}?foodId=${this.tags[0].food._id}&x=${this.tags[0].position.x}&y=${this.tags[0].position.y}`
      );
    },
    onClickSendAutoFoodTagMessage() {
      api.user.getUser(this.meal.owner._id).then(mealOwner => {
        if (this.tags.length <= 0) {
          alert('등록 된 음식 태그가 없습니다.');
          return;
        }

        if (!mealOwner.autoFoodTag) {
          alert('자동 음식 태그 비활성화 상태입니다.');
          return;
        }

        return api.message
          .sendMessage(
            mealOwner._id,
            `자동 음식 태그 완료 🤖`,
            `${
              this.tags[this.tags.length - 1].food.name
            } 드셨나요?\n음식 태그를 통해 보다 체계적으로 식사를 관리해보세요 👀`,
            `https://floob.co.kr/tag-food/${this.meal._id}`
          )
          .then(() => {
            alert('메시지가 발송 되었습니다.');
          });
      });
    }
  },
  computed: {
    isAdmin() {
      return (
        this.$store.state.isLoggedIn &&
        this.$store.state.user.introduction &&
        this.$store.state.user.introduction.includes(`I am a floob`)
      );
    }
  },
  created() {
    this.$emit('update:dialog', {
      show: meal => {
        this.init();
        this.meal = meal;
        if (Array.isArray(this.meal.foodTags)) {
          this.meal.foodTags.forEach(tag => {
            this.tags.push(tag);
          });
        }

        if (Array.isArray(this.meal.unsavedFoodTags)) {
          this.meal.unsavedFoodTags.forEach(tag => {
            this.tags.push(tag);
          });
        }
        this.isVisible = true;
      },
      hide: () => {
        this.isVisible = false;
      }
    });
  }
};
</script>

<style>
.floob-input-text-align-right input {
  text-align: right;
}
</style>
