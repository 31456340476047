<template>
  <section>
    <v-bottom-sheet v-model="isVisible" overlay-opacity="0.64">
      <v-list class="floob-action-sheet pa-2">
        <template v-if="quickMode">
          <v-list-item style="height:53px">
            <v-list-item-content>
              <v-list-item-title>
                <h1>식사기록 추가</h1>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <template v-if="isLoading">
                <span> <v-btn text small :loading="true"></v-btn></span>
              </template>
            </v-list-item-action>
          </v-list-item>
          <v-list-item class="pb-2">
            <v-list-item-content>
              <v-list-item-subtitle>
                식사 기록 방식을 선택해주세요
              </v-list-item-subtitle>
              <div style="color:grey" class="pt-1">
                <div style="color:rgb(171 171 171); font-size:0.8em;">
                  타임라인에 등록된 후 게시물을 선택하여 식사의 상세
                  기록(식사시간, 제목 및 내용, 평가, 음식 정보, 식당 정보 등)을
                  추가하거나 수정 할 수 있습니다.
                </div>
              </div></v-list-item-content
            >
          </v-list-item>
        </template>
        <v-list-item v-else class="pb-2">
          <v-list-item-content>
            <div style="color:rgb(171 171 171); width:100%">
              <div v-if="meal && meal.files && meal.files.length > 0">
                <v-container
                  v-if="
                    meal.files[0].type === 'image' ||
                      meal.files[0].type === 'emoji'
                  "
                  fluid
                  class="px-0 pt-2 pb-6"
                >
                  <v-row no-gutters>
                    <v-col sm="6" md="4" xl="3">
                      <v-card flat rounded="lg" color="transparent">
                        <v-img
                          :src="meal.files[0].data || meal.files[0].url"
                          :max-width="
                            meal.files[0].type === 'emoji' ? '120px' : '450px'
                          "
                          aspect-ratio="1"
                        >
                        </v-img>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
                식사 사진을 변경하려면 아래에서 원하는 기록 방식을 선택하여
                주십시오
              </div>
              <div v-else>
                식사 사진을 등록해주세요
              </div>
            </div>
          </v-list-item-content>
        </v-list-item>
        <template v-if="isIOS">
          <v-list-item
            :ripple="$store.state.isDisplayTouch"
            @click="onClickCamera"
            :disabled="isLoading"
            class="pl-2"
          >
            <v-list-item-avatar class="mr-1">
              <v-icon :color="$vuetify.theme.dark ? 'white' : 'black'">
                mdi-camera-outline
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <div>
                  사진 촬영
                </div>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            :ripple="$store.state.isDisplayTouch"
            @click="onClickPhotos"
            :disabled="isLoading"
            class="pl-2"
          >
            <v-list-item-avatar class="mr-1">
              <v-icon :color="$vuetify.theme.dark ? 'white' : 'black'">
                mdi-image-outline
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <div>
                  사진 라이브러리
                </div>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            @click="onClickEmoji"
            :ripple="$store.state.isDisplayTouch"
            :disabled="isLoading"
            class="pl-2"
          >
            <v-list-item-avatar class="mr-1">
              <v-icon :color="$vuetify.theme.dark ? 'white' : 'black'">
                mdi-emoticon-outline
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <div>
                  이모지
                </div>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            @click="onClickInstagram"
            :ripple="$store.state.isDisplayTouch"
            :disabled="isLoading"
            class="pl-2"
          >
            <v-list-item-avatar class="mr-1">
              <v-icon :color="$vuetify.theme.dark ? 'white' : 'black'">
                mdi-instagram
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <div>인스타그램</div>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-else>
          <v-list-item
            @click="onClickFile"
            :ripple="$store.state.isDisplayTouch"
            :disabled="isLoading"
            class="pl-2"
          >
            <v-list-item-avatar class="mr-1">
              <v-icon :color="$vuetify.theme.dark ? 'white' : 'black'">
                mdi-camera-outline
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <div v-if="$store.state.isMobileDevice">
                  {{ `사진 촬영 또는 라이브러리` }}
                </div>
                <div v-else>
                  {{ `사진 파일 선택` }}
                </div>
              </v-list-item-title>
            </v-list-item-content>

            <input
              type="file"
              style="display: none"
              ref="mealFileInput"
              accept="image/*"
              @change="onChangeMealFile"
            />
          </v-list-item>
          <v-list-item
            @click="onClickEmoji"
            :ripple="$store.state.isDisplayTouch"
            :disabled="isLoading"
            class="pl-2"
          >
            <v-list-item-avatar class="mr-1">
              <v-icon :color="$vuetify.theme.dark ? 'white' : 'black'">
                mdi-emoticon-outline
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                <div>이모지</div>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            @click="onClickInstagram"
            :ripple="$store.state.isDisplayTouch"
            :disabled="isLoading"
            class="pl-2"
          >
            <v-list-item-avatar class="mr-1">
              <v-icon :color="$vuetify.theme.dark ? 'white' : 'black'">
                mdi-instagram
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title><div>인스타그램</div> </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>

      <v-dialog
        v-model="isVisibleEmojiSelection"
        :fullscreen="$vuetify.breakpoint.xs"
        transition="dialog-bottom-transition"
        max-width="500px"
        scrollable
      >
        <v-card
          :tile="$vuetify.breakpoint.xs"
          :class="{ 'floob-dialog-card': !$vuetify.breakpoint.xs }"
        >
          <v-card-title class="floob-toolbar-title"
            ><v-btn
              text
              x-large
              @click="isVisibleEmojiSelection = false"
              :ripple="$store.state.isDisplayTouch"
              class="floob-toolbar-button-left"
            >
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>

            <v-toolbar-title><h1>이모지</h1></v-toolbar-title>
            <v-btn text x-large :ripple="$store.state.isDisplayTouch"> </v-btn
          ></v-card-title>
          <v-divider></v-divider>

          <v-card-text class="pa-0" style="color:unset !important">
            <v-container fluid>
              <v-row no-gutters>
                <v-col
                  cols="2"
                  class="pa-2"
                  v-for="(emoji, idx) in emojis"
                  :key="idx"
                >
                  <div @click="onSelectedEmoji(emoji)">
                    <v-img
                      v-if="false"
                      :src="emoji.url"
                      contain
                      :width="24"
                      :height="24"
                    ></v-img>
                    <div style="font-size:xx-large;">
                      {{ emoji.symbol }}
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions> </v-card-actions>
        </v-card>
      </v-dialog>

      <floob-meal-content-instagram-dialog
        :dialog.sync="mealContentInstagramDialog"
        @selected="onSelectedInstagramMedia"
      >
      </floob-meal-content-instagram-dialog>

      <floob-message-snackbar
        :dialog.sync="messageSnackbar"
      ></floob-message-snackbar>

      <floob-browser-dialog :dialog.sync="browserDialog">
      </floob-browser-dialog>
    </v-bottom-sheet>
  </section>
</template>

<script>
import moment from 'moment';

import converter from '@/util/converter';
import wvc from '@/util/webViewController';
import emoji from '@/util/emoji';
import exifr from 'exifr';

import compressor from '@/util/compressor';

import floobBrowserDialog from '@/components/browserDialog.vue';
import floobMessageSnackbar from '@/components/messageSnackbar.vue';
import floobMealContentInstagramDialog from '@/components/mealContentInstagramDialog.vue';

export default {
  components: {
    floobBrowserDialog,
    floobMessageSnackbar,
    floobMealContentInstagramDialog
  },
  props: {
    meal: Object
  },
  data() {
    return {
      // modules
      browserDialog: null,
      messageSnackbar: null,
      mealContentInstagramDialog: null,

      // controlls
      isVisible: false,
      isVisibleEmojiSelection: false,
      quickMode: false,
      randomedEmoji: null,
      isLoading: false,
      selectedMenu: null
    };
  },
  methods: {
    init() {
      this.isVisibleEmojiSelection = false;
      this.isLoading = false;
      this.quickMode = false;
      this.randomedEmoji = emoji.getRandomEmojiSymbol();
    },
    onClickCamera() {
      this.selectedMenu = 'camera';
      wvc.openCamera(this.quickMode);
    },
    onClickPhotos() {
      this.selectedMenu = 'photo-library';
      wvc.openPhotos(this.quickMode);
    },
    onClickFile() {
      this.$refs.mealFileInput.click();
    },
    onChangeMealFile(e) {
      const files = e.target.files;

      if (files && files.length > 0) {
        let mealFile = files[0];

        if (mealFile.name.lastIndexOf('.') <= 0) {
          return;
        }

        if (mealFile.type.includes('image/')) {
          exifr
            .parse(mealFile, true)
            .then(exif => {
              return exif;
            })
            .catch(err => {
              console.log('Invalid format from exifr.', err.message);
            })
            .finally(exif => {
              if (exif) {
                console.log('exif', exif);
              } else {
                console.log(`No EXIF data found in image '${mealFile.name}'.`);
              }

              compressor.compressImage(mealFile).then(compressedFile => {
                const fr = new FileReader();

                fr.readAsDataURL(compressedFile);

                fr.addEventListener('load', () => {
                  let meal = {};

                  if (exif && exif.DateTimeOriginal) {
                    meal.mealedDate = moment(
                      exif.DateTimeOriginal,
                      'YYYY:MM:DD HH:mm:ss'
                    );
                  }
                  if (
                    exif &&
                    exif.GPSLatitude &&
                    exif.GPSLatitudeRef &&
                    exif.GPSLongitude &&
                    exif.GPSLongitudeRef
                  ) {
                    meal.location = {
                      latitude: converter.convertDMSToDD(
                        exif.GPSLatitude[0],
                        exif.GPSLatitude[1],
                        exif.GPSLatitude[2],
                        exif.GPSLatitudeRef
                      ),
                      longitude: converter.convertDMSToDD(
                        exif.GPSLongitude[0],
                        exif.GPSLongitude[1],
                        exif.GPSLongitude[2],
                        exif.GPSLongitudeRef
                      )
                    };
                  }

                  meal.files = [
                    {
                      type: 'image',
                      url: null,
                      data: fr.result
                    }
                  ];
                  this.$emit('selected', meal);
                });
              });
            });
        } else if (mealFile.type.includes('video/')) {
          const fr = new FileReader();

          fr.readAsDataURL(mealFile);

          fr.addEventListener('load', () => {
            this.$emit('selected', {
              type: 'video',
              url: fr.result
            });
          });
        }
      }
    },
    onClickEmoji() {
      this.isVisibleEmojiSelection = true;
    },
    onSelectedEmoji(emoji) {
      this.isVisibleEmojiSelection = false;

      this.$emit('selected', {
        files: [
          {
            type: 'emoji',
            url: emoji.url
          }
        ]
      });
    },
    onClickInstagram() {
      this.mealContentInstagramDialog.show();
    },
    onSelectedInstagramMedia(media) {
      let meal = {
        files: [
          {
            type: 'image',
            url: media.media_url
          }
        ],
        sourceUrl: media.permalink
      };

      if (media.caption) {
        let captionLines = media.caption.split('\n');

        meal.title = captionLines[0];

        if (captionLines.length > 1) {
          meal.desc = captionLines
            .slice(1, captionLines.length)
            .join('\n')
            .trim();
        }
      }

      if (media.timestamp && moment(media.timestamp).isValid()) {
        meal.mealedDate = moment(media.timestamp);
      }

      this.$emit('selected', meal);
    }
  },
  computed: {
    isAdmin() {
      return (
        this.$store.state.isLoggedIn &&
        this.$store.state.user.introduction &&
        this.$store.state.user.introduction.includes(`I am a floob`)
      );
    },
    isIOS() {
      return (
        this.$cookies.get('webview') && this.$cookies.get('platform') === 'ios'
      );
    },
    emojis() {
      return emoji.getEmojis();
    }
  },
  created() {
    if (
      this.$cookies.get('webview') &&
      this.$cookies.get('platform') === 'ios'
    ) {
      const createMeal = meal => {
        wvc.completedSendingData();

        if (meal && Array.isArray(meal.files) && meal.files.length > 0) {
          if (meal.files[0].type === 'image') {
            compressor
              .compressImageFromDataUrl(meal.files[0].data)
              .then(compressedFile => {
                const fr = new FileReader();

                fr.readAsDataURL(compressedFile);

                fr.addEventListener('load', () => {
                  meal.files[0] = {
                    type: 'image',
                    url: null,
                    data: fr.result
                  };

                  this.$emit('selected', meal);
                });
              });
          }
        }
      };
      window.FloobWebView.createMeal = createMeal;
      window.Floob._webview.createMeal = createMeal;
    }
    this.$emit('update:dialog', {
      show: options => {
        this.init();
        if (options) {
          if (options.quickMode) {
            this.quickMode = true;

            window.Floob._analytics.logEvent('create_meal', {
              quick_mode: true
            });
          }
        }
        this.isVisible = true;
      },
      hide: () => {
        this.isVisible = false;
      },
      set: keyValue => {
        if (keyValue.key) {
          this[keyValue.key] = keyValue.value;
        }
      }
    });
  }
};
</script>

<style></style>
