<template>
<section>
    
    <floob-header></floob-header>

    <v-container :fluid="$vuetify.breakpoint.mdAndDown" class="py-0">
      <v-row
        align="center"
        no-gutters
        v-bind:style="{ height: frameHeight + 'px' }"
      >
        <v-col
          sm="6"
          offset-sm="3"
          md="4"
          offset-md="4"
          xl="2"
          offset-xl="5"
          class="pa-0"
        >
        <v-card flat class="text-center">
            
                <h1><b>404 Error</b></h1>
                <h3>Page not found</h3>
            
                <v-img height="80" contain src="https://static.thenounproject.com/png/1166614-200.png">
                </v-img>
        </v-card>
        </v-col>
      </v-row>
    </v-container>
  
    <floob-footer></floob-footer>
</section>
</template>

<script>

import floobHeader from '@/components/header_v2.vue';
import floobFooter from '@/components/footer.vue';

export default {
    components: {
        floobHeader,
        floobFooter
    },
  computed: {
    frameHeight() {
      return window.innerHeight * 0.7;
    }
  },

}
</script>

<style>

</style>