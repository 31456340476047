<template>
  <section>
    <v-app-bar
      app
      fixed
      :color="$vuetify.theme.dark ? null : 'white'"
      clipped-right
      flat
      v-bind:class="{ 'floob-header': !$vuetify.theme.dark }"
    >
      <v-toolbar-title>
        <div>
          <v-avatar
            style="margin-bottom:2px"
            size="18"
            rounded
            @click="onClickTitle"
            ><v-img src="/favicon.ico"></v-img></v-avatar
          ><span class="pl-2"
            ><b>{{ title }}</b></span
          >
        </div>
      </v-toolbar-title>
      <v-spacer> </v-spacer>

      <template v-if="$route.name != 'login'">
        <!-- mobile -->
        <template v-if="$vuetify.breakpoint.xsOnly">
          <v-btn
            icon
            :ripple="$store.state.isDisplayTouch"
            @click.stop="drawer = !drawer"
          >
            <v-icon>
              mdi-menu
            </v-icon>
          </v-btn>

          <v-navigation-drawer
            app
            v-model="drawer"
            hide-overlay
            right
            temporary
            width="100%"
          >
            <v-toolbar flat>
              <v-toolbar-title>
                <div>
                  <v-avatar style="margin-bottom:2px" size="18" rounded
                    ><v-img src="/favicon.ico"></v-img></v-avatar
                  ><span class="pl-2"><b>Floob</b></span>
                </div>
              </v-toolbar-title>

              <v-spacer> </v-spacer>
              <v-btn
                icon
                :ripple="$store.state.isDisplayTouch"
                @click="drawer = false"
              >
                <v-icon>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-toolbar>

            <v-list class="px-4">
              <template v-if="isLoggedIn">
                <v-list-item @click="onClickTimeline">
                  <v-list-item-content>
                    <v-list-item-title>타임라인</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item @click="onClickReport">
                  <v-list-item-content>
                    <v-list-item-title>리포트</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="false" @click="onClickSetting">
                  <v-list-item-content>
                    <v-list-item-title>설정</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item @click="onClickLogout">
                  <v-list-item-content>
                    <v-list-item-title>로그아웃</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-else>
                <v-list-item @click="onClickLogin">
                  <v-list-item-content>
                    <v-list-item-title>로그인</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </v-navigation-drawer>
        </template>

        <!-- tablet or desktop -->
        <template v-else>
          <v-menu v-if="isLoggedIn" offset-y open-on-hover>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                :ripple="$store.state.isDisplayTouch"
              >
                <v-avatar :size="32">
                  <v-img :src="$store.state.user.profileImageUrl"> </v-img>
                </v-avatar>
              </v-btn>
            </template>
            <v-list nav dense>
              <v-list-item @click="onClickTimeline">
                <v-list-item-content>
                  <v-list-item-title>타임라인</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item @click="onClickReport">
                <v-list-item-content>
                  <v-list-item-title>리포트</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item v-if="false" @click="onClickSetting">
                <v-list-item-content>
                  <v-list-item-title>설정</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="onClickLogout">
                <v-list-item-content>
                  <v-list-item-title>로그아웃</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn
            v-else
            text
            :ripple="$store.state.isDisplayTouch"
            @click="onClickLogin"
            ><b>로그인</b>
          </v-btn>
        </template>
      </template>
    </v-app-bar>
  </section>
</template>
<script>
export default {
  props: {
    title: {
      default: 'Floob'
    }
  },
  components: {},
  data() {
    return {
      //modules

      drawer: false
    };
  },
  methods: {
    onClickTitle() {
      this.$router.push({ name: 'home' });
    },
    onClickLogin() {
      this.$router.push({ name: 'login' });
    },
    onClickLogout() {
      window.Floob._analytics.logEvent('logout', {
        user_name: this.$store.getters.user.nickname
      });

      window.$cookies.remove(
        'access_token',
        '/',
        location.hostname.replace('www', '')
      );

      this.$store.dispatch('set', { key: 'user', value: null });

      window.location.reload();
    },
    onClickTimeline() {
      this.$router.push({ name: 'timeline' });
    },
    onClickSetting() {
      this.$router.push({ name: 'setting' });
    },
    onClickReport() {
      this.$router.push({ name: 'report' });
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    }
  },
  created() {}
};
</script>
