<template>
  <section>
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xs"
      transition="dialog-bottom-transition"
      max-width="500px"
      scrollable
    >
      <v-card
        :tile="$vuetify.breakpoint.xs"
        :class="{ 'floob-dialog-card': !$vuetify.breakpoint.xs }"
      >
        <v-card-title class="floob-toolbar-title"
          ><v-btn
            text
            x-large
            @click="isVisible = false"
            :ripple="$store.state.isDisplayTouch"
            class="pl-0"
          >
            취소
          </v-btn>

          <v-toolbar-title><h1>사용자 아이디</h1></v-toolbar-title>
          <v-btn
            text
            x-large
            :ripple="$store.state.isDisplayTouch"
            :loading="isLoading"
            @click="updateUserNickname"
            class="pr-0"
          >
            등록
          </v-btn></v-card-title
        >
        <v-divider></v-divider>
        <v-card-text class="px-2">
          <v-container fluid>
            <v-row>
              <v-col>
                <div>
                  <v-text-field
                    v-model="nickname"
                    solo-inverted
                    flat
                    :autofocus="false"
                    :rules="[
                      n => {
                        return (
                          rules.counterMin(n) || '최소 5자 이상이 필요합니다.'
                        );
                      },
                      n => {
                        return (
                          rules.counterMax(n) ||
                          '최대 20자를 초과할 수 없습니다.'
                        );
                      },
                      n => {
                        return (
                          rules.pattern(n) ||
                          '알파벳 소문자와 숫자 그리고 특수문자는 _ 만 허용합니다.'
                        );
                      }
                    ]"
                    :error-messages="errorMessage"
                    :messages="creatableMessage"
                    @blur="onBlurNickname"
                    ref="nicknameInput"
                  >
                  </v-text-field>
                </div>

                <div style="color:rgb(171 171 171);" class="px-1 pt-3">
                  Floob 서비스를 사용하기 위해 고유한 사용자 아이디 생성이
                  필요합니다.
                  <br />
                  나를 잘 표현하며 간결한 아이디를 생성해보세요.😃
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions> </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import api from '@/api';

export default {
  data() {
    return {
      isVisible: false,
      user: null,
      nickname: '',
      isValidCreateNickname: false,
      isValidNicknamePattern: false,
      isDuplicatedNickname: false,
      isLoading: false,
      rules: {
        counterMin: n => n.length >= 5,
        counterMax: n => n.length <= 20,
        pattern: value => {
          const pattern = /^[a-z0-9_]{5,20}$/;
          return pattern.test(value);
        }
      }
    };
  },
  methods: {
    init() {
      this.user = null;
      this.nickname = '';
      this.isDuplicatedNickname = false;
      this.isLoading = false;
    },
    updateUserNickname() {
      if (this.isValidCreateNickname) {
        this.isLoading = true;

        api.user
          .updateUser({
            _id: this.user._id,
            nickname: this.nickname
          })
          .then(() => {
            window.Floob._analytics.logEvent('signup_success', {
              user_id: this.user._id,
              user_name: this.nickname
            });
            this.$emit('updated', {
              ...this.user,
              nickname: this.nickname
            });
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.$refs.nicknameInput.focus();
      }
    },
    onBlurNickname() {
      this.isValidCreateNickname =
        this.isValidNicknamePattern && !this.isDuplicatedNickname;
    }
  },
  computed: {
    errorMessage() {
      if (this.isDuplicatedNickname) {
        return '중복 된 아이디가 존재합니다.';
      } else {
        return null;
      }
    },
    creatableMessage() {
      if (this.isValidNicknamePattern && !this.isDuplicatedNickname) {
        return '등록 가능합니다.';
      } else {
        return null;
      }
    }
  },
  watch: {
    isVisible(value) {
      if (!value) {
        this.init();
      }
    },
    nickname(nickname) {
      if (nickname && nickname.replace(/ /gi, '')) {
        this.isValidNicknamePattern = this.rules.pattern(this.nickname);

        api.user.getUserExsitsByNickname(nickname).then(isExsists => {
          if (isExsists) {
            this.isDuplicatedNickname = true;
          } else {
            this.isDuplicatedNickname = false;
          }
        });
      }
    }
  },
  created() {
    this.init();
    this.$emit('update:dialog', {
      show: user => {
        this.user = user;
        this.isVisible = true;
      }
    });
  }
};
</script>

<style></style>
