import Vue from 'vue';
import VueRouter from 'vue-router';
import app from '@/views/app.vue';
import home from '@/views/home.vue';
import login from '@/views/login.vue';
import setting from '@/views/setting.vue';
import report from '@/views/report.vue';
import meals from '@/views/meals.vue';
import meal from '@/views/meal.vue';
import story from '@/views/story.vue';
import tagFood from '@/views/tagFood.vue';
import introduction from '@/views/introduction.vue';
import timeline from '@/views/timeline.vue';
import support from '@/views/support.vue';
import privacy from '@/views/privacy.vue';
import oauth from '@/views/oauth.vue';
import admin from '@/views/admin.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: home
  },
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/meals',
    name: 'meals',
    component: meals
  },
  {
    path: '/setting',
    name: 'setting',
    component: setting,
    meta: {
      title: 'Floob: Setting'
    }
  },
  {
    path: '/report',
    name: 'report',
    component: report,
    meta: {
      title: 'Floob: Report'
    }
  },
  {
    path: '/app',
    name: 'app',
    component: app
  },
  {
    path: '/meal/:mealId',
    name: 'meal',
    component: meal
  },
  {
    path: '/story/:userId',
    name: 'story',
    component: story
  },
  {
    path: '/tag-food/:mealId',
    name: 'tag-food',
    component: tagFood
  },
  {
    path: '/intro',
    name: 'intro',
    component: introduction
  },
  {
    path: '/introduction',
    name: 'introduction',
    component: introduction
  },
  {
    path: '/timeline',
    name: 'timeline',
    component: timeline,
    meta: {
      title: 'Floob: Timeline'
    }
  },
  {
    path: '/support',
    name: 'support',
    component: support,
    meta: {
      title: 'Floob: 고객 지원'
    }
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: privacy,
    meta: {
      title: 'Floob: 개인정보처리방침'
    }
  },
  {
    path: '/oauth/:site',
    name: 'oauth',
    component: oauth
  },
  {
    path: '/admin',
    name: 'admin',
    component: admin
  }
];

const router = new VueRouter({
  routes,
  mode: 'history'
});

const DEFAULT_TITLE = 'Floob: 한입의 음식 기록';

router.afterEach(to => {
  if (window.Floob && window.Floob._analytics) {
    window.Floob._analytics.logEvent('page_view', {
      page_location: window.location.href,
      page_path: to.path,
      page_title: to.meta && to.meta.title ? to.meta.title : DEFAULT_TITLE
    });
  }
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;

    if (to.name === 'app') {
      document.getElementsByTagName('html')[0].style.overflow = 'hidden';
    }
  });
});

export default router;
