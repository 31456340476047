<template>
  <v-dialog v-model="isVisible" fullscreen :transition="transition">
    <v-card tile>
      <v-card-title class="floob-toolbar-title">
        <v-btn
          text
          x-large
          @click="isVisible = false"
          :ripple="$store.state.isDisplayTouch"
          class="floob-toolbar-button-left"
        >
          <v-icon>{{ closeIcon }}</v-icon></v-btn
        >
        <v-toolbar-title
          ><h1>{{ title }}</h1>
        </v-toolbar-title>

        <v-btn
          text
          x-large
          disabled
          :ripple="$store.state.isDisplayTouch"
          class="pr-0"
        >
        </v-btn>
      </v-card-title>
      <v-card-title class="py-0" v-if="subtitle">
        <v-subheader class="floob-toolbar-subtitle">{{ subtitle }}</v-subheader>
      </v-card-title>
      <v-card-text class="pa-0">
        <iframe :src="url" :style="frameStyle"> </iframe>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      isVisible: false,
      title: null,
      subtitle: null,
      url: null,
      frameStyle: null,
      defaultFrameStyle:
        'border: 0px; display:block; width:100vw; height: 100vh',
      transition: 'dialog-bottom-transition',
      closeIcon: 'mdi-chevron-down'
    };
  },
  watch: {
    isVisible(value) {
      if (!value) {
        this.url = null;
      }
    }
  },
  created() {
    this.$emit('update:dialog', {
      show: (title, subtitle, url, frameStyle, options) => {
        this.title = title || null;
        this.subtitle = subtitle || null;
        this.url = url;
        this.frameStyle = frameStyle || this.defaultFrameStyle;
        if (options) {
          if (options.transition) {
            if (options.transition === 'slide-x-reverse') {
              this.transition = `${options.transition}-transition`;
              this.closeIcon = 'mdi-chevron-left';
            }
          }
        }
        this.isVisible = true;
      },
      hide: () => {
        this.isVisible = false;
      }
    });
  }
};
</script>

<style></style>
