<template>
  <section v-if="recommendedFloobers && recommendedFloobers.length > 0">
    <v-list dense>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle>
            <b>추천 스토리</b>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-sheet class="mx-auto px-0 pb-8">
      <v-data-table
        dense
        class="catalina-table"
        :headers="followRecommendedFloobersRanksHeaders"
        :items="[followRecommendedFloobersRanks]"
        item-key="_id"
        hide-default-footer
        mobile-breakpoint="0"
        hide-default-header
      >
        <template v-slot:item.rank0="{ item }">
          <td class="px-0">
            <v-card
              class="d-flex align-center mr-4 ml-4"
              width="135"
              rounded="lg"
              flat
              :color="$vuetify.theme.dark ? '#272727' : 'grey lighten-4'"
            >
              <v-card-text class="text-center pb-0">
                <v-avatar
                  style="background-color:white"
                  @click="onClickStory(item.rank0)"
                >
                  <img
                    :src="item.rank0.profileImageUrl"
                    :alt="item.rank0.nickname"
                  />
                </v-avatar>
                <v-list
                  dense
                  class="pa-0"
                  style="background-color: transparent"
                >
                  <v-list-item class="px-0 pt-1">
                    <v-list-item-content>
                      <v-list-item-title @click="onClickStory(item.rank0)">
                        <b>{{ item.rank0.name }}</b>
                      </v-list-item-title>
                      <v-list-item-subtitle @click="onClickStory(item.rank0)">
                        {{ `@${item.rank0.nickname}` }}
                      </v-list-item-subtitle>
                      <v-btn
                        v-if="item.rank0.isFollowing"
                        text
                        color="grey"
                        @click="onClickUnfollow(item.rank0)"
                        :loading="item.rank0.isLoading"
                        :disabled="isLoading"
                        :ripple="$store.state.isDisplayTouch"
                      >
                        팔로잉
                      </v-btn>
                      <v-btn
                        v-else
                        text
                        color="primary"
                        @click="onClickFollow(item.rank0)"
                        :ripple="$store.state.isDisplayTouch"
                        :loading="item.rank0.isLoading"
                        :disabled="isLoading"
                      >
                        팔로우
                      </v-btn>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </td>
        </template>
        <template v-slot:item.rank1="{ item }">
          <td class="px-0">
            <v-card
              class="d-flex align-center mr-4"
              width="135"
              rounded="lg"
              flat
              :color="$vuetify.theme.dark ? '#272727' : 'grey lighten-4'"
            >
              <v-card-text class="text-center pb-0">
                <v-avatar
                  style="background-color:white"
                  @click="onClickStory(item.rank1)"
                >
                  <img
                    :src="item.rank1.profileImageUrl"
                    :alt="item.rank1.nickname"
                  />
                </v-avatar>
                <v-list
                  dense
                  class="pa-0"
                  style="background-color: transparent"
                >
                  <v-list-item class="px-0 pt-1">
                    <v-list-item-content>
                      <v-list-item-title @click="onClickStory(item.rank1)">
                        <b>{{ item.rank1.name }}</b>
                      </v-list-item-title>
                      <v-list-item-subtitle @click="onClickStory(item.rank1)">
                        {{ `@${item.rank1.nickname}` }}
                      </v-list-item-subtitle>
                      <v-btn
                        v-if="item.rank1.isFollowing"
                        text
                        color="grey"
                        @click="onClickUnfollow(item.rank1)"
                        :loading="item.rank1.isLoading"
                        :disabled="isLoading"
                        :ripple="$store.state.isDisplayTouch"
                      >
                        팔로잉
                      </v-btn>
                      <v-btn
                        v-else
                        text
                        color="primary"
                        @click="onClickFollow(item.rank1)"
                        :ripple="$store.state.isDisplayTouch"
                        :loading="item.rank1.isLoading"
                        :disabled="isLoading"
                      >
                        팔로우
                      </v-btn>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </td>
        </template>
        <template v-slot:item.rank2="{ item }">
          <td class="px-0">
            <v-card
              class="d-flex align-center mr-4"
              width="135"
              rounded="lg"
              flat
              :color="$vuetify.theme.dark ? '#272727' : 'grey lighten-4'"
            >
              <v-card-text class="text-center pb-0">
                <v-avatar
                  style="background-color:white"
                  @click="onClickStory(item.rank2)"
                >
                  <img
                    :src="item.rank2.profileImageUrl"
                    :alt="item.rank2.nickname"
                  />
                </v-avatar>
                <v-list
                  dense
                  class="pa-0"
                  style="background-color: transparent"
                >
                  <v-list-item class="px-0 pt-1">
                    <v-list-item-content>
                      <v-list-item-title @click="onClickStory(item.rank2)">
                        <b>{{ item.rank2.name }}</b>
                      </v-list-item-title>
                      <v-list-item-subtitle @click="onClickStory(item.rank2)">
                        {{ `@${item.rank2.nickname}` }}
                      </v-list-item-subtitle>
                      <v-btn
                        v-if="item.rank2.isFollowing"
                        text
                        color="grey"
                        @click="onClickUnfollow(item.rank2)"
                        :loading="item.rank2.isLoading"
                        :disabled="isLoading"
                        :ripple="$store.state.isDisplayTouch"
                      >
                        팔로잉
                      </v-btn>
                      <v-btn
                        v-else
                        text
                        color="primary"
                        @click="onClickFollow(item.rank2)"
                        :ripple="$store.state.isDisplayTouch"
                        :loading="item.rank2.isLoading"
                        :disabled="isLoading"
                      >
                        팔로우
                      </v-btn>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </td>
        </template>
        <template v-slot:item.rank3="{ item }">
          <td class="px-0">
            <v-card
              class="d-flex align-center mr-4"
              width="135"
              rounded="lg"
              flat
              :color="$vuetify.theme.dark ? '#272727' : 'grey lighten-4'"
            >
              <v-card-text class="text-center pb-0">
                <v-avatar
                  style="background-color:white"
                  @click="onClickStory(item.rank3)"
                >
                  <img
                    :src="item.rank3.profileImageUrl"
                    :alt="item.rank3.nickname"
                  />
                </v-avatar>
                <v-list
                  dense
                  class="pa-0"
                  style="background-color: transparent"
                >
                  <v-list-item class="px-0 pt-1">
                    <v-list-item-content>
                      <v-list-item-title @click="onClickStory(item.rank3)">
                        <b>{{ item.rank3.name }}</b>
                      </v-list-item-title>
                      <v-list-item-subtitle @click="onClickStory(item.rank3)">
                        {{ `@${item.rank3.nickname}` }}
                      </v-list-item-subtitle>
                      <v-btn
                        v-if="item.rank3.isFollowing"
                        text
                        color="grey"
                        @click="onClickUnfollow(item.rank3)"
                        :loading="item.rank3.isLoading"
                        :disabled="isLoading"
                        :ripple="$store.state.isDisplayTouch"
                      >
                        팔로잉
                      </v-btn>
                      <v-btn
                        v-else
                        text
                        color="primary"
                        @click="onClickFollow(item.rank3)"
                        :ripple="$store.state.isDisplayTouch"
                        :loading="item.rank3.isLoading"
                        :disabled="isLoading"
                      >
                        팔로우
                      </v-btn>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </td>
        </template>
        <template v-slot:item.rank4="{ item }">
          <td class="px-0">
            <v-card
              class="d-flex align-center mr-4"
              width="135"
              rounded="lg"
              flat
              :color="$vuetify.theme.dark ? '#272727' : 'grey lighten-4'"
            >
              <v-card-text class="text-center pb-0">
                <v-avatar
                  style="background-color:white"
                  @click="onClickStory(item.rank4)"
                >
                  <img
                    :src="item.rank4.profileImageUrl"
                    :alt="item.rank4.nickname"
                  />
                </v-avatar>
                <v-list
                  dense
                  class="pa-0"
                  style="background-color: transparent"
                >
                  <v-list-item class="px-0 pt-1">
                    <v-list-item-content>
                      <v-list-item-title @click="onClickStory(item.rank4)">
                        <b>{{ item.rank4.name }}</b>
                      </v-list-item-title>
                      <v-list-item-subtitle @click="onClickStory(item.rank4)">
                        {{ `@${item.rank4.nickname}` }}
                      </v-list-item-subtitle>
                      <v-btn
                        v-if="item.rank4.isFollowing"
                        text
                        color="grey"
                        @click="onClickUnfollow(item.rank4)"
                        :loading="item.rank4.isLoading"
                        :disabled="isLoading"
                        :ripple="$store.state.isDisplayTouch"
                      >
                        팔로잉
                      </v-btn>
                      <v-btn
                        v-else
                        text
                        color="primary"
                        @click="onClickFollow(item.rank4)"
                        :ripple="$store.state.isDisplayTouch"
                        :loading="item.rank4.isLoading"
                        :disabled="isLoading"
                      >
                        팔로우
                      </v-btn>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </td>
        </template>
        <template v-slot:item.rank5="{ item }">
          <td class="px-0">
            <v-card
              class="d-flex align-center mr-4"
              width="135"
              rounded="lg"
              flat
              :color="$vuetify.theme.dark ? '#272727' : 'grey lighten-4'"
            >
              <v-card-text class="text-center pb-0">
                <v-avatar
                  style="background-color:white"
                  @click="onClickStory(item.rank5)"
                >
                  <img
                    :src="item.rank5.profileImageUrl"
                    :alt="item.rank5.nickname"
                  />
                </v-avatar>
                <v-list
                  dense
                  class="pa-0"
                  style="background-color: transparent"
                >
                  <v-list-item class="px-0 pt-1">
                    <v-list-item-content>
                      <v-list-item-title @click="onClickStory(item.rank5)">
                        <b>{{ item.rank5.name }}</b>
                      </v-list-item-title>
                      <v-list-item-subtitle @click="onClickStory(item.rank5)">
                        {{ `@${item.rank5.nickname}` }}
                      </v-list-item-subtitle>
                      <v-btn
                        v-if="item.rank5.isFollowing"
                        text
                        color="grey"
                        @click="onClickUnfollow(item.rank5)"
                        :loading="item.rank5.isLoading"
                        :disabled="isLoading"
                        :ripple="$store.state.isDisplayTouch"
                      >
                        팔로잉
                      </v-btn>
                      <v-btn
                        v-else
                        text
                        color="primary"
                        @click="onClickFollow(item.rank5)"
                        :ripple="$store.state.isDisplayTouch"
                        :loading="item.rank5.isLoading"
                        :disabled="isLoading"
                      >
                        팔로우
                      </v-btn>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </td>
        </template>
      </v-data-table>
      <v-slide-group v-if="false" :show-arrows="false">
        <v-slide-item
          v-for="floober in followRecommendedFloobers"
          :key="floober._id"
          ><v-card
            class="d-flex align-center mx-2"
            width="135"
            rounded="lg"
            flat
            :color="$vuetify.theme.dark ? '#272727' : 'grey lighten-4'"
          >
            <v-card-text class="text-center pb-0">
              <v-avatar
                style="background-color:white"
                @click="onClickStory(floober)"
              >
                <img :src="floober.profileImageUrl" :alt="floober.nickname" />
              </v-avatar>
              <v-list dense class="pa-0" style="background-color: transparent">
                <v-list-item class="px-0 pt-1">
                  <v-list-item-content>
                    <v-list-item-title @click="onClickStory(floober)">
                      <b>{{ floober.name }}</b>
                    </v-list-item-title>
                    <v-list-item-subtitle @click="onClickStory(floober)">
                      {{ `@${floober.nickname}` }}
                    </v-list-item-subtitle>
                    <v-btn
                      v-if="floober.isFollowing"
                      text
                      color="grey"
                      @click="onClickUnfollow(floober)"
                      :loading="floober.isLoading"
                      :disabled="isLoading"
                      :ripple="$store.state.isDisplayTouch"
                    >
                      팔로잉
                    </v-btn>
                    <v-btn
                      v-else
                      text
                      color="primary"
                      @click="onClickFollow(floober)"
                      :ripple="$store.state.isDisplayTouch"
                      :loading="floober.isLoading"
                      :disabled="isLoading"
                    >
                      팔로우
                    </v-btn>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-slide-item>
      </v-slide-group>
    </v-sheet>

    <floob-story-dialog
      :dialog.sync="storyDialog"
      v-on:updated="onUpdatedFollow"
    ></floob-story-dialog>
  </section>
</template>

<script>
import _ from 'lodash';
import api from '@/api';

import floobStoryDialog from '@/components/storyDialog.vue';

export default {
  components: {
    floobStoryDialog
  },

  data() {
    return {
      // modules
      storyDialog: null,

      // controlls
      isLoading: false,

      // datas
      recommendedFloobers: [],
      itemHeaders: []
    };
  },
  methods: {
    init() {
      this.isLoading = false;
      this.recommendedFloobers = [];
    },
    getRecommendedStory() {
      api.user.getUsersByAuthenticatedAuth('floober').then(floobers => {
        if (this.isLoggedIn) {
          let followedUserIds = [...this.$store.state.user.followUserIds];

          floobers = _.shuffle(floobers);
          this.recommendedFloobers = _.map(
            _.take(
              _.reject(floobers, f => {
                return (
                  f._id === this.$store.state.user._id ||
                  followedUserIds.includes(f._id)
                );
              }),
              5
            ),
            f => {
              return { ...f, isLoading: false, isFollowing: false };
            }
          );
        }

        // this.recommendedFloobers = _.map(
        //   _.orderBy(floobers, ['followCount'], ['desc']),
        //   f => {
        //     return {
        //       ...f,
        //       isLoading: false
        //     };
        //   }
        // );
      });
    },
    onClickStory(floober) {
      this.storyDialog.show(floober._id);
    },
    onUpdatedFollow() {
      this.$emit('updated', true);
    },
    onClickUnfollow(floober) {
      this.isLoading = true;
      floober.isLoading = true;

      let followUserIds = [...this.$store.state.user.followUserIds];
      followUserIds = _.reject(followUserIds, followedUserId => {
        return followedUserId === floober._id;
      });

      setTimeout(() => {
        api.user
          .updateUser({
            _id: this.$store.state.user._id,
            followUserIds: followUserIds
          })
          .then(updated => {
            if (updated) {
              this.$store.dispatch('set', {
                key: 'user',
                value: {
                  ...this.$store.state.user,
                  followUserIds: followUserIds
                }
              });

              floober.isFollowing = false;
              this.isUpdated = true;

              this.$emit('updated', true);
            }
          })
          .finally(() => {
            floober.isLoading = false;
            this.isLoading = false;
          });
      }, 1000);
    },
    onClickFollow(floober) {
      this.isLoading = true;
      floober.isLoading = true;

      let followUserIds = [...this.$store.state.user.followUserIds];
      followUserIds.push(floober._id);

      followUserIds = _.uniq(followUserIds);

      setTimeout(() => {
        api.user
          .updateUser({
            _id: this.$store.state.user._id,
            followUserIds: followUserIds
          })
          .then(updated => {
            if (updated) {
              this.$store.dispatch('set', {
                key: 'user',
                value: {
                  ...this.$store.state.user,
                  followUserIds: followUserIds
                }
              });

              floober.isFollowing = true;
              this.isUpdated = true;

              this.$emit('updated', true);
            }
          })
          .finally(() => {
            floober.isLoading = false;
            this.isLoading = false;
          });
      }, 1000);
    }
  },

  computed: {
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    },
    followRecommendedFloobers() {
      return _.map(this.recommendedFloobers, floober => {
        if (
          Array.isArray(this.$store.state.user.followUserIds) &&
          this.$store.state.user.followUserIds.includes(floober._id)
        ) {
          return {
            ...floober,
            isFollowing: true
          };
        } else {
          return {
            ...floober,
            isFollowing: false
          };
        }
      });
    },
    followRecommendedFloobersRanks() {
      let ranks = {};
      this.followRecommendedFloobers.forEach((floober, idx) => {
        ranks[`rank${idx}`] = floober;
      });
      return ranks;
    },
    followRecommendedFloobersRanksHeaders() {
      return _.map(this.followRecommendedFloobers, (floober, idx) => {
        return {
          text: floober.name,
          align: 'center',
          sortable: false,
          value: `rank${idx}`,
          width: '200px'
        };
      });
    }
  },
  created() {
    this.$emit('update:module', {
      reload: () => {
        this.init();

        setTimeout(() => {
          this.getRecommendedStory();
        }, 1000);
      }
    });

    setTimeout(() => {
      this.getRecommendedStory();
    }, 3000);
  }
};
</script>

<style>
.catalina-table td {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
</style>
