import axios from 'axios';

const API_BASE_URL = 'https://link.floob.co.kr';
//const API_BASE_URL = 'http://localhost:3000';

export default {
  getSiteContents(url) {
    return axios({
      url: `${API_BASE_URL}/site-content`,
      method: 'POST',
      data: {
        url
        // headers: {
        //   'user-agent': window.navigator.userAgent
        // }
      }
    }).then(res => {
      return res.data;
    });
  }
};
