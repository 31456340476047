<template>
  <section>
    <v-app-bar
      v-if="!hideHeader"
      app
      fixed
      flat
      :color="$vuetify.theme.dark ? null : 'white'"
      v-bind:class="{ 'floob-header': isScrolled && !$vuetify.theme.dark }"
    >
      <v-toolbar-title>
        <h1 class="px-2" @click="scrollTop">설정</h1>
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>

    <v-sheet
      class="floob-tab-item"
      :height="$route.name === 'app' ? frameHeight : null"
      v-scroll.self="onScroll"
      id="floobSettingFrameSheet"
    >
      <v-container fluid id="floob-setting-content" class="py-0">
        <template v-if="isLoggedIn && user">
          <v-row>
            <v-col>
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <div>
                      <h1>
                        프로필
                      </h1>
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="pr-2 pb-8">
                  <v-list-item-content>
                    <v-list-item-title>
                      <b>
                        {{ user.nickname }}
                      </b>
                    </v-list-item-title>

                    <v-list-item-subtitle v-if="joinedYear"
                      >가입년도 : {{ joinedYear }}년</v-list-item-subtitle
                    >
                  </v-list-item-content>
                  <v-list-item-avatar
                    size="64"
                    @click="onClickUpdateProfileImage"
                  >
                    <v-img
                      v-if="user.profileImageUrl"
                      :src="user.profileImageUrl"
                    ></v-img>
                    <v-icon color="grey lighten-1" v-else x-large
                      >mdi-account-circle</v-icon
                    >
                    <input
                      type="file"
                      style="display: none"
                      ref="profileImageFile"
                      accept="image/*"
                      @change="onChangeProfileImageFile"
                    />
                  </v-list-item-avatar>
                </v-list-item>
                <v-list-item
                  class="pr-0"
                  @click="onClickUpdateUserName"
                  :ripple="$store.state.isDisplayTouch"
                >
                  <v-list-item-content class="py-0">
                    <v-list-item-title v-if="user.name"
                      >{{ user.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle v-else>
                      이름을 입력해주세요
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn text :ripple="$store.state.isDisplayTouch" disabled
                      ><v-icon>mdi-chevron-right</v-icon></v-btn
                    >
                  </v-list-item-action>

                  <v-dialog
                    v-model="isVisibleUserNameUpdate"
                    :fullscreen="$vuetify.breakpoint.xs"
                    transition="slide-x-reverse-transition"
                    max-width="500px"
                    scrollable
                  >
                    <v-card
                      :tile="$vuetify.breakpoint.xs"
                      :class="{ 'floob-dialog-card': !$vuetify.breakpoint.xs }"
                      v-bind:style="{
                        backgroundColor:
                          !$vuetify.breakpoint.xs && $vuetify.theme.dark
                            ? '#2d2d2d'
                            : null
                      }"
                    >
                      <v-card-title class="floob-toolbar-title"
                        ><v-btn
                          text
                          x-large
                          @click="isVisibleUserNameUpdate = false"
                          :ripple="$store.state.isDisplayTouch"
                          class="floob-toolbar-button-left"
                        >
                          <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>

                        <v-toolbar-title><h1>프로필 이름</h1></v-toolbar-title>
                        <v-btn
                          text
                          x-large
                          :loading="isLoading"
                          :ripple="$store.state.isDisplayTouch"
                          @click="onClickSaveUserName"
                          class="pr-0"
                        >
                          저장
                        </v-btn></v-card-title
                      >

                      <v-card-text class="px-2">
                        <v-container fluid>
                          <v-row>
                            <v-col>
                              <div>
                                <v-text-field
                                  v-model="name"
                                  solo-inverted
                                  flat
                                  :autofocus="false"
                                  hide-details
                                >
                                </v-text-field>
                              </div>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-card-actions> </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-list-item>
                <v-list-item
                  class="pr-0"
                  @click="onClickUpdateUserIntroduction"
                  :ripple="$store.state.isDisplayTouch"
                >
                  <v-list-item-content>
                    <div v-if="user.introduction">
                      <v-icon color="grey lighten-2"
                        >mdi-format-quote-open</v-icon
                      >
                      <div v-html="introductionHtml"></div>
                    </div>
                    <v-list-item-subtitle v-else>
                      자기소개를 입력해주세요
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action class="ml-0">
                    <v-btn text :ripple="$store.state.isDisplayTouch" disabled
                      ><v-icon>mdi-chevron-right</v-icon></v-btn
                    >
                  </v-list-item-action>

                  <v-dialog
                    v-model="isVisibleUserIntroductionUpdate"
                    :fullscreen="$vuetify.breakpoint.xs"
                    transition="slide-x-reverse-transition"
                    max-width="500px"
                    scrollable
                  >
                    <v-card
                      :tile="$vuetify.breakpoint.xs"
                      :class="{ 'floob-dialog-card': !$vuetify.breakpoint.xs }"
                      v-bind:style="{
                        backgroundColor:
                          !$vuetify.breakpoint.xs && $vuetify.theme.dark
                            ? '#2d2d2d'
                            : null
                      }"
                    >
                      <v-card-title class="floob-toolbar-title"
                        ><v-btn
                          text
                          x-large
                          @click="isVisibleUserIntroductionUpdate = false"
                          :ripple="$store.state.isDisplayTouch"
                          class="floob-toolbar-button-left"
                        >
                          <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>

                        <v-toolbar-title><h1>프로필 소개</h1></v-toolbar-title>
                        <v-btn
                          text
                          x-large
                          :loading="isLoading"
                          @click="onClickSaveUserIntroduction"
                          :ripple="$store.state.isDisplayTouch"
                          class="pr-0"
                        >
                          저장
                        </v-btn></v-card-title
                      >

                      <v-card-text class="pa-0">
                        <v-container fluid>
                          <v-row no-gutters>
                            <v-col class="pa-2">
                              <div>
                                <v-textarea
                                  :auto-grow="isIntroductionAuthGrow"
                                  v-model="introduction"
                                  solo-inverted
                                  flat
                                  :autofocus="false"
                                  no-resize
                                  hide-details
                                  :rows="introductionRows"
                                ></v-textarea>
                              </div>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-card-actions> </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-list-item>
                <v-list-item v-if="introductionLines.length > 3">
                  <v-btn
                    v-if="isShowIntroductionMore"
                    text
                    small
                    :ripple="$store.state.isDisplayTouch"
                    @click="isShowIntroductionMore = false"
                    color="grey"
                    class="px-0"
                    >간단히 보기</v-btn
                  >
                  <v-btn
                    v-else
                    text
                    small
                    :ripple="$store.state.isDisplayTouch"
                    @click="isShowIntroductionMore = true"
                    color="grey"
                    class="px-0"
                    >... 자세히 보기</v-btn
                  >
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>

          <v-divider class="my-3"></v-divider>

          <v-row>
            <v-col>
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <div>
                      <h1>서비스</h1>
                    </div>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  v-if="isAdmin"
                  :ripple="$store.state.isDisplayTouch"
                  class="pr-0"
                  @click="onClickFriend"
                >
                  <v-list-item-content>
                    <v-list-item-title>친구</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn text :ripple="$store.state.isDisplayTouch" disabled
                      ><v-icon>mdi-chevron-right</v-icon></v-btn
                    >
                  </v-list-item-action>
                </v-list-item>

                <v-list-item
                  v-if="isAvailableAutoFoodTag || isAdmin"
                  class="pr-1"
                  :ripple="$store.state.isDisplayTouch"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      ><span @click.stop.prevent="onClickAutoFoodTagHelp">
                        자동 음식 태그<v-icon
                          small
                          class="pl-1"
                          color="grey lighten-1"
                          >mdi-help-circle</v-icon
                        ><v-chip
                          label
                          x-small
                          color="amber"
                          dark
                          class="px-1 ml-2"
                        >
                          <b>New</b></v-chip
                        >
                      </span></v-list-item-title
                    >
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-switch
                      v-model="isAutoFoodTag"
                      color="success"
                      inset
                      :ripple="$store.state.isDisplayTouch"
                    >
                    </v-switch>
                  </v-list-item-action>
                </v-list-item>

                <v-list-item :ripple="$store.state.isDisplayTouch" class="pr-0">
                  <v-list-item-content>
                    <v-list-item-title>
                      <span @click.stop.prevent="onClickStoryHelp">
                        스토리<v-icon small class="pl-1" color="grey lighten-1"
                          >mdi-help-circle</v-icon
                        >
                      </span>
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action @click="onClickOpenStory">
                    <v-btn text :ripple="$store.state.isDisplayTouch" disabled
                      ><v-icon>mdi-chevron-right</v-icon></v-btn
                    >
                  </v-list-item-action>
                </v-list-item>

                <v-list-item
                  :ripple="$store.state.isDisplayTouch"
                  class="pr-0"
                  @click="onClickReport"
                >
                  <v-list-item-content>
                    <v-list-item-title>식사 보고서</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn text :ripple="$store.state.isDisplayTouch" disabled
                      ><v-icon>mdi-chevron-right</v-icon></v-btn
                    >
                  </v-list-item-action>
                </v-list-item>

                <v-list-item
                  @click="onClickCustomerService"
                  :ripple="$store.state.isDisplayTouch"
                  class="pr-0"
                >
                  <v-list-item-content>
                    <v-list-item-title>고객 센터</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn text :ripple="$store.state.isDisplayTouch" disabled
                      ><v-icon>mdi-chevron-right</v-icon></v-btn
                    >
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>

          <v-divider class="my-3"></v-divider>

          <v-row>
            <v-col>
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <div>
                      <h1>인증</h1>
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  v-if="
                    $store.state.user.auth.apple &&
                      $store.state.user.auth.apple.isAuthenticated
                  "
                  class="pr-0"
                  :ripple="$store.state.isDisplayTouch"
                  @click="onClicAppleAuthHelp"
                >
                  <v-list-item-avatar size="24">
                    <v-img
                      src="https://floob.blob.core.windows.net/image/apple-icon.png"
                    >
                    </v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>애플</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn
                      v-if="
                        $store.state.user.auth.apple &&
                          $store.state.user.auth.apple.isAuthenticated
                      "
                      text
                      class="px-0"
                      :ripple="$store.state.isDisplayTouch"
                    >
                      <v-icon color="green lighten-1"
                        >mdi-check-circle-outline</v-icon
                      >
                    </v-btn>
                    <v-btn
                      v-else
                      color="primary"
                      text
                      class="px-0"
                      :ripple="$store.state.isDisplayTouch"
                      >인증</v-btn
                    >
                  </v-list-item-action>
                </v-list-item>
                <v-list-item
                  v-if="
                    $store.state.user.auth.kakao &&
                      $store.state.user.auth.kakao.isAuthenticated
                  "
                  class="pr-0"
                  :ripple="$store.state.isDisplayTouch"
                  @click="onClickKakaoAuthHelp"
                >
                  <v-list-item-avatar size="24">
                    <v-img src="https://image.floob.co.kr/kakao-login-icon.png">
                    </v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>카카오</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn
                      v-if="
                        $store.state.user.auth.kakao &&
                          $store.state.user.auth.kakao.isAuthenticated
                      "
                      text
                      class="px-0"
                      :ripple="$store.state.isDisplayTouch"
                    >
                      <v-icon color="green lighten-1"
                        >mdi-check-circle-outline</v-icon
                      >
                    </v-btn>
                    <v-btn
                      v-else
                      color="primary"
                      text
                      class="px-0"
                      :ripple="$store.state.isDisplayTouch"
                      >인증</v-btn
                    >
                  </v-list-item-action>
                </v-list-item>
                <v-list-item
                  v-if="
                    $store.state.user.auth.facebook &&
                      $store.state.user.auth.facebook.isAuthenticated
                  "
                  class="pr-0"
                  :ripple="$store.state.isDisplayTouch"
                  @click="onClicFacebookAuthHelp"
                >
                  <v-list-item-avatar size="24">
                    <v-img
                      src="https://floob.blob.core.windows.net/image/facebook-icon.png"
                    >
                    </v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>페이스북</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn
                      v-if="
                        $store.state.user.auth.facebook &&
                          $store.state.user.auth.facebook.isAuthenticated
                      "
                      text
                      class="px-0"
                      :ripple="$store.state.isDisplayTouch"
                    >
                      <v-icon color="green lighten-1"
                        >mdi-check-circle-outline</v-icon
                      >
                    </v-btn>
                    <v-btn
                      v-else
                      color="primary"
                      text
                      class="px-0"
                      :ripple="$store.state.isDisplayTouch"
                      >인증</v-btn
                    >
                  </v-list-item-action>
                </v-list-item>
                <v-list-item class="pr-0">
                  <v-list-item-avatar size="24">
                    <v-img
                      src="https://floob.blob.core.windows.net/image/instagram-icon.ico"
                    >
                    </v-img>
                  </v-list-item-avatar>
                  <v-list-item-content
                    :ripple="$store.state.isDisplayTouch"
                    @click="onClicInstagramAuthHelp"
                  >
                    <v-list-item-title
                      >인스타그램
                      <v-btn
                        v-if="instagramUserName"
                        text
                        small
                        color="grey"
                        :ripple="$store.state.isDisplayTouch"
                        @click.stop.prevent="onClicInstagramAuthLink"
                        class="px-1"
                      >
                        <v-icon small class="pr-1">mdi-link</v-icon>
                        <b>{{ instagramUserName }}</b>
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn
                      v-if="isLoadingInstagramAuthorization"
                      text
                      disabled
                      :ripple="$store.state.isDisplayTouch"
                      :loading="true"
                    >
                    </v-btn>
                    <template v-else>
                      <v-btn
                        v-if="isAuthenticatedInstagram"
                        text
                        :ripple="$store.state.isDisplayTouch"
                        color="grey lighten-1"
                        @click="onClicInstagramAuthLink"
                      >
                        <v-icon color="green lighten-1"
                          >mdi-check-circle-outline</v-icon
                        >
                      </v-btn>
                      <v-btn
                        v-else-if="isExpiredAuthenticatedInstagram"
                        text
                        :ripple="$store.state.isDisplayTouch"
                        color="grey lighten-1"
                        @click="onClickAuthorizeInstagram"
                        >인증 만료됨<v-icon>mdi-chevron-right</v-icon></v-btn
                      >
                      <v-btn
                        v-else
                        text
                        :ripple="$store.state.isDisplayTouch"
                        color="grey lighten-1"
                        @click="onClickAuthorizeInstagram"
                        >인증하기<v-icon>mdi-chevron-right</v-icon></v-btn
                      >
                    </template>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>

          <v-divider class="mt-3 mb-6"></v-divider>

          <v-row class="mb-10 pb-10">
            <v-col>
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <div>
                      <h1>앱</h1>
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  class="pr-1"
                  :ripple="$store.state.isDisplayTouch"
                  @click="onClickDarkMode"
                >
                  <v-list-item-content>
                    <v-list-item-title>다크모드</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-switch
                      v-model="isDarkMode"
                      color="success"
                      inset
                      :ripple="$store.state.isDisplayTouch"
                      :disabled="!isValidDarkMode"
                    >
                    </v-switch>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item
                  class="pr-1"
                  :ripple="$store.state.isDisplayTouch"
                  @click="onClickAppReview"
                >
                  <v-list-item-content>
                    <v-list-item-title>평가하기</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  :ripple="$store.state.isDisplayTouch"
                  @click="onClickVersion"
                >
                  <v-list-item-content>
                    <v-list-item-title v-if="isAdmin && $cookies.get('webview')"
                      >버전
                      {{ `${$store.state.version} (웹 ${version})` }}

                      <template v-if="isLatestVersion != null">
                        <v-btn
                          v-if="isLatestVersion"
                          small
                          text
                          color="grey"
                          class="px-0"
                          :ripple="$store.state.isDisplayTouch"
                          ><b>Latest</b></v-btn
                        >
                        <v-btn
                          v-else
                          small
                          text
                          color="grey"
                          class="px-0"
                          :ripple="$store.state.isDisplayTouch"
                          ><b>{{
                            `Update avaliable ${latestAppVersion}`
                          }}</b></v-btn
                        >
                      </template>
                    </v-list-item-title>
                    <v-list-item-title v-else
                      >버전 {{ $store.state.version }}
                      <template v-if="isLatestVersion != null">
                        <v-btn
                          v-if="isLatestVersion"
                          small
                          text
                          color="grey"
                          class="px-0"
                          :ripple="$store.state.isDisplayTouch"
                          ><b>Latest</b></v-btn
                        >
                        <v-btn
                          v-else
                          small
                          text
                          color="grey"
                          class="px-0"
                          :ripple="$store.state.isDisplayTouch"
                          ><b>{{
                            `Update avaliable ${latestAppVersion}`
                          }}</b></v-btn
                        >
                      </template></v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  @click="onClickLogout"
                  :ripple="$store.state.isDisplayTouch"
                >
                  <v-list-item-content>
                    <v-list-item-title class="red--text text--lighten-2">
                      <b>로그아웃</b></v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </template>
        <v-row v-else no-gutters>
          <v-col>
            <v-btn
              text
              block
              :ripple="$store.state.isDisplayTouch"
              class="pt-10 mt-10"
              @click="
                $store.dispatch('set', {
                  key: 'isVisibleLoginDialog',
                  value: true
                })
              "
              color="grey"
            >
              {{ `로그인이 필요합니다${getRandomEmojiSymbol()}` }}
            </v-btn>
          </v-col>
        </v-row>
        <template v-if="isAdmin">
          <v-divider class="mt-3 mb-6"></v-divider>
          <v-row>
            <v-col>
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <div>
                      <h1>관리자</h1>
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  class="pr-0"
                  :ripple="$store.state.isDisplayTouch"
                  @click="userListDialog.show()"
                >
                  <v-list-item-content>
                    <v-list-item-title>사용자 관리</v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn text :ripple="$store.state.isDisplayTouch" disabled
                      ><v-icon>mdi-chevron-right</v-icon></v-btn
                    >
                  </v-list-item-action>
                </v-list-item>
                <v-list-item
                  class="pr-0"
                  :ripple="$store.state.isDisplayTouch"
                  @click="mealListDialog.show()"
                >
                  <v-list-item-content>
                    <v-list-item-title>식사 관리</v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn text :ripple="$store.state.isDisplayTouch" disabled
                      ><v-icon>mdi-chevron-right</v-icon></v-btn
                    >
                  </v-list-item-action>
                </v-list-item>
                <v-list-item
                  class="pr-0"
                  :ripple="$store.state.isDisplayTouch"
                  @click="cookieDialog.show()"
                >
                  <v-list-item-content>
                    <v-list-item-title>쿠키 관리</v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn text :ripple="$store.state.isDisplayTouch" disabled
                      ><v-icon>mdi-chevron-right</v-icon></v-btn
                    >
                  </v-list-item-action>
                </v-list-item>
                <v-list-item
                  class="pr-0"
                  :ripple="$store.state.isDisplayTouch"
                  @click="isVisibleWebviewUrlUpdate = true"
                >
                  <v-list-item-content>
                    <v-list-item-title>웹뷰 URL 설정</v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn text :ripple="$store.state.isDisplayTouch" disabled
                      ><v-icon>mdi-chevron-right</v-icon></v-btn
                    >
                  </v-list-item-action>
                </v-list-item>

                <v-list-item class="pr-0" :ripple="$store.state.isDisplayTouch">
                  <v-list-item-content>
                    <v-list-item-title>터치 표시</v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-switch
                      v-model="isDisplayTouch"
                      color="success"
                      inset
                      :ripple="$store.state.isDisplayTouch"
                    >
                    </v-switch>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item
                  @click="onClickReload"
                  :ripple="$store.state.isDisplayTouch"
                >
                  <v-list-item-content>
                    <v-list-item-title class="red--text text--lighten-2">
                      <b>앱 새로고침</b></v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </template>
      </v-container>
    </v-sheet>

    <floob-friend-dialog :dialog.sync="friendDialog"> </floob-friend-dialog>

    <floob-story-dialog :dialog.sync="storyDialog"> </floob-story-dialog>

    <floob-customer-service-dialog :dialog.sync="csDialog">
    </floob-customer-service-dialog>

    <floob-browser-dialog :dialog.sync="browserDialog"> </floob-browser-dialog>
    <floob-message-dialog :dialog.sync="messageDialog"> </floob-message-dialog>

    <floob-user-list-dialog
      :dialog.sync="userListDialog"
    ></floob-user-list-dialog>

    <floob-meal-list-dialog
      :dialog.sync="mealListDialog"
    ></floob-meal-list-dialog>

    <floob-cookie-dialog :dialog.sync="cookieDialog"></floob-cookie-dialog>

    <v-dialog
      v-model="isVisibleWebviewUrlUpdate"
      :fullscreen="$vuetify.breakpoint.xs"
      transition="slide-x-reverse-transition"
      max-width="500px"
      scrollable
    >
      <v-card
        :tile="$vuetify.breakpoint.xs"
        :class="{ 'floob-dialog-card': !$vuetify.breakpoint.xs }"
        v-bind:style="{
          backgroundColor:
            !$vuetify.breakpoint.xs && $vuetify.theme.dark ? '#2d2d2d' : null
        }"
      >
        <v-card-title class="floob-toolbar-title"
          ><v-btn
            text
            x-large
            @click="isVisibleWebviewUrlUpdate = false"
            :ripple="$store.state.isDisplayTouch"
            class="floob-toolbar-button-left"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>

          <v-toolbar-title><h1>웹뷰 URL</h1></v-toolbar-title>
          <v-btn
            text
            x-large
            :loading="isLoading"
            :ripple="$store.state.isDisplayTouch"
            @click="onClickSaveWebviewUrl"
            class="pr-0"
          >
            저장
          </v-btn></v-card-title
        >

        <v-card-text class="px-2">
          <v-container fluid>
            <v-row>
              <v-col>
                <div>
                  <v-text-field
                    v-model="webviewUrl"
                    solo-inverted
                    flat
                    :autofocus="false"
                    hide-details
                  >
                  </v-text-field>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions> </v-card-actions>
      </v-card>
    </v-dialog>

    <floob-message-snackbar
      :dialog.sync="messageSnackbar"
    ></floob-message-snackbar>
  </section>
</template>

<script>
const VERSION = require('../../../package.json').version;

import moment from 'moment';
import semver from 'semver';

import api from '@/api';
import instagramApi from '@/api/instagram';
import converter from '@/util/converter';
import compressor from '@/util/compressor';
import emoji from '@/util/emoji';
import wvc from '@/util/webViewController';

import floobFriendDialog from '@/components/friendDialog.vue';
import floobStoryDialog from '@/components/storyDialog.vue';
import floobCustomerServiceDialog from '@/components/customerServiceDialog.vue';
import floobBrowserDialog from '@/components/browserDialog.vue';
import floobMessageDialog from '@/components/messageDialog.vue';

import floobUserListDialog from '@/components/admin/userListDialog.vue';
import floobMealListDialog from '@/components/admin/mealListDialog.vue';
import floobCookieDialog from '@/components/admin/cookieDialog.vue';

import floobMessageSnackbar from '@/components/messageSnackbar.vue';

export default {
  props: {
    hideHeader: {
      default: false
    }
  },
  components: {
    floobFriendDialog,
    floobStoryDialog,
    floobCustomerServiceDialog,
    floobBrowserDialog,
    floobMessageDialog,
    floobMessageSnackbar,
    // admin
    floobUserListDialog,
    floobMealListDialog,
    floobCookieDialog
  },
  data() {
    return {
      // meat
      version: VERSION,

      // modules
      friendDialog: null,
      storyDialog: null,
      csDialog: null,
      browserDialog: null,
      messageDialog: null,
      messageSnackbar: null,
      userListDialog: null,
      mealListDialog: null,
      cookieDialog: null,

      // controlls
      isLoading: false,
      isLatestVersion: null,
      latestAppVersion: null,
      isScrolled: false,
      window: {
        width: 0,
        height: 0
      },
      isVisibleUserNameUpdate: false,
      isVisibleUserIntroductionUpdate: false,
      isIntroductionAuthGrow: true,
      isShowIntroductionMore: false,
      isLoadingInstagramAuthorization: false,
      isVisibleWebviewUrlUpdate: false,
      webviewUrl: 'https://floob.co.kr/app?platform=ios',

      // datas
      name: null,
      introduction: null,
      instagramUserName: null
    };
  },
  methods: {
    init() {
      this.isLoading = false;
      this.isScrolled = false;
      this.isLoadingInstagramAuthorization = false;
      this.instagramUserName = null;
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    scrollTop() {
      document
        .getElementById('floobSettingFrameSheet')
        .scrollTo({ top: 0, behavior: 'smooth' });
    },
    onClickReload() {
      location.reload();
    },
    logOut() {
      window.Floob._analytics.logEvent('logout', {
        user_name: this.$store.getters.user.nickname
      });

      window.$cookies.remove(
        'access_token',
        '/',
        location.hostname.replace('www', '')
      );

      this.$store.dispatch('set', { key: 'user', value: null });
    },
    getRandomEmojiSymbol() {
      return emoji.getRandomEmojiSymbol();
    },
    onScroll(e) {
      if (e.target.scrollTop > 0) {
        this.isScrolled = true;
      } else {
        this.isScrolled = false;
      }
    },
    onClickSaveWebviewUrl() {
      window.location.href = this.webviewUrl;
    },
    onClickUpdateUserName() {
      this.name = this.$store.getters.user.name;
      this.isVisibleUserNameUpdate = true;
    },
    onClickUpdateUserIntroduction() {
      this.introduction = this.$store.getters.user.introduction;
      this.isVisibleUserIntroductionUpdate = true;
    },
    onClickLogout() {
      this.logOut();

      if (this.$cookies.get('webview')) {
        wvc.onLoggedOut();
      } else {
        window.location.href = '/';
      }
    },
    onClickSaveUserName() {
      this.updateUserName(this.name);
    },
    onClickSaveUserIntroduction() {
      this.updateUserIntroduction(this.introduction);
    },
    onClickUpdateProfileImage() {
      this.$refs.profileImageFile.value = '';
      this.$refs.profileImageFile.click();
    },
    onChangeProfileImageFile(e) {
      const files = e.target.files;

      if (files[0] !== undefined) {
        let selectedImageFile = files[0];

        if (selectedImageFile.name.lastIndexOf('.') <= 0) {
          return;
        }

        compressor.compressImage(selectedImageFile).then(compressedFile => {
          const fr = new FileReader();

          fr.readAsDataURL(compressedFile);

          fr.addEventListener('load', () => {
            this.profileImageUri = fr.result;

            let imageFile = converter.convertDataURItoBlob(
              this.profileImageUri
            );

            this.updateUserProfileImage(imageFile);
          });
        });
      }
    },
    updateUserName(name) {
      this.isLoading = true;

      api.user
        .updateUser({
          _id: this.$store.state.user._id,
          name: name
        })
        .then(updated => {
          if (updated) {
            this.$store.dispatch('set', {
              key: 'user',
              value: {
                ...this.user,
                name: updated.name
              }
            });
          } else {
            this.messageSnackbar.show(
              '이름 변경이 실패하였습니다. 다시 시도하여 주시기 바랍니다.'
            );
          }
          this.isVisibleUserNameUpdate = false;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    updateUserIntroduction(introduction) {
      this.isLoading = true;

      api.user
        .updateUser({
          _id: this.$store.state.user._id,
          introduction: introduction
        })
        .then(updated => {
          if (updated) {
            this.$store.dispatch('set', {
              key: 'user',
              value: {
                ...this.user,
                introduction: this.introduction
              }
            });
          } else {
            this.messageSnackbar.show(
              '자기소개 변경이 실패하였습니다. 다시 시도하여 주시기 바랍니다.'
            );
          }
          this.isVisibleUserIntroductionUpdate = false;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    updateUserProfileImage(file) {
      api.user.updateUserProfileImage(file).then(imageUrl => {
        this.$store.dispatch('set', {
          key: 'user',
          value: {
            ...this.user,
            profileImageUrl: imageUrl
          }
        });
      });
    },
    onClickFriend() {
      this.friendDialog.show();
    },
    onClickAutoFoodTagHelp() {
      this.messageDialog.show({
        title: '자동 음식 태그란? 🤖',
        content: `식사 기록 게시물의 음식 정보를 자동으로 분석하여 태그합니다.
        자동 태그는 언제든지 수정이 가능하며 태깅이 완료 되면 푸시 알림을 보내드리니 앱 알림을 허용해주세요. 🔔

        <div style="color:#E57373;">음식 이미지 인식이 가능한 게시물들로 진행되며 실제와 차이가 있을 수 있습니다</div>`,
        additionalButtons: [
          {
            text: '알림 설정 보기',
            action: () => {
              if (this.$cookies.get('webview')) {
                if (this.$cookies.get('platform') === 'ios') {
                  wvc.openUrl('app-settings:');
                }
              }
            }
          },
          {
            text: '닫기',
            action: () => {
              this.messageDialog.hide();
            }
          }
        ]
      });
    },
    onClickStoryHelp() {
      this.messageDialog.show({
        title: '스토리란? 📙',
        content: `<div>내 식사기록 중 의미 있었던 식사들을 스토리로 만들어서 관리 및 공유 할 수 있습니다.    
        스토리를 통해 '내가 좋아하는 음식 모음집' 또는 '나의 맛집 기록'과 같은 나만의 식사 스토리를 만들어보세요. 😊
        
        <b>설정방법</b>
        타임라인의 식사 항목별 더보기(・・・) 또는 식사 수정에서 '스토리 포함'을 활성화합니다.
        </div>`,
        additionalButtons: [
          {
            text: '내 스토리 보기',
            action: () => {
              this.storyDialog.show(this.$store.state.user._id);
            }
          },
          {
            text: '닫기',
            action: () => {
              this.messageDialog.hide();
            }
          }
        ]
      });
    },
    onClickOpenStory() {
      this.storyDialog.show(this.$store.state.user._id);
    },
    onClickReport() {
      this.$store.dispatch('set', { key: 'menu', value: 'report' });
    },
    onClickCustomerService() {
      this.csDialog.show();
    },
    onClickKakaoAuthHelp() {
      this.messageDialog.alert(
        `카카오 계정 인증은 Floob의 간편 회원가입과 로그인을 지원합니다.`,
        '카카오 인증이란?'
      );
    },
    onClicAppleAuthHelp() {
      this.messageDialog.alert(
        `애플 계정 인증은 Floob의 간편 회원가입과 로그인을 지원합니다.`,
        '애플 인증이란?'
      );
    },
    onClicFacebookAuthHelp() {
      this.messageDialog.alert(
        `페이스북 계정 인증은 Floob의 간편 회원가입과 로그인을 지원합니다.`,
        '페이스북 인증이란?'
      );
    },
    onClicInstagramAuthHelp() {
      this.messageDialog.alert(
        `인스타그램 인증을 하면 Floob의 식사기록 등록 시 인스타그램 게시물을 연동해서 등록 할 수 있는 간편 등록 서비스를 지원합니다.\n\n인증 만료 시 재인증이 필요할 수 있습니다.`,
        '인스타그램 인증이란?'
      );
    },
    onClicInstagramAuthLink() {
      this.messageDialog.show({
        title: '인스타그램 인증',
        content: `인스타그램 인증이 완료 되었습니다\n\n연결 된 계정\n<b style="font-size:1.2em;">${this.instagramUserName}</b>`,
        isVisibleCancelButton: false,
        isVisibleOkButton: false,
        additionalButtons: [
          {
            text: '연결 해제',
            color: 'red lighten-1',
            action: () => {
              this.instagramUserName = null;
              return api.auth.unverifyInstagramAuth().then(() => {
                this.checkAuthorizedInstagram(false);
                this.messageDialog.hide();
                this.messageSnackbar.show('인스타그램 인증이 해제되었습니다.');
              });
            }
          },
          {
            text: '확인',
            action: () => {
              this.messageDialog.hide();
            }
          }
        ]
      });
    },
    onClickDarkMode() {
      if (!this.isValidDarkMode) {
        if (this.$cookies.get('platform') === 'ios') {
          this.messageDialog.alert(
            `iPhone은 시스템 디스플레이 설정으로 제어가 가능합니다. 아래 방법을 참고하여 주시기 바랍니다.

            1. iPhone 홈 > 설정 > 디스플레이 및 밝기
            2. 라이트 모드 또는 다크 모드 선택`,
            'Floob 앱 다크모드 설정 방법'
          );
        } else {
          this.messageDialog.alert(
            `시스템 디스플레이 설정으로 제어 가능합니다.`,
            'Floob 앱 다크모드 설정 방법'
          );
        }
      }
    },
    onClickAppReview() {
      if (
        this.$cookies.get('platform') === 'ios' &&
        semver.valid(this.$store.state.version) &&
        semver.gte(this.$store.state.version, '1.4.0')
      ) {
        wvc.openUrl(
          'https://apps.apple.com/app/id1545936680?&action=write-review'
        );
      }
    },
    checkAuthorizedInstagram(isAlert) {
      api.user
        .getUserBySession()
        .then(user => {
          if (user && user.nickname) {
            if (
              user &&
              user.auth &&
              user.auth.instagram &&
              user.auth.instagram.isAuthenticated
            ) {
              if (isAlert) {
                this.messageSnackbar.show('인스타그램 인증이 완료 되었습니다.');
              }
            } else {
              if (isAlert) {
                this.messageSnackbar.show('인스타그램 인증이 실패하였습니다.');
              }
            }

            this.$store.dispatch('set', { key: 'user', value: { ...user } });
          }
        })
        .catch(err => {
          if (err) {
            console.error(err);
          }
        })
        .finally(() => {
          this.isLoadingInstagramAuthorization = false;
        });
    },
    authorizeInstagram() {
      const FLOOB_INSTAGRAM_APP_ID = '157797632830975';
      const REDIRECTION_URL = 'https://floob.co.kr/auth/instagram.html';
      let instagramAuthPopup = window.open(
        `https://api.instagram.com/oauth/authorize?client_id=${FLOOB_INSTAGRAM_APP_ID}&scope=user_profile,user_media&response_type=code&redirect_uri=${REDIRECTION_URL}`
      );
      var instagramAuthPopupTick = setInterval(() => {
        if (instagramAuthPopup.closed) {
          clearInterval(instagramAuthPopupTick);
          console.log('instagramAuthPopup closed.');

          this.checkAuthorizedInstagram(true);
        }
      }, 500);
    },
    onClickAuthorizeInstagram() {
      this.isLoadingInstagramAuthorization = true;
      if (this.$cookies.get('webview')) {
        wvc.openInstagramLogin();
      } else {
        this.authorizeInstagram();
      }
    },
    onClickVersion() {
      if (
        this.$cookies.get('platform') === 'ios' &&
        semver.valid(this.$store.state.version) &&
        semver.gte(this.$store.state.version, '1.4.0')
      ) {
        wvc.openUrl(`https://apps.apple.com/kr/app/floob/id1545936680`);
      }
    }
  },
  computed: {
    sharedMenu() {
      return this.$store.state.menu;
    },
    isAvailableAutoFoodTag() {
      if (semver.gte(this.$store.state.version, '1.7.0')) {
        return true;
      } else {
        return false;
      }
    },
    isAdmin() {
      return (
        this.isLoggedIn &&
        this.$store.state.user.introduction &&
        this.$store.state.user.introduction.includes(`I am a floob`)
      );
    },
    isIOS() {
      return (
        this.$cookies.get('webview') && this.$cookies.get('platform') === 'ios'
      );
    },
    frameHeight() {
      let headerHeight = 56;
      let bottomNavHeight = 80;
      return this.window.height - headerHeight - bottomNavHeight;
    },
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    },
    isValidDarkMode() {
      return !(
        this.$cookies.get('webview') && this.$cookies.get('platform') === 'ios'
      );
    },
    user() {
      return this.$store.state.user;
    },
    joinedYear() {
      if (this.$store.state.user && this.$store.state.user.createdDate) {
        return moment(this.$store.state.user.createdDate).format('YYYY');
      } else {
        return '';
      }
    },
    introductionRows() {
      if (this.introduction) {
        if (this.introduction.split('\n').length > 6) {
          return 6;
        } else {
          return this.introduction.split('\n').length;
        }
      } else {
        return 1;
      }
    },
    introductionLines() {
      if (this.$store.state.user.introduction) {
        return this.$store.state.user.introduction.split('\n');
      } else {
        return [];
      }
    },
    introductionHtml() {
      if (this.$store.state.user.introduction) {
        if (this.isShowIntroductionMore) {
          return converter.convertTextToHtml(
            this.$store.state.user.introduction
          );
        } else {
          return converter.convertTextToHtml(
            this.introductionLines.slice(0, 3).join('\n')
          );
        }
      } else {
        return '';
      }
    },
    isDarkMode: {
      get() {
        return this.$vuetify.theme.dark;
      },
      set(value) {
        if (value) {
          this.$store.dispatch('set', { key: 'theme', value: 'dark' });
        } else {
          this.$store.dispatch('set', { key: 'theme', value: 'light' });
        }
      }
    },
    isDisplayTouch: {
      get() {
        return this.$store.state.isDisplayTouch;
      },
      set(value) {
        this.$store.dispatch('set', { key: 'isDisplayTouch', value: value });
      }
    },
    isAuthenticatedInstagram() {
      return (
        this.$store.state.user &&
        this.$store.state.user.auth &&
        this.$store.state.user.auth.instagram &&
        this.$store.state.user.auth.instagram.isAuthenticated &&
        this.$store.state.user.auth.instagram.accessToken
      );
    },
    isExpiredAuthenticatedInstagram() {
      return (
        this.$store.state.user &&
        this.$store.state.user.auth &&
        this.$store.state.user.auth.instagram &&
        moment(
          this.$store.state.user.auth.instagram.authenticatedDate
        ).isValid()
      );
    },
    lastAuthenticatedDateByInstagram() {
      return moment(
        this.$store.state.user.auth.instagram.authenticatedDate
      ).format('YY-MM-DD');
    },
    isAutoFoodTag: {
      get() {
        if (this.$store.state.user && this.$store.state.user.autoFoodTag) {
          return true;
        } else {
          return false;
        }
      },
      set(value) {
        api.user.updateUser({
          _id: this.$store.state.user._id,
          autoFoodTag: value
        });
      }
    }
  },
  watch: {
    sharedMenu(value) {
      if (value === 'setting') {
        if (this.$cookies.get('platform') === 'ios') {
          api.app.version('ios').then(version => {
            this.latestAppVersion = version;
            if (semver.lt(this.$store.state.version, version)) {
              this.isLatestVersion = false;
            } else {
              this.isLatestVersion = true;
            }
          });
        }
      }
    },
    introduction(value) {
      if (value && value.split('\n').length > 6) {
        this.isIntroductionAuthGrow = false;
      } else {
        this.isIntroductionAuthGrow = true;
      }
    },
    isAuthenticatedInstagram(value) {
      if (value) {
        this.isLoadingInstagramAuthorization = true;
        return instagramApi
          .getUser({
            fields: 'account_type,id,media_count,username',
            access_token: this.$store.state.user.auth.instagram.accessToken
          })
          .then(data => {
            // console.log('instagramApi.getUser', data);
            this.instagramUserName = data.username;
          })
          .catch(err => {
            console.log('instagram getUser error occured', err);

            return api.auth.expireInstagramAuth().then(() => {
              this.checkAuthorizedInstagram(false);
            });
          })
          .finally(() => {
            this.isLoadingInstagramAuthorization = false;
          });
      }
    }
  },
  created() {
    this.$emit('update:module', {
      scrollTop: () => {
        this.scrollTop();
      }
    });

    window.addEventListener('resize', this.handleResize);
    this.handleResize();

    if (
      this.$cookies.get('webview') &&
      this.$cookies.get('platform') === 'ios'
    ) {
      const checkAuthorizedInstagramOnSetting = () => {
        this.checkAuthorizedInstagram(true);
      };
      window.FloobWebView.checkAuthorizedInstagramOnSetting = checkAuthorizedInstagramOnSetting;
      window.Floob._webview.checkAuthorizedInstagramOnSetting = checkAuthorizedInstagramOnSetting;
    }
  }
};
</script>

<style></style>
