<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col>
        <v-card flat>
          <v-card-title>
            <h1>
              Floob 개인정보 처리방침
            </h1></v-card-title
          >
          <v-card-text>
            <floob-privacy
              v-bind:style="{ height: `${frameHeight}px` }"
            ></floob-privacy>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import floobPrivacy from '@/components/privacy.vue';
export default {
  components: {
    floobPrivacy
  },
  data() {
    return {
      window: {
        width: 0,
        height: 0
      }
    };
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    }
  },
  computed: {
    frameHeight() {
      let headerHeight = 56;
      let bottomNavHeight = 0;
      return this.window.height - headerHeight - bottomNavHeight;
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }
};
</script>

<style scoped>
pre {
  padding: 5px;
  overflow-x: hidden;
  white-space: pre-wrap;
}
</style>
