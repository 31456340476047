var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-dialog',{attrs:{"fullscreen":_vm.$vuetify.breakpoint.xs,"transition":"dialog-bottom-transition","max-width":"500px","scrollable":""},model:{value:(_vm.isVisible),callback:function ($$v) {_vm.isVisible=$$v},expression:"isVisible"}},[_c('v-card',{class:{ 'floob-dialog-card': !_vm.$vuetify.breakpoint.xs },attrs:{"tile":_vm.$vuetify.breakpoint.xs}},[_c('v-card-title',{staticClass:"floob-toolbar-title"},[_c('v-btn',{staticClass:"pl-0",attrs:{"text":"","x-large":"","ripple":_vm.$store.state.isDisplayTouch},on:{"click":function($event){_vm.isVisible = false}}},[_vm._v(" 취소 ")]),_c('v-toolbar-title',[_c('h1',[_vm._v("사용자 아이디")])]),_c('v-btn',{staticClass:"pr-0",attrs:{"text":"","x-large":"","ripple":_vm.$store.state.isDisplayTouch,"loading":_vm.isLoading},on:{"click":_vm.updateUserNickname}},[_vm._v(" 등록 ")])],1),_c('v-divider'),_c('v-card-text',{staticClass:"px-2"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('div',[_c('v-text-field',{ref:"nicknameInput",attrs:{"solo-inverted":"","flat":"","autofocus":false,"rules":[
                    function (n) {
                      return (
                        _vm.rules.counterMin(n) || '최소 5자 이상이 필요합니다.'
                      );
                    },
                    function (n) {
                      return (
                        _vm.rules.counterMax(n) ||
                        '최대 20자를 초과할 수 없습니다.'
                      );
                    },
                    function (n) {
                      return (
                        _vm.rules.pattern(n) ||
                        '알파벳 소문자와 숫자 그리고 특수문자는 _ 만 허용합니다.'
                      );
                    }
                  ],"error-messages":_vm.errorMessage,"messages":_vm.creatableMessage},on:{"blur":_vm.onBlurNickname},model:{value:(_vm.nickname),callback:function ($$v) {_vm.nickname=$$v},expression:"nickname"}})],1),_c('div',{staticClass:"px-1 pt-3",staticStyle:{"color":"rgb(171 171 171)"}},[_vm._v(" Floob 서비스를 사용하기 위해 고유한 사용자 아이디 생성이 필요합니다. "),_c('br'),_vm._v(" 나를 잘 표현하며 간결한 아이디를 생성해보세요.😃 ")])])],1)],1)],1),_c('v-card-actions')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }