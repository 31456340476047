var moment = require('moment');
var imageToURI = require('image-to-data-uri');

exports.convertDataURItoBlob = dataURI => {
  if (dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI
      .split(',')[0]
      .split(':')[1]
      .split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  } else {
    return null;
  }
};

exports.convertImageUrlToDataURI = imageUrl => {
  return new Promise((resolve, reject) => {
    imageToURI(imageUrl, (err, dataUri) => {
      if (!err) {
        resolve(dataUri);
      } else {
        reject(err);
      }
    });
  });
};

const urlify = text => {
  var urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, url => {
    if (window.$cookies.get('webview')) {
      return `<a onclick="window.Floob._webview.openUrl('${url}')">${url}</a>`;
    } else {
      return `<a target="_blank" href="${url}">${url}</a>`;
    }
  });
};

exports.convertTextToHtml = text => {
  if (text) {
    text = urlify(text);
    return text.replace(/\n/gi, '<br />');
  } else {
    return '';
  }
};

exports.convertHourToText = hour => {
  if (hour === 0) {
    return `오전 ${hour}시`;
  } else if (hour <= 12) {
    return `오전 ${hour}시`;
  } else if (hour === 12) {
    return `오후 ${hour}시`;
  } else {
    return `오후 ${hour - 12}시`;
  }
};

const convertMealType = mealedDate => {
  let mealedHour = moment(mealedDate).hours();

  if (mealedHour >= 5 && mealedHour < 11) {
    return 'breakfast';
  } else if (mealedHour >= 11 && mealedHour < 17) {
    return 'lunch';
  } else if (mealedHour >= 17 && mealedHour < 23) {
    return 'dinner';
  } else {
    return 'midnight-snack';
  }
};

exports.convertMealType = convertMealType;

exports.convertLongMealDateString = mealedDate => {
  return moment(mealedDate).format('LLLL');
};

exports.convertShortenMealDateString = mealedDate => {
  let mealType = convertMealType(mealedDate);
  if (mealType === 'breakfast') {
    return `${moment(mealedDate).format('M월 D일')} • 아침 식사`;
  } else if (mealType === 'lunch') {
    return `${moment(mealedDate).format('M월 D일')} • 점심 식사`;
  } else if (mealType === 'dinner') {
    return `${moment(mealedDate).format('M월 D일')} • 저녁 식사`;
  } else {
    return `${moment(mealedDate).format('M월 D일')} • 야식`;
  }
};

exports.convertDMSToDD = (degrees, minutes, seconds, direction) => {
  var dd = degrees + minutes / 60 + seconds / (60 * 60);

  if (direction == 'S' || direction == 'W') {
    dd = dd * -1;
  } // Don't do anything for N or E
  return dd;
};

exports.convertYoutubeEmbededUrl = url => {
  let youtubeVideoId;
  if (url.includes('//youtube.com') || url.includes('//www.youtube.com')) {
    youtubeVideoId = url.split('v=')[1];
  } else if (url.includes('//youtu.be')) {
    youtubeVideoId = url.split('youtu.be/')[1];
  }
  return `https://www.youtube.com/embed/${youtubeVideoId}`;
};

exports.convertSite = url => {
  if (url) {
    if (url.includes('//youtube.com') || url.includes('//www.youtube.com')) {
      return { code: 'youtube', name: 'YouTube' };
    } else if (
      url.includes('//instagram.com') ||
      url.includes('//www.instagram.com')
    ) {
      return { code: 'instagram', name: 'Instagram' };
    } else if (
      url.includes('//coupang.com') ||
      url.includes('//www.coupang.com')
    ) {
      return { code: 'coupang', name: 'Coupang' };
    } else if (url.includes('//web.coupangeats.com')) {
      return { code: 'coupang-eats', name: 'Coupang Eats' };
    } else if (
      url.includes('//www.baemin.com') ||
      url.includes('//baemin.me')
    ) {
      return { code: 'baemin', name: 'Baemin' };
    } else if (url.includes('//www.yogiyo.co.kr')) {
      return { code: 'yogiyo', name: 'Yogiyo' };
    } else if (url.includes('comic.naver.com')) {
      return { code: 'naver-webtoon', name: 'Naver Webtoon' };
    } else {
      return { code: 'none', name: 'None' };
    }
  } else {
    return { code: 'none', name: 'None' };
  }
};

exports.convertSiteNameBySiteContent = siteContent => {
  if (siteContent.ogSiteName) {
    return siteContent.ogSiteName;
  } else {
    let url = siteContent.url;
    if (url) {
      if (url.includes('//youtube.com') || url.includes('//www.youtube.com')) {
        return 'YouTube';
      } else if (
        url.includes('//instagram.com') ||
        url.includes('//www.instagram.com')
      ) {
        return 'Instagram';
      } else if (
        url.includes('//coupang.com') ||
        url.includes('//www.coupang.com')
      ) {
        return 'Coupang';
      } else if (url.includes('//web.coupangeats.com')) {
        return 'Coupang Eats';
      } else if (
        url.includes('//www.baemin.com') ||
        url.includes('//baemin.me')
      ) {
        return 'Baemin';
      } else if (url.includes('//www.yogiyo.co.kr')) {
        return 'Yogiyo';
      } else {
        return '';
      }
    } else {
      return '';
    }
  }
};

exports.convertDeliverySite = code => {
  let result = {
    code: code
  };
  if (code === 'baemin') {
    result.name = '배달의 민족';
    result.name_eng = 'Baemin';
    result.iconUrl = 'http://img.woowahan.com/www/common/favicon.ico';
  } else if (code === 'coupang-eats') {
    result.name = '쿠팡이츠';
    result.name_eng = 'Coupang Eats';
    result.iconUrl =
      'https://v.fastcdn.co/u/94a64a10/45682545-0-Image-from-iOS-164.png';
  } else if (code === 'yogiyo') {
    result.name = '요기요';
    result.name_eng = 'Yogiyo';
    result.iconUrl = 'https://www.yogiyo.co.kr/mobile/image/favicon.ico';
  }
  return result;
};
