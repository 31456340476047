var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-dialog',{attrs:{"persistent":_vm.isPersistent,"max-width":"300px","content-class":"floob-dialog"},model:{value:(_vm.isVisible),callback:function ($$v) {_vm.isVisible=$$v},expression:"isVisible"}},[_c('v-card',{staticClass:"floob-dialog-card",style:({
        backgroundColor: _vm.$vuetify.theme.dark ? '#2d2d2d' : null
      }),attrs:{"flat":""}},[_c('v-card-title',{staticClass:"pt-4",staticStyle:{"font-size":"1rem","font-weight":"bold"}},[_vm._v(_vm._s(_vm.title))]),_c('v-card-text',{domProps:{"innerHTML":_vm._s(_vm.contentHtml)}}),_c('v-card-actions',{staticClass:"pb-3"},[(_vm.type !== 'alert' && _vm.isVisibleCancelButton)?_c('v-btn',{staticStyle:{"font-size":"1rem !important"},attrs:{"text":"","ripple":_vm.$store.state.isDisplayTouch},on:{"click":_vm.onClickCancel}},[_vm._v("취소")]):_vm._e(),(_vm.isVisibleCustomerServiceButton)?_c('v-btn',{staticStyle:{"font-size":"1rem !important"},attrs:{"text":"","ripple":_vm.$store.state.isDisplayTouch,"color":"red"},on:{"click":_vm.onClickCustomerServiceOpen}},[_vm._v(" 오류 신고 ")]):_vm._e(),_c('v-spacer'),_vm._l((_vm.additionalButtons),function(additionalButton,idx){return _c('v-btn',{key:idx,staticStyle:{"font-size":"1rem !important"},attrs:{"text":"","ripple":_vm.$store.state.isDisplayTouch,"color":additionalButton.color || null},on:{"click":function () {
              additionalButton.action();
              if (!additionalButton.isPersistent) {
                _vm.isVisible = false;
              }
            }}},[_vm._v(" "+_vm._s(additionalButton.text)+" ")])}),(_vm.isVisibleOkButton)?_c('v-btn',{staticStyle:{"font-size":"1rem !important"},attrs:{"text":"","ripple":_vm.$store.state.isDisplayTouch},on:{"click":_vm.onClickOk}},[_vm._v("확인")]):_vm._e()],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }