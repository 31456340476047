<template>
  <section>
    <floob-header></floob-header>

    <v-container :fluid="$vuetify.breakpoint.mdAndDown" class="py-0">
      <v-row
        align="center"
        no-gutters
        v-bind:style="{ height: frameHeight + 'px' }"
      >
        <v-col
          sm="6"
          offset-sm="3"
          md="4"
          offset-md="4"
          xl="2"
          offset-xl="5"
          class="pa-0"
        >
          <floob-login
            v-on:success="onSuccessLogin"
            v-on:fail="onFailLogin"
          ></floob-login>
        </v-col>
      </v-row>
    </v-container>

    <floob-footer :fixed="true"></floob-footer>
  </section>
</template>

<script>
import floobHeader from '@/components/header_v2.vue';
import floobLogin from '@/components/login_v2.vue';
import floobFooter from '@/components/footer.vue';

export default {
  components: {
    floobHeader,
    floobLogin,
    floobFooter
  },
  methods: {
    onSuccessLogin() {
      this.$router.push({ name: 'timeline' });
    },
    onFailLogin(error) {
      console.log('Fail login', error);
    }
  },
  computed: {
    frameHeight() {
      return window.innerHeight * 0.7;
    }
  },
  created() {}
};
</script>

<style></style>
