<template>
  <v-footer :fixed="fixed" dark padless>
    <v-card class="flex" flat tile color="light-blue darken-4">
      <v-card-title>
        <strong class="subheading">Floob</strong>

        <v-spacer></v-spacer>

        <v-btn
          dark
          icon
          href="https://www.instagram.com/floob.app/"
          target="_blank"
        >
          <v-icon size="24px">mdi-instagram </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        Copyright ⓒ BradleyPig, Co. All Rights Reserved
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  props: {
    fixed: {
      default: false
    }
  }
};
</script>

<style></style>
