<template>
  <section>
    <v-app-bar
      v-if="!hideHeader"
      app
      fixed
      flat
      :color="$vuetify.theme.dark ? null : 'white'"
      v-bind:class="{ 'floob-header': isScrolled && !$vuetify.theme.dark }"
    >
      <v-toolbar-title>
        <h1 class="px-2" @click="scrollTop">보고서</h1>
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>

    <v-sheet
      class="floob-tab-item"
      :height="$route.name === 'app' ? frameHeight : null"
      v-scroll.self="onScroll"
      id="floobReportFrameSheet"
      :color="$vuetify.theme.dark || $vuetify.breakpoint.xs ? null : '#efefef'"
    >
      <v-container
        fluid
        id="floob-report-content"
        v-bind:class="{
          'py-0': $vuetify.breakpoint.xs,
          'pa-0': !$vuetify.breakpoint.xs
        }"
      >
        <template v-if="isLoggedIn">
          <v-row no-gutters>
            <v-col>
              <v-card tile flat>
                <!-- date month controller -->
                <v-card-title style="height:56px" class="pa-0">
                  <v-btn
                    small
                    icon
                    :ripple="$store.state.isDisplayTouch"
                    color="amber"
                    class="ml-2"
                    :disabled="isLoading"
                    @click="onClickPrevMonth"
                  >
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                  <v-toolbar-title class="px-0"
                    ><v-btn
                      text
                      x-large
                      color="amber"
                      class="px-0"
                      :ripple="$store.state.isDisplayTouch"
                      :loading="isLoading"
                      :disabled="isLoading"
                      @click="getMeals"
                      >{{ thisYearMonthString }}</v-btn
                    > </v-toolbar-title
                  ><v-btn
                    small
                    icon
                    :ripple="$store.state.isDisplayTouch"
                    color="amber"
                    :disabled="isLoading"
                    @click="onClickNextMonth"
                  >
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-card-title>

                <v-card-text class="pa-0">
                  <!-- 식사 정보 없음 -->
                  <v-container v-if="!isLoading && meals.length === 0">
                    <v-row no-gutters>
                      <v-col>
                        <v-btn
                          text
                          block
                          :ripple="$store.state.isDisplayTouch"
                          class="pt-10 mt-10"
                          color="grey"
                        >
                          {{ `식사 정보가 없습니다 😟` }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pa-0">
              <!-- report body -->
              <v-card tile flat color="transparent">
                <v-card-text class="pa-0">
                  <v-container v-show="meals.length > 0" class="py-0">
                    <v-row
                      v-bind:class="{
                        'py-8': !$vuetify.breakpoint.xs
                      }"
                    >
                      <v-col sm="6" class="py-0">
                        <v-container fluid class="py-0">
                          <!-- 요약 -->
                          <v-row v-if="false">
                            <v-col
                              class="py-0"
                              v-bind:class="{
                                'px-0': $vuetify.breakpoint.xs,
                                'pb-4': !$vuetify.breakpoint.xs
                              }"
                            >
                              <v-card
                                :rounded="!$vuetify.breakpoint.xs ? 'xl' : null"
                                :tile="$vuetify.breakpoint.xs"
                                min-height="200"
                                flat
                              >
                                <v-container
                                  v-if="!isLoading && meals.length > 0"
                                  fluid
                                  class="px-2 py-0"
                                >
                                  <v-row no-gutters>
                                    <v-col>
                                      <v-list>
                                        <v-list-item class="pr-0">
                                          <v-list-item-content>
                                            <v-list-item-title>
                                              <h1>
                                                요약
                                              </h1>
                                            </v-list-item-title>
                                          </v-list-item-content>
                                        </v-list-item>
                                      </v-list>
                                      <v-expansion-panels
                                        v-model="summaryPannels"
                                        multiple
                                        readonly
                                        flat
                                      >
                                        <v-expansion-panel class="mt-0">
                                          <v-expansion-panel-header
                                            disable-icon-rotate
                                            class="px-4 py-0"
                                          >
                                            <span style="font-size:1rem;"
                                              >총 식사 횟수</span
                                            >
                                            <template v-slot:actions>
                                              <span>
                                                34회
                                              </span>
                                            </template>
                                          </v-expansion-panel-header>
                                          <v-expansion-panel-content>
                                            <v-list-item
                                              class="pl-6"
                                              style="min-height:20px; height: 20px;"
                                            >
                                              <v-list-item-content>
                                                <v-list-item-subtitle>
                                                  아침식사
                                                </v-list-item-subtitle>
                                              </v-list-item-content>
                                              <v-list-item-action-text
                                                class="text--right"
                                              >
                                                2회
                                              </v-list-item-action-text>
                                            </v-list-item>
                                            <v-list-item
                                              class="pl-6"
                                              style="min-height:20px; height: 20px;"
                                            >
                                              <v-list-item-content>
                                                <v-list-item-subtitle>
                                                  점심식사
                                                </v-list-item-subtitle>
                                              </v-list-item-content>
                                              <v-list-item-action-text
                                                class="text--right"
                                              >
                                                21회
                                              </v-list-item-action-text>
                                            </v-list-item>
                                            <v-list-item
                                              class="pl-6"
                                              style="min-height:20px; height: 20px;"
                                            >
                                              <v-list-item-content>
                                                <v-list-item-subtitle>
                                                  저녁식사
                                                </v-list-item-subtitle>
                                              </v-list-item-content>
                                              <v-list-item-action-text
                                                class="text--right"
                                              >
                                                5회
                                              </v-list-item-action-text>
                                            </v-list-item>
                                            <v-list-item
                                              class="pl-6"
                                              style="min-height:20px; height: 20px;"
                                            >
                                              <v-list-item-content>
                                                <v-list-item-subtitle>
                                                  야식
                                                </v-list-item-subtitle>
                                              </v-list-item-content>

                                              <v-list-item-action-text
                                                class="text--right"
                                              >
                                                12회
                                              </v-list-item-action-text>
                                            </v-list-item>
                                          </v-expansion-panel-content>
                                        </v-expansion-panel>

                                        <v-expansion-panel class="mt-0">
                                          <v-expansion-panel-header
                                            disable-icon-rotate
                                            class="px-4 py-0"
                                          >
                                            <span style="font-size:1rem;"
                                              >식사 만족도</span
                                            >
                                            <template v-slot:actions>
                                              <span
                                                class="amber--text"
                                                style="font-size:1.2rem;"
                                              >
                                                <v-icon
                                                  color="amber"
                                                  class="pr-1"
                                                  style="margin-top: -2px;"
                                                  >mdi-star</v-icon
                                                >
                                                <b>{{
                                                  reviewScoreAverage
                                                    ? reviewScoreAverage.toFixed(
                                                        1
                                                      )
                                                    : ''
                                                }}</b>
                                              </span>
                                            </template>
                                          </v-expansion-panel-header>
                                          <v-expansion-panel-content
                                            class="px-2"
                                          >
                                            <v-list-item
                                              style="min-height:20px; height: 20px;"
                                            >
                                              <v-list-item-content>
                                              </v-list-item-content>
                                              <v-list-item-action-text
                                                class="text--right"
                                              >
                                                총 34회 식사 중 3개의 평가
                                              </v-list-item-action-text>
                                            </v-list-item>
                                            <v-list-item
                                              style="min-height:20px; height: 20px;"
                                            >
                                              <v-list-item-content>
                                                <v-progress-linear
                                                  color="amber"
                                                  value="50"
                                                  rounded
                                                  height="10"
                                                >
                                                </v-progress-linear>
                                              </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item
                                              :ripple="
                                                $store.state.isDisplayTouch
                                              "
                                            >
                                              <v-btn
                                                text
                                                block
                                                color="grey"
                                                :ripple="
                                                  $store.state.isDisplayTouch
                                                "
                                                >더 평가하기</v-btn
                                              >
                                            </v-list-item>
                                          </v-expansion-panel-content>
                                        </v-expansion-panel>

                                        <v-expansion-panel>
                                          <v-expansion-panel-header
                                            disable-icon-rotate
                                            class="px-4 py-0"
                                          >
                                            <span style="font-size:1rem;"
                                              >총 섭취 열량</span
                                            >
                                            <template v-slot:actions>
                                              <span>
                                                1,234kcal
                                              </span>
                                            </template>
                                          </v-expansion-panel-header>
                                          <v-expansion-panel-content>
                                            <v-list-item
                                              class="pl-6"
                                              style="min-height:20px; height: 20px;"
                                            >
                                              <v-list-item-content>
                                                <v-list-item-subtitle>
                                                  아침식사
                                                </v-list-item-subtitle>
                                              </v-list-item-content>
                                              <v-list-item-action-text
                                                class="text--right"
                                              >
                                                20kcal
                                              </v-list-item-action-text>
                                            </v-list-item>
                                            <v-list-item
                                              class="pl-6"
                                              style="min-height:20px; height: 20px;"
                                            >
                                              <v-list-item-content>
                                                <v-list-item-subtitle>
                                                  점심식사
                                                </v-list-item-subtitle>
                                              </v-list-item-content>
                                              <v-list-item-action-text
                                                class="text--right"
                                              >
                                                210kcal
                                              </v-list-item-action-text>
                                            </v-list-item>
                                            <v-list-item
                                              class="pl-6"
                                              style="min-height:20px; height: 20px;"
                                            >
                                              <v-list-item-content>
                                                <v-list-item-subtitle>
                                                  저녁식사
                                                </v-list-item-subtitle>
                                              </v-list-item-content>
                                              <v-list-item-action-text
                                                class="text--right"
                                              >
                                                500kcal
                                              </v-list-item-action-text>
                                            </v-list-item>
                                            <v-list-item
                                              class="pl-6"
                                              style="min-height:20px; height: 20px;"
                                            >
                                              <v-list-item-content>
                                                <v-list-item-subtitle>
                                                  야식
                                                </v-list-item-subtitle>
                                              </v-list-item-content>

                                              <v-list-item-action-text
                                                class="text--right"
                                              >
                                                1,200kcal
                                              </v-list-item-action-text>
                                            </v-list-item>
                                          </v-expansion-panel-content>
                                        </v-expansion-panel>
                                      </v-expansion-panels>
                                      <v-list-item
                                        :ripple="$store.state.isDisplayTouch"
                                      >
                                        <v-btn
                                          v-if="summaryPannels.length === 0"
                                          text
                                          block
                                          color="grey"
                                          :ripple="$store.state.isDisplayTouch"
                                          @click="summaryPannels = [1, 2]"
                                          >자세히 보기</v-btn
                                        >
                                        <v-btn
                                          v-else
                                          text
                                          block
                                          color="grey"
                                          :ripple="$store.state.isDisplayTouch"
                                          @click="summaryPannels = []"
                                          >간단히 보기</v-btn
                                        >
                                      </v-list-item>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card>
                            </v-col>
                          </v-row>

                          <!-- 식사 만족도 -->
                          <v-row v-if="false">
                            <v-col
                              class="py-0"
                              v-bind:class="{
                                'px-0': $vuetify.breakpoint.xs,
                                'pb-4': !$vuetify.breakpoint.xs
                              }"
                            >
                              <v-card
                                :rounded="!$vuetify.breakpoint.xs ? 'xl' : null"
                                :tile="$vuetify.breakpoint.xs"
                                min-height="200"
                                flat
                              >
                                <v-container
                                  v-if="!isLoading && meals.length > 0"
                                  fluid
                                  class="px-2 py-0"
                                >
                                  <v-row no-gutters>
                                    <v-col>
                                      <v-list>
                                        <v-list-item class="pr-0">
                                          <v-list-item-content>
                                            <v-list-item-title>
                                              <h1>
                                                식사 만족도
                                              </h1>
                                            </v-list-item-title>
                                          </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item class="pr-0">
                                          <v-list-item-content>
                                            <v-progress-linear
                                              color="amber"
                                              value="50"
                                              rounded
                                              class="pl-2"
                                              height="10"
                                            >
                                            </v-progress-linear>
                                          </v-list-item-content>
                                          <v-list-item-action class="ml-0">
                                            <v-btn
                                              large
                                              text
                                              color="amber"
                                              class="px-0"
                                              style="font-size:1.2em;"
                                              :loading="isLoading"
                                            >
                                              <v-icon small>mdi-star</v-icon>
                                              {{
                                                reviewScoreAverage
                                                  ? reviewScoreAverage.toFixed(
                                                      1
                                                    )
                                                  : ''
                                              }}
                                            </v-btn>
                                          </v-list-item-action>
                                        </v-list-item>

                                        <template v-if="!isLoading">
                                          <v-list-item
                                            v-if="reviewedMeals.length < 10"
                                          >
                                            <v-btn
                                              text
                                              block
                                              :ripple="
                                                $store.state.isDisplayTouch
                                              "
                                              class="py-10"
                                              color="grey"
                                            >
                                              {{
                                                `식사 평가가 부족합니다(${reviewedMeals.length}/10) 😟`
                                              }}
                                            </v-btn>
                                          </v-list-item>
                                          <template
                                            v-else-if="reviewScoreAverage > 0"
                                          >
                                            <v-list-item>
                                              <v-list-item-action class="mr-0">
                                                <v-btn
                                                  x-large
                                                  text
                                                  color="amber"
                                                  class="px-0"
                                                >
                                                  <v-icon>mdi-star</v-icon>
                                                  {{
                                                    reviewScoreAverage
                                                      ? reviewScoreAverage.toFixed(
                                                          1
                                                        )
                                                      : ''
                                                  }}
                                                </v-btn>
                                              </v-list-item-action>
                                              <v-list-item-content>
                                                <div style="font-size:32px">
                                                  {{ getReviewSummaryEmoji }}
                                                </div>
                                              </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item>
                                              <v-list-item-content>
                                                <div
                                                  v-html="getReviewSummaryHtml"
                                                  class="px-6"
                                                ></div>
                                              </v-list-item-content>
                                            </v-list-item>
                                          </template>
                                          <v-list-item v-else>
                                            <v-btn
                                              text
                                              block
                                              :ripple="
                                                $store.state.isDisplayTouch
                                              "
                                              class="py-10"
                                              color="grey"
                                            >
                                              {{ `식사 평가가 없습니다 😟` }}
                                            </v-btn>
                                          </v-list-item>
                                        </template>
                                      </v-list>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card>
                            </v-col>
                          </v-row>

                          <v-divider
                            v-if="false"
                            v-show="
                              $vuetify.breakpoint.xs &&
                                !isLoading &&
                                meals.length > 0
                            "
                            class="mx-1"
                          ></v-divider>

                          <!-- 식사 추이 -->
                          <v-row>
                            <v-col
                              class="py-0"
                              v-bind:class="{
                                'px-0': $vuetify.breakpoint.xs,
                                'pb-4': !$vuetify.breakpoint.xs
                              }"
                            >
                              <v-card
                                :rounded="!$vuetify.breakpoint.xs ? 'xl' : null"
                                :tile="$vuetify.breakpoint.xs"
                                min-height="200"
                                flat
                              >
                                <v-container fluid class="pa-2">
                                  <v-row no-gutters>
                                    <v-col>
                                      <v-list
                                        v-if="!isLoading && meals.length > 0"
                                      >
                                        <v-list-item class="pr-0">
                                          <v-list-item-content>
                                            <v-list-item-title>
                                              <h1>식사 추이</h1>
                                            </v-list-item-title>
                                          </v-list-item-content>

                                          <v-list-item-action>
                                            <v-btn
                                              text
                                              class="pr-0 pl-0"
                                              :ripple="
                                                $store.state.isDisplayTouch
                                              "
                                              color="grey"
                                              @click="
                                                isVisibleMealTypeSelection = true
                                              "
                                              >{{
                                                mealTrendByDailyChartSelectableMealTypes[
                                                  selectedMealTrendByDailyChartMealType
                                                ].text
                                              }}<v-icon
                                                v-if="
                                                  isVisibleMealTypeSelection
                                                "
                                                >mdi-menu-up</v-icon
                                              >
                                              <v-icon v-else
                                                >mdi-menu-down</v-icon
                                              ></v-btn
                                            >
                                          </v-list-item-action>
                                        </v-list-item>
                                      </v-list>

                                      <v-row v-if="!isLoading" justify="center">
                                        <v-btn
                                          x-small
                                          :depressed="
                                            selectedMealTrendMealType ===
                                              'count'
                                          "
                                          :text="
                                            selectedMealTrendMealType != 'count'
                                          "
                                          min-width="50"
                                          class="mr-1"
                                          :color="
                                            selectedMealTrendMealType ===
                                            'count'
                                              ? 'primary'
                                              : 'grey lighten-1'
                                          "
                                          :ripple="false"
                                          @click="
                                            selectedMealTrendMealType = 'count'
                                          "
                                          ><b>횟수</b></v-btn
                                        >
                                        <v-btn
                                          x-small
                                          :depressed="
                                            selectedMealTrendMealType ===
                                              'calorie'
                                          "
                                          :text="
                                            selectedMealTrendMealType !=
                                              'calorie'
                                          "
                                          min-width="50"
                                          class="mr-1"
                                          :color="
                                            selectedMealTrendMealType ===
                                            'calorie'
                                              ? 'primary'
                                              : 'grey lighten-1'
                                          "
                                          :ripple="false"
                                          @click="
                                            selectedMealTrendMealType =
                                              'calorie'
                                          "
                                          ><b>열량</b></v-btn
                                        >
                                      </v-row>
                                      <div
                                        class="px-2"
                                        style="min-height: 240px;"
                                      >
                                        <v-list-item
                                          v-if="
                                            selectedMealTrendMealType ===
                                              'calorie' && calorieTotalSum === 0
                                          "
                                        >
                                          <v-btn
                                            text
                                            block
                                            :ripple="
                                              $store.state.isDisplayTouch
                                            "
                                            class="pt-10 mt-10"
                                            color="grey"
                                          >
                                            {{ `섭취한 열량 정보가 없습니다` }}
                                          </v-btn>
                                        </v-list-item>
                                        <template v-else>
                                          <apexchart
                                            v-if="isReadyMealTrendChart"
                                            :options="mealTrendChartOptions"
                                            :series="mealTrendChartSeries"
                                          ></apexchart>
                                        </template>
                                      </div>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card>
                            </v-col>
                          </v-row>

                          <v-divider
                            v-show="
                              $vuetify.breakpoint.xs &&
                                !isLoading &&
                                meals.length > 0
                            "
                            class="mx-1"
                          ></v-divider>
                        </v-container>
                      </v-col>

                      <v-col sm="6" class="py-0">
                        <v-container fluid class="py-0">
                          <v-row>
                            <v-col
                              class="py-0"
                              v-bind:class="{
                                'px-0': $vuetify.breakpoint.xs,
                                'pb-4': !$vuetify.breakpoint.xs
                              }"
                            >
                              <!-- 음식 통계 -->
                              <v-card
                                :rounded="!$vuetify.breakpoint.xs ? 'xl' : null"
                                :tile="$vuetify.breakpoint.xs"
                                min-height="200"
                                flat
                              >
                                <v-container fluid class="pa-2">
                                  <v-row no-gutters>
                                    <v-col>
                                      <v-list
                                        v-if="!isLoading && meals.length > 0"
                                      >
                                        <v-list-item>
                                          <v-list-item-content class="py-0">
                                            <v-list-item-title>
                                              <h1>음식 통계</h1>
                                            </v-list-item-title>
                                          </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item v-if="foods.length === 0">
                                          <v-btn
                                            text
                                            block
                                            :ripple="
                                              $store.state.isDisplayTouch
                                            "
                                            class="mt-10 mb-10"
                                            color="grey"
                                          >
                                            {{ `섭취한 음식 정보가 없습니다` }}
                                          </v-btn>
                                        </v-list-item>
                                        <div v-else style="min-height:240px;">
                                          <apexchart
                                            v-if="isReadyMealStatByFoodChart"
                                            :options="
                                              mealStatByFoodChartOptions
                                            "
                                            :series="mealStatByFoodChartSeries"
                                          ></apexchart>
                                        </div>
                                      </v-list>
                                    </v-col>
                                  </v-row>
                                  <v-row
                                    v-if="
                                      !isLoading &&
                                        meals.length > 0 &&
                                        foods.length > 0
                                    "
                                    no-gutters
                                  >
                                    <v-col>
                                      <v-list dense>
                                        <v-list-item
                                          v-for="(food, idx) in isVisibleAllFood
                                            ? foodStatsByRank
                                            : foodStatsByTop"
                                          :key="idx"
                                        >
                                          <v-list-item-avatar size="24">
                                            <v-icon
                                              :large="
                                                selectedMealStatByFoodChartIdx >=
                                                  0 &&
                                                  selectedMealStatByFoodChartIdx ===
                                                    idx
                                              "
                                              :style="
                                                `color:${chartColors[idx] ||
                                                  'rgb(229 229 229)'}`
                                              "
                                              >mdi-record</v-icon
                                            >
                                          </v-list-item-avatar>
                                          <v-list-item-content>
                                            <v-list-item-title
                                              ><b>{{ food.name }}</b
                                              >&nbsp;<span
                                                style="color:grey; padding-left:5px;"
                                                >• {{ food.count }}회 섭취</span
                                              ></v-list-item-title
                                            >
                                          </v-list-item-content>
                                          <v-list-item-action
                                            >{{
                                              `${parseInt(
                                                food.calorieSum.toFixed(0)
                                              ).toLocaleString()}kcal`
                                            }}
                                          </v-list-item-action>
                                          <v-list-item-action
                                            v-if="false"
                                            style="width:40px; text-align:right;"
                                            class="ml-1"
                                          >
                                            <span
                                              class="ml-1"
                                              style="color:grey; width:100%;"
                                              >{{
                                                `${Math.round(
                                                  (food.calorieSum /
                                                    calorieTotalSum) *
                                                    100
                                                )}%`
                                              }}</span
                                            >
                                          </v-list-item-action>
                                        </v-list-item>
                                        <v-list-item
                                          v-if="
                                            !isVisibleAllFood &&
                                              foodStatsByRank.length >
                                                foodStatsByTop.length
                                          "
                                          :ripple="$store.state.isDisplayTouch"
                                        >
                                          <v-btn
                                            text
                                            block
                                            color="grey"
                                            :ripple="
                                              $store.state.isDisplayTouch
                                            "
                                            :loading="isLoadingShowAllFood"
                                            @click="onClickShowAllFood"
                                            >모두 보기</v-btn
                                          >
                                        </v-list-item>
                                      </v-list>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card>
                            </v-col>
                          </v-row>

                          <v-divider
                            v-show="
                              $vuetify.breakpoint.xs &&
                                !isLoading &&
                                meals.length > 0
                            "
                            class="mx-1"
                          ></v-divider>

                          <v-row>
                            <v-col
                              class="py-0"
                              v-bind:class="{
                                'px-0': $vuetify.breakpoint.xs,
                                'pb-4': !$vuetify.breakpoint.xs
                              }"
                            >
                              <!-- 식당 통계 -->
                              <v-card
                                :rounded="!$vuetify.breakpoint.xs ? 'xl' : null"
                                :tile="$vuetify.breakpoint.xs"
                                min-height="200"
                                flat
                              >
                                <v-container fluid class="pa-2">
                                  <v-row no-gutters>
                                    <v-col>
                                      <v-list
                                        v-if="!isLoading && meals.length > 0"
                                      >
                                        <v-list-item>
                                          <v-list-item-content class="py-0">
                                            <v-list-item-title>
                                              <h1>식당 통계</h1>
                                            </v-list-item-title>
                                          </v-list-item-content>
                                        </v-list-item>

                                        <v-list-item
                                          v-if="stores.length === 0"
                                          class="pb-10"
                                        >
                                          <v-btn
                                            text
                                            block
                                            :ripple="
                                              $store.state.isDisplayTouch
                                            "
                                            class="mt-10 mb-10"
                                            color="grey"
                                          >
                                            {{ `방문한 식당 정보가 없습니다` }}
                                          </v-btn>
                                        </v-list-item>
                                      </v-list>
                                      <v-list
                                        v-if="
                                          !isLoading &&
                                            meals.length > 0 &&
                                            stores.length > 0
                                        "
                                        dense
                                        class="pb-10"
                                      >
                                        <v-list-item
                                          v-for="item in stores"
                                          :key="item.store._id"
                                          class="pr-0"
                                          :ripple="$store.state.isDisplayTouch"
                                          @click="showStorePopup(item.store)"
                                        >
                                          <v-list-item-content>
                                            <v-list-item-title
                                              ><b>{{ item.store.name }}</b
                                              >&nbsp;<span
                                                style="color:grey; padding-left:5px;"
                                                >• {{ item.mealsCount }}회
                                                방문</span
                                              ></v-list-item-title
                                            >
                                            <v-list-item-subtitle>
                                              {{ item.store.address }}
                                            </v-list-item-subtitle>
                                          </v-list-item-content>
                                          <v-list-item-action
                                            ><v-btn
                                              text
                                              :ripple="
                                                $store.state.isDisplayTouch
                                              "
                                              disabled
                                              ><v-icon
                                                >mdi-chevron-right</v-icon
                                              ></v-btn
                                            >
                                          </v-list-item-action>
                                        </v-list-item>
                                      </v-list>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </template>
        <v-row v-else no-gutters>
          <v-col>
            <v-btn
              text
              block
              :ripple="$store.state.isDisplayTouch"
              class="pt-10 mt-10"
              @click="
                $store.dispatch('set', {
                  key: 'isVisibleLoginDialog',
                  value: true
                })
              "
              color="grey"
            >
              {{ `로그인이 필요합니다${getRandomEmojiSymbol()}` }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>

    <v-bottom-sheet v-model="isVisibleMealTypeSelection">
      <v-list class="pa-2 floob-action-sheet">
        <v-list-item class="pb-2">
          <v-list-item-content>
            <div style="color:grey">
              식사 종류 선택
              <br /><br />
              <div style="color:rgb(171 171 171); font-size:0.8em;">
                식사 추이 차트에서 일일 식사기록수를 식사 시간대 <br />
                종류별로 선택하여 확인 할 수 있습니다.
              </div>
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          :ripple="$store.state.isDisplayTouch"
          v-for="mealType in chartMealTypes"
          :key="mealType.code"
          @click="onClickMealTrendByDailyChartSelectMealType(mealType.code)"
        >
          <v-list-item-content>
            <v-list-item-title
              >{{ mealType.text }}
              <v-icon
                v-if="mealType.code === selectedMealTrendByDailyChartMealType"
                color="primary"
                small
                >mdi-check</v-icon
              ></v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-bottom-sheet>

    <floob-browser-dialog :dialog.sync="browserDialog"> </floob-browser-dialog>
  </section>
</template>

<script>
import _ from 'lodash';
import semver from 'semver';
import moment from 'moment';

import wvc from '@/util/webViewController';

import api from '@/api';
import emoji from '@/util/emoji';
import converter from '@/util/converter';

import VueApexCharts from 'vue-apexcharts';

import floobBrowserDialog from '@/components/browserDialog.vue';

export default {
  props: {
    hideHeader: {
      default: false
    }
  },
  components: {
    floobBrowserDialog,
    apexchart: VueApexCharts
  },
  data() {
    return {
      // modules
      browserDialog: null,

      // controlls
      isLoading: false,
      isLoadingReviewSummary: false,
      isLoadingShowAllFood: false,
      isVisibleAllFood: false,
      isScrolled: false,
      window: {
        width: 0,
        height: 0
      },
      isReadyMealTrendChart: false,
      isReadyMealStatByFoodChart: false,
      mealTrendChartSeries: [],
      selectedMealTrendMealType: 'count',
      mealStatByFoodChartOptions: {
        chart: {
          id: 'mealStatByFoodChart',
          type: 'donut',
          events: {
            dataPointSelection: (event, chartContext, config) => {
              this.selectedMealStatByFoodChartIdx =
                config.selectedDataPoints[0].length > 0
                  ? config.selectedDataPoints[0][0]
                  : null;
            }
          }
        },
        states: {
          active: {
            filter: {
              type: 'none' /* none, lighten, darken */
            }
          }
        },
        plotOptions: {
          pie: {
            donut: {
              size: '50%'
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false,
          position: 'bottom'
        },
        labels: [],
        tooltip: {
          enabled: true,
          fixed: {
            enabled: false,
            position: 'topLeft',
            offsetX: 0,
            offsetY: 0
          },
          y: {
            formatter: val => {
              if (this.calorieTotalSum > 0) {
                return `${parseInt(val.toFixed(0)).toLocaleString()}kcal`;
              } else {
                return `0kcal`;
              }
            }
          }
        }
      },
      mealStatByFoodChartSeries: [],
      selectedMealStatByFoodChartIdx: null,
      colors: {
        green: 'rgb(75, 192, 192)',
        blue: 'rgb(54, 162, 235)',
        indigo: 'rgb(94, 93, 191)',
        purple: 'rgb(153, 102, 255)',
        red: 'rgb(255, 99, 132)',
        orange: 'rgb(255, 159, 64)',
        yellow: 'rgb(255, 205, 86)',
        grey: 'rgb(229, 229, 229)'
      },
      isVisibleMealTypeSelection: false,
      mealTrendByDailyChartSelectableMealTypes: {
        all: {
          code: 'all',
          text: '전체'
        },
        breakfast: {
          code: 'breakfast',
          text: '아침식사',
          color: 'rgb(255, 99, 132)'
        },
        lunch: {
          code: 'lunch',
          text: '점심식사',
          color: 'rgb(255, 205, 86)'
        },
        dinner: {
          code: 'dinner',
          text: '저녁식사',
          color: 'rgb(94, 93, 191)'
        },
        'midnight-snack': {
          code: 'midnight-snack',
          text: '야식',
          color: 'rgb(153, 102, 255)'
        }
      },
      selectedMealTrendByDailyChartMealType: 'all',
      foodStatsTopCount: 8,
      summaryPannels: [],

      // datas
      meals: [],
      date: moment(),
      reviewScoreAverage: null
    };
  },
  methods: {
    init() {
      this.isLoading = false;
      this.isScrolled = false;
      this.isLoadingShowAllFood = false;
      this.isVisibleAllFood = false;
      this.isVisibleMealTypeSelection = false;
      this.selectedMealTrendByDailyChartMealType = 'all';
      this.reviewScoreAverage = null;
      this.summaryPannels = [];
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    scrollTop() {
      document
        .getElementById('floobReportFrameSheet')
        .scrollTo({ top: 0, behavior: 'smooth' });
    },
    onScroll(e) {
      if (e.target.scrollTop > 0) {
        this.isScrolled = true;
      } else {
        this.isScrolled = false;
      }
    },
    onClickPrevMonth() {
      this.date = moment(this.date).add(-1, 'months');
      this.getMeals();
    },
    onClickNextMonth() {
      this.date = moment(this.date).add(1, 'months');
      this.getMeals();
    },
    getMeals() {
      this.isLoading = true;
      this.selectedMealTrendMealType = 'count';
      this.selectedMealTrendByDailyChartMealType = 'all';
      this.isReadyMealTrendChart = false;
      this.isReadyMealStatByFoodChart = false;
      this.isVisibleAllFood = false;
      this.summaryPannels = [];

      setTimeout(() => {
        api.meal
          .getMealsByReport(
            this.startMealedDateString,
            this.endMealedDateString
          )
          .then(meals => {
            this.meals = _.map(
              _.filter(meals, meal => {
                return moment(meal.mealedDate).isValid();
              }),
              meal => {
                return {
                  ...meal,
                  foods: _.map(meal.foodTags, foodTag => {
                    return foodTag.food;
                  }),
                  type: converter.convertMealType(meal.mealedDate)
                };
              }
            );

            //console.log('report meals', this.meals);
            //console.log('report food', this.foods);

            this.setReviewScoreAverage();
            this.renderMealTrendByDailyChart();
            this.renderMealStatByFoodChart();
          })
          .finally(() => {
            this.isLoading = false;
          });
      }, 500);
    },
    requestAppReview() {
      if (this.calorieTotalSum > 300) {
        this.$cookies.set(
          'request_app_review',
          true,
          '30d',
          '/',
          location.hostname.replace('www', '')
        );
        setTimeout(() => {
          wvc.requestReview();
        }, 5000);
      }
    },
    setReviewScoreAverage() {
      if (this.reviewedMeals.length > 0) {
        this.reviewScoreAverage =
          _.reduce(
            this.reviewedMeals,
            (sum, meal) => {
              return sum + meal.review.score;
            },
            0
          ) / this.reviewedMeals.length;

        //this.reviewScoreAverage = 1;
      } else {
        this.reviewScoreAverage = null;
      }
    },
    renderMealTrendByDailyChart() {
      if (this.selectedMealTrendMealType === 'calorie') {
        this.mealTrendChartSeries = [
          {
            name: '섭취 열량',
            data: this.getMealCalorieSumByDate(
              this.selectedMealTrendByDailyChartMealType
            )
          }
        ];
      } else {
        this.mealTrendChartSeries = [
          {
            name: '식사 횟수',
            data: this.getMealCountsByDate(
              this.selectedMealTrendByDailyChartMealType
            )
          }
        ];
      }

      // if (this.selectedMealTrendByDailyChartMealType === 'all') {
      //   // this.mealTrendChartOptions.colors = [
      //   //   this.mealTrendByDailyChartSelectableMealTypes.breakfast.color,
      //   //   this.mealTrendByDailyChartSelectableMealTypes.lunch.color,
      //   //   this.mealTrendByDailyChartSelectableMealTypes.dinner.color,
      //   //   this.mealTrendByDailyChartSelectableMealTypes['midnight-snack'].color
      //   // ];
      //   this.mealTrendChartSeries = [
      //     {
      //       name: this.mealTrendByDailyChartSelectableMealTypes.breakfast.text,
      //       data: this.getMealCalorieSumByDate(
      //         this.mealTrendByDailyChartSelectableMealTypes.breakfast.code
      //       )
      //     },
      //     {
      //       name: this.mealTrendByDailyChartSelectableMealTypes.lunch.text,
      //       data: this.getMealCalorieSumByDate(
      //         this.mealTrendByDailyChartSelectableMealTypes.lunch.code
      //       )
      //     },
      //     {
      //       name: this.mealTrendByDailyChartSelectableMealTypes.dinner.text,
      //       data: this.getMealCalorieSumByDate(
      //         this.mealTrendByDailyChartSelectableMealTypes.dinner.code
      //       )
      //     },
      //     {
      //       name: this.mealTrendByDailyChartSelectableMealTypes[
      //         'midnight-snack'
      //       ].text,
      //       data: this.getMealCalorieSumByDate(
      //         this.mealTrendByDailyChartSelectableMealTypes['midnight-snack']
      //           .code
      //       )
      //     }
      //   ];
      // } else {
      //   // this.mealTrendChartOptions.colors = [
      //   //   this.mealTrendByDailyChartSelectableMealTypes[
      //   //     this.selectedMealTrendByDailyChartMealType
      //   //   ].color
      //   // ];
      //   this.mealTrendChartSeries = [
      //     {
      //       name: this.mealTrendByDailyChartSelectableMealTypes[
      //         this.selectedMealTrendByDailyChartMealType
      //       ].text,
      //       data: this.getMealCalorieSumByDate(
      //         this.selectedMealTrendByDailyChartMealType
      //       )
      //     }
      //   ];
      // }
      setTimeout(() => {
        if (!this.isLoading) this.isReadyMealTrendChart = true;
      }, 200);
    },
    renderMealStatByFoodChart() {
      if (this.isLoadingShowAllFood) {
        this.mealStatByFoodChartOptions.labels = _.map(
          this.foodStatsByRank,
          o => {
            return o.name;
          }
        );
        if (this.calorieTotalSum > 0) {
          this.mealStatByFoodChartSeries = _.map(this.foodStatsByRank, o => {
            return o.calorieSum;
          });
        } else {
          this.mealStatByFoodChartSeries = _.map(this.foodStatsByRank, o => {
            return o.count;
          });
        }
        this.mealStatByFoodChartOptions.colors = _.map(
          this.foodStatsByRank,
          (o, idx) => {
            if (this.chartColors[idx]) {
              return this.chartColors[idx];
            } else {
              return this.colors.grey;
            }
          }
        );
      } else {
        this.mealStatByFoodChartOptions.labels = _.map(
          this.foodStatsByTop,
          o => {
            return o.name;
          }
        );

        if (this.calorieTotalSum > 0) {
          this.mealStatByFoodChartSeries = _.map(this.foodStatsByTop, o => {
            return o.calorieSum;
          });
        } else {
          this.mealStatByFoodChartSeries = _.map(this.foodStatsByTop, o => {
            return o.count;
          });
        }

        this.mealStatByFoodChartOptions.colors = this.chartColors;
      }

      setTimeout(() => {
        if (!this.isLoading) this.isReadyMealStatByFoodChart = true;
      }, 200);
    },
    getRandomEmojiSymbol() {
      return emoji.getRandomEmojiSymbol();
    },
    onClickMealTrendByDailyChartSelectMealType(code) {
      if (this.selectedMealTrendByDailyChartMealType !== code) {
        this.selectedMealTrendByDailyChartMealType = code;
      }

      this.isVisibleMealTypeSelection = false;
      this.renderMealTrendByDailyChart();
    },
    onClickShowAllFood() {
      this.isLoadingShowAllFood = true;
      this.selectedMealStatByFoodChartIdx = null;
      this.isReadyMealStatByFoodChart = false;
      this.renderMealStatByFoodChart();
      setTimeout(() => {
        this.isVisibleAllFood = true;
        this.isLoadingShowAllFood = false;
      }, 1000);
    },
    showStorePopup(store) {
      if (store) {
        if (store.serviceProvider === 'kakao-map') {
          if (this.$cookies.get('webview')) {
            if (
              semver.valid(this.$cookies.get('version')) &&
              semver.gte(this.$cookies.get('version'), '1.4.0')
            ) {
              wvc.openUrl(
                `https://place.map.kakao.com/m/${store.serviceProviderPlaceId}`
              );
            } else {
              this.browserDialog.show(
                '식당',
                'Powered by Kakao Map',
                `https://place.map.kakao.com/m/${store.serviceProviderPlaceId}`
              );
            }
          } else {
            if (this.$store.state.isMobileDevice) {
              window.open(
                `https://place.map.kakao.com/m/${store.serviceProviderPlaceId}`
              );
            } else {
              window.open(
                `https://place.map.kakao.com/${store.serviceProviderPlaceId}`
              );
            }
          }
        }
      }
    },
    getMealCountsByDate(selectedMealTrendByDailyChartMealType) {
      return _.map(this.thisMonthDays, day => {
        let dateString = moment()
          .year(moment(this.date).year())
          .month(moment(this.date).month())
          .date(day)
          .format('YYYY-MM-DD');
        if (this.groupedMealsByDate[dateString]) {
          if (
            selectedMealTrendByDailyChartMealType &&
            selectedMealTrendByDailyChartMealType != 'all'
          ) {
            return _.filter(this.groupedMealsByDate[dateString], meal => {
              return meal.type === selectedMealTrendByDailyChartMealType;
            }).length;
          } else {
            return this.groupedMealsByDate[dateString].length;
          }
        } else {
          return 0;
        }
      });
    },
    getMealCalorieSumByDate(selectedMealTrendByDailyChartMealType) {
      return _.map(this.thisMonthDays, day => {
        let dateString = moment()
          .year(moment(this.date).year())
          .month(moment(this.date).month())
          .date(day)
          .format('YYYY-MM-DD');
        if (this.groupedMealsByDate[dateString]) {
          if (
            selectedMealTrendByDailyChartMealType &&
            selectedMealTrendByDailyChartMealType != 'all'
          ) {
            return _.reduce(
              _.filter(this.groupedMealsByDate[dateString], meal => {
                return meal.type === selectedMealTrendByDailyChartMealType;
              }),
              (sum, meal) => {
                if (meal && meal.foodTags && Array.isArray(meal.foodTags)) {
                  return (
                    sum +
                    _.reduce(
                      meal.foodTags,
                      (sum2, foodTag) => {
                        if (
                          foodTag &&
                          foodTag.calorie &&
                          !isNaN(foodTag.calorie)
                        ) {
                          return sum2 + parseFloat(foodTag.calorie);
                        } else {
                          return sum2;
                        }
                      },
                      0
                    )
                  );
                } else {
                  return sum;
                }
              },
              0
            );
          } else {
            return _.reduce(
              this.groupedMealsByDate[dateString],
              (sum, meal) => {
                if (meal && meal.foodTags && Array.isArray(meal.foodTags)) {
                  return (
                    sum +
                    _.reduce(
                      meal.foodTags,
                      (sum2, foodTag) => {
                        if (
                          foodTag &&
                          foodTag.calorie &&
                          !isNaN(foodTag.calorie)
                        ) {
                          return sum2 + parseFloat(foodTag.calorie);
                        } else {
                          return sum2;
                        }
                      },
                      0
                    )
                  );
                } else {
                  return sum;
                }
              },
              0
            );
          }
        } else {
          return 0;
        }
      });
    }
  },
  computed: {
    isIOS() {
      return (
        this.$cookies.get('webview') && this.$cookies.get('platform') === 'ios'
      );
    },
    frameHeight() {
      let headerHeight = 56;
      let bottomNavHeight = 80;
      return this.window.height - headerHeight - bottomNavHeight;
    },
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    },
    thisMonth() {
      return moment(this.date).month() + 1;
    },
    thisMonthDays() {
      return Array.from(
        {
          length: moment(this.date)
            .add(1, 'months')
            .startOf('month')
            .diff(moment(this.date).startOf('month'), 'days')
        },
        (_, i) => i + 1
      );
    },
    thisYearMonthString() {
      return this.date.format('YYYY년 M월');
    },
    startMealedDateString() {
      return moment(this.date)
        .startOf('month')
        .format('YYYY-MM-DDTHH:mm:ssZ');
    },
    endMealedDateString() {
      return moment(this.date)
        .add(1, 'months')
        .startOf('month')
        .format('YYYY-MM-DDTHH:mm:ssZ');
    },
    chartColors() {
      return _.values(this.colors);
    },
    chartMealTypes() {
      return _.values(this.mealTrendByDailyChartSelectableMealTypes);
    },
    reviewedMeals() {
      if (this.meals && this.meals.length > 0) {
        return _.filter(this.meals, meal => {
          return meal && meal.review && meal.review.score;
        });
      } else {
        return [];
      }
    },
    groupedMealsByDate() {
      if (this.meals && this.meals.length > 0) {
        return _.groupBy(_.orderBy(this.meals, ['mealedDate'], ['desc']), _ => {
          return moment(_.mealedDate).format('YYYY-MM-DD');
        });
      } else {
        return [];
      }
    },
    mealCountsByDate() {
      return this.getMealCountsByDate(
        this.selectedMealTrendByDailyChartMealType
      );
    },
    mealCalorieSumByDate() {
      return this.getMealCalorieSumByDate(
        this.selectedMealTrendByDailyChartMealType
      );
    },
    foodsTotalCount() {
      return _.sumBy(this.foodStatsByRank, o => {
        return o.count;
      });
    },
    stores() {
      let mealsByStoreIds = _.groupBy(this.meals, meal => {
        if (meal && meal.store && meal.store._id) {
          return meal.store._id;
        }
        return null;
      });

      delete mealsByStoreIds.null;

      return _.toArray(
        _.mapValues(mealsByStoreIds, mealsByStoreId => {
          return {
            store: mealsByStoreId[0].store,
            mealsCount: mealsByStoreId.length
          };
        })
      );
    },
    foods() {
      return _.reduce(
        this.meals,
        (foods, meal) => {
          let mealedFoods = [];
          if (meal.food && meal.food._id && meal.food.name) {
            mealedFoods.push(meal.food);
          }
          if (meal.foods && meal.foods.length > 0) {
            mealedFoods = mealedFoods.concat(
              _.filter(meal.foods, food => {
                return food && food._id && food.name;
              })
            );
          }
          if (mealedFoods.length > 0) {
            return foods.concat(mealedFoods);
          } else {
            return foods;
          }
        },
        []
      );
    },
    foodTags() {
      return _.reduce(
        this.meals,
        (foodTags, meal) => {
          let mealedFoodTags = [];
          if (meal.foodTags && meal.foodTags.length > 0) {
            mealedFoodTags = mealedFoodTags.concat(
              _.filter(meal.foodTags, foodTag => {
                return foodTag.food && foodTag.food._id && foodTag.food.name;
              })
            );
          }
          if (mealedFoodTags.length > 0) {
            return foodTags.concat(mealedFoodTags);
          } else {
            return foodTags;
          }
        },
        []
      );
    },
    foodStatsByRank() {
      return _.orderBy(
        _.map(
          _.toPairs(
            _.groupBy(this.foodTags, foodTag => {
              return foodTag.food.name;
            })
          ),
          o => {
            return {
              name: o[0],
              count: o[1].length,
              calorieSum: _.reduce(
                o[1],
                (sum, foodTag) => {
                  if (foodTag && !isNaN(foodTag.calorie)) {
                    return sum + parseFloat(foodTag.calorie);
                  } else {
                    return sum;
                  }
                },
                0
              )
            };
          }
        ),
        ['calorieSum'],
        ['desc']
      );
    },
    foodStatsByTop() {
      if (this.foodStatsByRank.length > this.foodStatsTopCount) {
        let foodStatsByTop = _.slice(
          this.foodStatsByRank,
          0,
          this.foodStatsTopCount - 1
        );

        foodStatsByTop.push({
          name: '그 외',
          count: _.sumBy(
            _.slice(this.foodStatsByRank, this.foodStatsTopCount - 1),
            o => {
              return o.count;
            }
          ),
          calorieSum: _.sumBy(
            _.slice(this.foodStatsByRank, this.foodStatsTopCount - 1),
            o => {
              return o.calorieSum;
            }
          )
        });
        return foodStatsByTop;
      } else {
        return this.foodStatsByRank;
      }
    },
    getReviewSummaryEmoji() {
      if (this.reviewScoreAverage) {
        if (this.reviewScoreAverage >= 4.5) {
          return '🤩';
        } else if (this.reviewScoreAverage >= 3.5) {
          return '😋';
        } else if (this.reviewScoreAverage >= 2.5) {
          return '🙂';
        } else if (this.reviewScoreAverage >= 1.5) {
          return '😞';
        } else {
          return '😭';
        }
      } else {
        return null;
      }
    },
    getReviewSummaryHtml() {
      let comment = '';
      if (this.reviewScoreAverage) {
        // current month
        if (
          moment(this.date).year() === moment().year() &&
          moment(this.date).month() === moment().month()
        ) {
          if (this.reviewScoreAverage >= 4.5) {
            comment = '아주 훌륭한 식사를 하고 계시군요. 좋습니다!!👍\n\n';
          } else if (this.reviewScoreAverage >= 3.5) {
            comment =
              '좋은 식사를 하고 계십니다.👏👏\n\n꾸준히 나에게 맞는 좋은 식사를 만들어가세요.';
          } else if (this.reviewScoreAverage >= 2.5) {
            comment =
              '혹시 일상적인 식사가 반복 되나요?\n\n가끔은 맛있는 식당을 찾아보거나 새로운 요리에 도전해보시는 것은 어떨까요?🙉';
          } else if (this.reviewScoreAverage >= 1.5) {
            comment =
              '최근 안좋은 식사 경험들이 있으셨나요?\n\n바쁜 일상이라도 좋은 식사를 챙겨 먹는 것은 삶의 질을 높이는데 도움이 될 수 있습니다.💪';
          } else {
            comment =
              '최악의 식사만 경험하고 계시군요.\n\n식생활 및 식습관을 바꿔 보거나 주변 좋은 지인들과 맛있는 식사를 가지는 이벤트를 만들어 보는 건 어떨까요?🥺';
          }
        }
        // prev month
        else if (moment().diff(moment(this.date)) > 0) {
          comment = `${this.thisMonth}월 `;
          if (this.reviewScoreAverage >= 4.5) {
            comment += '식사는 훌륭했습니다.';
          } else if (this.reviewScoreAverage >= 3.5) {
            comment += '식사는 대체로 좋았습니다.';
          } else if (this.reviewScoreAverage >= 2.5) {
            comment += '식사는 보통이었습니다.';
          } else if (this.reviewScoreAverage >= 1.5) {
            comment += '식사는 별로였습니다.';
          } else {
            comment += '식사는 최악이었습니다.';
          }
        } else {
          comment = '미래에서 오셨군요.😝👽😘';
        }
      }

      return converter.convertTextToHtml(comment);
    },
    isDateThisMonth() {
      return moment(this.date).month() === moment().month();
    },
    mealTrendChartOptions() {
      return {
        chart: {
          id: 'mealTrendByDailyChart',
          type: 'bar',
          // stacked: true,
          toolbar: {
            show: false
          }
        },
        states: {
          active: {
            filter: {
              type: 'none' /* none, lighten, darken */,
              value: 0
            }
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 3,
            columnWidth: '90%'
          }
        },
        grid: {
          show: false
        },
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        tooltip: {
          theme: this.$store.state.theme,
          fixed: {
            enabled: true,
            position: 'topLeft',
            offsetX: 30,
            offsetY: 10
          },
          y: {
            formatter: val => {
              if (this.selectedMealTrendMealType === 'calorie') {
                return `${parseInt(val.toFixed(0)).toLocaleString()}kcal`;
              } else {
                return `${parseInt(val).toLocaleString()}회`;
              }
            }
          },
          x: {
            formatter: val => {
              return `${val}일`;
            }
          },
          marker: {
            show: false
          }
        },
        fill: {
          opacity: 1
        },
        legend: {
          position: 'top'
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          type: 'category',
          categories: this.thisMonthDays,
          tickAmount: 10
        },
        yaxis: {
          labels: {
            show: false,
            formatter: val => {
              return val.toFixed(0);
            }
          },
          max:
            this.selectedMealTrendMealType === 'calorie'
              ? _.max(this.mealCalorieSumByDate) * 1.2
              : _.max(this.mealCountsByDate) * 1.2,
          tickAmount: 6
        }
      };
    },
    calorieTotalSum() {
      return _.reduce(
        this.mealCalorieSumByDate,
        (sum, calorie) => {
          return sum + calorie;
        },
        0
      );
    }
  },
  watch: {
    isLoggedIn(value) {
      if (value) {
        this.getMeals();
      }
    },
    selectedMealTrendMealType() {
      this.isReadyMealTrendChart = false;
      this.renderMealTrendByDailyChart();
    }
  },
  mounted() {},
  created() {
    this.$emit('update:module', {
      scrollTop: () => {
        this.scrollTop();
      },
      reload: () => {
        this.getMeals();

        if (
          this.$cookies.get('webview') &&
          !this.$cookies.get('request_app_review')
        ) {
          setTimeout(() => {
            this.requestAppReview();
          }, 5000);
        }
      }
    });

    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }
};
</script>

<style></style>
