<template>
  <section>
    <v-container :fluid="$vuetify.breakpoint.mdAndDown" class="py-0">
      <v-row>
        <v-col
          sm="6"
          offset-sm="3"
          md="4"
          offset-md="4"
          xl="2"
          offset-xl="5"
          class="pa-0"
        >
          <floob-tutorial v-if="!isLoggedIn"></floob-tutorial>

          <floob-feed
            v-show="menu === 'feed'"
            :module.sync="feedModule"
          ></floob-feed>

          <floob-timeline
            v-show="menu === 'timeline'"
            :module.sync="timelineModule"
          ></floob-timeline>

          <floob-report
            v-show="menu === 'report'"
            :module.sync="reportModule"
          ></floob-report>

          <floob-setting
            v-show="menu === 'setting'"
            :module.sync="settlingModule"
          ></floob-setting>
        </v-col>
      </v-row>
    </v-container>

    <!-- Start App blocker  -->

    <v-snackbar
      v-if="!isRequiredUpdateApp && !isExistsUpdateApp"
      v-model="isRequiredUpdateWeb"
      class="floob-snackbar"
      elevation="24"
      :timeout="-1"
    >
      <v-avatar size="16" rounded><v-img src="/favicon.ico"></v-img></v-avatar>
      <span class="pl-2"
        ><b>Floob 업데이트 발견</b><br />최신 데이터를 위해 새로고침이
        필요합니다</span
      >
      <template v-slot:action="{}">
        <v-btn icon @click="onClickReload">확인</v-btn>
      </template>
    </v-snackbar>

    <v-dialog
      v-if="!isRequiredUpdateApp"
      v-model="isExistsUpdateApp"
      persistent
      content-class="floob-dialog"
      :transition="false"
    >
      <v-card
        v-bind:style="{
          backgroundColor: $vuetify.theme.dark ? '#2d2d2d' : null
        }"
      >
        <v-card-title style="font-size:1rem; font-weight:bold;"
          ><span>최신 앱 버전이 있습니다 🚀</span></v-card-title
        >
        <v-card-text v-if="false" style="text-align:center;">
          <v-avatar rounded>
            <v-img
              src="https://blob.floob.co.kr/user/5fd368c6d78489878ec67fb5"
            ></v-img>
          </v-avatar>
        </v-card-text>
        <v-card-text>
          <div>
            앱스토어에 최신 버전이 존재합니다.
            <br />앱을 업데이트하여 최신 기능과 안정적인 서비스를 이용하시기
            바랍니다.
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            :ripple="$store.state.isDisplayTouch"
            @click="onClickUpdateAppNext"
            >다음에</v-btn
          >

          <v-spacer></v-spacer>
          <v-btn
            text
            :ripple="$store.state.isDisplayTouch"
            @click="onClickOpenStore"
            >스토어로 이동</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="isRequiredUpdateApp"
      persistent
      :overlay-opacity="1"
      :overlay-color="$vuetify.theme.dark ? 'black' : 'white'"
      content-class="floob-dialog"
      :transition="false"
    >
      <v-card
        v-bind:style="{
          backgroundColor: $vuetify.theme.dark ? '#2d2d2d' : null
        }"
      >
        <v-card-title style="font-size:1rem; font-weight:bold;"
          ><span>앱 업데이트가 필요합니다 🚀</span></v-card-title
        >
        <v-card-text>
          <div>
            <br />
            새로운 버전이 출시 되었습니다.
            <br />
            <br />
            현재 버전은 사용이 중단되어 앱 업데이트 후 이용해 주시기 바랍니다.
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            :ripple="$store.state.isDisplayTouch"
            @click="onClickOpenStore"
            >스토어로 이동</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-overlay
      v-if="$route.name === 'app'"
      :value="isLoading"
      :opacity="1"
      :color="$vuetify.theme.dark ? 'black' : 'white'"
      :z-index="1000"
    >
      <v-progress-circular
        indeterminate
        :width="5"
        :size="80"
        color="amber lighten-1"
      >
        <div style="font-size:32px;">
          {{ randomedEmoji }}
        </div>
      </v-progress-circular>
      <v-btn
        x-large
        block
        text
        class="px-0"
        :color="$vuetify.theme.dark ? 'white' : 'grey darken-3'"
        ><v-avatar size="15" rounded>
          <v-img
            :width="15"
            src="https://floob.co.kr/favicon.ico"
          ></v-img> </v-avatar
        ><b class="pl-1">Floob</b></v-btn
      >
    </v-overlay>

    <!-- End App blocker  -->
    <floob-bottom-nav
      :menu.sync="menu"
      v-on:selected="onSelectMenu"
    ></floob-bottom-nav>

    <floob-meal-dialog :dialog.sync="mealDialog"></floob-meal-dialog>

    <floob-meal-update-dialog
      :dialog.sync="mealUpdateDialog"
    ></floob-meal-update-dialog>

    <floob-story-dialog :dialog.sync="storyDialog"></floob-story-dialog>

    <floob-privacy-dialog :dialog.sync="privacyDialog"></floob-privacy-dialog>

    <floob-message-dialog :dialog.sync="messageDialog"> </floob-message-dialog>

    <floob-app-open-overlay
      v-if="isVisibleAppOpenOverlay"
    ></floob-app-open-overlay>
  </section>
</template>

<script>
import URL from 'url';
import { Path } from 'path-parser';

import floobTutorial from '@/components/pages/tutorial.vue';

import floobBottomNav from '@/components/bottomNav.vue';
import floobFeed from '@/components/pages/feed.vue';
import floobTimeline from '@/components/pages/timeline.vue';
import floobReport from '@/components/pages/report.vue';
import floobSetting from '@/components/pages/setting.vue';

import floobMealDialog from '@/components/mealDialog.vue';
import floobMealUpdateDialog from '@/components/mealUpdateDialog.vue';
import floobStoryDialog from '@/components/storyDialog.vue';
import floobPrivacyDialog from '@/components/privacyDialog.vue';
import floobMessageDialog from '@/components/messageDialog.vue';

import floobAppOpenOverlay from '@/components/appOpenOverlay.vue';

import api from '@/api';
import emoji from '@/util/emoji';
import wvc from '@/util/webViewController';

import smoothscroll from 'smoothscroll-polyfill';

export default {
  components: {
    floobTutorial,
    floobBottomNav,
    floobFeed,
    floobTimeline,
    floobReport,
    floobSetting,
    floobMealDialog,
    floobMealUpdateDialog,
    floobStoryDialog,
    floobPrivacyDialog,
    floobMessageDialog,
    floobAppOpenOverlay
  },
  data() {
    return {
      // modules
      mealDialog: null,
      mealUpdateDialog: null,
      storyDialog: null,
      privacyDialog: null,
      messageDialog: null,
      feedModule: null,
      timelineModule: null,
      reportModule: null,
      settlingModule: null,

      // controlls
      isLoading: false,
      menu: 'timeline',
      isVisibleAppOpenOverlay: false,
      randomedEmoji: '',
      timer: null,
      isIgnoreAppUpdate: false
    };
  },
  methods: {
    onSelectMenu(menu) {
      if (menu === this.menu) {
        if (menu === 'feed') {
          this.feedModule.scrollTop();
        } else if (menu === 'timeline') {
          this.timelineModule.scrollTop();
        } else if (menu === 'report') {
          this.reportModule.scrollTop();
        } else if (menu === 'setting') {
          this.settlingModule.scrollTop();
        }
      } else {
        this.menu = menu;

        this.reportModule.reload();

        window.Floob._analytics.logEvent('view_page', {
          name: this.menu
        });
      }
    },
    setAppLink(link) {
      if (link) {
        try {
          let url = URL.parse(link);

          if (url.pathname === '/meal/create') {
            this.menu = 'timeline';
            this.timelineModule.showMealCreation();
          } else if (url.pathname.includes('/meal')) {
            let path = new Path('/meal/:mealId');
            let route = path.test(url.path);
            this.mealDialog.show({
              _id: route.mealId
            });
          } else if (url.pathname.includes('/tag-food')) {
            let path = new Path('/tag-food/:mealId');
            let route = path.test(url.path);
            this.mealUpdateDialog.show(
              { _id: route.mealId },
              {
                focus: 'food'
              }
            );
          } else if (url.pathname === '/privacy') {
            this.privacyDialog.show();
          } else if (url.pathname.includes('/story')) {
            let path = new Path('/story/:userId');
            let route = path.test(url.path);
            this.storyDialog.show(route.userId);
          }
        } catch (err) {
          alert(`Floob link error occured.\n: ${link}`);
        }
      }
    },
    setDeviceToken(deviceToken) {
      api.user.upsertDeviceToken(deviceToken);
    },
    onClickUpdateAppNext() {
      this.$cookies.set(
        'ignore_app_update',
        true,
        '30s',
        '/',
        location.hostname.replace('www', '')
      );
      this.isIgnoreAppUpdate = true;
    },
    onClickOpenStore() {
      if (this.$cookies.get('platform') === 'ios') {
        wvc.openUrl(`https://apps.apple.com/kr/app/floob/id1545936680`);
      }
    },
    onClickReload() {
      location.reload();
    },
    setTheme(theme) {
      this.$store.dispatch('set', { key: 'theme', value: theme });
    }
  },
  watch: {
    isLoading(value) {
      if (value) {
        this.timer = setInterval(() => {
          this.randomedEmoji = emoji.getRandomEmojiSymbol();
        }, 1000);
      } else {
        clearInterval(this.timer);
      }
    },
    isRequiredUpdateWeb() {
      return this.$store.state.apps.web.version.isRequiredUpdate;
    },
    isRequiredUpdateApp() {
      if (this.$cookies.get('platform') === 'ios') {
        return this.$store.state.apps.ios.version.isRequiredUpdate;
      } else {
        return false;
      }
    },
    isExistsUpdateApp() {
      if (this.$cookies.get('platform') === 'ios') {
        return (
          this.$store.state.apps.ios.version.isExsitsUpdate &&
          !this.isIgnoreAppUpdate
        );
      } else {
        return false;
      }
    },
    isLoggedIn(value) {
      if (value) {
        setTimeout(() => {
          wvc.checkNotificationDetermined();
        }, 3000);
      }
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    },
    isRequiredUpdateWeb() {
      return this.$store.state.apps.web.version.isRequiredUpdate;
    },
    isRequiredUpdateApp() {
      if (this.$cookies.get('platform') === 'ios') {
        return this.$store.state.apps.ios.version.isRequiredUpdate;
      } else {
        return false;
      }
    },
    isExistsUpdateApp() {
      if (this.$cookies.get('platform') === 'ios') {
        return (
          this.$store.state.apps.ios.version.isExsitsUpdate &&
          !this.isIgnoreAppUpdate
        );
      } else {
        return false;
      }
    }
  },
  created() {
    this.isLoading = true;

    setTimeout(() => {
      this.isLoading = false;
    }, 3000);

    this.$store.dispatch('setApps');

    // Theme
    if (this.$route.name === 'app' && this.$route.query.theme) {
      delete this.$route.query.theme;
      const qs = new URLSearchParams(this.$route.query).toString();
      location.href = qs ? `/app?${qs}` : '/app';
    }

    smoothscroll.polyfill();

    // WebView & Platform
    if (this.$route.query.platform) {
      this.$cookies.set(
        'webview',
        true,
        Infinity,
        '/',
        location.hostname.replace('www', '')
      );

      this.$cookies.set(
        'platform',
        this.$route.query.platform,
        Infinity,
        '/',
        location.hostname.replace('www', '')
      );

      if (this.$cookies.get('ignore_app_update')) {
        this.isIgnoreAppUpdate = true;
      }

      window.Floob._webview.setState = kv => {
        this.$store.dispatch('set', kv);
        if (kv.key === 'version') {
          this.$cookies.set(
            'version',
            kv.value,
            Infinity,
            '/',
            location.hostname.replace('www', '')
          );

          this.$store.dispatch('setApps');
        }
      };
      window.Floob._webview.setTheme = theme => {
        this.setTheme(theme);
      };
      window.Floob._webview.checkAuthorizedInstagram = () => {};
      window.Floob._webview.checkAuthorizedInstagramOnSetting = () => {};

      if (this.$cookies.get('platform') === 'ios') {
        window.Floob._webview.openUrl = url => {
          wvc.openUrl(url);
        };
        window.Floob._webview.setAppLink = link => {
          this.setAppLink(link);
        };
        window.Floob._webview.setDeviceToken = deviceToken => {
          this.setDeviceToken(deviceToken);
        };
        window.Floob._webview.confirmNotification = () => {
          setTimeout(() => {
            this.messageDialog.show({
              title: 'Floob 알림 설정',
              content: `알림을 허용해주세요 🙏

          식사 시간에 따른 리마인드 및 유용한 정보를 보내드리겠습니다.
          사용 패턴을 분석해서 알림이 발송되므로 귀찮게 하지 않을게요. 😉

          혹시 알림 수신을 중단하고 싶으시면 언제든지 시스템의 알림 설정에서 해제할 수 있습니다.`,
              additionalButtons: [
                {
                  text: '확인',
                  action: () => {
                    wvc.openNotificationRequest();
                  }
                }
              ]
            });
          }, 1000);
        };
      } else if (this.$cookies.get('platform') === 'android') {
        console.log('This is android app.');
      }
    } else {
      if (document.location.hostname != 'localhost') {
        this.isVisibleAppOpenOverlay = true;
      }
    }
  },
  mounted() {
    window.Floob._analytics.logEvent('view_page', {
      name: this.menu
    });

    if (this.$route.name === 'meal') {
      this.setAppLink(this.$route.path);
    }
  }
};
</script>

<style></style>
