<template>
  <section>
    <floob-header></floob-header>

    <v-container fluid class="py-0">
      <v-row no-gutters>
        <v-col sm="6" class="pa-0">
          <floob-timeline :hideHeader="true" class="pb-8"></floob-timeline>
        </v-col>
        <v-col sm="6" class="pa-0">
          <floob-report></floob-report>
        </v-col>
      </v-row>
    </v-container>
    <floob-footer></floob-footer>
  </section>
</template>

<script>
import _ from 'lodash';
import api from '@/api';
import moment from 'moment';

import floobHeader from '@/components/header_v2.vue';
import floobFooter from '@/components/footer.vue';

import floobTimeline from '@/components/pages/timeline.vue';
import floobReport from '@/components/pages/report.vue';

export default {
  components: {
    floobHeader,
    floobFooter,
    floobTimeline,
    floobReport
  },
  data() {
    return {
      // controlls
      searchCount: 10,
      isLoading: false,
      isReloading: false,
      isNoMoreMeals: false,

      // datas
      meals: []
    };
  },
  methods: {
    init() {
      this.meals = [];
      this.isLoading = false;
      this.isReloading = false;
      this.isNoMoreMeals = false;
      this.isVisibleReload = false;
    },
    reload() {
      this.init();
      this.isReloading = true;
      this.getMorePrevMeals();
    },
    getMorePrevMeals() {
      if (!this.isLoading && !this.isNoMoreMeals) {
        this.isLoading = true;

        setTimeout(() => {
          api.meal
            .getMealsByTimeline(
              this.searchCount,
              this.lastMealDate,
              this.lastMealId
            )
            .then(meals => {
              console.log('getMealsByTimeline', meals);
              if (meals && meals.length > 0) {
                meals.forEach(meal => {
                  if (_.find(this.meals, { _id: meal._id })) {
                    return;
                  }
                  this.meals.push({
                    ...meal
                  });
                });
              } else {
                this.isNoMoreMeals = true;
              }
            })
            .finally(() => {
              this.isLoading = false;
            });
        }, 1000);
      }
    }
  },

  computed: {
    lastMealId() {
      if (this.meals && this.meals.length > 0) {
        return this.meals[this.meals.length - 1]._id;
      } else {
        return null;
      }
    },
    lastMealDate() {
      if (this.meals && this.meals.length > 0) {
        return this.meals[this.meals.length - 1].mealedDate;
      } else {
        return null;
      }
    },
    groupedDates() {
      return _.keys(this.groupedMealsByDate);
    },
    groupedMealsByDate() {
      if (this.meals && this.meals.length > 0) {
        return _.groupBy(_.orderBy(this.meals, ['mealedDate'], ['desc']), _ => {
          return moment(_.mealedDate).format('YYYY-MM-DD');
        });
      } else {
        return [];
      }
    }
  },
  created() {
    this.getMorePrevMeals();
  }
};
</script>

<style></style>
