var _ = require('lodash');

var emojis = [
  {
    name: 'grapes',
    symbol: '🍇',
    url: 'https://blob.floob.co.kr/emoji/grapes.png'
  },
  {
    name: 'melon',
    symbol: '🍈',
    url: 'https://blob.floob.co.kr/emoji/melon.png'
  },
  {
    name: 'watermelon',
    symbol: '🍉',
    url: 'https://blob.floob.co.kr/emoji/watermelon.png'
  },
  {
    name: 'tangerine',
    symbol: '🍊',
    url: 'https://blob.floob.co.kr/emoji/tangerine.png'
  },
  {
    name: 'lemon',
    symbol: '🍋',
    url: 'https://blob.floob.co.kr/emoji/lemon.png'
  },
  {
    name: 'banana',
    symbol: '🍌',
    url: 'https://blob.floob.co.kr/emoji/banana.png'
  },
  {
    name: 'pineapple',
    symbol: '🍍',
    url: 'https://blob.floob.co.kr/emoji/pineapple.png'
  },
  {
    name: 'mango',
    symbol: '🥭',
    url: 'https://blob.floob.co.kr/emoji/mango.png'
  },
  {
    name: 'red-apple',
    symbol: '🍎',
    url: 'https://blob.floob.co.kr/emoji/red-apple.png'
  },
  {
    name: 'green-apple',
    symbol: '🍏',
    url: 'https://blob.floob.co.kr/emoji/green-apple.png'
  },
  {
    name: 'pear',
    symbol: '🍐',
    url: 'https://blob.floob.co.kr/emoji/pear.png'
  },
  {
    name: 'peach',
    symbol: '🍑',
    url: 'https://blob.floob.co.kr/emoji/peach.png'
  },
  {
    name: 'cherries',
    symbol: '🍒',
    url: 'https://blob.floob.co.kr/emoji/cherries.png'
  },
  {
    name: 'strawberry',
    symbol: '🍓',
    url: 'https://blob.floob.co.kr/emoji/strawberry.png'
  },
  {
    name: 'blueberries',
    symbol: '🫐',
    url: 'https://blob.floob.co.kr/emoji/blueberries.png'
  },
  {
    name: 'kiwi-fruit',
    symbol: '🥝',
    url: 'https://blob.floob.co.kr/emoji/kiwi-fruit.png'
  },
  {
    name: 'tomato',
    symbol: '🍅',
    url: 'https://blob.floob.co.kr/emoji/tomato.png'
  },
  {
    name: 'olive',
    symbol: '🫒',
    url: 'https://blob.floob.co.kr/emoji/olive.png'
  },
  {
    name: 'coconut',
    symbol: '🥥',
    url: 'https://blob.floob.co.kr/emoji/coconut.png'
  },
  {
    name: 'avocado',
    symbol: '🥑',
    url: 'https://blob.floob.co.kr/emoji/avocado.png'
  },
  {
    name: 'eggplant',
    symbol: '🍆',
    url: 'https://blob.floob.co.kr/emoji/eggplant.png'
  },
  {
    name: 'potato',
    symbol: '🥔',
    url: 'https://blob.floob.co.kr/emoji/potato.png'
  },
  {
    name: 'carrot',
    symbol: '🥕',
    url: 'https://blob.floob.co.kr/emoji/carrot.png'
  },
  {
    name: 'ear-of-corn',
    symbol: '🌽',
    url: 'https://blob.floob.co.kr/emoji/ear-of-corn.png'
  },
  {
    name: 'hot-pepper',
    symbol: '🌶',
    url: 'https://blob.floob.co.kr/emoji/hot-pepper.png'
  },
  {
    name: 'bell-pepper',
    symbol: '🫑',
    url: 'https://blob.floob.co.kr/emoji/bell-pepper.png'
  },
  {
    name: 'cucumber',
    symbol: '🥒',
    url: 'https://blob.floob.co.kr/emoji/cucumber.png'
  },
  {
    name: 'leafy-green',
    symbol: '🥬',
    url: 'https://blob.floob.co.kr/emoji/leafy-green.png'
  },
  {
    name: 'broccoli',
    symbol: '🥦',
    url: 'https://blob.floob.co.kr/emoji/broccoli.png'
  },
  {
    name: 'garlic',
    symbol: '🧄',
    url: 'https://blob.floob.co.kr/emoji/garlic.png'
  },
  {
    name: 'onion',
    symbol: '🧅',
    url: 'https://blob.floob.co.kr/emoji/onion.png'
  },
  {
    name: 'mushroom',
    symbol: '🍄',
    url: 'https://blob.floob.co.kr/emoji/mushroom.png'
  },
  {
    name: 'peanuts',
    symbol: '🥜',
    url: 'https://blob.floob.co.kr/emoji/peanuts.png'
  },
  {
    name: 'chestnut',
    symbol: '🌰',
    url: 'https://blob.floob.co.kr/emoji/chestnut.png'
  },
  {
    name: 'bread',
    symbol: '🍞',
    url: 'https://blob.floob.co.kr/emoji/bread.png'
  },
  {
    name: 'croissant',
    symbol: '🥐',
    url: 'https://blob.floob.co.kr/emoji/croissant.png'
  },
  {
    name: 'baguette-bread',
    symbol: '🥖',
    url: 'https://blob.floob.co.kr/emoji/baguette-bread.png'
  },
  {
    name: 'flatbread',
    symbol: '🫓',
    url: 'https://blob.floob.co.kr/emoji/flatbread.png'
  },
  {
    name: 'pretzel',
    symbol: '🥨',
    url: 'https://blob.floob.co.kr/emoji/pretzel.png'
  },
  {
    name: 'bagel',
    symbol: '🥯',
    url: 'https://blob.floob.co.kr/emoji/bagel.png'
  },
  {
    name: 'pancakes',
    symbol: '🥞',
    url: 'https://blob.floob.co.kr/emoji/pancakes.png'
  },
  {
    name: 'waffle',
    symbol: '🧇',
    url: 'https://blob.floob.co.kr/emoji/waffle.png'
  },
  {
    name: 'cheese-wedge',
    symbol: '🧀',
    url: 'https://blob.floob.co.kr/emoji/cheese-wedge.png'
  },
  {
    name: 'meat-on-bone',
    symbol: '🍖',
    url: 'https://blob.floob.co.kr/emoji/meat-on-bone.png'
  },
  {
    name: 'poultry-leg',
    symbol: '🍗',
    url: 'https://blob.floob.co.kr/emoji/poultry-leg.png'
  },
  {
    name: 'cut-of-meat',
    symbol: '🥩',
    url: 'https://blob.floob.co.kr/emoji/cut-of-meat.png'
  },
  {
    name: 'bacon',
    symbol: '🥓',
    url: 'https://blob.floob.co.kr/emoji/bacon.png'
  },
  {
    name: 'hamburger',
    symbol: '🍔',
    url: 'https://blob.floob.co.kr/emoji/hamburger.png'
  },
  {
    name: 'french-fries',
    symbol: '🍟',
    url: 'https://blob.floob.co.kr/emoji/french-fries.png'
  },
  {
    name: 'pizza',
    symbol: '🍕',
    url: 'https://blob.floob.co.kr/emoji/pizza.png'
  },
  {
    name: 'hot-dog',
    symbol: '🌭',
    url: 'https://blob.floob.co.kr/emoji/hot-dog.png'
  },
  {
    name: 'sandwich',
    symbol: '🥪',
    url: 'https://blob.floob.co.kr/emoji/sandwich.png'
  },
  {
    name: 'taco',
    symbol: '🌮',
    url: 'https://blob.floob.co.kr/emoji/taco.png'
  },
  {
    name: 'burrito',
    symbol: '🌯',
    url: 'https://blob.floob.co.kr/emoji/burrito.png'
  },
  {
    name: 'tamale',
    symbol: '🫔',
    url: 'https://blob.floob.co.kr/emoji/tamale.png'
  },
  {
    name: 'stuffed-flatbread',
    symbol: '🥙',
    url: 'https://blob.floob.co.kr/emoji/stuffed-flatbread.png'
  },
  {
    name: 'falafel',
    symbol: '🧆',
    url: 'https://blob.floob.co.kr/emoji/falafel.png'
  },
  { name: 'egg', symbol: '🥚', url: 'https://blob.floob.co.kr/emoji/egg.png' },
  {
    name: 'cooking',
    symbol: '🍳',
    url: 'https://blob.floob.co.kr/emoji/cooking.png'
  },
  {
    name: 'shallow-pan-of-food',
    symbol: '🥘',
    url: 'https://blob.floob.co.kr/emoji/shallow-pan-of-food.png'
  },
  {
    name: 'pot-of-food',
    symbol: '🍲',
    url: 'https://blob.floob.co.kr/emoji/pot-of-food.png'
  },
  {
    name: 'fondue',
    symbol: '🫕',
    url: 'https://blob.floob.co.kr/emoji/fondue.png'
  },
  {
    name: 'bowl-with-spoon',
    symbol: '🥣',
    url: 'https://blob.floob.co.kr/emoji/bowl-with-spoon.png'
  },
  {
    name: 'green-salad',
    symbol: '🥗',
    url: 'https://blob.floob.co.kr/emoji/green-salad.png'
  },
  {
    name: 'popcorn',
    symbol: '🍿',
    url: 'https://blob.floob.co.kr/emoji/popcorn.png'
  },
  {
    name: 'butter',
    symbol: '🧈',
    url: 'https://blob.floob.co.kr/emoji/butter.png'
  },
  {
    name: 'salt',
    symbol: '🧂',
    url: 'https://blob.floob.co.kr/emoji/salt.png'
  },
  {
    name: 'canned-food',
    symbol: '🥫',
    url: 'https://blob.floob.co.kr/emoji/canned-food.png'
  },
  {
    name: 'bento-box',
    symbol: '🍱',
    url: 'https://blob.floob.co.kr/emoji/bento-box.png'
  },
  {
    name: 'rice-cracker',
    symbol: '🍘',
    url: 'https://blob.floob.co.kr/emoji/rice-cracker.png'
  },
  {
    name: 'rice-ball',
    symbol: '🍙',
    url: 'https://blob.floob.co.kr/emoji/rice-ball.png'
  },
  {
    name: 'cooked-rice',
    symbol: '🍚',
    url: 'https://blob.floob.co.kr/emoji/cooked-rice.png'
  },
  {
    name: 'curry-rice',
    symbol: '🍛',
    url: 'https://blob.floob.co.kr/emoji/curry-rice.png'
  },
  {
    name: 'steaming-bowl',
    symbol: '🍜',
    url: 'https://blob.floob.co.kr/emoji/steaming-bowl.png'
  },
  {
    name: 'spaghetti',
    symbol: '🍝',
    url: 'https://blob.floob.co.kr/emoji/spaghetti.png'
  },
  {
    name: 'roasted-sweet-potato',
    symbol: '🍠',
    url: 'https://blob.floob.co.kr/emoji/roasted-sweet-potato.png'
  },
  {
    name: 'oden',
    symbol: '🍢',
    url: 'https://blob.floob.co.kr/emoji/oden.png'
  },
  {
    name: 'sushi',
    symbol: '🍣',
    url: 'https://blob.floob.co.kr/emoji/sushi.png'
  },
  {
    name: 'fried-shrimp',
    symbol: '🍤',
    url: 'https://blob.floob.co.kr/emoji/fried-shrimp.png'
  },
  {
    name: 'fish-cake-with-swirl',
    symbol: '🍥',
    url: 'https://blob.floob.co.kr/emoji/fish-cake-with-swirl.png'
  },
  {
    name: 'moon-cake',
    symbol: '🥮',
    url: 'https://blob.floob.co.kr/emoji/moon-cake.png'
  },
  {
    name: 'dango',
    symbol: '🍡',
    url: 'https://blob.floob.co.kr/emoji/dango.png'
  },
  {
    name: 'dumpling',
    symbol: '🥟',
    url: 'https://blob.floob.co.kr/emoji/dumpling.png'
  },
  {
    name: 'fortune-cookie',
    symbol: '🥠',
    url: 'https://blob.floob.co.kr/emoji/fortune-cookie.png'
  },
  {
    name: 'takeout-box',
    symbol: '🥡',
    url: 'https://blob.floob.co.kr/emoji/takeout-box.png'
  },
  {
    name: 'crab',
    symbol: '🦀',
    url: 'https://blob.floob.co.kr/emoji/crab.png'
  },
  {
    name: 'lobster',
    symbol: '🦞',
    url: 'https://blob.floob.co.kr/emoji/lobster.png'
  },
  {
    name: 'shrimp',
    symbol: '🦐',
    url: 'https://blob.floob.co.kr/emoji/shrimp.png'
  },
  {
    name: 'squid',
    symbol: '🦑',
    url: 'https://blob.floob.co.kr/emoji/squid.png'
  },
  {
    name: 'octopus',
    symbol: '🐙',
    url: 'https://blob.floob.co.kr/emoji/octopus.png'
  },
  {
    name: 'fish',
    symbol: '🐟',
    url: 'https://blob.floob.co.kr/emoji/fish.png'
  },
  {
    name: 'blowfish',
    symbol: '🐡',
    url: 'https://blob.floob.co.kr/emoji/blowfish.png'
  },
  {
    name: 'oyster',
    symbol: '🦪',
    url: 'https://blob.floob.co.kr/emoji/oyster.png'
  },
  {
    name: 'spiral-shell.png',
    symbol: '🐚',
    url: 'https://blob.floob.co.kr/emoji/spiral-shell.png'
  },
  {
    name: 'soft-ice-cream',
    symbol: '🍦',
    url: 'https://blob.floob.co.kr/emoji/soft-ice-cream.png'
  },
  {
    name: 'shaved-ice',
    symbol: '🍧',
    url: 'https://blob.floob.co.kr/emoji/shaved-ice.png'
  },
  {
    name: 'ice-cream',
    symbol: '🍨',
    url: 'https://blob.floob.co.kr/emoji/ice-cream.png'
  },
  {
    name: 'doughnut',
    symbol: '🍩',
    url: 'https://blob.floob.co.kr/emoji/doughnut.png'
  },
  {
    name: 'cookie',
    symbol: '🍪',
    url: 'https://blob.floob.co.kr/emoji/cookie.png'
  },
  {
    name: 'birthday-cake',
    symbol: '🎂',
    url: 'https://blob.floob.co.kr/emoji/birthday-cake.png'
  },
  {
    name: 'shortcake',
    symbol: '🍰',
    url: 'https://blob.floob.co.kr/emoji/shortcake.png'
  },
  {
    name: 'cupcake',
    symbol: '🧁',
    url: 'https://blob.floob.co.kr/emoji/cupcake.png'
  },
  { name: 'pie', symbol: '🥧', url: 'https://blob.floob.co.kr/emoji/pie.png' },
  {
    name: 'chocolate-bar',
    symbol: '🍫',
    url: 'https://blob.floob.co.kr/emoji/chocolate-bar.png'
  },
  {
    name: 'candy',
    symbol: '🍬',
    url: 'https://blob.floob.co.kr/emoji/candy.png'
  },
  {
    name: 'lollipop',
    symbol: '🍭',
    url: 'https://blob.floob.co.kr/emoji/lollipop.png'
  },
  {
    name: 'custard',
    symbol: '🍮',
    url: 'https://blob.floob.co.kr/emoji/custard.png'
  },
  {
    name: 'honey-pot',
    symbol: '🍯',
    url: 'https://blob.floob.co.kr/emoji/honey-pot.png'
  },
  {
    name: 'baby-bottle',
    symbol: '🍼',
    url: 'https://blob.floob.co.kr/emoji/baby-bottle.png'
  },
  {
    name: 'glass-of-milk',
    symbol: '🥛',
    url: 'https://blob.floob.co.kr/emoji/glass-of-milk.png'
  },
  {
    name: 'hot-beverage',
    symbol: '☕',
    url: 'https://blob.floob.co.kr/emoji/hot-beverage.png'
  },
  {
    name: 'teapot',
    symbol: '🫖',
    url: 'https://blob.floob.co.kr/emoji/teapot.png'
  },
  {
    name: 'teacup-without-handle',
    symbol: '🍵',
    url: 'https://blob.floob.co.kr/emoji/teacup-without-handle.png'
  },
  {
    name: 'sake',
    symbol: '🍶',
    url: 'https://blob.floob.co.kr/emoji/sake.png'
  },
  {
    name: 'bottle-with-popping-cork',
    symbol: '🍾',
    url: 'https://blob.floob.co.kr/emoji/bottle-with-popping-cork.png'
  },
  {
    name: 'wine-glass',
    symbol: '🍷',
    url: 'https://blob.floob.co.kr/emoji/wine-glass.png'
  },
  {
    name: 'cocktail-glass',
    symbol: '🍸',
    url: 'https://blob.floob.co.kr/emoji/cocktail-glass.png'
  },
  {
    name: 'tropical-drink',
    symbol: '🍹',
    url: 'https://blob.floob.co.kr/emoji/tropical-drink.png'
  },
  {
    name: 'beer-mug',
    symbol: '🍺',
    url: 'https://blob.floob.co.kr/emoji/beer-mug.png'
  },
  {
    name: 'clinking-beer-mugs',
    symbol: '🍻',
    url: 'https://blob.floob.co.kr/emoji/clinking-beer-mugs.png'
  },
  {
    name: 'clinking-glasses',
    symbol: '🥂',
    url: 'https://blob.floob.co.kr/emoji/clinking-glasses.png'
  },
  {
    name: 'tumbler-glass',
    symbol: '🥃',
    url: 'https://blob.floob.co.kr/emoji/tumbler-glass.png'
  },
  {
    name: 'cup-with-straw',
    symbol: '🥤',
    url: 'https://blob.floob.co.kr/emoji/cup-with-straw.png'
  },
  {
    name: 'bubble-tea',
    symbol: '🧋',
    url: 'https://blob.floob.co.kr/emoji/bubble-tea.png'
  },
  {
    name: 'beverage-box',
    symbol: '🧃',
    url: 'https://blob.floob.co.kr/emoji/beverage-box.png'
  },
  {
    name: 'mate',
    symbol: '🧉',
    url: 'https://blob.floob.co.kr/emoji/mate.png'
  },
  { name: 'ice', symbol: '🧊', url: 'https://blob.floob.co.kr/emoji/ice.png' },
  {
    name: 'chopsticks',
    symbol: '🥢',
    url: 'https://blob.floob.co.kr/emoji/chopsticks.png'
  },
  {
    name: 'fork-and-knife-with-plate',
    symbol: '🍽',
    url: 'https://blob.floob.co.kr/emoji/fork-and-knife-with-plate.png'
  },
  {
    name: 'fork-and-knife',
    symbol: '🍴',
    url: 'https://blob.floob.co.kr/emoji/fork-and-knife.png'
  },
  {
    name: 'spoon',
    symbol: '🥄',
    url: 'https://blob.floob.co.kr/emoji/spoon.png'
  },
  {
    name: 'kitchen-knife',
    symbol: '🔪',
    url: 'https://blob.floob.co.kr/emoji/kitchen-knife.png'
  }
  // {
  //   name: 'amphora',
  //   symbol: '🏺',
  //   url: 'https://blob.floob.co.kr/emoji/amphora.png'
  // }
];

exports.getEmojis = () => {
  return emojis;
};

exports.getRandomEmojiSymbol = () => {
  return _.shuffle(
    _.filter(emojis, emoji => {
      return emoji.symbol ? true : false;
    })
  )[0].symbol;
};
