import Vue from 'vue';
import Vuex from 'vuex';

import semver from 'semver';

import api from '@/api';

import NodeCache from 'node-cache';
const sourceLinkCache = new NodeCache({ stdTTL: 100, checkperiod: 120 });

Vue.use(Vuex);

const VERSION = require('../../package.json').version;

export default new Vuex.Store({
  state: {
    apps: {
      web: {
        version: {}
      },
      api: {
        version: {}
      },
      ios: {
        version: {}
      },
      android: {
        version: {}
      }
    },
    version: '',
    theme: 'light',
    isLoggedIn: false,
    user: null,
    allowLocation: false,
    location: null,
    menu: 'timeline',
    isVisibleLoginDialog: false,
    isDisplayTouch: false,
    isMobileDevice: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
    isRequiredUpdate: false,
    sourceLinkCache: sourceLinkCache,
    loading: false,

    // events
    syncUpdatedMeal: null,
    syncDeletedMeal: null
  },
  getters: {
    user: state => {
      return state.user || null;
    }
  },
  mutations: {
    set(state, keyValue) {
      state[keyValue.key] = keyValue.value;
      if (keyValue.key === 'user') {
        if (state.user) {
          state.isLoggedIn = true;
        } else {
          state.isLoggedIn = false;
        }
      } else if (keyValue.key === 'theme') {
        const theme = keyValue.value;
        if (theme === 'dark') {
          document.getElementsByTagName('body')[0].bgColor = '#121212';
          window.$cookies.set(
            'theme',
            'dark',
            Infinity,
            '/',
            location.hostname.replace('www', '')
          );
        } else {
          document.getElementsByTagName('body')[0].bgColor = 'white';
          window.$cookies.set(
            'theme',
            'light',
            Infinity,
            '/',
            location.hostname.replace('www', '')
          );
        }
      }
    },
    checkLatestVersion(state) {
      api.app.version('web').then(version => {
        if (semver.lt(VERSION, version)) {
          state.isRequiredUpdate = true;
        } else {
          state.isRequiredUpdate = false;
        }
      });
    },
    setApps(state) {
      api.app.getAppInfo().then(appInfo => {
        let apps = {
          ...appInfo
        };

        apps.web.version.current = require('../../package.json').version;

        if (semver.lt(apps.web.version.current, apps.web.version.latest)) {
          apps.web.version.isExsitsUpdate = true;
        } else {
          apps.web.version.isExsitsUpdate = false;
        }

        if (
          semver.lt(apps.web.version.current, apps.web.version.minimumRequired)
        ) {
          apps.web.version.isRequiredUpdate = true;
        } else {
          apps.web.version.isRequiredUpdate = false;
        }

        if (window.$cookies.get('platform') === 'ios') {
          apps.ios.version.current = state.version;

          if (semver.lt(apps.ios.version.current, apps.ios.version.latest)) {
            apps.ios.version.isExsitsUpdate = true;
          } else {
            apps.ios.version.isExsitsUpdate = false;
          }

          if (
            semver.lt(
              apps.ios.version.current,
              apps.ios.version.minimumRequired
            )
          ) {
            apps.ios.version.isRequiredUpdate = true;
          } else {
            apps.ios.version.isRequiredUpdate = false;
          }
        }

        state.apps = apps;
      });
    }
  },
  actions: {
    set(context, keyValue) {
      context.commit('set', keyValue);
    },
    checkLatestVersion(context) {
      context.commit('checkLatestVersion');
    },
    setApps(context) {
      context.commit('setApps');
    }
  },
  modules: {}
});
