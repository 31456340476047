<template>
  <section>
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xs"
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card tile>
        <v-card-title class="floob-toolbar-title">
          <v-btn
            text
            x-large
            @click="onClickClose"
            :ripple="$store.state.isDisplayTouch"
            class="floob-toolbar-button-left"
          >
            <v-icon>mdi-chevron-down</v-icon></v-btn
          >
          <v-toolbar-title><h1>피드 추가</h1> </v-toolbar-title>
          <v-btn
            text
            x-large
            :ripple="$store.state.isDisplayTouch"
            class="pr-0"
            disabled
          >
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-title class="px-0 pb-0">
          <v-container fluid class="py-0 px-2">
            <v-row no-gutters>
              <v-col>
                <v-list three-line>
                  <v-subheader>
                    <b>스토리</b>
                  </v-subheader>
                  <v-list-item
                    v-for="(floober, idx) in followFloobers"
                    :key="idx"
                    class="pr-0"
                  >
                    <v-list-item-avatar @click="onClickStory(floober)">
                      <v-img :src="floober.profileImageUrl"> </v-img>
                    </v-list-item-avatar>
                    <v-list-item-content @click="onClickStory(floober)">
                      <v-list-item-title>
                        {{ floober.name }}
                        <v-chip
                          v-if="floober.gender === 'bot'"
                          label
                          x-small
                          color="amber"
                          dark
                          class="px-1 mb-1"
                        >
                          Bot</v-chip
                        >
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{
                          isLoading
                            ? floober.introduction
                            : `${floober.introduction}...`
                        }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action class="ml-0">
                      <v-btn
                        v-if="floober.isFollowing"
                        text
                        color="grey"
                        @click="onClickUnfollow(floober)"
                        :loading="floober.isLoading"
                        :disabled="isLoading"
                        :ripple="$store.state.isDisplayTouch"
                      >
                        팔로잉
                      </v-btn>
                      <v-btn
                        v-else
                        text
                        color="primary"
                        @click="onClickFollow(floober)"
                        :loading="floober.isLoading"
                        :disabled="isLoading"
                        :ripple="$store.state.isDisplayTouch"
                      >
                        팔로우
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>
      </v-card>

      <floob-story-dialog
        :dialog.sync="storyDialog"
        v-on:updated="onUpdatedFollow"
      ></floob-story-dialog>
    </v-dialog>
  </section>
</template>

<script>
import _ from 'lodash';
import api from '@/api';

import floobStoryDialog from '@/components/storyDialog.vue';

export default {
  components: {
    floobStoryDialog
  },
  data() {
    return {
      // modules
      storyDialog: null,

      // controlls
      isVisible: false,
      isLoading: false,
      isUpdated: false,

      // datas
      floobers: []
    };
  },
  methods: {
    init() {
      this.isLoading = false;
      this.isUpdated = false;
    },
    onClickStory(floober) {
      this.storyDialog.show(floober._id);
    },
    onClickClose() {
      if (this.isUpdated) {
        this.$emit('updated', this.floobers);
      }
      this.isVisible = false;
    },
    onClickUnfollow(floober) {
      this.isLoading = true;
      floober.isLoading = true;

      let followUserIds = [...this.$store.state.user.followUserIds];
      followUserIds = _.reject(followUserIds, followedUserId => {
        return followedUserId === floober._id;
      });

      setTimeout(() => {
        api.user
          .updateUser({
            _id: this.$store.state.user._id,
            followUserIds: followUserIds
          })
          .then(updated => {
            if (updated) {
              this.$store.dispatch('set', {
                key: 'user',
                value: {
                  ...this.$store.state.user,
                  followUserIds: followUserIds
                }
              });

              this.isUpdated = true;
            }
          })
          .finally(() => {
            floober.isLoading = false;
            this.isLoading = false;
          });
      }, 1000);
    },
    onClickFollow(floober) {
      this.isLoading = true;
      floober.isLoading = true;

      let followUserIds = [...this.$store.state.user.followUserIds];
      followUserIds.push(floober._id);

      setTimeout(() => {
        api.user
          .updateUser({
            _id: this.$store.state.user._id,
            followUserIds: followUserIds
          })
          .then(updated => {
            if (updated) {
              this.$store.dispatch('set', {
                key: 'user',
                value: {
                  ...this.$store.state.user,
                  followUserIds: followUserIds
                }
              });

              this.isUpdated = true;
            }
          })
          .finally(() => {
            floober.isLoading = false;
            this.isLoading = false;
          });
      }, 1000);
    },
    onUpdatedFollow() {
      this.isUpdated = true;
    }
  },
  computed: {
    followFloobers() {
      return _.map(this.floobers, floober => {
        if (
          Array.isArray(this.$store.state.user.followUserIds) &&
          this.$store.state.user.followUserIds.includes(floober._id)
        ) {
          return {
            ...floober,
            isFollowing: true
          };
        } else {
          return {
            ...floober,
            isFollowing: false
          };
        }
      });
    }
  },
  created() {
    this.$emit('update:dialog', {
      show: () => {
        this.init();

        api.user.getUsersByAuthenticatedAuth('floober').then(floobers => {
          if (this.$store.state.user) {
            floobers = _.reject(floobers, floober => {
              return floober._id === this.$store.state.user._id;
            });
          }
          this.floobers = _.map(
            _.orderBy(floobers, ['followCount'], ['desc']),
            f => {
              return {
                ...f,
                isLoading: false
              };
            }
          );
        });
        this.isVisible = true;
      }
    });
  }
};
</script>

<style></style>
