<template>
  <section>
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xs"
      transition="slide-x-reverse-transition"
      max-width="600px"
      persistent
      scrollable
    >
      <v-card
        :tile="$vuetify.breakpoint.xs"
        :class="{ 'floob-dialog-card': !$vuetify.breakpoint.xs }"
      >
        <v-card-title class="floob-toolbar-title">
          <v-btn
            text
            x-large
            @click="isVisible = false"
            :ripple="$store.state.isDisplayTouch"
            class="floob-toolbar-button-left"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-toolbar-title><h1>식사 관리</h1></v-toolbar-title>
          <v-btn text x-large class="pr-0"> </v-btn>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text class="pa-0">
          <v-list nav>
            <template v-for="meal in meals">
              <div :key="meal._id">
                <floob-meal-list-item :meal="meal"> </floob-meal-list-item>
                <v-list-item class="mb-0">
                  <v-list-item-avatar
                    size="24"
                    v-if="meal.owner.profileImageUrl"
                    class="mr-2"
                    @click="onClickStory(meal.owner)"
                  >
                    <v-img
                      v-if="meal.owner.profileImageUrl"
                      :src="meal.owner.profileImageUrl"
                    ></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-subtitle @click="onClickStory(meal.owner)">
                      {{
                        `${meal.owner.nickname}${
                          meal.owner.autoFoodTag ? ` • 🤖` : ''
                        }`
                      }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item
                  v-if="meal.review && meal.review.score"
                  class="mb-0"
                >
                  <v-list-item-subtitle>
                    {{ `평점 : ${meal.review.score}` }}
                  </v-list-item-subtitle>
                </v-list-item>

                <v-list-item
                  v-if="meal.foodTags && meal.foodTags.length > 0"
                  class="mb-0"
                >
                  <v-list-item-subtitle>
                    {{
                      `음식수 : ${meal.foodTags.length} (${getCalorieSum(
                        meal.foodTags
                      )}kcal)`
                    }}
                  </v-list-item-subtitle>
                </v-list-item>

                <v-list-item v-if="meal.store" class="mb-0">
                  <v-list-item-subtitle>
                    {{ `식당 : ${meal.store.name}` }}
                  </v-list-item-subtitle>
                </v-list-item>

                <v-divider class="pb-2 mt-4"></v-divider>
              </div>
            </template>
            <v-list-item v-if="isNoMoreMeals">
              <v-list-item-content>
                <v-btn text block color="grey">No more datas </v-btn>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-else>
              <v-list-item-content>
                <v-btn
                  text
                  block
                  :ripple="false"
                  color="grey"
                  :loading="isLoading"
                  @click="getMorePrevMeals"
                  >More
                </v-btn>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <floob-story-dialog :dialog.sync="storyDialog"></floob-story-dialog>
  </section>
</template>

<script>
import _ from 'lodash';

import api from '@/api';

import floobMealListItem from '@/components/mealListItem.vue';
import floobStoryDialog from '@/components/storyDialog.vue';

export default {
  components: {
    floobMealListItem,
    floobStoryDialog
  },
  data() {
    return {
      // modules
      storyDialog: null,

      // controlls
      isVisible: false,
      isLoading: false,
      isNoMoreMeals: false,
      searchCount: 30,

      // data
      meals: []
    };
  },
  methods: {
    init() {
      this.isLoading = false;
      this.isNoMoreMeals = false;
      this.meals = [];
    },
    getMorePrevMeals() {
      if (!this.isLoading && !this.isNoMoreMeals) {
        this.isLoading = true;

        setTimeout(() => {
          api.meal
            .getMealsByAdmin(this.searchCount, this.lastMealId)
            .then(meals => {
              if (meals && meals.length > 0) {
                meals.forEach(meal => {
                  if (_.find(this.meals, { _id: meal._id })) {
                    return;
                  }
                  this.meals.push({
                    ...meal
                  });
                });
              } else {
                this.isNoMoreMeals = true;
              }
            })
            .finally(() => {
              this.isLoading = false;
              this.isVisibleReload = false;
            });
        }, 500);
      }
    },
    getCalorieSum(foodTags) {
      if (foodTags && Array.isArray(foodTags)) {
        return _.reduce(
          foodTags,
          (sum, foodTag) => {
            if (foodTag && foodTag.calorie && !isNaN(foodTag.calorie)) {
              return sum + foodTag.calorie;
            } else {
              return sum;
            }
          },
          0
        );
      } else {
        return null;
      }
    },
    onClickStory(user) {
      this.storyDialog.show(user._id);
    }
  },
  computed: {
    lastMealId() {
      if (this.meals && this.meals.length > 0) {
        return this.meals[this.meals.length - 1]._id;
      } else {
        return null;
      }
    }
  },
  created() {
    this.$emit('update:dialog', {
      show: () => {
        this.init();
        this.isVisible = true;

        this.getMorePrevMeals();
      }
    });
  }
};
</script>

<style></style>
