var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"flat":"","tile":""}},[_c('v-card-title',{staticClass:"px-2"},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","solo-inverted":"","flat":"","hide-details":"","color":"black","dense":""},model:{value:(_vm.searchedKeyword),callback:function ($$v) {_vm.searchedKeyword=$$v},expression:"searchedKeyword"}})],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.users,"items-per-page":10,"mobile-breakpoint":10,"search":_vm.searchedKeyword},scopedSlots:_vm._u([{key:"item.profileImageUrl",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-2"},[_c('v-avatar',{attrs:{"size":"36"}},[(item.profileImageUrl)?_c('img',{attrs:{"src":item.profileImageUrl}}):_c('v-icon',{attrs:{"color":"grey lighten-1","x-large":""}},[_vm._v("mdi-account-circle")])],1)],1)]}},{key:"item.nickname",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',[_c('v-icon',{attrs:{"x-small":"","color":item.sessions && item.sessions.length > 0
                      ? 'green lighten-2'
                      : 'grey'}},[_vm._v("mdi-circle ")]),_vm._v(" "+_vm._s(item.nickname)+" ")],1)])]}},{key:"item.status",fn:function(ref){
                      var item = ref.item;
return [_c('div',[_c('v-chip',{attrs:{"label":"","x-small":"","color":_vm.convertStatus(item.status).color,"dark":""}},[_c('b',[_vm._v(_vm._s(_vm.convertStatus(item.status).text))])])],1)]}},{key:"item.auth",fn:function(ref){
                      var item = ref.item;
return [_c('div',{staticClass:"my-2"},[(item.auth.apple)?_c('v-avatar',{staticClass:"mr-1",attrs:{"size":"18"}},[_c('img',{attrs:{"src":"https://www.pngkey.com/png/full/901-9019597_apple-logo-riot-chat-logo.png"}})]):_vm._e(),(item.auth.kakao)?_c('v-avatar',{staticClass:"mr-1",attrs:{"size":"18"}},[_c('img',{attrs:{"src":"https://image.floob.co.kr/kakao-login-icon.png"}})]):_vm._e(),(item.auth.instagram)?_c('v-avatar',{staticClass:"mr-1",attrs:{"size":"18"}},[_c('img',{attrs:{"src":"https://toppng.com/uploads/preview/instagram-logo-circle-11549679754rhbcorxntv.png"}})]):_vm._e()],1)]}},{key:"item.lastSession",fn:function(ref){
                      var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(("" + (item.lastSession && item.lastSession.expireDate ? ((_vm.convertShortDateString( item.lastSession.expireDate )) + " expired") : '')))+" ")])]}},{key:"item.createdDate",fn:function(ref){
                      var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.convertShortDateString(item.createdDate))+" ")])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }