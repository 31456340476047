<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col>
        <v-card flat>
          <v-card-title>
            <h1>
              Floob
            </h1></v-card-title
          >
          <v-card-text>
            <pre v-bind:style="{ height: `${frameHeight}px` }">
           
<v-img :width="16" src="/favicon.ico"></v-img>Floob logo



<b>제품</b>
• <a href="https://apps.apple.com/kr/app/floob/id1545936680" target="_blank">Download Floob iOS</a> 📲
• <a href="/#/app">Launch Floob Web</a> 🚀



<b>소개</b>
Floob에서 식사기록을 하면서 나만의 음식 생활 라이프스타일을 만들어보세요 😋



<b>특징</b>
 • 간편한 식사 기록 ✍️
 • 타임라인 식사이력 🕒
 • 식사 만족도 평가 ⭐️
 • 음식등록 및 태깅 📌
 • 식사 보고서 제공 📊



<b>지원</b>
Floob 앱서비스 지원은 아래 Instagram DM으로 문의 주시면 빠르게 도와 드리겠습니다 🙏
<a href="https://www.instagram.com/floob.app/">@floob.app</a>





© 2020. Bradley Pig Co., Ltd. All rights reserved. 
<a href="/#/privacy">개인정보처리방침</a> | <a href="tel:050-6721-0148">050-6721-0148</a> 
                </pre>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      window: {
        width: 0,
        height: 0
      }
    };
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    }
  },
  computed: {
    frameHeight() {
      let headerHeight = 56;
      let bottomNavHeight = 50;
      return this.window.height - headerHeight - bottomNavHeight;
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }
};
</script>

<style scoped>
pre {
  padding: 5px;
  overflow-x: hidden;
  white-space: pre-wrap;
}
</style>
