<template>
  <v-dialog
    v-model="isVisible"
    fullscreen
    transition="slide-x-reverse-transition"
    persistent
    scrollable
  >
    <v-card tile>
      <v-card-title class="floob-toolbar-title">
        <v-btn
          text
          x-large
          @click="onClickClose"
          :ripple="$store.state.isDisplayTouch"
          class="floob-toolbar-button-left"
        >
          <v-icon>mdi-chevron-left</v-icon></v-btn
        >
        <v-toolbar-title><h1>고객 센터</h1> </v-toolbar-title>

        <v-btn text x-large :ripple="$store.state.isDisplayTouch" class="pr-0">
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pl-2 pr-0">
        <v-list>
          <v-list-item
            :ripple="$store.state.isDisplayTouch"
            @click="onClickTutorial"
          >
            <v-list-item-content>
              <v-list-item-title>
                튜토리얼
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action class="ml-0">
              <v-btn text :ripple="$store.state.isDisplayTouch" disabled
                ><v-icon>mdi-chevron-right</v-icon></v-btn
              >
            </v-list-item-action>
          </v-list-item>

          <v-list-item
            :ripple="$store.state.isDisplayTouch"
            @click="onClickFaq"
          >
            <v-list-item-content>
              <v-list-item-title>
                자주 묻는 질문
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action class="ml-0">
              <v-btn text :ripple="$store.state.isDisplayTouch" disabled
                ><v-icon>mdi-chevron-right</v-icon></v-btn
              >
            </v-list-item-action>
          </v-list-item>
          <v-list-item
            :ripple="$store.state.isDisplayTouch"
            @click="onClickCustomerMessage"
          >
            <v-list-item-content>
              <v-list-item-title>
                고객의견 보내기
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action class="ml-0">
              <v-btn text :ripple="$store.state.isDisplayTouch" disabled
                ><v-icon>mdi-chevron-right</v-icon></v-btn
              >
            </v-list-item-action>
          </v-list-item>
          <v-list-item
            :ripple="$store.state.isDisplayTouch"
            @click="privacyDialog.show()"
          >
            <v-list-item-content>
              <v-list-item-title>
                개인정보 처리방침
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action class="ml-0">
              <v-btn text :ripple="$store.state.isDisplayTouch" disabled
                ><v-icon>mdi-chevron-right</v-icon></v-btn
              >
            </v-list-item-action>
          </v-list-item>
          <v-list-item
            :ripple="$store.state.isDisplayTouch"
            @click="isVisibleWithdrawal = true"
          >
            <v-list-item-content>
              <v-list-item-title>
                회원 탈퇴
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action class="ml-0">
              <v-btn text :ripple="$store.state.isDisplayTouch" disabled
                ><v-icon>mdi-chevron-right</v-icon></v-btn
              >
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>

    <floob-privacy-dialog :dialog.sync="privacyDialog"> </floob-privacy-dialog>

    <!-- 회원 탈퇴 -->
    <v-dialog
      v-model="isVisibleWithdrawal"
      fullscreen
      scrollable
      transition="slide-x-reverse-transition"
      persistent
    >
      <v-card tile flat class="px-4">
        <v-card-title>
          <v-toolbar-title><h1>회원 탈퇴</h1></v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              color="grey"
              text
              @click="onClickCloseWithdrawal"
              :ripple="$store.state.isDisplayTouch"
              class="pr-0"
            >
              닫기
            </v-btn>
          </v-toolbar-items>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <pre style="overflow: auto; white-space: pre-wrap;">

먼저 Floob을 이용해주셔서 감사합니다.


Floob 회원 탈퇴 요청 후 회원님의 사용자ID를 다른 사람이 Floob에서 사용하지 않는 한 동일한 사용자ID로 다시 가입하거나 해당 사용자ID를 다른 계정으로 가입 할 수 있습니다.

회원님의 계정이 커뮤니티 가이드라인을 위반하여 삭제된 경우 동일한 사용자ID로 다시 가입할 수 없습니다.

보안상의 이유로 Floob은 회원님을 대신하여 계정을 삭제할 수 없습니다. 삭제를 요청하려면 계정에 로그인할 수 있어야 합니다.

회원 탈퇴와 동시에 계정 삭제 요청이 진행되며 계정 삭제 요청 후 30일이 경과하면 계정과 모든 정보가 영구적으로 삭제되어 정보를 가져올 수 없습니다. 이 30일 동안 콘텐츠에는 Floob의 이용 약관 및 데이터 정책이 적용되며 Floob을 사용하는 다른 사람이 콘텐츠에 액세스할 수 없습니다.




<v-img :width="16" src="https://floob.co.kr/favicon.ico"></v-img>Floob Team


© 2020. Bradley Pig Co., Ltd. All rights reserved.
                </pre>
          <v-btn
            block
            text
            color="red lighten-2"
            @click="onClickWithdrawal"
            :ripple="$store.state.isDisplayTouch"
            :loading="isLoadingWithdrawal"
          >
            위 사항을 확인하였으며 탈퇴합니다
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

    <floob-message-dialog :dialog.sync="messageDialog"> </floob-message-dialog>
  </v-dialog>
</template>

<script>
import semver from 'semver';

import api from '@/api';

import wvc from '@/util/webViewController';

import floobPrivacyDialog from '@/components/privacyDialog.vue';
import floobMessageDialog from '@/components/messageDialog.vue';
export default {
  components: {
    floobPrivacyDialog,
    floobMessageDialog
  },
  data() {
    return {
      //modules
      privacyDialog: null,
      messageDialog: null,

      // controlls
      isVisible: false,
      isVisibleWithdrawal: false,
      isLoadingWithdrawal: false,
      withdrawalCount: 0,

      isVisiblePrivacy: false
    };
  },
  methods: {
    init() {
      this.isVisibleWithdrawal = false;
      this.isLoadingWithdrawal = false;
      this.withdrawalCount = 0;
    },
    onClickClose() {
      this.init();
      this.isVisible = false;
    },
    onClickWithdrawal() {
      this.withdrawalCount = 3;
      this.messageDialog.confirm(
        '최종 확인을 누르면 탈퇴 처리가 진행됩니다.',
        null,
        () => {
          this.isLoadingWithdrawal = true;
          setTimeout(() => {
            api.user
              .withdrawUser(this.$store.state.user)
              .then(withdrawnUser => {
                if (withdrawnUser) {
                  alert(
                    '탈퇴 처리가 완료 되었습니다.\n부족한 부분들 개선하여 추후 더 좋은 모습으로 찾아볼 수 있도록 노력하겠습니다. 😢'
                  );
                  location.reload();
                } else {
                  alert(
                    '탈퇴 처리가 실패 되었습니다.\n다시 시도하여 주시기 바랍니다.'
                  );
                }
              });
          }, 2000);
        }
      );
    },
    onClickCloseWithdrawal() {
      this.withdrawalCount = 0;
      this.isLoadingWithdrawal = false;
      this.isVisibleWithdrawal = false;
    },
    onClickTutorial() {
      if (this.$cookies.get('webview')) {
        if (semver.gte(this.$store.state.version, '1.4.3')) {
          wvc.openUrlInApp(
            'https://www.notion.so/29683b49b9a240f5b983f52f88fa83f3'
          );
        } else {
          wvc.openUrl('https://www.notion.so/29683b49b9a240f5b983f52f88fa83f3');
        }
      } else {
        window.open('https://www.notion.so/29683b49b9a240f5b983f52f88fa83f3');
      }
    },
    onClickFaq() {
      if (this.$cookies.get('webview')) {
        if (semver.gte(this.$store.state.version, '1.4.3')) {
          wvc.openUrlInApp(
            'https://www.notion.so/FAQ-3d7f3c913f0a4e02ba5d732e3dbd93a8'
          );
        } else {
          wvc.openUrl(
            'https://www.notion.so/FAQ-3d7f3c913f0a4e02ba5d732e3dbd93a8'
          );
        }
      } else {
        window.open(
          'https://www.notion.so/FAQ-3d7f3c913f0a4e02ba5d732e3dbd93a8'
        );
      }
    },
    onClickCustomerMessage() {
      let url = `https://docs.google.com/forms/d/e/1FAIpQLSc7e2prRQI9sF2dKdAKZg9JaZiyVkErbYdqYDKVwfMuQ_yxaw/viewform?usp=pp_url&entry.485428648=${
        this.$store.state.user && this.$store.state.user.nickname
          ? this.$store.state.user.nickname
          : ''
      }`;
      if (this.$cookies.get('webview')) {
        wvc.openUrl(url);
      } else {
        window.open(url);
      }
    }
  },
  created() {
    this.$emit('update:dialog', {
      show: () => {
        this.init();
        this.isVisible = true;
      },
      hide: () => {
        this.isVisible = false;
      }
    });
  }
};
</script>

<style></style>
