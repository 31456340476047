<template>
  <v-dialog v-model="isVisible" scrollable fullscreen :transition="false">
    <v-card tile flat>
      <div>
        <v-breadcrumbs :items="items" divider="/"></v-breadcrumbs>
      </div>
      <div>
        <floob-admin-user-management></floob-admin-user-management>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import floobAdminUserManagement from '@/components/admin/userManagement.vue';

export default {
  components: {
    floobAdminUserManagement
  },
  data() {
    return {
      isVisible: true,
      items: [
        {
          text: 'Admin',
          disabled: false,
          href: ''
        },
        {
          text: 'User Management',
          disabled: true,
          href: ''
        }
      ]
    };
  }
};
</script>

<style></style>
