import imageCompression from 'browser-image-compression';

const compressImage = imageFile => {
  console.log(
    'imageCompression - originalFile instanceof Blob',
    imageFile instanceof Blob
  ); // true
  console.log(
    `imageCompression - originalFile size ${imageFile.size / 1024 / 1024} MB`
  );

  let options = {
    maxSizeMB: 1,
    //maxWidthOrHeight: 1920
    useWebWorker: true
  };
  return imageCompression(imageFile, options)
    .then(compressedFile => {
      console.log(
        'imageCompression - compressedFile instanceof Blob',
        compressedFile instanceof Blob
      ); // true
      console.log(
        `imageCompression - compressedFile size ${compressedFile.size /
          1024 /
          1024} MB`
      ); // smaller than maxSizeMB
      return compressedFile;
    })
    .catch(error => {
      console.error('Fail to compress image file', error);
      return imageFile;
    });
};

export default {
  compressImage: compressImage,
  getFileFromDataUrl: dataUrl => {
    return imageCompression.getFilefromDataUrl(dataUrl, 'file');
  },
  compressImageFromDataUrl: dataUrl => {
    return imageCompression
      .getFilefromDataUrl(dataUrl, 'file')
      .then(imageFile => {
        return compressImage(imageFile);
      });
  }
};
