<template>
  <section>
    <floob-header></floob-header>

    <v-container :fluid="$vuetify.breakpoint.mdAndDown" class="py-0">
      <v-row>
        <v-col sm="6" offset-sm="3" md="8" offset-md="2" class="pa-0">
          <v-container>
            <v-row v-if="false" class="pb-4">
              <v-col>
                <div>
                  <h1 style="font-size:2.3em !important; ">Floob</h1>
                </div>
              </v-col>
            </v-row>
            <v-row class="pb-4">
              <v-col>
                <div>
                  Floob에서 식사를 기록하고 나만의 푸드 라이프 스타일을
                  만들어보세요
                </div>
              </v-col>
            </v-row>
            <v-row class="pb-8">
              <v-col>
                <v-img
                  max-width="375px"
                  contain
                  src="https://image.floob.co.kr/banner-download-appstore.png"
                  @click="onClickDownloadAppStore"
                ></v-img>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div>
                  <h2>FEATURE</h2>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div>
                  <h3>✍️ 빠른 식사 기록</h3>
                  <div>
                    간단히 사진 또는 이모지만으로 식사 기록이 가능합니다
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div>
                  <h3>🕒 쌓이는 타임라인</h3>
                  <div>
                    쌓여 가는 기록을 통해 나만의 식사 스토리를 만들어 보세요
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div>
                  <h3>📌 간편한 음식 태깅</h3>
                  <div>
                    식사 사진에 음식을 태그해서 음식 정보를 등록 할 수 있습니다
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div>
                  <h3>⭐️ 식사 만족도 평가</h3>
                  <div>
                    식사의 경험이 어땠는지 스스로 평가해보세요
                    <br />식사 생활의 질을 높이는데 도움이 될 수 있습니다
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div>
                  <h3>📍 식당 정보 등록</h3>
                  <div>
                    맛집이나 기억하고 싶은 식당들을 식사 기록에 등록하고 얼마나
                    자주 방문하는지도 확인해보세요
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div>
                  <h3>📊 보고서 통계</h3>
                  <div>
                    식사 기록에 등록 된 평가 및 음식, 식당 정보들을 통해 매달
                    나의 식사는 어땠는지 확인해보세요
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row class="pt-12">
              <v-col>
                <div>
                  <h2>CONTACT</h2>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div>
                  <h3>Instagram</h3>
                  <a target="_blank" href="https://www.instagram.com/floob.app"
                    >instagram.com/floob.app</a
                  >
                </div>
              </v-col>
            </v-row>
            <v-divider class="mt-12 mb-4"></v-divider>
            <v-row class="mb-8">
              <v-col>
                <div style="font-size:0.8em; color: grey;">
                  © 2020. Bradley Pig Co., Ltd. All rights reserved.
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>

    <floob-footer></floob-footer>
  </section>
</template>

<script>
import floobHeader from '@/components/header_v2.vue';
import floobFooter from '@/components/footer.vue';

export default {
  components: {
    floobHeader,
    floobFooter
  },
  data() {
    return {
      isVisible: true
    };
  },
  methods: {
    onClickDownloadAppStore() {
      window.open('https://apps.apple.com/kr/app/floob/id1545936680');
    }
  }
};
</script>

<style></style>
