<template>
  <section>
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xs"
      transition="slide-x-reverse-transition"
      max-width="400px"
      persistent
      scrollable
    >
      <v-card
        :tile="$vuetify.breakpoint.xs"
        :class="{ 'floob-dialog-card': !$vuetify.breakpoint.xs }"
        height="800"
      >
        <v-card-title class="floob-toolbar-title">
          <v-btn
            text
            x-large
            @click="onClickClose"
            :ripple="$store.state.isDisplayTouch"
            class="floob-toolbar-button-left"
          >
            <v-icon>mdi-chevron-left</v-icon></v-btn
          >
          <v-toolbar-title><h1>친구</h1> </v-toolbar-title>
          <v-btn
            text
            x-large
            :ripple="$store.state.isDisplayTouch"
            :loading="isLoading"
            class="pr-0"
            @click="onClickAddFriend"
          >
            추가
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-title class="px-0 pb-0"> </v-card-title>
        <v-card-text class="pa-0">
          <v-container fluid class="pt-0">
            <v-row v-if="waitingFriendships.length > 0" no-gutters>
              <v-col>
                <v-subheader class="pl-2"><b>요청 대기중</b></v-subheader>
                <v-list class="pt-0">
                  <v-list-item
                    v-for="fs in waitingFriendships"
                    :key="fs._id"
                    :ripple="$store.state.isDisplayTouch"
                  >
                    <v-list-item-avatar>
                      <v-img
                        v-if="fs.friend.profileImageUrl"
                        :src="fs.friend.profileImageUrl"
                      ></v-img>
                      <v-icon v-else color="grey lighten-1" size="46"
                        >mdi-account-circle</v-icon
                      >
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-subtitle>{{
                        fs.friend.nickname
                      }}</v-list-item-subtitle>
                      <v-list-item-title>{{
                        fs.friend.name
                      }}</v-list-item-title>
                    </v-list-item-content>

                    <template v-if="fs.type === 'requested'">
                      <v-list-item-action>
                        <v-list-item-action-text
                          v-text="'내가 요청함'"
                        ></v-list-item-action-text>
                      </v-list-item-action>
                      <v-list-item-action>
                        <v-btn
                          depressed
                          small
                          @click="onClickCancle(fs)"
                          :ripple="$store.state.isDisplayTouch"
                        >
                          <b>취소</b>
                        </v-btn>
                      </v-list-item-action>
                    </template>
                    <template v-else-if="fs.type === 'recieved'">
                      <v-list-item-action>
                        <v-btn
                          depressed
                          small
                          color="green"
                          dark
                          @click="onClickAccept(fs)"
                          :ripple="$store.state.isDisplayTouch"
                        >
                          <b>수락</b>
                        </v-btn>
                      </v-list-item-action>
                      <v-list-item-action>
                        <v-btn
                          depressed
                          small
                          @click="onClickReject(fs)"
                          :ripple="$store.state.isDisplayTouch"
                        >
                          <b>거절</b>
                        </v-btn>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-subheader class="pl-2"
                  ><b>{{
                    `친구 목록 (${
                      connectedFriendships && connectedFriendships.length > 0
                        ? connectedFriendships.length
                        : 0
                    })`
                  }}</b></v-subheader
                >
                <v-list class="pt-0">
                  <v-list-item
                    v-for="fs in connectedFriendships"
                    :key="fs._id"
                    :ripple="$store.state.isDisplayTouch"
                  >
                    <v-list-item-avatar @click="onClickStory(fs.friend._id)">
                      <v-img
                        v-if="fs.friend.profileImageUrl"
                        :src="fs.friend.profileImageUrl"
                      ></v-img>
                      <v-icon v-else color="grey lighten-1" size="46"
                        >mdi-account-circle</v-icon
                      >
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-subtitle>{{
                        fs.friend.nickname
                      }}</v-list-item-subtitle>
                      <v-list-item-title>{{
                        fs.friend.name
                      }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn
                        depressed
                        small
                        @click="onClickDelete(fs)"
                        :ripple="$store.state.isDisplayTouch"
                      >
                        <b>삭제</b>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions> </v-card-actions>
      </v-card>
      <floob-story-dialog :dialog.sync="storyDialog"></floob-story-dialog>
      <floob-friendship-creation-dialog
        :dialog.sync="friendshipCreationDialog"
        :friendships.sync="friendships"
        v-on:created="onCreatedFriendship"
      ></floob-friendship-creation-dialog>
    </v-dialog>

    <floob-message-snackbar :dialog.sync="messageSnackbar">
    </floob-message-snackbar>
    <floob-message-dialog :dialog.sync="messageDialog"> </floob-message-dialog>
  </section>
</template>

<script>
import _ from 'lodash';

import api from '@/api';

import floobStoryDialog from '@/components/storyDialog.vue';
import floobFriendshipCreationDialog from '@/components/friendshipCreationDialog.vue';
import floobMessageSnackbar from '@/components/messageSnackbar.vue';
import floobMessageDialog from '@/components/messageDialog.vue';

export default {
  components: {
    floobStoryDialog,
    floobFriendshipCreationDialog,
    floobMessageSnackbar,
    floobMessageDialog
  },
  data() {
    return {
      // controlls
      isVisible: false,
      isLoading: false,

      // datas
      searchKeyword: null,
      searchedUsers: [],
      friendships: [],

      // modules
      messageSnackbar: null,
      messageDialog: null,
      storyDialog: null,
      friendshipCreationDialog: null,

      timer: null
    };
  },
  methods: {
    init() {
      this.isLoading = false;
      this.searchKeyword = null;
      this.searchedUsers = [];
      this.searchedUsers = [
        {
          _id: 1,
          nickname: 'floob',
          name: 'Floob',
          profileImageUrl:
            'https://blob.floob.co.kr/user/5fd368c6d78489878ec67fb5?t=1616147847220'
        },

        {
          _id: 2,
          nickname: 'floob2',
          name: 'Floob2',
          profileImageUrl:
            'https://blob.floob.co.kr/user/5fd368c6d78489878ec67fb5?t=1616147847220'
        }
      ];
    },
    onClickClose() {
      // this.searchedUsers = [];
      this.isVisible = false;
    },
    onClickStory(userId) {
      this.storyDialog.show(userId);
    },
    updateSelectableFriends(keyword) {
      if (keyword && keyword.replace(/ /gi, '')) {
        api.user
          .getUsersByCondition({})
          .then(users => {
            this.searchedUsers = _.orderBy(users, ['nickname'], ['asc']);
          })
          .finally(() => {
            this.timer = null;
            this.isLoading = false;
          });
      } else {
        this.searchedUsers = [];
      }
    },
    onCreatedFriendship(createdFriendship) {
      this.friendships.push(createdFriendship);
    },
    getFriendships() {
      api.friendship.getFriendships().then(friendships => {
        this.friendships = friendships;
      });
    },
    onClickCancle(friendship) {
      api.friendship
        .deleteFriendship({
          _id: friendship._id
        })
        .then(deleted => {
          let idx = _.findIndex(this.friendships, fs => {
            return fs._id === deleted._id;
          });
          if (idx >= 0) {
            this.friendships.splice(idx, 1);
          }
        });
    },
    onClickAccept(friendship) {
      api.friendship
        .updateFriendship({
          _id: friendship._id,
          status: 'connected'
        })
        .then(updated => {
          let idx = _.findIndex(this.friendships, fs => {
            return fs._id === updated._id;
          });
          this.friendships[idx].status = updated.status;
        });
    },
    onClickReject(friendship) {
      api.friendship
        .deleteFriendship({
          _id: friendship._id
        })
        .then(deleted => {
          let idx = _.findIndex(this.friendships, fs => {
            return fs._id === deleted._id;
          });

          if (idx >= 0) {
            this.friendships.splice(idx, 1);
          }
        });
    },
    onClickDelete(friendship) {
      this.messageDialog.confirm(
        `다시 친구가 되길 원하는 경우 ${friendship.friend.nickname}님에게 친구 요청 후 수락을 기다려야합니다.`,
        '친구를 삭제하시겠습니까?',
        () => {
          api.friendship
            .deleteFriendship({
              _id: friendship._id
            })
            .then(deleted => {
              let idx = _.findIndex(this.friendships, f => {
                return f._id === deleted._id;
              });

              if (idx >= 0) {
                this.friendships.splice(idx, 1);
              }
            });
        }
      );
    },
    onClickAddFriend() {
      this.friendshipCreationDialog.show();
    }
  },
  watch: {
    isVisible(value) {
      this.init();
      if (value) {
        this.getFriendships();
      }
    },
    isLoggedIn(value) {
      if (value) {
        this.init();
        this.getFriendships();
      }
    },
    searchKeyword(keyword) {
      this.isLoading = true;
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.updateSelectableFriends(keyword);
        }, 500);
      } else {
        this.timer = setTimeout(() => {
          this.updateSelectableFriends(keyword);
        }, 500);
      }
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    },
    waitingFriendships() {
      return _.map(
        _.filter(this.friendships, fs => {
          return fs.status === 'waiting';
        }),
        fs => {
          if (fs.requester._id === this.$store.state.user._id) {
            return {
              ...fs,
              type: 'requested',
              friend: fs.acceptor
            };
          } else {
            return {
              ...fs,
              type: 'recieved',
              friend: fs.requester
            };
          }
        }
      );
    },
    connectedFriendships() {
      return _.map(
        _.filter(this.friendships, fs => {
          return fs.status === 'connected';
        }),
        fs => {
          if (fs.requester._id === this.$store.state.user._id) {
            return {
              ...fs,
              type: 'requested',
              friend: fs.acceptor
            };
          } else {
            return {
              ...fs,
              type: 'recieved',
              friend: fs.requester
            };
          }
        }
      );
    }
  },
  created() {
    this.init();

    //this.getFriends();

    this.$emit('update:dialog', {
      show: () => {
        this.searchFoodsKeyword = '';
        //this.searchedUsers = [];

        this.isVisible = true;
      },
      hide: () => {
        this.searchFoodsKeyword = '';
        //this.searchedUsers = [];
        this.isVisible = false;
      }
    });
  }
};
</script>

<style></style>
