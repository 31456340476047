<template>
  <v-app>
    <v-main>
      <router-view v-if="$route.name"></router-view>
      <floob-page-not-found v-else> </floob-page-not-found>
    </v-main>
  </v-app>
</template>
<script>
const VERSION = require('../package.json').version;

import moment from 'moment';
import firebase from 'firebase/app';
import 'firebase/analytics';

import api from '@/api';

import floobPageNotFound from '@/components/notFound.vue';

export default {
  name: 'App',
  components: {
    floobPageNotFound
  },
  data: () => ({}),
  methods: {
    setTheme(theme) {
      this.$store.dispatch('set', { key: 'theme', value: theme });
    }
  },
  watch: {
    theme(value) {
      if (value === 'dark') {
        this.$vuetify.theme = { dark: true };
      } else {
        this.$vuetify.theme = { dark: false };
      }
    }
  },
  computed: {
    theme() {
      return this.$store.state.theme;
    }
  },
  created() {
    // Google Analytics
    const firebaseConfig = {
      apiKey: 'AIzaSyCzDSDX2PdLZrzYq6RkbZChIIbPOD9IvuA',
      authDomain: 'floob-app.firebaseapp.com',
      projectId: 'floob-app',
      storageBucket: 'floob-app.appspot.com',
      messagingSenderId: '310945399113',
      appId: '1:310945399113:web:1dc341e1d14b051d4ada98',
      measurementId: 'G-G7V8VZFV2X'
    };
    // Initialize Firebase
    firebase.initializeApp(firebaseConfig);
    const analytics = firebase.analytics();
    window.Floob = {
      _analytics: analytics,
      _webview: {}
    };

    window.FloobWebView = {
      setState: kv => {
        this.$store.dispatch('set', kv);
      },
      setTheme: theme => {
        this.setTheme(theme);
      }
    };

    // hash redirectoin
    if (this.$route.hash) {
      location.href = this.$route.hash.replace('#', '');
    }

    // SSL
    if (
      process.env.NODE_ENV === 'production' &&
      document.location.protocol == 'http:' &&
      document.location.hostname != 'localhost'
    ) {
      document.location.href = document.location.href.replace(
        'http:',
        'https:'
      );
    }

    // Language
    moment.locale('ko');

    // Version
    this.$store.dispatch('set', { key: 'version', value: VERSION });

    // Theme
    if (this.$route.name === 'app' && this.$route.query.theme) {
      if (this.$route.query.theme === 'dark') {
        this.setTheme('dark');
      } else if (this.$route.query.theme === 'light') {
        this.setTheme('light');
      }
    }

    if (this.$cookies.get('theme')) {
      this.setTheme(this.$cookies.get('theme'));
    }

    api.auth.refreshAccessTokenBySession().finally(() => {
      api.user
        .getUserBySession()
        .then(user => {
          if (user && user.nickname) {
            this.$store.dispatch('set', {
              key: 'user',
              value: { ...user }
            });
          }
        })
        .catch(err => {
          if (err) {
            console.error(err);
          }
        });
    });
  },
  mounted() {
    // Kakako login
    let kakaoLoginScript = document.createElement('script');
    kakaoLoginScript.onload = () => {
      window.Kakao.init('cb6cd341c825417068321519b67f08f9');
    };
    kakaoLoginScript.async = true;
    kakaoLoginScript.src = '//developers.kakao.com/sdk/js/kakao.min.js';
    document.head.appendChild(kakaoLoginScript);

    // Kakako map api
    let kakaoMapScript = document.createElement('script');
    kakaoMapScript.onload = () => {
      var kakao = window.kakao;

      kakao.maps.load(() => {
        console.log('kakao map onloaded');
      });
    };

    kakaoMapScript.async = true;
    kakaoMapScript.src =
      '//dapi.kakao.com/v2/maps/sdk.js?autoload=false&appkey=cb6cd341c825417068321519b67f08f9&libraries=services,clusterer,drawing';
    document.head.appendChild(kakaoMapScript);

    // Facebook SDK
    if (!this.$cookies.get('webview')) {
      let facebookScript = document.createElement('script');
      facebookScript.onload = () => {};
      facebookScript.async = true;
      facebookScript.src = '/js/facebook-sdk.js';
      document.body.appendChild(facebookScript);
    }
  }
};
</script>

<style lang="scss">
* {
  text-transform: none !important;
}

html {
  font-size: 15px;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

h1 {
  font-size: 1.1em;
}

.v-divider {
  border-color: rgba(0, 0, 0, 0.05) !important;
}

.v-btn::before {
  background-color: transparent !important;
}

.v-list-item:before {
  background-color: transparent !important;
}

.v-list .v-list-item--active.v-list-item:before {
  background-color: currentColor !important;
}

.v-input input:focus,
.v-input input:active,
.v-input textarea:focus,
.v-input textarea:active textarea {
  color: white !important;
}

.v-input--is-focused .v-text-field__suffix {
  color: white !important;
}

.theme--dark.v-input input:focus,
.theme--dark.v-input input:active,
.theme--dark.v-input textarea:focus,
.theme--dark.v-input textarea:active textarea {
  color: rgba(0, 0, 0, 0.87) !important;
}

.theme--dark.v-input--is-focused .v-text-field__suffix {
  color: rgba(0, 0, 0, 0.87) !important;
}

.theme--dark.v-navigation-drawer {
  background-color: #1e1e1e;
}

.floob-tab-item {
  overflow-y: auto !important;
}

.floob-action-sheet.v-sheet.v-list {
  padding-bottom: 40px !important;
}

.floob-action-sheet.theme--dark.v-list {
  background-color: #2d2d2d !important;
}
.floob-action-sheet.theme--dark.v-card {
  background-color: #2d2d2d !important;
}

.floob-header .v-toolbar__content {
  border-bottom: 1px solid #e1e1e1 !important;
}

.floob-dialog.v-dialog {
  border-radius: 12px !important;
}

.floob-dialog-card.v-card {
  border-radius: 12px !important;
}

.floob-bottom-sheet {
  margin-left: 20px !important;
  margin-right: 20px !important;
  margin-bottom: 40px !important;
}

.floob-bottom-sheet .v-sheet {
  border-radius: 10px !important;
}

.floob-toolbar-title.v-card__title {
  padding-top: 4px !important;
  padding-bottom: 0px !important;
  padding-right: 16px !important;
  padding-left: 16px !important;
}

.floob-toolbar-title .v-toolbar__title {
  flex-grow: 1 !important;
  text-align: center;
  font-size: 1.1rem !important;
}

.floob-toolbar-button-left {
  padding-left: 0px !important;
}
.floob-toolbar-button-left i {
  margin-left: -16px !important;
}

.floob-toolbar-subtitle {
  text-align: center;
  font-size: 0.6rem !important;
  display: unset !important;
  width: 100%;
  margin-top: -24px;
  height: 30px !important;
}

.floob-disable-events {
  pointer-events: none;
}

.floob-snackbar .v-snack__wrapper {
  border-radius: 10px !important;
  background-color: #2d2d2d !important;
  min-width: unset !important;
}

.floob-meal-food-tag-img .v-responsive__content {
  position: relative !important;
}

.floob-meal-food-tag-img .v-btn {
  position: absolute !important;
}

.floob-meal .v-skeleton-loader {
  border-radius: 0px;
}

.floob-meal .v-skeleton-loader__image {
  height: 375px;
}

tbody {
  tr:hover {
    background-color: transparent !important;
  }
}

::-webkit-scrollbar {
  display: none;
}

.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  background: unset !important;
}

.v-expansion-panel-content__wrap {
  padding: 0px !important;
}
</style>
