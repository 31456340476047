<template>
  <v-dialog
    v-model="isVisible"
    fullscreen
    scrollable
    transition="slide-x-reverse-transition"
    persistent
  >
    <v-card tile flat class="px-4">
      <v-card-title>
        <v-toolbar-title><h1>개인정보 처리방침</h1></v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            color="grey"
            text
            @click="isVisible = false"
            :ripple="$store.state.isDisplayTouch"
            class="pr-0"
          >
            닫기
          </v-btn>
        </v-toolbar-items>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <floob-privacy></floob-privacy>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import floobPrivacy from '@/components/privacy.vue';

export default {
  props: {
    dialog: null
  },
  components: {
    floobPrivacy
  },
  data() {
    return {
      // controlls
      isVisible: false
    };
  },
  created() {
    this.$emit('update:dialog', {
      show: () => {
        this.isVisible = true;
      },
      hide: () => {
        this.isVisible = false;
      }
    });
  }
};
</script>

<style></style>
