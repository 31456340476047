<template>
  <section>
    <v-app-bar
      app
      fixed
      flat
      :color="$vuetify.theme.dark ? null : 'white'"
      v-bind:class="{ 'floob-header': isScrolled && !$vuetify.theme.dark }"
    >
      <v-toolbar-title>
        <h1 class="px-2" @click="scrollTop">
          피드
        </h1>
      </v-toolbar-title>
      <v-spacer> </v-spacer
      ><v-btn
        v-if="$store.state.isLoggedIn"
        text
        small
        class="px-0"
        :ripple="$store.state.isDisplayTouch"
        @click="followDialog.show()"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-app-bar>

    <v-sheet
      class="floob-tab-item"
      :height="frameHeight"
      v-scroll.self="onScroll"
      id="floobFeedFrameSheet"
    >
      <v-container
        fluid
        id="floob-feed-content"
        class="pa-0"
        v-bind:style="
          `background-color:${
            $vuetify.theme.dark
              ? `${meals.length > 1 ? 'rgb(39 39 39)' : ''}`
              : `${meals.length > 1 ? '#e9e9e9' : ''}`
          }; !important; min-height: ${frameHeight + 50}px;`
        "
      >
        <v-row v-if="isVisibleReload" no-gutters>
          <v-col>
            <v-card flat tile>
              <v-btn
                block
                text
                color="grey"
                :ripple="$store.state.isDisplayTouch"
                ><v-icon>mdi-refresh</v-icon></v-btn
              >
            </v-card>
          </v-col>
        </v-row>

        <!-- Recommended floobers -->
        <v-row no-gutters>
          <v-col>
            <floob-recommended-story-slide
              :module.sync="recommendedStorySlide"
              v-on:updated="onUpdatedRecommendedFollow"
            ></floob-recommended-story-slide>
          </v-col>
        </v-row>

        <template v-if="meals.length > 0">
          <!-- AD Banner -->
          <v-row v-if="false" no-gutters>
            <v-col>
              <v-card flat tile class="py-4 text-center">
                <a
                  target="_blank"
                  referrerpolicy="unsafe-url"
                  @click="openUrl('https://coupa.ng/b0q1m7')"
                  ><img
                    :src="
                      'https://ads-partners.coupang.com/banners/484971?subId=&traceId=V0-301-371ae01f4226dec2-I484971&w=320&h=50'
                    "
                    alt=""
                /></a>
              </v-card>
            </v-col>
          </v-row>

          <v-row
            no-gutters
            v-for="(meal, idx) in meals"
            :key="meal._id"
            class="mb-2"
          >
            <v-col cols="12" xs="12">
              <floob-meal-card
                :id="`floob-feed-meal-${meal._id}`"
                :meal="meal"
                v-on:focus="scrollToMeal"
              ></floob-meal-card>
            </v-col>

            <!-- Recommended floobers -->
            <v-col
              cols="12"
              xs="12"
              v-if="false && idx === recommendedStoryOrder - 1"
            >
              <floob-recommended-story-slide
                class="mt-2"
              ></floob-recommended-story-slide>
            </v-col>
          </v-row>

          <v-row
            v-if="!isNoMoreMeals"
            no-gutters
            style="margin-top: -8px; margin-bottom:8px;"
          >
            <v-col>
              <v-card flat tile>
                <v-btn
                  block
                  text
                  color="grey"
                  :loading="isLoading"
                  :ripple="$store.state.isDisplayTouch"
                  @click="getMorePrevMeals"
                  class="py-8"
                  >더보기</v-btn
                >
              </v-card>
            </v-col>
          </v-row>
        </template>

        <template v-else>
          <v-row v-if="isReloading" no-gutters>
            <v-col
              cols="12"
              class="py-0"
              v-for="idx in Array.from(Array(10).keys())"
              :key="idx"
              ><v-skeleton-loader
                type="list-item-avatar-two-line, image"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
          <template v-else>
            <v-row no-gutters class="pt-12">
              <v-col>
                <v-btn
                  block
                  text
                  color="grey"
                  :ripple="$store.state.isDisplayTouch"
                  class="my-12"
                  @click="followDialog.show()"
                  :loading="isLoading"
                  >팔로우를 추가해서 피드를 받아 보세요 👀</v-btn
                >
              </v-col>
            </v-row>
          </template>
        </template>
      </v-container>
    </v-sheet>
    <floob-follow-dialog
      :dialog.sync="followDialog"
      v-on:updated="onUpdatedFollow"
    ></floob-follow-dialog>
  </section>
</template>

<script>
import _ from 'lodash';
import $ from 'jquery';

import api from '@/api';
import wvc from '@/util/webViewController';

import floobMealCard from '@/components/mealCardV2.vue';
import floobFollowDialog from '@/components/followDialog.vue';
import floobRecommendedStorySlide from '@/components/recommendedStorySlide.vue';

export default {
  components: {
    floobMealCard,
    floobFollowDialog,
    floobRecommendedStorySlide
  },
  data() {
    return {
      // modules
      followDialog: null,
      storyDialog: null,
      recommendedStorySlide: null,

      // controlls
      searchCount: 10,
      isLoading: false,
      isReloading: false,
      isVisibleReload: false,
      isNoMoreMeals: false,
      isScrolled: false,
      window: {
        width: 0,
        height: 0
      },

      // datas
      meals: [],
      recommendedStoryOrder: 5
    };
  },
  methods: {
    init() {
      this.meals = [];
      this.isLoading = false;
      this.isReloading = false;
      this.isNoMoreMeals = false;
      this.isScrolled = false;
    },
    reload() {
      document
        .getElementById('floobFeedFrameSheet')
        .scrollTo({ top: 0, behavior: 'auto' });

      this.init();
      this.isReloading = true;
      this.getMorePrevMeals();
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    scroll(y) {
      if (!this.isLoading && y < -1) {
        if (y <= -30) {
          this.isVisibleReload = true;
        }

        if (y <= -80) {
          this.reload();
        }
      } else {
        this.isVisibleReload = false;
      }

      const loadingBufferSize = 0;

      if (y > 0) {
        this.isScrolled = true;
      } else {
        this.isScrolled = false;
      }

      if (
        !this.isLoading &&
        !this.isNoMoreMeals &&
        y + this.frameHeight >
          $('#floob-feed-content').height() - loadingBufferSize
      ) {
        this.getMorePrevMeals();
      }
    },
    onScroll(e) {
      this.scroll(e.target.scrollTop);
    },
    getMorePrevMeals() {
      if (!this.isLoading && !this.isNoMoreMeals) {
        this.isLoading = true;

        setTimeout(() => {
          api.meal
            .getMealsByFeed(
              this.searchCount,
              this.lastMealDate,
              this.lastMealId
            )
            .then(meals => {
              if (meals && meals.length > 0) {
                meals.forEach(meal => {
                  if (_.find(this.meals, { _id: meal._id })) {
                    return;
                  }
                  this.meals.push({
                    ...meal
                  });
                });
              } else {
                this.isNoMoreMeals = true;
              }
            })
            .finally(() => {
              this.isLoading = false;
              this.isReloading = false;
              this.isVisibleReload = false;
            });
        }, 1000);
      }
    },
    scrollTop() {
      document
        .getElementById('floobFeedFrameSheet')
        .scrollTo({ top: 0, behavior: 'smooth' });
    },
    scrollToMeal(meal) {
      const elementId = `floob-feed-meal-${meal._id}`;
      var element = document.getElementById(elementId);
      element.scrollIntoView({
        block: 'start',
        behavior: 'auto'
      });
    },
    openUrl(url) {
      wvc.openUrl(url);
    },
    onUpdatedFollow() {
      this.reload();
      this.recommendedStorySlide.reload();
    },
    onUpdatedRecommendedFollow() {
      this.reload();
    },
    onClickStory(floober) {
      this.storyDialog.show(floober._id);
    }
  },
  watch: {
    isLoggedIn(value) {
      if (value) {
        this.init();
        this.getMorePrevMeals();
      }
    },
    updatedMeal(value) {
      if (this.meals && this.meals.length > 0 && value) {
        let idx = _.findIndex(this.meals, meal => {
          return meal._id === value._id;
        });
        this.meals.splice(idx, 1, value);
      }
    },
    deletedMeal(value) {
      if (this.meals && this.meals.length > 0 && value) {
        let idx = _.findIndex(this.meals, meal => {
          return meal._id === value._id;
        });
        this.meals.splice(idx, 1);
      }
    }
  },
  computed: {
    updatedMeal() {
      return this.$store.state.syncUpdatedMeal;
    },
    deletedMeal() {
      return this.$store.state.syncDeletedMeal;
    },
    frameHeight() {
      let headerHeight = 56;
      let bottomNavHeight = 80;
      return this.window.height - headerHeight - bottomNavHeight;
    },
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    },
    firstMealId() {
      if (this.meals && this.meals.length > 0) {
        return this.meals[0]._id;
      } else {
        return null;
      }
    },
    lastMealId() {
      if (this.meals && this.meals.length > 0) {
        return this.meals[this.meals.length - 1]._id;
      } else {
        return null;
      }
    },
    lastMealDate() {
      if (this.meals && this.meals.length > 0) {
        return this.meals[this.meals.length - 1].mealedDate;
      } else {
        return null;
      }
    },
    filteredMeals() {
      if (this.meals && this.meals.length > 0) {
        return _.filter(this.meals, meal => {
          return meal && meal.owner && meal.owner.profileImageUrl;
        });
      } else {
        return [];
      }
    },
    isAdmin() {
      return (
        this.isLoggedIn &&
        this.$store.state.user.introduction &&
        this.$store.state.user.introduction.includes(`I am a floob`)
      );
    }
  },
  created() {
    this.$emit('update:module', {
      scrollTop: () => {
        this.scrollTop();
      }
    });
    window.addEventListener('resize', this.handleResize);
    this.handleResize();

    document.addEventListener('scroll', () => {
      this.scroll(window.scrollY);
    });

    this.getMorePrevMeals();
  }
};
</script>

<style></style>
