<template>
  <section>
    <floob-meal-update-dialog
      :dialog.sync="mealUpdateDialog"
      v-on:closed="onClosedMeal"
    >
    </floob-meal-update-dialog>
  </section>
</template>

<script>
import floobMealUpdateDialog from '@/components/mealUpdateDialog.vue';

import api from '@/api';

export default {
  components: { floobMealUpdateDialog },
  data() {
    return {
      // modules
      mealUpdateDialog: null,

      // datas
      meal: null
    };
  },
  methods: {
    onClosedMeal() {
      this.$router.push({ name: 'app' });
    }
  },
  created() {},
  mounted() {
    let foodId = this.$route.query.foodId;
    if (foodId) {
      let positionX = !isNaN(this.$route.query.x)
        ? parseFloat(this.$route.query.x)
        : 0.5;

      let positionY = !isNaN(this.$route.query.y)
        ? parseFloat(this.$route.query.y)
        : 0.5;

      api.food.getFood(foodId).then(food => {
        if (food) {
          this.mealUpdateDialog.show(
            { _id: this.$route.params.mealId },
            {
              focus: 'food',
              foodTags: [
                {
                  food: food,
                  position: {
                    x: positionX,
                    y: positionY
                  }
                }
              ]
            }
          );
        } else {
          this.mealUpdateDialog.show(
            { _id: this.$route.params.mealId },
            {
              focus: 'food'
            }
          );
        }
      });
    } else {
      this.mealUpdateDialog.show(
        { _id: this.$route.params.mealId },
        {
          focus: 'food'
        }
      );
    }
  }
};
</script>

<style></style>
