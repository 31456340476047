<template>
  <section>
    <floob-meal-dialog
      :dialog.sync="mealDialog"
      :closable="false"
    ></floob-meal-dialog>

    <floob-app-open-overlay
      v-if="$store.state.isMobileDevice"
      :closable="true"
    ></floob-app-open-overlay>
  </section>
</template>

<script>
import floobMealDialog from '@/components/mealDialog.vue';

import floobAppOpenOverlay from '@/components/appOpenOverlay.vue';

export default {
  components: { floobMealDialog, floobAppOpenOverlay },
  data() {
    return {
      mealDialog: null
    };
  },
  methods: {},
  created() {},
  mounted() {
    this.mealDialog.show({ _id: this.$route.params.mealId });
  }
};
</script>

<style></style>
