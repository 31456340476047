<template>
  <section>
    <floob-header :title="'리포트'"></floob-header>

    <floob-report :hideHeader="true"></floob-report>

    <div class="pa-12"></div>
    <floob-footer
      v-if="$vuetify.breakpoint.smAndUp"
      :fixed="true"
    ></floob-footer>
  </section>
</template>

<script>
import floobHeader from '@/components/header_v2.vue';
import floobFooter from '@/components/footer.vue';

import floobReport from '@/components/pages/report.vue';

export default {
  components: {
    floobHeader,
    floobFooter,
    floobReport
  },
  data() {
    return {};
  },
  methods: {},
  created() {},
  mounted() {}
};
</script>

<style></style>
