<template>
  <section>
    <floob-header :title="'설정'"></floob-header>
    <floob-setting :hideHeader="true"></floob-setting>
  </section>
</template>

<script>
import floobHeader from '@/components/header_v2.vue';
import floobSetting from '@/components/pages/setting.vue';

export default {
  components: {
    floobHeader,
    floobSetting
  }
};
</script>

<style></style>
