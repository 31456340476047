var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-dialog',{attrs:{"fullscreen":"","transition":"slide-x-reverse-transition","persistent":"","scrollable":""},model:{value:(_vm.isVisible),callback:function ($$v) {_vm.isVisible=$$v},expression:"isVisible"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-card-title',{staticClass:"floob-toolbar-title"},[_c('v-btn',{staticClass:"pl-0",attrs:{"text":"","x-large":"","ripple":_vm.$store.state.isDisplayTouch},on:{"click":_vm.onClickClose}},[_vm._v(" 취소 ")]),_c('v-toolbar-title',[_c('h1',[_vm._v("음식 태그")])]),_c('v-btn',{staticClass:"pr-0",attrs:{"text":"","x-large":"","ripple":_vm.$store.state.isDisplayTouch,"loading":_vm.isLoading},on:{"click":_vm.onClickSave}},[_vm._v("저장 ")])],1),_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-0",attrs:{"sm":"6","offset-sm":"3","md":"4","offset-md":"4","xl":"2","offset-xl":"5"}},[(_vm.meal && _vm.meal.files && _vm.meal.files[0].type === 'image')?_c('v-card',{attrs:{"flat":"","rounded":!_vm.$vuetify.breakpoint.xs ? 'xl' : false,"tile":_vm.$vuetify.breakpoint.xs ? true : false}},[_c('v-img',{ref:"mealFoodTagContentImg",staticClass:"floob-meal-food-tag-img",attrs:{"src":_vm.meal.files[0].data || _vm.meal.files[0].url,"aspect-ratio":"1"},on:{"click":_vm.onClickMealContent,"load":_vm.onLoadImage}},[(_vm.isLoadedContent)?_vm._l((_vm.tags),function(tag,idx){return _c('v-btn',{key:idx,staticClass:"px-2",style:({
                      color: 'white',
                      left: ((tag.position.x *
                        _vm.$refs.mealFoodTagContentImg.$el.clientWidth) + "px"),
                      top: ((tag.position.y *
                        _vm.$refs.mealFoodTagContentImg.$el.clientHeight) + "px")
                    }),attrs:{"depressed":"","small":"","color":"rgb(39 39 39 / 90%)","ripple":_vm.$store.state.isDisplayTouch},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onClickDeleteTag(idx)}}},[_vm._v(_vm._s(tag.food.name)+" ")])}):_vm._e()],2)],1):_vm._e()],1)],1)],1),_c('v-list',{staticClass:"pb-0"},[_c('v-list-item',{staticClass:"pb-2"},[_c('v-list-item-content',[_c('div',{staticStyle:{"color":"grey"}},[_c('div',{staticStyle:{"color":"rgb(171 171 171)"}},[_vm._v(" 식사 사진을 눌러서 음식을 태그하세요. "),_c('br'),_vm._v("태그를 다시 눌러서 삭제 할 수 있습니다. ")])])])],1),_c('v-divider'),(_vm.tags.length > 0)?[_c('v-subheader',{staticClass:"mt-2"},[_c('h1',[_vm._v("태그한 음식 정보")]),_c('v-tooltip',{staticClass:"mr-4",attrs:{"bottom":"","nudge-bottom":"","transition":"slide-y-reverse-transition","max-width":"94%","color":"black","open-on-click":true,"open-on-hover":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"grey"},on:{"click":function($event){_vm.isVisibleHelp = !_vm.isVisibleHelp}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-help-circle-outline")])],1)]}}],null,false,2671414794),model:{value:(_vm.isVisibleHelp),callback:function ($$v) {_vm.isVisibleHelp=$$v},expression:"isVisibleHelp"}},[(false)?_c('span',[_vm._v("음식별로 칼로리 정보가 표기 될 수 있습니다."),_c('br'),_vm._v(" 이 정보는 식품의약품안전처의 식품영양성분 데이터베이스를 참고하여 제공되며 이는 대략적인 수치이며 실제 섭취량과 차이가 있을 수 있습니다.")]):_vm._e(),_c('span',[_vm._v("음식별로 섭취한 열량을 입력하면 리포트에서 일별 합산 열량을 확인 할 수 있습니다.")])])],1)]:_vm._e()],2),_c('v-card-text',{staticClass:"pa-0"},[_c('v-list',{staticClass:"py-0"},_vm._l((_vm.tags),function(tag,idx){return _c('v-list-item',{key:idx},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(tag.food.name)+" ")])],1),_c('v-list-item-action',[_c('v-text-field',{staticClass:"floob-input-text-align-right",staticStyle:{"max-width":"120px"},attrs:{"solo-inverted":"","flat":"","autofocus":false,"hide-details":"","dense":"","suffix":"kcal","type":"number"},model:{value:(tag.calorie),callback:function ($$v) {_vm.$set(tag, "calorie", $$v)},expression:"tag.calorie"}})],1)],1)}),1)],1),(_vm.isAdmin)?_c('v-card-actions',[_c('v-btn',{attrs:{"color":"grey","text":""},on:{"click":_vm.onClickSendAutoFoodTagMessage}},[_vm._v(" 자동 음식 태그 완료 ")])],1):_vm._e()],1)],1),_c('floob-food-selection-dialog',{attrs:{"dialog":_vm.foodSelectionDialog},on:{"update:dialog":function($event){_vm.foodSelectionDialog=$event},"selected":_vm.onSelectedFood}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }