<template>
  <v-dialog
    v-model="isVisible"
    fullscreen
    hide-overlay
    transition="slide-x-reverse-transition"
    persistent
  >
    <v-card>
      <div class="pt-6 pl-6">
        <v-avatar style="margin-bottom:2px" size="18" rounded
          ><v-img src="/favicon.ico"></v-img></v-avatar
        ><span class="pl-2"><b>Floob</b></span>
      </div>
      <v-card-title class="pt-12">
        <br />
        <h1>시작하기</h1>
      </v-card-title>
      <v-card-text>
        <br />
        <div>
          <v-chip class="px-1 mx-1" label small color="grey lighten-2"
            ><b style="color:#da7171;">Floob</b></v-chip
          >에 오신 것을 환영합니다! 👋
        </div>
        <br />
        <div>
          매일 먹는 식사 한끼를 그냥 지나치지 말고
          <v-chip class="px-1 mx-1" label small color="grey lighten-2"
            ><b style="color:#da7171;">Floob</b></v-chip
          >에 기록해보세요
        </div>
        <div>
          가볍게 식사 기록을 남기는 것만으로도 나만의 멋진 <b>Food Story</b>를
          만들 수 있습니다
        </div>

        <br />
        <br />
        <div>👇 지금 바로 로그인해서 식사기록을 만들어보세요</div>
      </v-card-text>
      <floob-login></floob-login>
    </v-card>
  </v-dialog>
</template>

<script>
import emoji from '@/util/emoji';

import floobLogin from '@/components/login.vue';

export default {
  components: {
    floobLogin
  },
  data() {
    return {
      isVisible: true,
      randomedEmoji: ''
    };
  },
  created() {
    this.randomedEmoji = emoji.getRandomEmojiSymbol();
  }
};
</script>

<style></style>
