<template>
  <section>
    <v-skeleton-loader v-if="isLoading" type="list-item-avatar-two-line">
    </v-skeleton-loader>
    <template v-else>
      <v-list-item
        v-if="meal"
        :ripple="$store.state.isDisplayTouch"
        @click="onClickMeal"
        class="pr-1"
      >
        <v-list-item-avatar rounded>
          <template v-if="meal.files.length > 0">
            <v-img
              v-if="meal.files[0].type === 'image'"
              :src="meal.files[0].url"
            ></v-img>

            <v-img
              v-else-if="meal.files[0].type === 'emoji'"
              :src="meal.files[0].url"
              contain
              :height="32"
            ></v-img>
          </template>
          <v-icon v-else size="58">mdi-image</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-subtitle>
            {{ convertMealTypeString(meal.mealedDate)
            }}<v-chip
              v-if="meal.isStory"
              label
              x-small
              color="amber lighten-1"
              dark
              class="px-1 mb-1 ml-1"
            >
              <b>Story</b></v-chip
            >
          </v-list-item-subtitle>
          <v-list-item-title>{{ meal.title }} </v-list-item-title>
        </v-list-item-content>

        <v-list-item-action>
          <v-btn
            small
            icon
            color="grey"
            :ripple="$store.state.isDisplayTouch"
            @click.stop.prevent="onClickMore"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </template>

    <floob-meal-dialog
      :dialog.sync="mealDialog"
      v-on:updated="onUpdatedMeal"
      v-on:deleted="onDeletedMeal"
    >
    </floob-meal-dialog>
    <floob-meal-update-dialog
      :dialog.sync="mealUpdateDialog"
      v-on:updated="onUpdatedMeal"
    >
    </floob-meal-update-dialog>

    <v-bottom-sheet v-model="isVisibleMore">
      <v-list class="pa-2 floob-action-sheet">
        <v-list-item>
          <v-list-item-avatar rounded size="32">
            <template v-if="meal.files.length > 0">
              <v-img
                v-if="meal.files[0].type === 'image'"
                :src="meal.files[0].url"
              ></v-img>
              <v-img
                v-if="meal.files[0].type === 'emoji'"
                :width="24"
                :height="24"
                contain
                :src="meal.files[0].url"
              ></v-img>
            </template>
            <v-icon v-else size="36">mdi-image</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-if="meal.title">
              {{ meal.title }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{
                isVisibleDetailMealedDate
                  ? convertLongMealDateString(meal.mealedDate)
                  : `${convertShortenMealDateString(meal.mealedDate)}`
              }}
              <v-btn
                v-if="isVisibleDetailMealedDate"
                text
                x-small
                :ripple="$store.state.isDisplayTouch"
                color="grey"
                class="px-0"
                @click="isVisibleDetailMealedDate = false"
                >간단히</v-btn
              >
              <v-btn
                v-else
                text
                x-small
                :ripple="$store.state.isDisplayTouch"
                color="grey"
                class="px-0"
                @click="isVisibleDetailMealedDate = true"
                >자세히</v-btn
              >
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :ripple="$store.state.isDisplayTouch" class="pr-2">
          <v-list-item-content>
            <v-list-item-title
              >스토리 포함<v-btn
                text
                x-small
                :ripple="$store.state.isDisplayTouch"
                color="grey"
                @click="onClickStoryPreivew"
                >미리보기</v-btn
              ></v-list-item-title
            >
          </v-list-item-content>
          <v-list-item-action>
            <v-switch
              v-model="meal.isStory"
              color="success"
              inset
              :ripple="$store.state.isDisplayTouch"
              :disabled="isLoadingUpdateStory"
            >
            </v-switch>
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item
          :ripple="$store.state.isDisplayTouch"
          @click="onClickShare"
        >
          <v-list-item-content>
            <v-list-item-title>공유하기</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          :ripple="$store.state.isDisplayTouch"
          @click="onClickUpdate"
        >
          <v-list-item-content>
            <v-list-item-title>수정하기</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="isAdmin || checkAvailableVersion('1.9.0')"
          :ripple="$store.state.isDisplayTouch"
          @click="onClickDuplicate"
        >
          <v-list-item-content>
            <v-list-item-title>복사하기</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          :ripple="$store.state.isDisplayTouch"
          @click="onClickDelete"
        >
          <v-list-item-content>
            <v-list-item-title class="red--text text--darken-2"
              >삭제</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <floob-message-dialog :dialog.sync="messageDialog"></floob-message-dialog>
    </v-bottom-sheet>
  </section>
</template>

<script>
import moment from 'moment';

import api from '@/api';
import converter from '@/util/converter';

import copy from 'clipboard-copy';

import floobMealDialog from '@/components/mealDialog.vue';
import floobMealUpdateDialog from '@/components/mealUpdateDialog.vue';
import floobMessageDialog from '@/components/messageDialog.vue';

import semver from 'semver';

export default {
  components: {
    floobMealDialog,
    floobMealUpdateDialog,
    floobMessageDialog
  },
  props: {
    meal: Object
  },
  data() {
    return {
      // modules
      mealDialog: null,
      mealUpdateDialog: null,
      messageDialog: null,

      // controlls
      isVisibleMore: false,
      isLoading: false,
      isLoadingUpdateStory: false,
      isVisibleDetailMealedDate: false
    };
  },
  methods: {
    onClickMeal() {
      this.onClickUpdate();
    },
    onClickStoryPreivew() {
      this.mealDialog.show(this.meal);
    },
    onClickMore() {
      this.isLoadingUpdateStory = false;
      this.isVisibleMore = true;
    },
    onClickShare() {
      let shareLink = `https://link.floob.co.kr/meal/${this.meal._id}`;
      if (
        document.location.protocol === 'https:' &&
        navigator &&
        typeof navigator.share === 'function'
      ) {
        navigator.share({
          text: `Floob: 식사기록 인 라이프\n@${
            this.meal.owner.nickname
          }\n${this.convertShortenMealDateString(this.meal.mealedDate)}\n${this
            .meal.title || ''}`,
          url: shareLink
        });
      } else {
        copy(shareLink).then(() => {
          this.messageDialog.alert('클립보드에 링크가 복사 되었습니다.');
        });
      }
    },
    onClickUpdate() {
      this.mealUpdateDialog.show(this.meal);
      setTimeout(() => {
        this.isVisibleMore = false;
      }, 500);
    },
    onClickDuplicate() {
      this.isVisibleMore = false;
      setTimeout(() => {
        this.messageDialog.confirm(
          `선택하신 게시물 내용으로 새로운 식사를 등록합니다.\n식사 일시 및 평가, 스토리 포함 여부는 복제 되지 않습니다.`,
          '식사 복사',
          () => {
            this.duplicateMeal();
          }
        );
      }, 500);
    },
    onClickDelete() {
      this.isVisibleMore = false;
      setTimeout(() => {
        this.messageDialog.confirm(
          '선택하신 게시물을 삭제하시겠습니까?',
          '식사 삭제',
          () => {
            this.deleteMeal();
          }
        );
      }, 500);
    },
    duplicateMeal() {
      api.meal.duplicateMeal(this.meal._id).then(duplicatedMeal => {
        this.$emit('duplicated', duplicatedMeal);
      });
    },
    deleteMeal() {
      api.meal.deleteMeal(this.meal).then(deletedMeal => {
        this.$emit('deleted', deletedMeal);
        this.$store.dispatch('set', {
          key: 'syncDeletedMeal',
          value: deletedMeal
        });
      });
    },
    onDeletedMeal(deletedMeal) {
      this.$emit('deleted', deletedMeal);
      this.$store.dispatch('set', {
        key: 'syncDeletedMeal',
        value: deletedMeal
      });
      this.mealDialog.hide();
    },
    onUpdatedMeal(updatedMeal) {
      //this.meal = updatedMeal;
      this.$emit('updated', updatedMeal);
      this.$store.dispatch('set', {
        key: 'syncUpdatedMeal',
        value: updatedMeal
      });
    },
    convertDetailedMealedDateString(mealedDate) {
      return moment(mealedDate).format('LLLL');
    },
    convertShortenMealDateString(mealedDate) {
      return converter.convertShortenMealDateString(mealedDate);
    },
    convertLongMealDateString(mealedDate) {
      return converter.convertLongMealDateString(mealedDate);
    },
    convertMealTypeString(mealedDate) {
      let mealType = converter.convertMealType(mealedDate);
      //let hourMinute = moment(mealedDate).format('HH시 mm분');

      if (mealType === 'breakfast') {
        return `아침 식사`;
      } else if (mealType === 'lunch') {
        return `점심 식사`;
      } else if (mealType === 'dinner') {
        return `저녁 식사`;
      } else {
        return `야식`;
      }
    },
    checkAvailableVersion(requiredVersion) {
      return semver.gte(this.$store.state.version, requiredVersion);
    }
  },
  computed: {
    isAdmin() {
      return (
        this.$store.state.isLoggedIn &&
        this.$store.state.user.introduction &&
        this.$store.state.user.introduction.includes(`I am a floob`)
      );
    },
    isStory() {
      return this.meal ? this.meal.isStory : false;
    }
  },
  watch: {
    isStory(value) {
      if (this.meal && this.meal._id) {
        this.isLoadingUpdateStory = true;
        setTimeout(() => {
          api.meal
            .updateMeal({
              _id: this.meal._id,
              isStory: value
            })
            .then(updatedMeal => {
              this.$emit('updated', updatedMeal);
            })
            .finally(() => {
              this.isLoadingUpdateStory = false;
            });
        }, 500);
      }
    }
  },
  created() {
    if (this.meal && this.meal.isRequiredFocus) {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
      }, 2000);
    }
  }
};
</script>

<style></style>
