<template>
  <section>
    <v-card tile flat>
      <v-card-title class="floob-toolbar-title">
        <v-btn
          text
          x-large
          :ripple="$store.state.isDisplayTouch"
          class="floob-toolbar-button-left"
        >
          Floob</v-btn
        >

        <v-toolbar-title></v-toolbar-title>
        <v-btn
          text
          x-large
          :ripple="$store.state.isDisplayTouch"
          class="pr-0"
          @click="onClickCancel"
        >
          취소
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-row align="center" style="height: 500px;">
            <v-col v-if="!isFailed" class="text-center">
              <h1>
                <img
                  style="width:19px; margin-bottom: -3px; margin-right:3px;"
                  src="https://floob.blob.core.windows.net/image/instagram-icon.ico"
                />Instagram authentication
              </h1>
              <v-btn text disabled :loading="isLoading"></v-btn>
            </v-col>
            <v-col v-if="isFailed" class="text-center">
              <h1>인증 실패</h1>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </section>
</template>

<script>
import api from '@/api';
import wvc from '@/util/webViewController';

export default {
  data() {
    return {
      isLoading: false,
      isFailed: false
    };
  },
  methods: {
    init() {
      this.isLoading = false;
      this.isFailed = false;
    },
    onClickCancel() {
      window.close();
    }
  },
  created() {
    this.init();
    const site = this.$route.params.site;
    const code = this.$route.query.code;

    if (site === 'instagram') {
      this.isLoading = true;
      api.auth
        .getAccessTokenByInstagram(code)
        .then(accessToken => {
          if (accessToken) {
            let user = {
              ...this.$store.state.user
            };
            user.auth.instagram = {
              isAuthenticated: true,
              accessToken: accessToken
            };
            this.$store.dispatch('set', { key: 'user', value: user });

            this.isLoading = false;
            setTimeout(() => {
              if (this.$cookies.get('webview')) {
                wvc.onSuccessInstagramLogin();
              } else {
                window.close();
              }
            }, 2000);
          }
        })
        .catch(err => {
          console.error('Instagram getAccessToken error occuered.', err);

          this.isLoading = false;
          this.isFailed = true;
        });
    }
  }
};
</script>

<style></style>
