<template>
  <section>
    <v-dialog
      v-model="isVisible"
      fullscreen
      transition="slide-x-reverse-transition"
      persistent
      scrollable
    >
      <v-card tile class="pa-0">
        <v-card-title class="floob-toolbar-title">
          <v-btn
            text
            x-large
            @click="onClickClose"
            :ripple="$store.state.isDisplayTouch"
            class="floob-toolbar-button-left"
          >
            <v-icon>mdi-chevron-left</v-icon></v-btn
          >

          <v-toolbar-title
            class="floob-toolbar-title"
            @click="scrollTop"
            style="width:33% !important;"
          >
            <v-avatar
              v-if="user && user.profileImageUrl && isShortProfile"
              size="24"
              ><v-img :src="user.profileImageUrl"></v-img>
            </v-avatar>
            {{ user ? user.nickname : '' }}</v-toolbar-title
          >

          <template v-if="isFollowable">
            <v-btn
              v-if="isFollowing"
              text
              x-large
              :ripple="$store.state.isDisplayTouch"
              class="pr-0"
              color="grey"
              @click="onClickUnfollow"
              :loading="isLoadingFollow"
            >
              팔로잉
            </v-btn>
            <v-btn
              v-else
              text
              x-large
              :ripple="$store.state.isDisplayTouch"
              class="pr-0"
              :color="isLoadingFollow ? 'grey' : 'primary'"
              @click="onClickFollow"
              :loading="isLoadingFollow"
            >
              팔로우
            </v-btn>
          </template>

          <v-btn
            v-else
            text
            x-large
            :ripple="$store.state.isDisplayTouch"
            color="grey"
            @click="isVisibleMore = true"
            class="pr-0"
            disabled
          >
          </v-btn>
        </v-card-title>

        <v-divider></v-divider>
        <v-card-text
          v-if="user"
          v-scroll.self="onScroll"
          class="pa-0"
          id="floobStoryFrameSheet"
        >
          <v-list class="px-2">
            <v-list-item class="pr-1">
              <v-list-item-avatar size="64"
                ><v-img
                  v-if="user.profileImageUrl"
                  :src="user.profileImageUrl"
                ></v-img>

                <v-icon color="grey lighten-1" v-else size="64"
                  >mdi-account-circle</v-icon
                ></v-list-item-avatar
              >

              <v-list-item-content>
                <v-container>
                  <v-row no-gutters>
                    <v-col class="text-center">
                      <b
                        ><div style="font-size:1.3em;">
                          {{ storyCount ? storyCount : '-' }}
                        </div>
                        <div class="mt-1">식사</div></b
                      >
                    </v-col>
                    <v-col class="text-center">
                      <b
                        ><div style="font-size:1.3em;">
                          {{ storeCount ? storeCount : '-' }}
                        </div>
                        <div class="mt-1">식당</div></b
                      >
                    </v-col>
                    <v-col v-if="isFloober" class="text-center">
                      <b
                        ><div style="font-size:1.3em;">
                          {{
                            followCount &&
                            !isNaN(followCount) &&
                            followCount > 0
                              ? followCount
                              : '-'
                          }}
                        </div>
                        <div class="mt-1">팔로워</div></b
                      >
                    </v-col>
                    <v-col v-if="false" class="text-center">
                      <b
                        ><div style="font-size:1.3em;">
                          {{ recipePostsCount ? recipePostsCount : '-' }}
                        </div>
                        <div class="mt-1">좋아요</div></b
                      >
                    </v-col>
                  </v-row>
                </v-container>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  icon
                  color="grey"
                  :ripple="$store.state.isDisplayTouch"
                  @click="isVisibleMore = true"
                >
                  <v-icon>
                    mdi-dots-vertical
                  </v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>

            <v-list-item class="px-6">
              <v-list-item-content>
                <v-list-item-title v-if="user.name"
                  ><b>{{ user.name }}</b>
                  <v-chip
                    v-if="user.gender === 'bot'"
                    label
                    x-small
                    color="amber"
                    dark
                    class="px-1 mb-1 ml-2"
                  >
                    Bot</v-chip
                  >
                </v-list-item-title>
                <div class="pt-2">
                  <v-icon color="grey lighten-2">mdi-format-quote-open</v-icon>
                  <div v-html="introductionHtml"></div>
                </div>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="introductionLines.length > 3">
              <v-btn
                v-if="isShowIntroductionMore"
                text
                small
                :ripple="$store.state.isDisplayTouch"
                @click="isShowIntroductionMore = false"
                color="grey"
                class="px-0"
                >간단히 보기</v-btn
              >
              <v-btn
                v-else
                text
                small
                :ripple="$store.state.isDisplayTouch"
                @click="isShowIntroductionMore = true"
                color="grey"
                class="px-0"
                >... 자세히 보기</v-btn
              >
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-sheet class="my-2">
            <v-container
              fluid
              class="pa-0"
              v-bind:style="
                `background-color:${
                  $vuetify.theme.dark ? 'rgb(39 39 39)' : '#e9e9e9'
                } !important;`
              "
            >
              <template v-if="meals && meals.length > 0">
                <v-row
                  v-for="meal in meals"
                  :key="meal._id"
                  no-gutters
                  class="mb-2"
                >
                  <v-col>
                    <floob-meal-card :meal="meal"></floob-meal-card>
                  </v-col>
                </v-row>

                <v-row
                  v-if="isNoMoreMeals"
                  no-gutters
                  style="margin-top: -8px; margin-bottom:8px;"
                >
                  <v-col>
                    <v-card flat tile>
                      <v-btn
                        block
                        text
                        color="grey"
                        :ripple="$store.state.isDisplayTouch"
                        >더이상 게시물이 없습니다😞</v-btn
                      >
                    </v-card>
                  </v-col>
                </v-row>
                <v-row
                  v-else
                  no-gutters
                  style="margin-top: -8px; margin-bottom:8px;"
                >
                  <v-col>
                    <v-card flat tile>
                      <v-btn
                        block
                        text
                        color="grey"
                        :loading="isLoading"
                        :ripple="$store.state.isDisplayTouch"
                        @click="getMorePrevMeals"
                        >더보기</v-btn
                      >
                    </v-card>
                  </v-col>
                </v-row>
              </template>

              <template v-else>
                <v-row no-gutters>
                  <v-col>
                    <v-card flat tile>
                      <v-btn
                        block
                        text
                        color="grey"
                        :ripple="$store.state.isDisplayTouch"
                        :loading="isLoading"
                        >스토리 게시물이 없습니다😞</v-btn
                      >
                    </v-card>
                  </v-col>
                </v-row>
              </template>
            </v-container>
          </v-sheet>
        </v-card-text>
        <v-card-actions v-show="false"> </v-card-actions>
      </v-card>
    </v-dialog>

    <floob-message-dialog :dialog.sync="messageDialog"></floob-message-dialog>

    <floob-message-snackbar
      :dialog.sync="messageSnackbar"
    ></floob-message-snackbar>
    <v-bottom-sheet v-model="isVisibleMore">
      <v-list v-if="user" class="pa-2 floob-action-sheet">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <h1>{{ `${user.nickname}님의 스토리` }}</h1>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="user">
          <v-list-item-avatar size="38" v-if="user.profileImageUrl">
            <v-img :src="user.profileImageUrl"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-if="user.name">
              <b>{{ user.name }}</b
              >{{ ` • @${user.nickname}` }}
            </v-list-item-title>
            <v-list-item-title v-else>
              {{ `@${user.nickname}` }}
            </v-list-item-title>
            <v-list-item-subtitle v-if="user.introduction">
              {{ user.introduction }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :ripple="$store.state.isDisplayTouch"
          @click="onClickShare"
        >
          <v-list-item-content>
            <v-list-item-title>공유하기</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <template v-if="isFollowable">
          <v-list-item
            v-if="isFollowing"
            :ripple="$store.state.isDisplayTouch"
            @click="onClickUnfollow"
          >
            <v-list-item-action v-if="isLoadingFollow">
              <v-btn
                text
                x-small
                :ripple="$store.state.isDisplayTouch"
                color="grey"
                :loading="isLoadingFollow"
              >
                팔로잉
              </v-btn>
            </v-list-item-action>
            <v-list-item-content v-else>
              <v-list-item-title>팔로우 취소</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-else
            :ripple="$store.state.isDisplayTouch"
            @click="onClickFollow"
          >
            <v-list-item-action v-if="isLoadingFollow">
              <v-btn
                text
                x-small
                :ripple="$store.state.isDisplayTouch"
                color="grey"
                :loading="isLoadingFollow"
              >
                팔로잉
              </v-btn>
            </v-list-item-action>
            <v-list-item-content v-else>
              <v-list-item-title>팔로우</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-bottom-sheet>
    <floob-login-dialog
      :dialog.sync="loginDialog"
      :comment="'로그인하면 팔로우를 할 수 있습니다 👀'"
    ></floob-login-dialog>
  </section>
</template>

<script>
import _ from 'lodash';
import copy from 'clipboard-copy';

import api from '@/api';
import converter from '@/util/converter';

import floobMessageDialog from '@/components/messageDialog.vue';
import floobMessageSnackbar from '@/components/messageSnackbar.vue';
import floobLoginDialog from '@/components/loginDialog.vue';

export default {
  components: {
    floobMessageDialog,
    floobMessageSnackbar,
    floobLoginDialog
  },
  data() {
    return {
      isVisible: false,
      isLoading: false,
      isLoadingUser: false,
      isLoadingFollow: false,
      isShowIntroductionMore: false,
      isShortProfile: false,
      isUpdated: false,
      searchCount: 10,
      waitAppendTimeMS: 1000,
      isNoMoreMeals: false,
      isVisibleMore: false,

      user: null,
      meals: [],
      mealCount: null,
      storyCount: null,
      storeCount: null,
      followCount: null,
      storePostsCount: null,
      recipePostsCount: null,

      messageDialog: null,
      messageSnackbar: null,
      loginDialog: null
    };
  },
  methods: {
    init() {
      this.isLoading = false;
      this.isLoadingUser = false;
      this.isLoadingFollow = false;
      this.isShowIntroductionMore = false;
      this.isShortProfile = false;
      this.isUpdated = false;
      this.searchCount = 10;
      this.waitAppendTimeMS = 1000;
      this.isNoMoreMeals = false;
      this.isVisibleMore = false;
      this.user = null;
      this.meals = [];
      this.mealCount = null;
      this.storyCount = null;
      this.storeCount = null;
      this.followCount = null;
    },
    onClickClose() {
      if (this.isUpdated) {
        this.$emit('updated');
      }
      this.$emit('closed');
      this.isVisible = false;
    },
    scroll(y) {
      if (y >= 80) {
        this.isShortProfile = true;
      } else {
        this.isShortProfile = false;
      }
    },
    scrollTop() {
      document
        .getElementById('floobStoryFrameSheet')
        .scrollTo({ top: 0, behavior: 'smooth' });
    },
    onScroll(e) {
      this.scroll(e.target.scrollTop);
    },
    getMealsStatByStory() {
      api.meal.getMealStatByStory(this.user._id).then(mealStat => {
        console.log('getMealStatByStory mealStat', mealStat);

        if (mealStat) {
          if (!isNaN(mealStat.mealCount)) {
            this.mealCount = mealStat.mealCount;
          }

          if (!isNaN(mealStat.storyCount)) {
            this.storyCount = mealStat.storyCount;
          }

          if (!isNaN(mealStat.storeCount)) {
            this.storeCount = mealStat.storeCount;
          }

          if (!isNaN(mealStat.followCount)) {
            this.followCount =
              mealStat.followCount +
              (this.user.randomFollowCount ? this.user.randomFollowCount : 0);
          }
        }
      });
    },
    getMorePrevMeals() {
      if (!this.isLoading && !this.isNoMoreMeals) {
        this.isLoading = true;

        setTimeout(() => {
          api.meal
            .getMealsByStory(
              this.user._id,
              this.searchCount,
              this.lastMealDate,
              this.lastMealId
            )
            .then(meals => {
              console.log('story getMorePrevMeals', meals);
              if (meals && meals.length > 0) {
                meals.forEach(meal => {
                  if (_.find(this.meals, { _id: meal._id })) {
                    return;
                  }
                  this.meals.push({
                    ...meal
                  });
                });
              } else {
                this.isNoMoreMeals = true;
              }
            })
            .finally(() => {
              this.isLoading = false;
            });
        }, 1000);
      }
    },
    onClickUnfollow() {
      if (this.$store.state.isLoggedIn) {
        this.isLoadingFollow = true;

        let followUserIds = [...this.$store.state.user.followUserIds];
        followUserIds = _.reject(followUserIds, followedUserId => {
          return followedUserId === this.user._id;
        });

        setTimeout(() => {
          api.user
            .updateUser({
              _id: this.$store.state.user._id,
              followUserIds: followUserIds
            })
            .then(updated => {
              if (updated) {
                this.$store.dispatch('set', {
                  key: 'user',
                  value: {
                    ...this.$store.state.user,
                    followUserIds: followUserIds
                  }
                });
                this.followCount--;

                this.$emit('updated', true);
              }
            })
            .finally(() => {
              this.isLoadingFollow = false;
            });
        }, 1000);
      } else {
        this.loginDialog.show();
      }
    },
    onClickFollow() {
      if (this.$store.state.isLoggedIn) {
        this.isLoadingFollow = true;

        let followUserIds = [...this.$store.state.user.followUserIds];
        followUserIds.push(this.user._id);

        setTimeout(() => {
          api.user
            .updateUser({
              _id: this.$store.state.user._id,
              followUserIds: followUserIds
            })
            .then(updated => {
              if (updated) {
                this.$store.dispatch('set', {
                  key: 'user',
                  value: {
                    ...this.$store.state.user,
                    followUserIds: followUserIds
                  }
                });
                this.followCount++;

                this.$emit('updated', true);
              }
            })
            .finally(() => {
              this.isLoadingFollow = false;
            });
        }, 1000);
      } else {
        this.loginDialog.show();
      }
    },
    onClickShare() {
      let shareLink = `https://link.floob.co.kr/story/${this.user._id}`;
      if (
        document.location.protocol === 'https:' &&
        navigator &&
        typeof navigator.share === 'function'
      ) {
        navigator.share({
          text: `Floob: 식사기록 인 라이프\n${
            this.user.name
              ? `@${this.user.nickname}\n${this.user.name}`
              : `@${this.user.nickname}`
          }님의 스토리`,
          url: shareLink
        });
      } else {
        copy(shareLink).then(() => {
          this.messageDialog.alert('클립보드에 링크가 복사 되었습니다.');
        });
      }
    }
  },
  watch: {
    updatedMeal(value) {
      if (this.meals && this.meals.length > 0 && value) {
        let idx = _.findIndex(this.meals, meal => {
          return meal._id === value._id;
        });
        this.meals.splice(idx, 1, value);
      }
    }
  },
  computed: {
    isFloober() {
      return (
        this.user &&
        this.user.auth &&
        this.user.auth.floober &&
        this.user.auth.floober.isAuthenticated
      );
    },
    isFollowable() {
      return (
        this.isFloober &&
        (!this.$store.state.user || this.$store.state.user._id != this.user._id)
      );
    },
    isFollowing() {
      if (
        this.$store.state.user &&
        Array.isArray(this.$store.state.user.followUserIds) &&
        this.user &&
        this.$store.state.user.followUserIds.includes(this.user._id)
      ) {
        return true;
      } else {
        return false;
      }
    },
    updatedMeal() {
      return this.$store.state.updatedMeal;
    },
    lastMealId() {
      if (this.meals && this.meals.length > 0) {
        return this.meals[this.meals.length - 1]._id;
      } else {
        return null;
      }
    },
    lastMealDate() {
      if (this.meals && this.meals.length > 0) {
        return this.meals[this.meals.length - 1].mealedDate;
      } else {
        return null;
      }
    },
    introductionLines() {
      return this.user.introduction ? this.user.introduction.split('\n') : [];
    },
    introductionHtml() {
      if (this.user.introduction) {
        if (this.isShowIntroductionMore) {
          return converter.convertTextToHtml(this.user.introduction);
        } else {
          return converter.convertTextToHtml(
            this.introductionLines.slice(0, 3).join('\n')
          );
        }
      } else {
        return '';
      }
    }
  },
  beforeCreate() {
    this.$options.components.floobMealCard = require('@/components/mealCardV2.vue').default;
  },
  created() {
    this.$emit('update:dialog', {
      show: userId => {
        this.init();
        this.isLoadingUser = true;
        api.user
          .getUser(userId)
          .then(user => {
            if (user) {
              this.user = user;
              console.log('story getUser', user);

              this.getMealsStatByStory();
              this.getMorePrevMeals();
            } else {
              console.log('No user data.');
              alert('존재하지 않는 사용자 스토리입니다.');
              this.isVisible = false;
            }
          })
          .finally(() => {
            this.isLoadingUser = false;
          });
        this.isVisible = true;
      }
    });
  }
};
</script>

<style></style>
