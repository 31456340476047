<template>
  <div>
    <v-snackbar
      v-if="closable"
      class="floob-snackbar"
      v-model="isVisibleOpenApp"
      elevation="24"
      :timeout="-1"
      top
    >
      <v-avatar size="16" rounded><v-img src="/favicon.ico"></v-img></v-avatar>
      <span class="pl-2"
        ><b>Floob</b><br />앱에서 다양한 기능을 만나보세요</span
      >
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="onClickOpenApp">앱으로 보기</v-btn>
        <v-btn icon small
          ><v-icon x-small v-bind="attrs" @click="isVisibleOpenApp = false"
            >mdi-close</v-icon
          ></v-btn
        >
      </template>
    </v-snackbar>

    <v-overlay
      v-else
      :value="isVisibleOpenApp"
      z-index="1000"
      @click="onClickOverlay"
    >
      <v-snackbar
        class="floob-snackbar"
        v-model="isVisibleOpenApp"
        elevation="24"
        :timeout="-1"
        top
      >
        <v-avatar size="16" rounded
          ><v-img src="/favicon.ico"></v-img
        ></v-avatar>
        <span class="pl-2"
          ><b>Floob</b><br />iOS 앱에서만 이용이 가능합니다</span
        >
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="onClickOpenApp">앱으로 열기</v-btn>
        </template>
      </v-snackbar>
    </v-overlay>
  </div>
</template>

<script>
export default {
  props: {
    closable: Boolean,
    position: String
  },
  data() {
    return {
      isVisibleOpenApp: true
    };
  },
  methods: {
    onClickOverlay() {
      alert('');
    },
    onClickOpenApp() {
      if (this.$route.name === 'meal') {
        window.open(
          `https://floob.page.link/?link=https://floob.co.kr/meal/${this.$route.params.mealId}&isi=1545936680&ibi=com.floob.app.ios`
        );
      } else if (this.$route.name === 'story') {
        window.open(
          `https://floob.page.link/?link=https://floob.co.kr/story/${this.$route.params.userId}&isi=1545936680&ibi=com.floob.app.ios`
        );
      } else {
        window.open('https://floob.page.link/zRx8');
      }
    }
  }
};
</script>

<style></style>
