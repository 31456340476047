<template>
  <section>
    <v-dialog
      v-model="isVisible"
      fullscreen
      transition="slide-x-reverse-transition"
      persistent
      scrollable
    >
      <v-card
        :tile="$vuetify.breakpoint.xs"
        :class="{ 'floob-dialog-card': !$vuetify.breakpoint.xs }"
        height="800"
      >
        <v-card-title class="floob-toolbar-title">
          <v-btn
            text
            x-large
            @click="isVisible = false"
            :ripple="$store.state.isDisplayTouch"
            class="floob-toolbar-button-left"
          >
            <v-icon>mdi-chevron-left</v-icon></v-btn
          >
          <v-toolbar-title><h1>식당 선택</h1> </v-toolbar-title>
          <v-btn
            text
            x-large
            :ripple="$store.state.isDisplayTouch"
            :loading="isLoading"
            class="pr-0"
            disabled
          >
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-title class="px-0 pb-0">
          <v-container fluid class="py-0">
            <v-row no-gutters>
              <v-col>
                <v-list class="pb-0">
                  <v-list-item class="px-1"
                    ><v-list-item-content class="pt-0">
                      <v-text-field
                        v-model="searchStoreKeyword"
                        solo-inverted
                        flat
                        :autofocus="false"
                        hide-details
                        label="검색할 식당명을 입력해주십시오"
                        color="black"
                        :append-icon="
                          isLoading ? 'mdi-dots-horizontal' : 'mdi-magnify'
                        "
                        @click:append.stop.prevent="onClickSearchStores"
                        v-on:keyup.enter="onClickSearchStores"
                      >
                      </v-text-field>
                      <v-list-item-subtitle class="pt-2 pr-2 text-right">
                        <span class="grey--text"
                          >Powered by
                          <b class=" grey--text text--darken-1"
                            >kakao map</b
                          ></span
                        >
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-subheader v-if="searchStoreKeyword" class="pl-2"
                    ><b>{{
                      `검색 결과 (${searchedStores.length}건)`
                    }}</b></v-subheader
                  >
                  <v-subheader v-if="false" class="pl-2"
                    ><b>내 주변 식당 (Suggested)</b></v-subheader
                  >
                  <v-banner v-if="false">
                    위치 정보가 없습니다
                  </v-banner>
                </v-list>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>

        <v-card-text class="pa-0">
          <v-container fluid class="pt-0">
            <v-row no-gutters>
              <v-col>
                <!-- 식당 리스트 -->
                <v-list class="pt-0">
                  <v-list-item
                    v-for="store in searchedStores"
                    :key="store._id"
                    :ripple="$store.state.isDisplayTouch"
                    style="height:52px;"
                    class="mb-0"
                    @click="onClickStore(store)"
                  >
                    <v-list-item-content>
                      <v-list-item-title
                        ><b>{{ store.name }}</b
                        >&nbsp;<span style="color:grey; padding-left:5px;">{{
                          store.category
                        }}</span></v-list-item-title
                      >
                      <v-list-item-subtitle>
                        {{
                          `${
                            isNaN(store.distance)
                              ? ''
                              : `${store.distance.toLocaleString()}m` + ' | '
                          } ${store.address}`
                        }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
            <v-row v-if="searchedStores.length > 0 && !isNoMoreStores">
              <v-col>
                <v-btn
                  block
                  text
                  color="grey"
                  :loading="isLoading"
                  :ripple="$store.state.isDisplayTouch"
                  @click="onClickMoreStores"
                  >더보기</v-btn
                >
              </v-col>
            </v-row>
            <v-row v-if="!isLoading">
              <v-col v-if="isNoMoreStores">
                <v-btn
                  block
                  text
                  color="grey"
                  :ripple="$store.state.isDisplayTouch"
                  >더이상 조회 가능한 식당이 없습니다😞</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
      <floob-message-dialog :dialog.sync="messageDialog">
      </floob-message-dialog>
      <floob-browser-dialog :dialog.sync="browserDialog">
      </floob-browser-dialog>
    </v-dialog>
  </section>
</template>

<script>
import _ from 'lodash';
import semver from 'semver';

import wvc from '@/util/webViewController';

import floobMessageDialog from '@/components/messageDialog.vue';
import floobBrowserDialog from '@/components/browserDialog.vue';

export default {
  components: {
    floobMessageDialog,
    floobBrowserDialog
  },
  data() {
    return {
      // modules
      messageDialog: null,
      browserDialog: null,

      // controlls
      isVisible: false,
      isLoading: false,
      searchStoreKeyword: null,

      isNoStores: false,
      isNoMoreStores: false,
      selectedStore: null,

      // datas
      searchedStores: []
    };
  },
  methods: {
    init() {
      this.isLoading = false;
      this.searchStoreKeyword = null;
      this.searchedStores = [];
      this.isNoStores = false;
      this.isNoMoreStores = false;
      this.selectedStore = null;
    },
    onClickSearchStores() {
      this.isLoading = true;
      this.searchedStores = [];
      this.isNoStores = false;
      this.isNoMoreStores = false;
      this.selectedStore = null;

      setTimeout(() => {
        this.searchStores();
      }, 1000);
    },
    onClickMoreStores() {
      this.isLoading = true;
      setTimeout(() => {
        this.searchMoreStores();
      }, 1000);
    },
    searchMoreStores() {},
    searchStores(position) {
      var places = new window.kakao.maps.services.Places();

      let options = {
        category_group_code: 'FD6,CE7'
      };

      if (position) {
        options.location = new window.kakao.maps.LatLng(
          position.coords.latitude,
          position.coords.longitude
        );
      }

      places.keywordSearch(
        this.searchStoreKeyword.replace(/ /gi, ''),
        (results, status, pagination) => {
          if (status === window.kakao.maps.services.Status.OK) {
            console.log('keywordSearch', results, pagination);

            this.searchedStores = this.searchedStores.concat(
              _.orderBy(
                _.map(
                  _.filter(results, result => {
                    return (
                      result.category_group_code === 'FD6' ||
                      result.category_group_code === 'CE7'
                    );
                  }),
                  result => {
                    return {
                      name: result.place_name,
                      serviceProvider: 'kakao-map',
                      serviceProviderPlaceId: result.id,
                      serviceProviderPlaceUrl: result.place_url,
                      address: result.road_address_name,
                      category: _.last(result.category_name.split(' > ')),
                      location: {
                        latitude: parseFloat(result.y),
                        longitude: parseFloat(result.x)
                      },
                      distance: parseInt(result.distance)
                    };
                  }
                ),
                ['distance'],
                ['asc']
              )
            );

            this.searchMoreStores = () => {
              if (pagination.hasNextPage) {
                pagination.nextPage();
              } else {
                this.isNoMoreStores = true;
                this.isLoading = false;
              }
            };
          } else {
            this.isNoStores = true;
          }

          this.isLoading = false;
        },
        options
      );
    },
    onClickStore(store) {
      this.selectedStore = store;

      this.messageDialog.show({
        title: '선택하신 식당을 식사에 추가합니다',
        content: `${store.name}\n${store.address}`,
        isVisibleCancelButton: true,
        additionalButtons: [
          {
            text: '식당 자세히 보기',
            isPersistent: true,
            action: () => {
              this.showStorePopup(store);
            }
          },
          {
            text: '확인',
            action: () => {
              this.$emit('selected', this.selectedStore);
            }
          }
        ]
      });
    },
    showStorePopup(store) {
      if (store) {
        if (store.serviceProvider === 'kakao-map') {
          if (this.$cookies.get('webview')) {
            if (
              semver.valid(this.$cookies.get('version')) &&
              semver.gte(this.$cookies.get('version'), '1.4.0')
            ) {
              wvc.openUrl(
                `https://place.map.kakao.com/m/${store.serviceProviderPlaceId}`
              );
            } else {
              this.browserDialog.show(
                '식당',
                'Powered by Kakao Map',
                `https://place.map.kakao.com/m/${store.serviceProviderPlaceId}`
              );
            }
          } else {
            if (this.$store.state.isMobileDevice) {
              window.open(
                `https://place.map.kakao.com/m/${store.serviceProviderPlaceId}`
              );
            } else {
              window.open(
                `https://place.map.kakao.com/${store.serviceProviderPlaceId}`
              );
            }
          }
        }
      }
    }
  },
  computed: {
    currentLocation() {
      return this.$store.state.location;
    }
  },
  created() {
    this.$emit('update:module', {
      show: () => {
        this.init();
        this.isVisible = true;

        if (this.$cookies.get('webview')) {
          wvc.getCurrentLocation();
        }
      },
      hide: () => {
        this.isVisible = false;
      }
    });
  }
};
</script>

<style></style>
