<template>
  <section>
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xs"
      transition="slide-x-reverse-transition"
      max-width="400px"
      persistent
      scrollable
    >
      <v-card
        :tile="$vuetify.breakpoint.xs"
        :class="{ 'floob-dialog-card': !$vuetify.breakpoint.xs }"
        height="600"
      >
        <v-card-title class="floob-toolbar-title">
          <v-btn
            text
            x-large
            @click="onClickClose"
            :ripple="$store.state.isDisplayTouch"
            class="floob-toolbar-button-left"
          >
            <v-icon>mdi-chevron-left</v-icon></v-btn
          >
          <v-toolbar-title><h1>음식 선택</h1> </v-toolbar-title>
          <v-btn
            text
            x-large
            :ripple="$store.state.isDisplayTouch"
            :loading="isLoading"
            class="pr-0"
            @click="onClickConfirmSelectedFood"
            disabled
          >
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-title class="px-0 pb-0">
          <v-container fluid class="py-0">
            <v-row no-gutters>
              <v-col>
                <!-- 식사 음식 -->
                <v-list class="pb-0">
                  <v-list-item class="px-1"
                    ><v-list-item-content class="pt-0">
                      <v-text-field
                        v-model="searchFoodsKeyword"
                        solo-inverted
                        flat
                        :autofocus="false"
                        hide-details
                        label="검색할 음식명을 입력해주십시오"
                        color="black"
                      >
                      </v-text-field>
                    </v-list-item-content>
                  </v-list-item>

                  <v-subheader v-if="searchFoodsKeyword" class="pl-2"
                    ><b>{{
                      `검색 결과 (${selectableFoods.length}건)`
                    }}</b></v-subheader
                  >
                  <v-subheader v-else class="pl-2"
                    ><b>추천 음식 (Suggested)</b></v-subheader
                  >
                </v-list>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-container fluid class="pt-0">
            <v-row no-gutters>
              <v-col>
                <!-- 식사 음식 -->
                <v-list class="pt-0">
                  <v-list-item
                    v-for="food in selectableFoods"
                    :key="food._id"
                    @click="onClickFood(food)"
                    :ripple="$store.state.isDisplayTouch"
                    style="height:52px;"
                    class="mb-0"
                  >
                    <v-list-item-avatar size="32" rounded>
                      <v-img v-if="food.imageUrl" :src="food.imageUrl"></v-img>
                      <v-icon small v-else>mdi-circle-outline</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        `${food.name}`
                      }}</v-list-item-title>
                      <v-list-item-subtitle v-if="false">{{
                        food.calorie ? `${food.calorie}kcal` : ''
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item
                    v-if="searchFoodsKeyword && !isLoading"
                    class="pr-0"
                    :ripple="$store.state.isDisplayTouch"
                    @click="onClickCreateFood"
                  >
                    <v-list-item-avatar size="32" rounded>
                      <v-icon small>mdi-help-circle-outline</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-subtitle
                        >찾으시는 음식이 없으신가요?
                      </v-list-item-subtitle>
                      <v-list-item-title>
                        {{ `'${searchFoodsKeyword}' 음식 등록하기` }}
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn text :ripple="$store.state.isDisplayTouch" disabled
                        ><v-icon>mdi-chevron-right</v-icon></v-btn
                      >
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions> </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isVisibleSelectedFood" max-width="450">
      <v-card v-if="selectedFood" class="floob-dialog-card">
        <v-card-title>선택한 음식을 식사에 등록합니다</v-card-title>
        <v-card-subtitle class="pt-4 pb-0"
          >📊 섭취 열량을 기록하면 리포트에서 일별 합산 되어 차트에서 확인할 수
          있습니다
        </v-card-subtitle>
        <v-card-text class="pb-0">
          <v-container fluid>
            <v-row
              ><v-col class="pa-0">
                <v-list>
                  <v-list-item
                    :ripple="$store.state.isDisplayTouch"
                    style="height:52px;"
                    class="pa-0"
                  >
                    <v-list-item-avatar
                      v-if="selectedFood.imageUrl"
                      size="32"
                      rounded
                    >
                      <v-img :src="selectedFood.imageUrl"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        `${selectedFood.name}`
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-subheader class="px-0">섭취 열량 (칼로리)</v-subheader>
                  <v-list-item
                    :ripple="$store.state.isDisplayTouch"
                    class="pa-0"
                  >
                    <v-list-item-content class="pt-0">
                      <v-text-field
                        class="floob-food-calorie"
                        type="number"
                        v-model="selectedFoodCalorie"
                        dense
                        solo-inverted
                        flat
                        :autofocus="false"
                        hide-details
                        suffix="kcal"
                      >
                      </v-text-field>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-btn
                  x-small
                  depressed
                  min-width="30px"
                  class="mr-1 mb-2"
                  @click="onClickAddCalorie(1)"
                  >+1</v-btn
                >
                <v-btn
                  x-small
                  depressed
                  min-width="30px"
                  class="mr-1 mb-2"
                  @click="onClickAddCalorie(10)"
                  >+10</v-btn
                >
                <v-btn
                  x-small
                  depressed
                  min-width="30px"
                  class="mr-1 mb-2"
                  @click="onClickAddCalorie(50)"
                  >+50</v-btn
                >
                <v-btn
                  x-small
                  depressed
                  min-width="30px"
                  class="mr-1 mb-2"
                  @click="onClickAddCalorie(100)"
                  >+100</v-btn
                >
                <v-btn
                  x-small
                  depressed
                  min-width="30px"
                  class="mr-1 mb-2"
                  @click="onClickAddCalorie(200)"
                  >+200</v-btn
                >
                <v-btn
                  x-small
                  depressed
                  min-width="30px"
                  class="mr-1 mb-2"
                  @click="onClickAddCalorie(300)"
                  >+300</v-btn
                >
                <v-btn
                  x-small
                  depressed
                  min-width="50px"
                  class="mr-1 mb-2"
                  @click="selectedFoodCalorie = 0"
                  >섭취량 없음</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions
          ><v-btn
            text
            :ripple="$store.state.isDisplayTouch"
            @click="selectedFood = null"
            >취소</v-btn
          >
          <v-spacer></v-spacer
          ><v-btn
            text
            :ripple="$store.state.isDisplayTouch"
            @click="onClickConfirm"
            >확인</v-btn
          ></v-card-actions
        >
      </v-card>
    </v-dialog>
    <floob-message-snackbar :dialog.sync="messageSnackbar">
    </floob-message-snackbar>
    <floob-message-dialog :dialog.sync="messageDialog"> </floob-message-dialog>
  </section>
</template>

<script>
import _ from 'lodash';

import api from '@/api';

import floobMessageSnackbar from '@/components/messageSnackbar.vue';
import floobMessageDialog from '@/components/messageDialog.vue';

export default {
  components: {
    floobMessageSnackbar,
    floobMessageDialog
  },
  data() {
    return {
      // controlls
      isVisible: false,
      isLoading: false,

      // datas
      foods: [],
      selectedFood: null,
      selectedFoodCalorie: 0,
      selectableFoods: [],
      searchFoodsKeyword: null,

      // modules
      messageSnackbar: null,
      messageDialog: null,

      timer: null
    };
  },
  methods: {
    init() {
      this.isLoading = false;
      this.selectedFood = null;
      this.selectedFoodCalorie = 0;
      this.searchFoodsKeyword = null;
    },
    onClickClose() {
      this.selectableFoods = [];
      this.isVisible = false;
    },
    onClickFood(food) {
      this.selectedFood = food;
      if (food.calorie && !isNaN(food.calorie)) {
        this.selectedFoodCalorie = parseFloat(food.calorie);
      }
    },
    onClickConfirm() {
      if (this.selectedFood) {
        this.selectedFood.calorie = parseFloat(this.selectedFoodCalorie);
        this.$emit('selected', this.selectedFood);
        this.init();
      }
    },
    onClickAddCalorie(calorie) {
      if (calorie) {
        this.selectedFoodCalorie += calorie;
      }
    },
    onClickConfirmSelectedFood() {
      if (this.selectedFood) {
        this.$emit('selected', this.selectedFood);
      } else {
        this.messageSnackbar.show('음식을 선택하여 주십시오');
      }
    },
    updateSelectableFoods(keyword) {
      if (keyword && keyword.replace(/ /gi, '')) {
        api.food
          .getFoodsByKeyword(keyword)
          .then(foods => {
            this.selectableFoods = _.orderBy(foods, ['name'], ['asc']);
          })
          .finally(() => {
            this.timer = null;
            this.isLoading = false;
          });
      } else {
        api.food
          .getFoodsByRank(10)
          .then(foods => {
            this.selectableFoods = _.shuffle(foods);
          })
          .finally(() => {
            this.timer = null;
            this.isLoading = false;
          });
      }
    },
    onClickRequestFood() {
      if (this.$cookies.get('webview')) {
        this.browserDialog.show(
          '음식 추가 요청 하기',
          '',
          'https://docs.google.com/forms/d/e/1FAIpQLSdMqxFTMqyhCUejlxC_KkSRN_QnyWSp4P68qV5Aq3FAjKw9hg/viewform?embedded=true',
          null,
          {
            transition: 'slide-x-reverse'
          }
        );
      } else {
        window.open(
          `https://docs.google.com/forms/d/e/1FAIpQLSdMqxFTMqyhCUejlxC_KkSRN_QnyWSp4P68qV5Aq3FAjKw9hg/viewform?usp=sf_link`
        );
      }
    },
    onClickCreateFood() {
      let name = this.searchFoodsKeyword.replace(/ /gi, '');
      this.messageDialog.confirm(
        `찾으시는 음식을 신규로 등록하고 작성 중인 식사 기록에 추가합니다.\n\n추가 될 음식명\n<b style="font-size:1.2em;">${name}</b>\n\n<div style="color:#E57373;">등록 된 음식이 추후 음식 사전 관리 정책에 따라 자동으로 수정 되거나 중복 음식과 통합 될 수 있습니다.</div>`,
        '신규 음식 등록',
        () => {
          api.food.getFoodByName(name).then(food => {
            if (food) {
              console.log('getFoodByName', food);
              alert(`'${name}'은(는) 이미 등록 된 음식명입니다.`);
            } else {
              let food = {
                name: name
              };
              api.food.createFood(food).then(createdFood => {
                console.log('createFood', createdFood);
                this.onClickFood(createdFood);
              });
            }
          });
        }
      );
    }
  },
  watch: {
    searchFoodsKeyword(keyword) {
      this.isLoading = true;
      this.selectedFood = null;

      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.updateSelectableFoods(keyword);
        }, 500);
      } else {
        this.timer = setTimeout(() => {
          this.updateSelectableFoods(keyword);
        }, 500);
      }
    }
  },
  computed: {
    isVisibleSelectedFood() {
      return this.selectedFood ? true : false;
    }
  },
  created() {
    this.init();
    this.$emit('update:dialog', {
      show: () => {
        this.searchFoodsKeyword = '';
        api.food.getFoodsByRank(10).then(foods => {
          this.selectableFoods = _.shuffle(foods);
        });
        this.isVisible = true;
      },
      hide: () => {
        this.selectableFoods = [];
        this.isVisible = false;
      }
    });
  }
};
</script>

<style>
.floob-food-calorie input {
  text-align: right !important;
}
</style>
