<template>
  <section>
    <floob-header :title="'타임라인'"></floob-header>
    <floob-timeline :hideHeader="true" class="pb-8"></floob-timeline>
  </section>
</template>

<script>
import floobHeader from '@/components/header_v2.vue';

import floobTimeline from '@/components/pages/timeline.vue';

export default {
  components: {
    floobHeader,
    floobTimeline
  },
  data() {
    return {};
  },
  created() {}
};
</script>

<style></style>
