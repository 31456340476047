<template>
  <section>
    <v-dialog
      v-model="isVisible"
      :fullscreen="$vuetify.breakpoint.xs"
      :transition="
        $vuetify.breakpoint.xs
          ? 'slide-x-reverse-transition'
          : 'scale-transition'
      "
      max-width="600px"
      persistent
      scrollable
    >
      <v-card
        :tile="$vuetify.breakpoint.xs"
        :class="{ 'floob-dialog-card': !$vuetify.breakpoint.xs }"
        height="600"
      >
        <v-card-title class="floob-toolbar-title">
          <v-btn
            v-if="isCreated"
            text
            x-large
            @click="onClickClose"
            :ripple="$store.state.isDisplayTouch"
            class="floob-toolbar-button-left"
          >
            <v-icon v-if="$vuetify.breakpoint.xs">mdi-chevron-left</v-icon>
            <template v-else>닫기</template>
          </v-btn>
          <v-btn
            v-else
            text
            x-large
            @click="onClickClose"
            :ripple="$store.state.isDisplayTouch"
            class="pl-0"
          >
            취소
          </v-btn>

          <v-toolbar-title><h1>식사 기록</h1></v-toolbar-title>
          <v-btn
            text
            x-large
            :ripple="$store.state.isDisplayTouch"
            :loading="isLoading"
            @click="onClickCreate"
            class="pr-0"
            :disabled="isCreated"
          >
            {{ isCreated ? '등록됨' : '등록' }}
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-0">
          <floob-meal-form
            v-if="meal"
            :meal.sync="meal"
            :isEditable="true"
            :isDetailed.sync="isDetailed"
            v-on:updated="onUpdatedMeal"
          >
          </floob-meal-form>
        </v-card-text>
      </v-card>

      <floob-message-dialog :dialog.sync="messageDialog">
      </floob-message-dialog>
    </v-dialog>
  </section>
</template>

<script>
import moment from 'moment';

import api from '@/api';
import converter from '@/util/converter';

import floobMealForm from '@/components/mealForm.vue';
import floobMessageDialog from '@/components/messageDialog.vue';

export default {
  components: {
    floobMealForm,
    floobMessageDialog
  },
  data() {
    return {
      // controlls
      isVisible: false,
      isLoading: false,
      isDetailed: false,
      isCreated: false,

      // datas
      meal: null,

      // modules
      messageDialog: null
    };
  },
  methods: {
    init() {
      this.isLoading = false;
      this.isDetailed = false;
      this.isCreated = false;
      this.meal = {
        mealedDate: moment(),
        files: []
      };
    },
    onClickClose() {
      this.meal = null;
      this.isVisible = false;
    },
    onClickCreate() {
      if (this.meal) {
        this.isLoading = true;

        let fileData =
          this.meal.files &&
          this.meal.files.length > 0 &&
          this.meal.files[0].type === 'image' &&
          this.meal.files[0].data
            ? this.meal.files[0].data
            : null;

        setTimeout(() => {
          api.meal
            .createMeal(this.meal, converter.convertDataURItoBlob(fileData))
            .then(createdMeal => {
              if (createdMeal) {
                this.isCreated = true;
                this.meal = createdMeal;

                window.Floob._analytics.logEvent('create_meal_success', {
                  id: createdMeal._id
                });

                this.messageDialog.show({
                  content:
                    '식사 기록이 등록 되었습니다.\n식사에 대한 더 많은 정보를 등록 하시겠습니까?',
                  isVisibleCancelButton: false,
                  isVisibleOkButton: false,
                  additionalButtons: [
                    {
                      text: '다음에 하기',
                      action: () => {
                        this.$emit('created', createdMeal);
                        this.isVisible = false;
                      }
                    },
                    {
                      text: '확인',
                      action: () => {
                        this.isDetailed = true;
                        this.$emit('created', createdMeal);
                      }
                    }
                  ]
                });
              }
            })
            .finally(() => {
              this.isLoading = false;
            });
        }, 1000);
      }
    },
    onUpdatedMeal(updatedMeal) {
      this.meal = updatedMeal;
      this.$emit('updated', updatedMeal);
    }
  },
  created() {
    this.$emit('update:dialog', {
      show: () => {
        this.init();
        this.isVisible = true;

        window.Floob._analytics.logEvent('create_meal');
      },
      hide: () => {
        this.isVisible = false;
      },
      set: meal => {
        if (meal && moment(meal.mealedDate).isValid()) {
          this.meal.mealedDate = moment(meal.mealedDate);
        }

        if (
          meal &&
          meal.location &&
          meal.location.latitude &&
          meal.location.longitude
        ) {
          this.meal.location = meal.location;
        } else {
          this.meal.location = null;
        }
        this.meal.files = meal.files;
      }
    });
  }
};
</script>

<style></style>
