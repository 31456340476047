<template>
  <section>
    <v-dialog
      v-model="isVisible"
      fullscreen
      transition="slide-x-reverse-transition"
      persistent
      scrollable
    >
      <v-card tile>
        <v-card-title class="floob-toolbar-title">
          <v-btn
            text
            x-large
            @click="onClickClose"
            :ripple="$store.state.isDisplayTouch"
            class="floob-toolbar-button-left"
          >
            <v-icon v-if="$vuetify.breakpoint.xs">mdi-chevron-left</v-icon>
            <template v-else>닫기</template>
          </v-btn>

          <v-toolbar-title><h1>식사 기록</h1></v-toolbar-title>
          <v-btn
            text
            x-large
            :ripple="$store.state.isDisplayTouch"
            class="pr-0"
          >
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text class="pa-0">
          <v-container fluid class="py-0">
            <v-row>
              <v-col
                sm="6"
                offset-sm="3"
                md="4"
                offset-md="4"
                xl="2"
                offset-xl="5"
                class="pa-0"
              >
                <v-skeleton-loader
                  v-if="isLoading"
                  type="list-item-avatar-two-line, article"
                  class="pa-4"
                ></v-skeleton-loader>
                <template v-else>
                  <floob-meal-form
                    v-if="meal"
                    :meal.sync="meal"
                    :isEditable.sync="isEditable"
                    :isFocusReview.sync="isFocusReview"
                    :isFocusFood.sync="isFocusFood"
                    v-on:updated="onUpdatedMeal"
                    v-on:deleted="onDeletedMeal"
                  >
                  </floob-meal-form>
                </template>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import moment from 'moment';

import api from '@/api';

import floobMealForm from '@/components/mealForm.vue';

export default {
  components: {
    floobMealForm
  },
  data() {
    return {
      isVisible: false,
      isLoading: false,
      isEditable: false,
      isFocusReview: false,
      isFocusFood: false,
      meal: null
    };
  },
  methods: {
    init() {
      this.isFocusReview = false;
      this.isFocusFood = false;
      this.meal = {
        mealedDate: moment(),
        files: []
      };
    },
    onClickClose() {
      this.isVisible = false;
      this.$emit('closed');
    },
    onUpdatedMeal(updatedMeal) {
      this.meal = updatedMeal;
      this.$emit('updated', updatedMeal);
    },
    onDeletedMeal(deletedMeal) {
      this.$emit('deleted', deletedMeal);
    }
  },
  created() {
    this.$emit('update:dialog', {
      show: (meal, payload) => {
        this.init();

        this.isLoading = true;

        setTimeout(() => {
          api.meal
            .getMeal(meal._id)
            .then(meal => {
              if (meal) {
                this.meal = meal;

                if (this.meal.owner._id === this.$store.state.user._id) {
                  this.isEditable = true;
                }
                if (payload) {
                  if (payload.focus === 'review') {
                    this.isFocusReview = true;
                  } else if (payload.focus === 'food') {
                    this.isFocusFood = true;
                    if (payload.foodTags) {
                      this.meal.unsavedFoodTags = payload.foodTags;
                    }
                  }
                }
              }
            })
            .finally(() => {
              this.isLoading = false;
            });
        }, 500);

        this.isVisible = true;
      },
      hide: () => {
        this.meal = null;
        this.isVisible = false;
      }
    });
  }
};
</script>

<style></style>
