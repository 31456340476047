<template>
  <section>
    <v-bottom-navigation
      v-model="selectedMenu"
      flat
      app
      grow
      class="pb-5 px-3"
      height="80px"
      mandatory
    >
      <v-btn v-if="false" value="home" :ripple="$store.state.isDisplayTouch">
        <v-icon>mdi-home-variant</v-icon>
      </v-btn>

      <v-btn
        value="feed"
        :ripple="$store.state.isDisplayTouch"
        @click="onClickMenuItem('feed')"
      >
        <v-icon class="floob-bottom-nav-item">mdi-playlist-check</v-icon>
      </v-btn>

      <v-btn
        value="timeline"
        :ripple="$store.state.isDisplayTouch"
        @click="onClickMenuItem('timeline')"
      >
        <v-icon class="floob-bottom-nav-item">mdi-history</v-icon>
      </v-btn>

      <v-btn
        value="report"
        :ripple="$store.state.isDisplayTouch"
        @click="onClickMenuItem('report')"
      >
        <v-icon class="floob-bottom-nav-item">mdi-chart-line-variant</v-icon>
      </v-btn>

      <v-btn
        v-if="isLoggedIn"
        value="setting"
        :ripple="$store.state.isDisplayTouch"
        @click="onClickMenuItem('setting')"
      >
        <v-avatar :size="32" class="floob-bottom-nav-item">
          <v-img
            v-if="$store.state.user.profileImageUrl"
            :src="$store.state.user.profileImageUrl"
          >
          </v-img>
          <v-icon v-else>mdi-account-circle</v-icon>
        </v-avatar>
      </v-btn>
      <v-btn
        v-else
        value="setting"
        :ripple="$store.state.isDisplayTouch"
        @click="onClickMenuItem('setting')"
      >
        <v-icon class="floob-bottom-nav-item"
          >mdi-account-circle-outline</v-icon
        >
      </v-btn>
    </v-bottom-navigation>

    <floob-login-dialog :dialog.sync="loginDialog"></floob-login-dialog>
  </section>
</template>

<script>
import moment from 'moment';

import floobLoginDialog from '@/components/loginDialog.vue';

export default {
  components: {
    floobLoginDialog
  },
  props: {
    menu: {
      type: String,
      default: 'timeline'
    }
  },
  data() {
    return {
      // modules
      loginDialog: null,
      mealContentSelectionDialog: null
    };
  },
  methods: {
    onClickMenuItem(menu) {
      this.$emit('selected', menu);
      if (!this.isLoggedIn) {
        this.loginDialog.show();
      }
    }
  },
  computed: {
    selectedMenu: {
      get() {
        return this.menu;
      },
      set(value) {
        this.$store.dispatch('set', { key: 'menu', value: value });
        this.$emit('update:menu', value);

        this.$store.dispatch('setApps');
      }
    },
    isAdmin() {
      return (
        this.isLoggedIn &&
        this.$store.state.user.introduction &&
        this.$store.state.user.introduction.includes(`I am a floob`)
      );
    },
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    },
    sharedMenu() {
      return this.$store.state.menu;
    },
    isVisibleLoginDialog() {
      return this.$store.state.isVisibleLoginDialog;
    }
  },
  watch: {
    isLoggedIn(value) {
      if (value) {
        this.loginDialog.hide();
      }
    },
    sharedMenu(value) {
      if (value != this.menu) {
        this.selectedMenu = value;
      }
    },
    isVisibleLoginDialog(value) {
      if (value) {
        this.loginDialog.show();
        this.$store.dispatch('set', {
          key: 'isVisibleLoginDialog',
          value: false
        });
      }
    }
  },
  created() {
    if (
      this.$cookies.get('webview') &&
      this.$cookies.get('platform') === 'ios'
    ) {
      const createPost = file => {
        this.postCreateDialog.show({
          type: file.type,
          data: file.data,
          location: file.location,
          date:
            file.mealedDate && moment(file.mealedDate).isValid()
              ? moment(file.mealedDate)
              : null
        });
      };
      window.FloobWebView.createPost = createPost;
      window.Floob._webview.createPost = createPost;
    }
  }
};
</script>

<style>
.floob-bottom-nav-item {
  margin-top: -20px;
}

.floob-bottom-nav.v-bottom-navigation {
  box-shadow: unset !important;
  border-top: 1px solid #e1e1e1 !important;
}

.floob-bottom-nav.v-bottom-navigation .v-btn {
  min-width: unset !important;
  margin-top: -12px !important;
}
</style>
