import Vue from 'vue';
import VueObserveVisibility from 'vue-observe-visibility';

import App from '@/App.vue';
//import '@/registerServiceWorker';
import router from '@/router';
import store from '@/shared';
import vuetify from '@/plugins/vuetify';
import '@/plugins/cookies';

Vue.use(VueObserveVisibility);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
