<template>
  <section>
    <template v-if="$cookies.get('webview')"
      ><v-bottom-sheet v-if="$vuetify.breakpoint.xs" v-model="isVisible">
        <v-list v-if="comment">
          <v-subheader class="pl-6">{{ comment }} </v-subheader>
        </v-list>
        <floob-login></floob-login>
      </v-bottom-sheet>
      <v-dialog
        v-else
        v-model="isVisible"
        transition="dialog-bottom-transition"
        max-width="500px"
      >
        <floob-login></floob-login>
      </v-dialog>
    </template>
    <template v-else>
      <v-bottom-sheet v-model="isVisible">
        <v-list>
          <v-list-item class="pr-6">
            <v-list-item-subtitle>
              <div style="white-space: unset !important;">
                {{ `앱에서 ${comment}` }}
              </div>
              <div class="pt-1" style="font-size:0.8em;">
                <b>현재 iOS 앱에서만 이용이 가능합니다 🙏</b>
              </div>
            </v-list-item-subtitle>
            <v-list-item-action>
              <v-btn
                small
                depressed
                class="px-0"
                color="primary"
                @click="onClickOpenApp"
              >
                <b>앱 열기</b>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list></v-bottom-sheet
      >
    </template>
  </section>
</template>

<script>
import floobLogin from '@/components/login.vue';

export default {
  components: {
    floobLogin
  },
  props: {
    comment: null
  },
  data() {
    return {
      isVisible: false
    };
  },
  methods: {
    onClickOpenApp() {
      if (this.$route.name === 'meal') {
        window.open(
          `https://floob.page.link/?link=https://floob.co.kr/meal/${this.$route.params.mealId}&isi=1545936680&ibi=com.floob.app.ios`
        );
      } else if (this.$route.name === 'story') {
        window.open(
          `https://floob.page.link/?link=https://floob.co.kr/story/${this.$route.params.userId}&isi=1545936680&ibi=com.floob.app.ios`
        );
      } else {
        window.open('https://floob.page.link/zRx8');
      }
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    }
  },
  watch: {
    isLoggedIn(value) {
      if (value) {
        this.isVisible = false;
      }
    }
  },
  created() {
    this.$emit('update:dialog', {
      show: () => {
        this.isVisible = true;
      },
      hide: () => {
        this.isVisible = false;
      }
    });
  }
};
</script>

<style></style>
