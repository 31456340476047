<template>
  <section>
    <floob-story-dialog :dialog.sync="storyDialog" v-on:closed="onCloseStory">
    </floob-story-dialog>

    <floob-app-open-overlay
      v-if="$store.state.isMobileDevice"
      :closable="true"
    ></floob-app-open-overlay>
  </section>
</template>

<script>
import floobStoryDialog from '@/components/storyDialog.vue';

import floobAppOpenOverlay from '@/components/appOpenOverlay.vue';

export default {
  components: {
    floobStoryDialog,
    floobAppOpenOverlay
  },
  data() {
    return {
      storyDialog: null
    };
  },
  methods: {
    showProfile(userId) {
      this.storyDialog.show(userId);
    },
    onCloseStory() {
      this.$router.push({ name: 'app' });
    }
  },
  created() {},
  mounted() {
    if (this.$route.name === 'story' && this.$route.params.userId) {
      this.showProfile(this.$route.params.userId);
    }
  }
};
</script>

<style></style>
