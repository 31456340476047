<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card flat tile>
          <v-card-title class="px-2">
            <v-text-field
              v-model="searchedKeyword"
              append-icon="mdi-magnify"
              label="Search"
              solo-inverted
              flat
              hide-details
              color="black"
              dense
            ></v-text-field>
          </v-card-title>

          <v-data-table
            :headers="headers"
            :items="users"
            :items-per-page="10"
            class="elevation-0"
            :mobile-breakpoint="10"
            :search="searchedKeyword"
          >
            <template v-slot:item.profileImageUrl="{ item }">
              <div class="my-2">
                <v-avatar size="36">
                  <img
                    v-if="item.profileImageUrl"
                    :src="item.profileImageUrl"
                  />
                  <v-icon color="grey lighten-1" v-else x-large
                    >mdi-account-circle</v-icon
                  >
                </v-avatar>
              </div>
            </template>
            <template v-slot:item.nickname="{ item }">
              <div>
                <span>
                  <v-icon
                    x-small
                    :color="
                      item.sessions && item.sessions.length > 0
                        ? 'green lighten-2'
                        : 'grey'
                    "
                    >mdi-circle
                  </v-icon>
                  {{ item.nickname }}
                </span>
              </div>
            </template>
            <template v-slot:item.status="{ item }">
              <div>
                <v-chip
                  label
                  x-small
                  :color="convertStatus(item.status).color"
                  dark
                >
                  <b>{{ convertStatus(item.status).text }}</b>
                </v-chip>
              </div>
            </template>

            <template v-slot:item.auth="{ item }">
              <div class="my-2">
                <v-avatar v-if="item.auth.apple" size="18" class="mr-1">
                  <img
                    src="https://www.pngkey.com/png/full/901-9019597_apple-logo-riot-chat-logo.png"
                  />
                </v-avatar>
                <v-avatar v-if="item.auth.kakao" size="18" class="mr-1">
                  <img src="https://image.floob.co.kr/kakao-login-icon.png" />
                </v-avatar>
                <v-avatar v-if="item.auth.instagram" size="18" class="mr-1">
                  <img
                    src="https://toppng.com/uploads/preview/instagram-logo-circle-11549679754rhbcorxntv.png"
                  />
                </v-avatar>
              </div>
            </template>
            <template v-slot:item.lastSession="{ item }">
              <div>
                {{
                  `${
                    item.lastSession && item.lastSession.expireDate
                      ? `${convertShortDateString(
                          item.lastSession.expireDate
                        )} expired`
                      : ''
                  }`
                }}
              </div>
            </template>
            <template v-slot:item.createdDate="{ item }">
              <div>
                {{ convertShortDateString(item.createdDate) }}
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment';
import api from '@/api';

export default {
  data() {
    return {
      searchedKeyword: '',
      headers: [
        {
          text: 'Profile',
          align: 'center',
          sortable: false,
          value: 'profileImageUrl'
        },
        {
          text: 'Id',
          align: 'start',
          sortable: false,
          value: 'nickname'
        },
        { text: 'Status', value: 'status' },
        { text: 'Name', value: 'name' },
        { text: 'Authentication', value: 'auth' },
        { text: 'Last Session', value: 'lastSession' },
        { text: 'Meals', value: 'mealCount' },
        { text: 'Created Date', value: 'createdDate' }
      ],
      users: []
    };
  },
  methods: {
    convertShortDateString(date) {
      return moment(date).format('YYYY-MM-DD HH:mm');
    },
    convertStatus(status) {
      if (status === 'normal') {
        return { text: '정상', color: 'green' };
      } else if (status === 'withdrawn') {
        return { text: '탈퇴', color: 'grey' };
      } else {
        return { text: status, color: 'black' };
      }
    }
  },
  created() {
    api.user.getUsersByAll().then(users => {
      console.log('getUsersByAll', users);
      this.users = users;
    });
  }
};
</script>

<style></style>
